/*global window*/
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import Config from "../../config";

const displayMapping = {
      "main": {
        "label": "MAIN SW",
        "title": "Main Appliance Software Version"
      },
      "uim": {
        "label": "UIM SW",
        "title": "User Interface Module Software Version"
      },
      "uim_slave": {
        "label": "UIM SLAVE SW",
        "title": "Secondary User Interface Module Software Version"
      },
      "wash_pump": {
        "label": "WASH PUMP SW",
        "title": "Wash Pump Drive Software Version"
      },
      "rs485": {
        "label": "RS485 SW",
        "title": "Software Version for RS485 communication"
      },
      "conf": {
        "label": "CONFIG SW",
        "title": "Appliance Model Configuration File Version"
      },
      "cfg": {
        "label": "CONFIG SW",
        "title": "Appliance Model Configuration File Version"
      },

      "hv": {
        "label": "HV SW",
        "title": "High Voltage Software Version"
      },
      "hiv": {
        "label": "HIV SW",
        "title": "High Voltage Software Version"
      },
      "lov": {
        "label": "LOV SW",
        "title": "Low Voltage Software Version"
      },
      "disp": {
        "label": "DISP SW",
        "title": "External Dispenser Software Version"
      },
      "lv": {
        "label": "LV SW",
        "title": "Low Voltage Software Version"
      },
      "ccm": {
        "label": "CCM SW",
        "title": "Central Control Module Software Version"
      },
      "lcd_mem": {
        "label": "LCD Mem SW",
        "title": "Memory file for LCD display on the UIM"
      }
    },
    titleMapping = {
      "ONLINE": "Connected Online",
      "OFFLINE": "Connected Offline",
      "READY": "Connected Ready",
      "CAPABLE": "Connected Capable"
    },
    AppAttribute = ReactClass({
      displayName: "AppAttribute",
      render() {
        const {label, value, className = "", title=""} = this.props,
            classs = `row subrow ${className}`,
            tooltip = displayMapping[label] ? displayMapping[label]["title"] : title,
            dispText = displayMapping[label] ? displayMapping[label]["label"] : label;

        return (<div className={classs}>
          <label title={tooltip}>
            {dispText}
          </label>
          <span>{value || "N/A"}</span>
        </div>);
      }
    });

export default ReactClass({
  displayName: "ApplianceInfoComponent",
  getInitialState() {
    return {
    };
  },
  gotoLogView() {
    const {application, appliance: {deviceId, serial}} = this.props;
    if(deviceId)
      application.route(`/appliance/${deviceId}/logs`);
    else if(serial)
      application.route(`/appliance/logs/${serial}`);
  },
  gotoGraphView() {
    const {application, appliance} = this.props;
    application.route("/appliance/" + appliance.deviceId + "/connected/graph");
  },
  render() {
    const {appliance:
            {serial = "", model = "", versions = [], connectedStatus = "", deviceId = "", type= ""},
            noOfVersionsInCol = 2,
            graphType = ""
          } = this.props,
        versionsRows = (versions
            ? Object.keys(versions).map(key => {
              return (<AppAttribute key={key} label={key} value={versions[key]} />);
            })
            : []),
        classs = "line",
        viewConnectedBtn = <button className="inline primary btnData"
            onClick={() => {
              const hash = window.location.href || '';
              if(hash.indexOf('/logs') > -1) {
                this.gotoGraphView();
              } else {
                this.gotoLogView();
              }
            }} >View {(window.location.href || '').indexOf('/logs') > -1 ? "Connected " : ""}Data</button>;

    return (<div className="Appliance-info row">
      <div className={(versionsRows.length >= 5 ? "col_3 line": classs)}>
        <div className="row title">
          <h2>APPLIANCE INFO</h2>
          {(!connectedStatus || connectedStatus === "UNAVAILABLE"
              ? null
              : <i className={`icon icon-wifi_icon conn-status ${connectedStatus}`} title={titleMapping[connectedStatus]}></i>)}
        </div>
        {/* Hide View Data Button */}
        {/* {type && ((deviceId && connectedStatus !== "UNAVAILABLE" && connectedStatus !== "READY" && connectedStatus !== "CAPABLE" && !graphType )
        || (deviceId && graphType === "non-connected" && connectedStatus === "ONLINE"))
          ? <div className="row">
            {viewConnectedBtn}
          </div>
          : null} */}
      </div>
      <div className={classs}>
        <AppAttribute label="MODEL NO" value= {model} />
        <AppAttribute label="SERIAL NO" value= {serial} />
      </div>
      {(versionsRows
        ? (versionsRows.map((key, i) => {
          if(i % noOfVersionsInCol === 0) {
            return (<div className={classs} key={"Appliance_Info_"+i}>
                {versionsRows.slice(i, i + noOfVersionsInCol)}
            </div>);
          }
        }))
        : null)}
    </div>);
  }
});
