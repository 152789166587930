import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import Config from "../../config";
import AnswerAdviser from "../../modules/answer-adviser";
import Util from "../../components/util";
 
export default ReactClass({
  displayName: "AnswerAdvisorFooter",
  render() {
    const imgBasePath = `${Config.baseDir}modules/answer-adviser/images/answer_advisor_logo.png`;
    let param = AnswerAdviser.getCRMTicket();
		const {CRM_TICKET_ID} = param,
				crmTicketId = CRM_TICKET_ID ? `&CRM_TICKET_ID=${CRM_TICKET_ID}` : "";
    return (
      <div className="sidebar-footer" onClick={() => Util.trackAAEvent(Config.answerAdvisorLink)}>
          <a href={Config.answerAdvisorLink}
              target="_blank">
            <img src={imgBasePath} alt="answer-advisor"/>
          </a>
        </div>
    );
  }
});
