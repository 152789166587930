const isDescendant = (parent, child) => {
  let node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

const isDescendantOrSelf = (parent, child) => {
  if (child == parent || isDescendant(parent, child)) {
    return true;
  }
  return false;
};

export default {
  makeDialog(panelEl, buttonEl, callback) {

    const listener = function (e) {
      if (!isDescendantOrSelf(panelEl, e.target) && !isDescendantOrSelf(buttonEl, e.target)) {
        e.stopPropagation();
        e.preventDefault();
        window.document.removeEventListener("mousedown", listener, true);
        callback();
        return false;
      }
    };

    window.document.addEventListener("mousedown", listener, true);

    return {
      hide() {

      }
    };
  }
};