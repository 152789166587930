/* global location setTimeout console */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import {AuthService} from "../auth";
import Config from "../../config";
import Service from "./Service";
import Navbar from "./NavBar";
import * as _ from "lodash";
import Util from "../../components/util";

const UploadFile = ReactClass({
  displayName: "UploadFile",
  getInitialState() {
    return {
      isUploading: false,
      file: null
    };
  },
  uploadFile(e) {
    const {files} = this.state;
    e.preventDefault();
    const {application: app} = this.props,
        {notifications} = app;
    app.showLoading(true);
    Service.uploadMultipleFiles(files).then(resp => {
      app.showLoading(false);

      notifications.success(files.length > 1
          ? `${files.length} configuration files uploaded successfully.`
          : resp.message);

      this.refs.uploadform.reset();
      this.setState({isUploading: false, files: ""});
    }).catch(errRes => {
      errRes.json().then(err => {
        app.showLoading(false);
        notifications.error(err.message + " " + err.cause.message);
        this.setState({isUploading: false, files: ""});
        this.refs.files.value = "";
      });
    });
  },
  selectFile(e) {
    this.setState({
      isUploading: true,
      files: e.target.files
    });
  },
  render() {
    const {files} = this.state;
    return (
      <form ref="uploadform" action="#">
        <div className="upload-file">
          <h3 className="title">Upload Configuration File</h3>
          <div className="input-file-container">
            <input ref="files" className="input-file"
                id="my-file" type="file" name="files" accept=".json"
                size="50" onChange={this.selectFile} multiple/>
            <label tabIndex="0" htmlFor="my-file"
                className="input-file-trigger">Select file(s)...</label>
            {(files && files.length
                ? <span className="file-name">{files.length} files. {_.map(files, "name").join(", ")}</span>
                : null
            )}
          </div>
          <button className="inline primary upload-btn"
            onClick={e => this.uploadFile(e)} disabled={!files || !this.state.isUploading || !files.length}>
            <i className="icon icon-upload-cloud"></i>
            Upload
          </button>
        </div>
      </form>
    );
  }
});

const ExportFile = ReactClass({
  displayName: "ExportFile",
  getInitialState() {
    return {
      exportUrl: ""
    };
  },
  componentWillMount() {
    // This code for getting Latest token from Adal and
    // Create exportUrl and bind latest token to that url.
    const promiseToken = AuthService.getAPIToken(),
        {apiServerUrl, apiUrl} = Config;
    promiseToken.then(authRes => {
      const exportUrl = `${apiServerUrl+apiUrl}/configexport?token=Bearer ${authRes.accessToken}`;
      this.setState({exportUrl});
    });
  },
  notifyExport() {
    Util.trackPageWidgetEvent("PAGE_VISIT", "Config Export", "");
    setTimeout(() => this.props.onExport, 500);
  },
  render() {
    const {exportUrl} = this.state;
    return (
      <div className="export-file">
        <h3 className="title">Export Configuration</h3>
          <a className="export-link" href={exportUrl} download onClick={this.notifyExport} disabled={!exportUrl}>
            <i className="icon icon-external-link"></i>Export
          </a>
      </div>
    );
  }
});

export default ReactClass({
  displayName: "AdminConfigView",
  notifyExport() {
    this.props.application.notifications.success("Export started. Check your system downloads folder.");
  },
  render() {
    return (
      <div className="view admin-config-view">
      <Navbar {...this.props}/>
        <div className="main-content">
          <div className="section">
            <UploadFile application={this.props.application}/>
            <ExportFile onExport={this.notifyExport}/>
          </div>
        </div>
      </div>
    );
  }
});

