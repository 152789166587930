"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _createReactClass = require("create-react-class");

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _masonryLayout = require("masonry-layout");

var _masonryLayout2 = _interopRequireDefault(_masonryLayout);

var _WidgetWrapper = require("./WidgetWrapper");

var _WidgetWrapper2 = _interopRequireDefault(_WidgetWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * K2 Inc. Confidential
 *
 * Copyright © 2015 K2 Inc. All rights reserved.
 *
 * Notice: All information contained herein is, and remains the property of K2 Inc. and its
 * suppliers, if any. The intellectual and technical concepts contained herein are proprietary to K2
 * Inc. and/or its suppliers, and may be covered by U.S. and foreign patents or patents in process,
 * and are protected by trade secret or copyright law. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from K2 Inc.
 */

var createWidgetProps = function createWidgetProps(widgetConfig) {
  for (var _len = arguments.length, exclude = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    exclude[_key - 1] = arguments[_key];
  }

  return Object.keys(widgetConfig).filter(function (k) {
    return exclude.indexOf(k) === -1;
  }).reduce(function (props, key) {
    props[key] = widgetConfig[key];
    return props;
  }, {});
};

/**
 * A top level layout component to lay out the widgets. Takes in an array of widget configuration
 * objects to render widgets
 */
var Layout = (0, _createReactClass2.default)({
  displayName: "Layout",
  masonry: null,
  ready: false,
  propTypes: {
    /**
     * An array of widget descriptors for this dashboard. Each item to contain following
     * {
     *    // Required
     *    id: "memory-gauge",     // Unique ID of the widget in this dashboard
     *    type: "widgets.gauge",  // The name with which the widget class was registered in Registry
     *
     *    // Optional
     *    title: "Server Memory", // Title for this widget (Only applicable if using WidgetPanel)
     *    channel: "/server/memory/Mb", // The DataLayer channel to subscribe to
     *    cols: 2,                      // Number of columns to occupy in dashboard (default is 1)
     *
     *    // Widget specific properties (Will be available in this.props)
     *    value: 1,
     *    maxValue: 2000000,
     *    width: 157,
     *    height: 80
     * }
     */
    widgets: _propTypes2.default.array.isRequired,
    columnWidth: _propTypes2.default.number,
    // gutterWidth: PropTypes.number,
    usePercentageWidth: _propTypes2.default.bool,
    DataLayer: _propTypes2.default.object,
    context: _propTypes2.default.object
  },

  getInitialState: function getInitialState() {
    return {};
  },

  render: function render() {
    var _this = this;

    var _props = this.props,
        DataLayer = _props.DataLayer,
        context = _props.context,
        widgets = this.props.widgets.map(function (widget) {
      var widgetProps = createWidgetProps(widget, "type");
      widgetProps.DataLayer = DataLayer;
      return _react2.default.createElement(_WidgetWrapper2.default, { onWidgetAdd: _this.widgetAdded,
        layout: _this.layoutContext,
        key: widget.id,
        cols: widget.cols || 1,
        type: widget.type,
        context: context,
        config: widgetProps });
    });


    return _react2.default.createElement(
      "div",
      { className: "dashboard-layout" },
      _react2.default.createElement(
        "div",
        { className: "widgets-container" },
        _react2.default.createElement("div", { className: "default-column" }),
        widgets
      )
    );
  },

  componentWillMount: function componentWillMount() {
    var _this2 = this;

    this.layoutContext = {
      layout: function layout() {
        _this2.layout();
      }
    };
  },

  componentDidMount: function componentDidMount() {
    this.initializeLayout();
    this.ready = true;
  },

  componentDidUpdate: function componentDidUpdate() {
    this.masonry.layout();
  },

  /**
   * Initializes the layout engine
   */
  initializeLayout: function initializeLayout() {
    var elem = _reactDom2.default.findDOMNode(this);
    var _props2 = this.props,
        _props2$horizontalOrd = _props2.horizontalOrder,
        horizontalOrder = _props2$horizontalOrd === undefined ? true : _props2$horizontalOrd,
        _props2$gutterWidth = _props2.gutterWidth,
        gutterWidth = _props2$gutterWidth === undefined ? 0 : _props2$gutterWidth,
        _props2$usePercentage = _props2.usePercentageWidth,
        usePercentageWidth = _props2$usePercentage === undefined ? false : _props2$usePercentage;

    this.masonry = new _masonryLayout2.default(elem.firstChild, {
      // initLayout: false,
      initLayout: true,
      itemSelector: ".widget-wrapper",
      originLeft: true,
      originTop: true,
      fitWidth: false,
      horizontalOrder: horizontalOrder,
      gutter: gutterWidth,
      percentPosition: usePercentageWidth,
      columnWidth: ".default-column"
    });
    // this.masonry.layout(); // since initLayout is false
    /*
    this.masonry.on("layoutComplete", (items) => {
    });
    */
  },

  /**
   * This is a callback to tell the dashboard that a widget as added. This initiates the layout of
   * widgets
   * @param {Object} w the Widget that was added
   */
  widgetAdded: function widgetAdded(w) {
    if (this.ready) {
      this.masonry.appended(_reactDom2.default.findDOMNode(w));
    }
  },

  layout: function layout() {
    if (this.ready) {
      this.masonry.layout();
    }
  }
});

exports.default = Layout;