/* console Promise */
import View from "./View";

export default [
  {
    path: "/appliance/:selectedDeviceId/consumer-apps-logs",
    controller: context => {
      return {
        component: View,
        context
      };
    }
  }
];
