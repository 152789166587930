import config from "../../config"

export default{
    getApplianceTypeConfig(){
        if(!config.ConfigData || !config.ConfigData.applianceType){
            return {};
        }
        return config.ConfigData.applianceType;
    },

    getApplianceFamilyConfig(){
        if(!config.ConfigData || !config.ConfigData.applianceFamily){
            return {
                "0":"Unknown"
            };
        }
        return config.ConfigData.applianceFamily;
    }
}