const transforms = {
      bool: config => {
        const {options: {key, truthy = "On", falsy = "Off"}, default: defaultValue = "Unknown"} = config;
        return data => data[key] === true ? truthy : (data[key] === false ? falsy : defaultValue);
      },
      oneof: config => {
        const {options = []} = config;
        return data => {
          let renderedValue = config.default;
          options.some(opt => {
            const {key, value = true, label} = opt;
            if(data[key] === value) {
              renderedValue = label;
              return true;
            }
            return false;
          });
          return renderedValue;
        };
      },
      collect: config => {
        const {options = []} = config;
        return data => {
          return options.reduce((acc, opt) => {
            const {key, value = true, label} = opt;
            if(data[key] === value) {
              acc.push(label);
            }
            return acc;
          }, []);
        };
      },
      range: (_ => {
        const getRangeLabel = (value, ranges) => {
          let label = value;
          ranges.some(range => {
            if(value >= range.min && value <= range.max) {
              label = range.label;
              return true;
            }
            return false;
          });
          return label;
        };
        return config => {
          const {options: {key, ranges: rangeDefs}} = config,
              ranges = [];
          Object.keys(rangeDefs).forEach(rangeNotation => {
            const [strMin, strMax] = rangeNotation.split(","),
                rangeValue = rangeDefs[rangeNotation],
                min = Number(strMin),
                max = Number(strMax);
            ranges.push({min, max, label: rangeValue});
          });
          return data => {
            const value = Number(data[key]);
            return getRangeLabel(value, ranges);
          };
        };
      })(),
      func: config => {
        const {options: {body = "return ''"}} = config;
        return new Function("data", body);
      },
      append: config => {
        const {options: {key, text = ""}} = config;
        return data => `${data[key]}${text}`;
      }
    },
    createTransform = defn => {
      const {name} = defn,
          transformFactory = transforms[name];
      if(!transformFactory) {
        throw new Error(`Data transformer not found ${name}`);
      }
      let transformer = transformFactory(defn);
      return function(data, ...args) {
        return transformer.call(defn, data, ...args);
      };
    };

export {
  createTransform
  // transforms
};
