/* global console, Image */
import React from "react";
import ReactClass from "create-react-class";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import ConsumerAppInfoPanel from "./Component";

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "ConsumerAppInfoWidget",
  getInitialState: function() {
    return {
      data: {
        appInfo: {},
        appliance: {}
      }
    };
  },
  render: function () {
    const {Header, Content} = WidgetPanel,
        {data: {appInfo, appliance}} = this.state;
    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{this.props.title}</span>
        </Header>
        <Content>
          <ConsumerAppInfoPanel appInfo={appInfo} appliance={appliance}/>
        </Content>
      </WidgetPanel>
    );
  },
  onData: function(data, channel) {
    this.setState({
      channel: channel,
      data: data
    });
  }
}));

const Info = {
  id: "appliance-dashboard:consumerAppInfo",
  name: "Consumer App Info",
  type: Widget,
  description: "Show Consumer App Info",
  icon: "",
  tags: "consumer app info"
};

export default {
  Info,
  Widget
};
