/* global console setTimeout */
import React from "react";
import ReactClass from "create-react-class";
import * as _ from "lodash";

export default ReactClass({
  displayName: "EventPropertySelector",
  getInitialState() {
    return {
      plotLocationSelection: "",
      relatedAttributes: [],
      series: [],
      relatedSelections: {}
    };
  },

  setPlotLocation(plotLocationSelection) {
    this.setState({
      plotLocationSelection
    });
  },

  handlePlotLocationChange(propId) {
    return evt => {
      this.setState({
        [propId]: evt.target.value
      });
    };
  },

  setRelatedSelection(propId, mode) {
    const {relatedSelections} = this.state,
        propSelection = relatedSelections[propId],
        checked = !(propSelection ? propSelection.selected : false);
    this.handleRelatedSelectionChange(propId, mode)({
      target: {
        checked
      }
    });
  },

  handleRelatedSelectionChange(propId, mode) {
    return evt => {
      const {relatedSelections} = this.state;

      if (evt.target.checked && !mode) {
        mode = "same-line";
      }

      let cfg = Object.assign(
        {},
        relatedSelections[propId] || {},
        {selected: evt.target.checked, mode}
      );
      this.setState({
        relatedSelections: Object.assign({}, relatedSelections, {
          [propId]: cfg
        })
      });
    };
  },

  componentDidMount() {
    const {prop, model, series = []} = this.props,
        relAttr = prop.relatedAttributes || [];

    if (relAttr.length) {
      const models = model.byValueField(...relAttr);
      this.setState({
        relatedAttributes: models,
        relatedSelections: {}
      });
    }

    this.setState({
      series,
      plotLocationSelection: series.length ? String(series[0].id) : "x-axis"
    });
  },

  getSelections() {
    const {relatedSelections, plotLocationSelection, series} = this.state,
        {panelIdx} = _.find(series, {id: plotLocationSelection}) || {};
    return {relatedSelections, plotLocationSelection, panelIdx};
  },

  render: function() {
    const {relatedAttributes, relatedSelections, plotLocationSelection, series} = this.state;
    return (
      <section className="type-selector type-enum">
        <ul className="plot-location">
          {series.map((s, index) => (
            <li key={index}>
              <input type="radio" value={String(s.id)}
                     checked={plotLocationSelection === String(s.id)}
                     onChange={this.handlePlotLocationChange("plotLocationSelection")} />
              <span onClick={() => this.setPlotLocation(String(s.id))}>
                Plot on "{s.displayName}"
              </span>
            </li>
          ))}
          <li>
            <input type="radio" value={"x-axis"}
                   checked={plotLocationSelection === "x-axis"}
                   onChange={this.handlePlotLocationChange("plotLocationSelection")} />
            <span onClick={() => this.setPlotLocation("x-axis")}>
              Plot on X-Axis
            </span>
          </li>
        </ul>

        {relatedAttributes.length ? (
          <div className="related-attributes">
            <h3>Related Attributes</h3>
            <ul>
              {relatedAttributes.map((attr, index) => (
                <li key={index}>
                  <input type="checkbox"
                         value={relatedSelections[attr.id] && relatedSelections[attr.id].selected}
                         onChange={this.handleRelatedSelectionChange(attr.id)} />
                  <span onClick={() => this.setRelatedSelection(attr.id)}>
                    {attr.displayName}
                  </span>

                  {_.includes(["Enum", "Bool"], attr.type) && (
                      <ul className="inline-radio-group">
                        <li>
                          <input type="radio" value={"same-line"}
                                 checked={relatedSelections[attr.id] && relatedSelections[attr.id].mode === "same-line"}
                                 onChange={this.handleRelatedSelectionChange(attr.id, "same-line")} />
                          <span onClick={() => this.setRelatedSelection(attr.id, "same-line")}>
                            On Same Line
                          </span>
                        </li>
                        <li>
                          <input type="radio" value={"x-axis"}
                                 checked={relatedSelections[attr.id] && relatedSelections[attr.id].mode === "x-axis"}
                                 onChange={this.handleRelatedSelectionChange(attr.id, "x-axis")} />
                          <span onClick={() => this.setRelatedSelection(attr.id, "x-axis")}>
                            On X-Axis
                          </span>
                        </li>
                      </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ): ""}
      </section>
    );
  }
});
