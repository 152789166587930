/* global console setTimeout AmCharts history Promise */
import React from "react";
import ReactClass from "create-react-class";
import * as _ from "lodash";

import GraphService, {ResultSet} from "./Service";
import Graph from "./Graph";
import StatsPanel from "./StatsPanel";
import PropertySelector from "./PropertySelector";
import GraphLegend from "./GraphLegend";
import TimeRangeSelector from "./TimeRangeSelector";
import LogSelector from "./LogSelector";
import ApplianceNav from "./ApplianceNav";
import {CSVLink} from "../common/react-csv";

import AI from "../../appInsights";
import PropertyModel from "./PropertyModel";
import config from "../../config";
import util from "../../components/util";

const fltrs = {
  preFetchLoad: {preFetch: "load"},
  preFetchRender: {preFetch: "render"},
  Enums: {type: "Enum"},
  Bools: {type: "Bool"}
};

/*
function setOpacity(graph, opacity) {
  const container = graph.chart.div,
      className = "amcharts-graph-" + graph.id,
      items = container.getElementsByClassName(className);
  if (undefined === items) {
    return;
  }

  for (let x in items) {
    if ("object" !== typeof items[x]) {
      continue;
    }

    let path = items[x].getElementsByTagName("path")[0];
    if (undefined !== path) {
      path.style.strokeOpacity = path.style.fillOpacity = opacity;
    }

    // set bullet opacity
    let bullets = items[x].getElementsByClassName("amcharts-graph-bullet");
    for (let y in bullets) {
      if ("object" !== typeof bullets[y])
        continue;
      bullets[y].style.fillOpacity = bullets[y].style.strokeOpacity = opacity;
      for(let i=0, labels = bullets[y].getElementsByTagName('text'); i < labels.length; i++) {
        labels[i].style.opacity = opacity;
      }
    }

    // set label opacity
    let labels = items[x].getElementsByClassName("amcharts-graph-label");
    for (let y in labels) {
      if ("object" !== typeof labels[y])
        continue;
      labels[y].style.opacity = opacity == 1 ? 1 : 0;
    }
  }
}
*/

export default ReactClass({
  displayName: "GraphDataView",
  chartRef: null,
  getInitialState() {
    return {
      graphConfig: GraphService.defaultConnectedChartConfig(),
      graphData: null,
      fieldSelection: [],
      hoverRecord: null,
      timeSelection: {},
      prop2Panel: {},
      fullscreen: false,
      isLhsPanelOpen: true,
      legendDragFrom: -1,
      timeRangeFlag: 1, // it is used to avoid setting default time range while selecting custom time range before graphing.
      propModel: null,
      selectedGraphPanel:-1,//show all
      excelGraphData: [],
      timeRangeIndexValue: "",
      customInitTimeRangeFilterSelection: {},
      showPopUp: false,
      indexFlag: 1,
      graphPropertyColors: GraphService.defaultGraphPropertyColors(),
      tempStateFieldSelection: [],
      deviceTimezone:""
    };
  },

  initView({currentAppliance}) {
    const {mode, route: {params: {applianceType: appType}}} = this.props;
    let {type} = currentAppliance;

    if(!type)
      type = appType;
    const URLparams = new URLSearchParams(window.location.href);
    const fieldSelection = URLparams.get('fieldSelection') ? URLparams.get('fieldSelection').split(",") : null;
    const timeRange = URLparams.get('timeRange') ? URLparams.get('timeRange').split(",") : null;
    const urlTimeRangeIndex = URLparams.get('timeRangeIndex') ? URLparams.get('timeRangeIndex') : null;
    this.setState({
      timeRangeIndexValue: urlTimeRangeIndex
    })
    const start = URLparams.get('start') ? URLparams.get('start') : "";
    const end = URLparams.get('end') ? URLparams.get('end') : "";
    const startDisplay = URLparams.get('startDisplay') ? URLparams.get('startDisplay') : {};
    const endDisplay = URLparams.get('endDisplay') ? URLparams.get('endDisplay') : {};
    let URLcustomTimeRange = {};
    if(start && end) {
      URLcustomTimeRange = {
        start: start,
        end: end,
        today: new Date(),
        startDisplay: new Date(startDisplay),
        endDisplay: new Date(endDisplay),
      }
    }
    let selection = GraphService.getFilterSelection();
    let selectedFilter = [];
    if(fieldSelection && timeRange) {
      const selectedURLTimeRangeValue = {
        customTimeRange: URLcustomTimeRange,
        timeRangeIndex: urlTimeRangeIndex,
        timeRangeSelection: {
          endTime: timeRange[1],
          startTime: timeRange[0]
        }
      }
      this.setState({
        customInitTimeRangeFilterSelection: selectedURLTimeRangeValue
      })
      GraphService.setTimeRangeFilterSelection({
        endTime: timeRange[1],
        startTime: timeRange[0]
      }, urlTimeRangeIndex, URLcustomTimeRange);
      PropertyModel.forModel(type, mode).then(propModel => {
        this.propertyModel = new PropertyModel(propModel);
        const propGroups = this.propertyModel.byTypeGrouped("Temp", "RPM", "Numeric", "Number", "Bool", "Enum", "EnumLine", "MixedEnumNumber");
        propGroups.map((item) => {
          item.properties.map((value) => {
            if(fieldSelection.includes(value.displayName)) {
              selectedFilter.push(value);
            }
          })
        })
        this.setSelection(currentAppliance, selectedFilter);
      }, errResponse => {
        notifications.error(`Configuration not found for appliance type ${this.props.type}.`);
      });
    } else {
    this.setSelection(currentAppliance, selection);
    }
  },

  setSelection(currentAppliance, selection) {
    const {connected, mode} = this.props,
        {deviceId, serial} = currentAppliance;
    let {type} = currentAppliance;
    if(selection && selection.length > 0) {
      const {application} = this.props,
      notifications = application.notifications;
      PropertyModel.forModel(type, mode).then(propModel => {
        this.setState({propModel});
      }, errResponse => {
        notifications.error(`Configuration not found for appliance type ${this.props.type}.`);
      });
      this.setState({
        currentAppliance,
        applianceType: type,
        initialized: true,
        connected: !!connected,
        fieldSelection: selection,
        mode
      });

      if (connected) {
        if (deviceId && type) {
          this.setState({ applianceType: type });
          this.reRenderProperties([], []);
        } else {
          this.props.application.route("/");
        }
      } else {
        this.fetchNonConnectedData({ deviceId, type, mode, serial })
          .then(graphData => {
            this.updateGraphConfig([]);
            setTimeout(() => this.updateGraphConfig(([]).reduce((acc, f) => {
              acc = acc.concat(_.filter(graphData.meta.request.properties, f));
              return acc;
            }, [])), 500);
          });
      }
    } else {
      console.log("Please select initials - ", selection)
      const uniqueId = Date.now();
      this.props.application.route(`/appliance/${deviceId}/graph/select-data/${uniqueId}`);
    }
  },

  mergeResultSet(r) {
    this.graphData = this.state.graphData;
    if (!this.graphData) {
      this.graphData = r;
    } else {
      this.graphData.merge(this.convertGraphTimesToLocalTimeZone(r));
    }
  },

  mergeAndRender(r) {
    if (r) {
      this.mergeResultSet(r);
      // this.setState({graphData: null});
      setTimeout(() => {
        const hasRecords = this.graphData.records &&this.graphData.records.length > 0;
        const hasOfflineRecords = this.graphData.offlineTimes && this.graphData.offlineTimes.length>0
        if (this.graphData  && (hasRecords|| hasOfflineRecords)) {
          this.setState({graphData: this.graphData});
        } else {
          this.setState({graphData: null, graphMessage: "No graph data for selected criteria"});
        }
      }, 10);
    }
    return r;
  },

  reRenderProperties(load, render, forceRefresh = false) {
    setTimeout(() => {
      const {deviceId, type} = this.state.currentAppliance;
      this.setState({statsLoading: true, graphMessage: "Loading..."});
      if(load.length > 0) {
        this.fetchAndUpdate({deviceId, type}, load, render, [], forceRefresh)
        .then(() => {
            // this.setState({statsLoading: false});
          /*
          // For All Enums, Bools values in stats panel
          this.fetchAndUpdate({deviceId, type}, [fltrs.Enums, fltrs.Bools], null, load)
            .then(() => {
              this.setState({statsLoading: false});
            });
          */
        });
      }
    });
  },

  fetchAndUpdate({deviceId, type}, loads, renders, excludeProps, forceRefresh = false) {
    return this.fetchConnectedData({deviceId, type}, loads, excludeProps, forceRefresh)
        .then(response => {
          renders !== null && this.updateGraphConfig([]);
          response && response.meta && setTimeout(() => {
            if (renders !== null) {
              let properties = (renders || []).reduce((acc, f) => {
                acc = acc.concat(_.filter(response.meta.request.properties, f));
                return acc;
              }, []);
              this.updateGraphConfig(properties);
            } else {
              this.updateGraphConfig();
            }
            //consider there is data available if offline times is not null or empty
            if(response.offlineTimes && response.offlineTimes.length>0){
              this.setState({statsLoading: false, graphMessage: null});
              return;
            }
            this.setState({statsLoading: false, graphMessage:"No Logging Data Found"});
          }, 100);
        });
  },

  fetchConnectedData(appliance, filters, excludeProps, forceRefresh = false) {
    const {deviceId, type} = appliance;
    let {startTime, endTime} = this.state.timeSelection;
    let initTimeRangeFilterSelection = GraphService.getTimeRangeFilterSelection();
    if(Object.keys(initTimeRangeFilterSelection.timeRangeSelection).length > 0 && this.state.timeRangeFlag === 1) {
        this.setState({
          timeRangeFlag: 2,
          timeSelection: initTimeRangeFilterSelection.timeRangeSelection
        });
      startTime = initTimeRangeFilterSelection.timeRangeSelection.startTime;
      endTime = initTimeRangeFilterSelection.timeRangeSelection.endTime;
    }
    else if (!startTime) {
      startTime = new Date();
      startTime.setDate(startTime.getDate() - 1);
      startTime = startTime.getTime();
      endTime = new Date().getTime();
    }

    if (filters && filters.length) {
      return this.fetchForProperties(deviceId, type, startTime, endTime, filters, excludeProps, forceRefresh);
    }
  },

  fetchForProperties(deviceId, type, startTime, endTime, filters, excludeProps, forceRefresh = false) {
    this.setBusy(true);
    return GraphService.fetchConnectedGraphData(deviceId, type, startTime, endTime, filters, excludeProps, forceRefresh)
        .then(r => {
          GraphService.fetchTimeZones().then((timezones) => {
            GraphService.getTimeZoneId(deviceId).then((response) => {
              if(timezones) {
                timezones.map((timeZone) => {
                  if(timeZone.id === response.timeZoneId) {
                    this.setState({
                      deviceTimezone: timeZone.displayName
                    });
                  }
                })
              }
            })
            .catch((err) => {
              this.setState({
                deviceTimezone: "Error Fetching Timezone"
              });
            })
          })
          return this.mergeAndRender(r);
        })
        .catch(err => {
          this.props.application.notifications.error("Error fetching appliance information.");
          this.setState({graphData: null, graphMessage: "Error Loading Graph Data"});
          throw err;
        })
      .finally(() => this.setBusy(false))
    ;
  },

  fetchNonConnectedData(appliance) {
    const {deviceId, type, mode: logType, serial} = appliance,
        {application} = this.props,
        notifications = application.notifications;

    let promise;
    if(deviceId || serial) {
      promise = GraphService.fetchNonConnectedGraphData(deviceId, type, logType, serial);
    }
    if (type) {
      this.setState({
        applianceType: type
      });
    }

    if(promise) {
      promise.then(graphData => {
        const {failureBlobPaths} = graphData;
        if(failureBlobPaths && failureBlobPaths.length > 0) {
          notifications.error(`Failed to fetch all the graph data. Some log file(s) are missing: ${failureBlobPaths[0]}, ${failureBlobPaths.length > 1 ? "..." : ""}.`);
        }
        this.graphData = graphData;
        setTimeout(() => {
          this.setState({graphData});
        });
        return graphData;
      });
    }else {
      this.props.application.route("/");
    }

    return promise;
  },

  setBusy(busy) {
    this.setState({busy});
  },

  handleTimerangeSelectionChange(timeSelection, index) {
    const { indexFlag } = this.state;
    this.setState({
      timeSelection,
      scrollStatus: false,
      graphData: null,
    });

    if(indexFlag !== 1) {
      this.setState({
        timeRangeIndexValue: index,
        indexFlag : indexFlag + 1
      });
    }
    this.setState({
      indexFlag : indexFlag + 1
    });
    if (this.state.fieldSelection) {
      const renderables = this.state.fieldSelection.map((value) => {
        if(value.name) {
          return ({
            name: value.name,
            id: value.id
          })
        } else {
          return ({
            displayName: value.displayName,
            id: value.id
          })
        }
      });
      this.reRenderProperties(renderables, renderables);
    }
  },

  handleLogSelectionChange({logEntry}) {
    this.setState({logSelection: logEntry});
    this.graphComponent.setTimeTo(logEntry);
  },

  handlePropertySelectionChange(selection) {
    const {deviceId, type} = this.state.currentAppliance,
        connected = this.graphData.meta.request.connected,
        pending = _.filter(selection, p => {
          return !this.graphData.hasData(p);
        });
    if (connected && pending && pending.length) {
      this.fetchConnectedData({deviceId, type}, pending.map(({id}) => ({id})))
          .then(() => setTimeout(() => this.updateGraphConfig(selection), 100))
      ;
    } else {
      this.updateGraphConfig(selection);
      if (this.state.logSelection) {
        setTimeout(() => this.handleLogSelectionChange({
          logEntry: this.state.logSelection
        }), 100);
      }
    }
  },
  convertGraphTimesToLocalTimeZone(graphData) {
    if(!graphData || (graphData && graphData.timezoneConverted)) {
      return graphData;
    }
    const times = graphData.time,
      records = graphData.records;
    function toHostTimeZone(timestamp){
        var hostTimeZoneDate = new Date();
        var timezoneOffset = hostTimeZoneDate.getTimezoneOffset();
        timezoneOffset = timezoneOffset * 60000;//in millis
        return timestamp;
      }

    times.forEach((t,index)=>{
      times[index] = toHostTimeZone(t);
    });
    records.forEach((r)=>{
      r.ts = toHostTimeZone(r.ts);
    });
    graphData.timezoneConverted = true
    return graphData
  },
  generateDeviceConnectivityStateRecords(key,fieldSelection,graphData, timeSelection){
    if(!graphData){
      return
    }
    if(!graphData.meta) graphData.meta = {};
    if(!graphData.meta.keyMap) graphData.meta.keyMap = {};
    if(!graphData.time) graphData.time = []
    if(!graphData.records) graphData.records = []
    
    const panelIdxArr = _.map(fieldSelection,"panelIdx");
    panelIdxArr.forEach((panelId)=>{
      //check if offline state for this panel ID already exist
      if(_.find(fieldSelection,{id:key+panelId})){
        return;//no need to add new offline state property for this panelId
      }
      //add offline state property for this panelId
      fieldSelection.push({
        "id" : key+panelId,
        "name" : "GENERATEDDEVICECONNECTIVITYSTATE",
        "displayName" : "Offline State",
        "type" : "OfflineState",
        "panelIdx" : panelId,
        "group" : "Unit Info",
        "color" : config.graphMissingdataColor || "#FF5E63",
        "valuesDescription":"1:Offline\r\n0:Online",
        "description":"Offline state",
        "valueMapping" : "1,Offline_0,Online"
      });
      graphData.meta.keyMap["GENERATEDDEVICECONNECTIVITYSTATE"] = key;
      const times = graphData.time;
      const records = graphData.records;
      const offlineCheckDeltaInMillis =  (config.graphMissingdataIntervalSeconds*1000) || 360000;
      //Enable following if FUTURE REQUIREMENT
      //Following is requred if records count is 0 and offline times are null or has no values
      // if( records.length==0  && (graphData.offlineTimes==null || graphData.offlineTimes.length==0) ){
      //   graphData.offlineTimes =[
      //     {
      //       startTime:timeSelection.startTime,
      //       endTime:timeSelection.endTime
      //     }
      //   ]
      // }
      
      if (graphData.offlineTimes) {//transactional data has offline times
        const offlineTimes = graphData.offlineTimes;
            // Insert offline start and end times into the times array
        const firstRecord = records[0];
        offlineTimes.forEach(offlinePeriod => {
          //process start time
          let index = times.indexOf(offlinePeriod.startTime);
          if (index==-1) { //time not in times, add it
            times.push(offlinePeriod.startTime);
            const tempRecord = {
              ...firstRecord,
              ts: offlinePeriod.startTime
            }
            tempRecord[key] = 1;
            records.push(tempRecord);
          }
          else{
            records[index][key] = 1;
          }
          //process end time
          index = times.indexOf(offlinePeriod.endTime);
          if (index==-1) {//time not in times, add it
            times.push(offlinePeriod.endTime);
            const tempRecord = {
              ...firstRecord,
              ts: offlinePeriod.endTime
            }
            tempRecord[key] = 0;
            records.push(tempRecord);
          }
          else{
            records[index][key] = 0;
          }
        });
        // Sort the times and record arrays
        times.sort((a, b) => a - b);
        records.sort((a, b) => a.ts - b.ts);
      }
      else{//polling data will not have offline times
        //fallback offline state display can only works if there are more than or equal to
        // 2 records, else we can't determine offline state of device, so keep graph as is
        if(records && records.length>=2){
          records[records.length-1][key] = 0;
          for(var t=1;t<times.length;t++){// taking time O(n)
            const delta = times[t]-times[t-1];
            records[t-1][key] = delta>offlineCheckDeltaInMillis? 1:0;
          }
        }
        else{
          console.log("Records count is less than 2, can't determine offline state")
        }
      }

    });

  },
  createExcelData(startDateString, endDateString, graphData) {
    let tempExcelGraphData=[];
    var zoomedStartDate = new Date(startDateString);
    var zoomedEndDate = new Date(endDateString);
    if(graphData.records) {
      tempExcelGraphData = _.filter(graphData.records, obj => {
        var graphDataDate = new Date(obj.ts);
        return ((graphDataDate.getTime() >= zoomedStartDate.getTime()) && (graphDataDate.getTime() <= zoomedEndDate.getTime()));
      })
      this.setState({excelGraphData: tempExcelGraphData});
    }
  },
  //To fetch allowed random colors for graph property
  fetchColorProperty() {
   const { graphPropertyColors } = this.state;
      var randomIndex = Math.floor(Math.random() * graphPropertyColors.length);
      var randomValue = graphPropertyColors[randomIndex];
      return randomValue;
  },
  updateGraphConfig(fieldSelection, timeSelection) {
    const {applianceType, mode, graphPropertyColors, tempStateFieldSelection} = this.state;
    fieldSelection = fieldSelection || this.state.fieldSelection;
    timeSelection = timeSelection || this.state.timeSelection;
    const gData = this.convertGraphTimesToLocalTimeZone(this.state.graphData);
    this.generateDeviceConnectivityStateRecords(util.OFFLINE_CONNECTIVITY_STATE_ID, fieldSelection, gData, timeSelection);
      const randomValue = this.fetchColorProperty();
    var newValue = [];
    var selectedColors = [];
    if(fieldSelection.length > 0 && tempStateFieldSelection.length == 0) {
      fieldSelection.map((value, index) => {
        if(!selectedColors.includes(randomValue)) {
          value.color = randomValue;
          selectedColors.push(randomValue);
          newValue.push(value);
        } else {
          const uniqueValues = graphPropertyColors.filter(value => !selectedColors.includes(value))
            .concat(selectedColors.filter(value => !graphPropertyColors.includes(value)));
          if(uniqueValues[0]) {
            value.color = uniqueValues[0];
            newValue.push(value);
            selectedColors.push(uniqueValues[0]);
          } else {
            const chars = "0123456789abcdef".split("");
            const randomColor = "#" + (_.times(6).map(() => _.sample(chars)).join(""));
            value.color = randomColor;
            newValue.push(value);
          }
        }
      })
    } else {
      fieldSelection.map((value, index) => {
      const obj = tempStateFieldSelection.find(obj => obj.name === value.name);
        if(obj && Object.keys(obj).length > 0) {
          newValue.push(value);
          selectedColors.push(value.color);
        } else {
          const uniqueValues = graphPropertyColors.filter(value => !selectedColors.includes(value))
          .concat(selectedColors.filter(value => !graphPropertyColors.includes(value)));
          if(uniqueValues[0]) {
            value.color = uniqueValues[0];
            newValue.push(value);
            selectedColors.push(uniqueValues[0]);
          } else {
            const chars = "0123456789abcdef".split("");
            const randomColor = "#" + (_.times(6).map(() => _.sample(chars)).join(""));
            value.color = randomColor;
            newValue.push(value);
          }
        }
      })
    }
    this.setState({
      tempStateFieldSelection: newValue
    })
    let graphConfig = GraphService.makeChartConfig(
        {applianceType, mode}, newValue ? newValue : [], gData,
        this.state.prop2Panel, true
    );
    this.setState({excelGraphData: []});
    this.setState({fieldSelection: newValue ? newValue : [], hoverRecord: null});

    const times = {
      ss: 1,
      mm: 60,
      hh: 60 * 60,
      dd: 24 *60 *60
    };

    let throttleBy = 500, lastZoomHandle;
    graphConfig.listeners = [
      {
        event: "zoomed", method: e => {
          if (lastZoomHandle) {
            window.clearTimeout(lastZoomHandle);
          }
          lastZoomHandle = window.setTimeout(() => {
            const matches = /^(\d+)?(..)$/.exec(e.period);
            if (!matches) {
              return;
            }
            const time = matches.length === 2 ?
                times[matches[1]] :
                Number(matches[1]) * times[matches[2]]
            ;
            AI.trackEvent("GraphZoom",
                _.pick(this.state.currentAppliance, "deviceId", "model", "name", "serial", "type"));
            this.setState({
              tinyEventMarkers: time >= times.mm,
              scrollStatus: {
                start: e.startDate,
                end: e.endDate
              }
            });
            if(this.state.scrollStatus) {
              this.createExcelData(e.startDate, e.endDate, this.state.graphData);
            }
            window.clearTimeout(lastZoomHandle);
            lastZoomHandle = null;
          }, throttleBy);
        }
      },
      {event: "rendered", method: e => {
        if (this.state.scrollStatus) {
          this.createExcelData(this.state.scrollStatus.start, this.state.scrollStatus.end, this.state.graphData);
          e.chart.zoom(this.state.scrollStatus.start, this.state.scrollStatus.end);
        }
      }}
    ];

    this.setState({graphConfig});
  },

  handleGraphHover(hoverRecord) {
    this.setState({hoverRecord});
  },

  handleFieldRemove({field, label, id}) {
    const fldName = _.findKey(this.state.graphData.meta.keyMap, key => key === field),
        prop = _.find(this.state.fieldSelection, f => (f.name === fldName && f.displayName === label && f.id === id));
    if (prop) {
      // this.propertySelectorRef.toggleProperty(prop.id, false);
      const filterList = this.state.fieldSelection.filter(field => field.id !== prop.id);
      this.handlePropertySelectionChange(filterList);

      AI.trackEvent("GraphRemoveProp",
          Object.assign({field: prop.displayName},
              _.pick(this.state.currentAppliance, "deviceId", "model", "name", "serial", "type")));
    }
  },

  handleFieldShuffle({field, from, to}) {
    let prop2Panel = _.cloneDeep(this.state.prop2Panel);
    prop2Panel[field] = to;

    const {applianceType, mode} = this.state;

    this.setState({
      prop2Panel,
      graphConfig: GraphService.makeChartConfig(
          {applianceType, mode},
          this.state.fieldSelection,
          this.state.graphData,
          prop2Panel,
          true
      )
    });

    AI.trackEvent("GraphMovePropToPanel",
        Object.assign({field, moveToPanel: prop2Panel[field]},
            _.pick(this.state.currentAppliance, "deviceId", "model", "name", "serial", "type")));
  },

  back() {
    history.back();
  },

  toggleFullScreen() {
    if(!this.state.fullscreen) {
      AI.trackEvent("GraphFullScreen",
          _.pick(this.state.currentAppliance, "deviceId", "model", "name", "serial", "type"));
    }
    this.setState({
      fullscreen: !this.state.fullscreen
    });
  },
  toggleSidePanel(){
    this.setState({
      isLhsPanelOpen: !this.state.isLhsPanelOpen
    })
  },
  setLegendDragFrom(value) {
    this.setState({legendDragFrom: value});
  },
  setHoverRecord(hoverRecord){
    this.setState({hoverRecord});
  },
  setLockedHoverRecord(hoverRecord) {
    this.setState({
      lockedHoverRecord: hoverRecord
    })
  },
  getGraphLegendDomByIndex(index){
    const legendClassName = "graph-legend "+index;
    const collection = document.getElementsByClassName(legendClassName);
    if(collection && collection.length>0){
      return  collection[0];
    }
    return null;
  },

  setVisibilityOfGraphPanel(panelTitle, minimize){
    const {propModel} = this.state,
    panels = propModel && propModel.panels ;
    if(!panels) return;
    const panelDom = this.getGraphPanelDomByPanelTitle(panelTitle);
    const legendDom = this.getGraphLegendDomByIndex(panels.titles.indexOf(panelTitle));
    if(panelDom && legendDom){
      if(minimize){
        panelDom.style.height = '0%';
        // legendDom.style.top = '-50%';
       // panelDom.style.display = 'none';
      }
      else{
        panelDom.style.height = '100%';
        // legendDom.style.top = '0%';
        //panelDom.style.display = 'inline-block';
      }
    }
  },
  setPanelsVisibility(exceptTitle, minimize){
    const {propModel} = this.state,
    panels = propModel && propModel.panels ;
    if(!panels) return;

    const exceptPanelIndex = panels.titles.indexOf(exceptTitle);
    console.log("Panels:", panels, "Except title:", exceptTitle, ' and index:', exceptPanelIndex);
    panels.titles.forEach((title, index)=>{
      if(exceptPanelIndex!=index){
        this.setVisibilityOfGraphPanel(title, minimize);
        panels.sizes[index]=0;
        //propModel.containerStates[title] = minimize;
      }
    });
    //maximize exceptPanel ID by default
    this.setVisibilityOfGraphPanel(exceptTitle, !minimize);
    panels.sizes[exceptPanelIndex]=100;
    //propModel.containerStates[exceptTitle] = !minimize;
    this.setState({
      ...this.state,
      propModel:{
        ...this.state.propModel,
        panels:{
          ...panels,
          sizes:panels.sizes
        }
      }
    })
  },
  getGraphPanelDomByPanelTitle(panelTitle){
    const {propModel} = this.state,
    panels = propModel && propModel.panels ;
    if(!panels) return null;
    const panelIndex = panels.titles.indexOf(panelTitle);
    const panelClassName = "amChartsPanel amcharts-stock-panel-div amcharts-stock-panel-div-stockPanel"+panelIndex;
    const collection = document.getElementsByClassName(panelClassName);
    if(collection && collection.length>0){
      const panelDom = collection[0];
      return panelDom;
    }
    return null;
  },
  getGraphPanelTabs(){
    const {propModel} = this.state,
    panels = propModel && propModel.panels;
    if(!panels) return;
    const buttons = panels.titles.map((title, index)=>{
      return (
        <a className={"panel-tabs-item"+(this.state.selectedGraphPanel==index?' selected':' un-selected')} onClick={(e)=>{
          this.setPanelsVisibility(title,true);
          this.setState({
            ...this.state,
            propModel:{
              ...this.state.propModel,
              panels:{
                ...panels,
                sizes:panels.sizes
              }
            },
            selectedGraphPanel:index
          });
          return false;
        }}>{title}</a>
      );
    })
    return <div className="panel-tab-container">
        <a className={"panel-tabs-item"+(this.state.selectedGraphPanel==-1?' selected':' un-selected')} 
          onClick={(e)=>{
            const {propModel} = this.state,
            panels = propModel && propModel.panels ;
            if(!panels) return;
            panels.titles.forEach((title, index)=>{
              const panelDom = this.getGraphPanelDomByPanelTitle(title);
              if(panelDom){
                panelDom.style.height = '50%';
                panels.sizes[index]=50;
              }
            });
            this.setState({
              ...this.state,
              propModel:{
                ...this.state.propModel,
                panels:{
                  ...panels,
                  sizes:panels.sizes
                }
              },
              selectedGraphPanel:-1
            });
         return false;
        }}>Show All</a>
        {buttons}
    </div>
  },

  //This function is used to create custom CSV header from keyMap based on this, data will be added to CSV.
  getGraphDataCSVHeader(graphData, properties){
      const value = Object.keys(graphData[0]).map((item) => {
        var tempObject ={};
        const fldName = _.findKey(properties, key =>
          {
            if(key == item && key !== "kCS") {
              tempObject['key'] = key;
            }
           return key == item;
          });
        if(fldName) {
          tempObject['label'] = fldName;
        } else {
          if(item == 'ts') {
            tempObject['label'] = 'TimeStamp'
            tempObject['key'] = item
          }
        }
        return tempObject;
      });
      return value;
  },
  setShowPopUp() {
    this.setState({
      showPopUp: true
    })
    setTimeout(() => {
      this.setState({
        showPopUp: false
      })}, 2000);
  },

  /*
  handleFieldToggle(field) {
    const panels = this.chartRef.state.chart.panels;

    let targetPanel, targetGraph;

    panels.forEach(p => {
      targetGraph = _.find(p.graphs, g => {
        if (g.valueField === field) {
          targetPanel = p;
          return true;
        }
      }) || targetGraph;
    });

    if (targetGraph) {
      targetPanel[targetGraph.hidden ? "showGraph" : "hideGraph"](targetGraph);

      AI.trackEvent("GraphToggleProp",
          Object.assign({field},
              _.pick(this.state.currentAppliance, "deviceId", "model", "name", "serial", "type")));
    }
  },

  handleFieldHover(field) {
    const panels = this.chartRef.state.chart.panels;

    panels.forEach(p => {
      p.graphs.forEach(g => {
        setOpacity(g, field ? (g.valueField === field ? 1 : 0.3) : 1);
      });
    });
  },

  handleFieldMove(moveMeta) {
    this.handleFieldShuffle(moveMeta);
    this.setState({legendDragFrom: -1});
  },
  */

  render: function() {
    const defaultPanelHeight = 50;
    const {applianceType, hoverRecord, initialized, connected, busy, mode, graphData: data, fieldSelection, isLhsPanelOpen, propModel, excelGraphData, showPopUp, timeRangeIndexValue, indexFlag} = this.state,
        graphType = connected ? "connected" : "non-connected",
        showProperties = data && data.meta ? _.keys(data.meta.keyMap) : null,
        {route, application} = this.props,
        initSelection = GraphService.getFilterSelection();
    let {startTime, endTime} = this.state.timeSelection;
        let initTimeRangeFilterSelection = GraphService.getTimeRangeFilterSelection();
        const panels = propModel && propModel.panels || {sizes: [defaultPanelHeight, defaultPanelHeight], titles: ["", ""]};
        let keyValue = {};
        this.state.graphConfig.dataSets.map((item) => {
          item.fieldMappings.map((val) => {
            if(val.prop) {
              let key = val.prop.name;
              keyValue[key] = val.fromField;
            }
          })
        })
    let csvHeader;

    if(data && data.records.length>0) {
      csvHeader = this.getGraphDataCSVHeader(data.records, keyValue);
    }
    const URLparams = new URLSearchParams(window.location.href);
    const urlTimeRangeIndex = URLparams.get('timeRangeIndex') ? URLparams.get('timeRangeIndex') : null;
    let rangeIndex;
    if(URLparams.get('fieldSelection')) {
        rangeIndex = urlTimeRangeIndex;
        if(urlTimeRangeIndex !== timeRangeIndexValue) {
          rangeIndex = timeRangeIndexValue;
        }
    } else {
      rangeIndex = initTimeRangeFilterSelection.timeRangeIndex;
      if(timeRangeIndexValue && rangeIndex !== timeRangeIndexValue) {
        rangeIndex = timeRangeIndexValue;
      }
    }

      const generateLink = () => {
      const selectedFields = [];
      const selectedTimeRange = [];
      fieldSelection.map((value) => {
        if(!selectedFields.includes(value.displayName)) {
          selectedFields.push(value.displayName);
        }
      });
      if(Object.keys(initTimeRangeFilterSelection.timeRangeSelection).length > 0 && this.state.timeRangeFlag === 1) {
        startTime = initTimeRangeFilterSelection.timeRangeSelection.startTime;
        endTime = initTimeRangeFilterSelection.timeRangeSelection.endTime;
      } else if (!startTime) {
        startTime = new Date();
        startTime.setDate(startTime.getDate() - 1);
        startTime = startTime.getTime();
        endTime = new Date().getTime();
      }
      selectedTimeRange.push(startTime);
      selectedTimeRange.push(endTime);
      const siteURL = (window.location.href).split('?');
      let selectedCustomTimeRange = new URLSearchParams(initTimeRangeFilterSelection.customTimeRange).toString();
      if(timeRangeIndexValue !== "custom" && indexFlag == 1) {
        selectedCustomTimeRange = "";
      }
      if(selectedCustomTimeRange && indexFlag == 1) {
        rangeIndex = "custom";
      }
      const link = `${siteURL[0]}?&fieldSelection=${encodeURIComponent(selectedFields)}&timeRange=${encodeURIComponent(selectedTimeRange)}&timeRangeIndex=${encodeURIComponent(rangeIndex)}${selectedCustomTimeRange ? "&="+selectedCustomTimeRange : ""}`;
      return link;
    };
    const link = generateLink();
    return (
      <ApplianceNav className="graph-data-view"
                    route={route} application={application} onLoad={this.initView}
                    graphType={graphType}>
          {initialized ? (
          <div className={`view-table ${this.state.fullscreen ? "full-screen" : ""}`}>
            <div className="row content-header">
              <div className="return-section" onClick={this.back}>
                <i className="icon icon-chevron-left" />
                <span>Return</span>
              </div>
              <p></p>
              {/* <GraphLegend
                index={0}
                className={`${(busy ? "loader" : "")}`}
                keyMap={data && data.meta && data.meta.keyMap}
                config={this.state.graphConfig}
                record={hoverRecord}
                onFieldMoveStart={index => this.setState({ legendDragFrom: index })}
                onFieldMoveCancel={() => this.setState({ legendDragFrom: -1 })}
                onFieldMoved={this.handleFieldMove}
                droppable={[-1, 0].indexOf(this.state.legendDragFrom) === -1}
                onFieldRemove={field => this.handleFieldRemove(field)}
                onFieldToggle={this.handleFieldToggle}
                onFieldHover={this.handleFieldHover} /> */}
              <i className={"icon icon-loader " + (busy ? "showing" : "")} />
              {showPopUp && (
              <div className="popup">
                <span>Copied to Clipboard</span>
              </div>)
              }
            
            {
              !busy && 
              <button className="inline primary header-action-graph"
                disabled={this.state.statsLoading}
                onClick={
                  (()=>{
                    if (this.state.fieldSelection) {
                      const renderables = this.state.fieldSelection.map((value) => {
                        if(value.name) {
                          return ({
                            name: value.name,
                            id: value.id
                          })
                        } else {
                          return ({
                            displayName: value.displayName,
                            id: value.id
                          })
                        }
                      });
                      this.reRenderProperties(renderables, renderables, true);
                    }
                  }).bind(this)
                } style={{height: "34px"}} >
                  <i className={`icon icon-rotate-cw${this.state.statsLoading? " anim spin" : ""}`}
                    title={"Refresh"} />
              </button>
            }
          
              <button className="inline primary header-action-graph"
              title="Copy Link To Share"
                      onClick={() => {
                        navigator.clipboard.writeText(link);
                        this.setShowPopUp();
                      }} style={{height: "34px"}} >Share
              </button>
              {(excelGraphData.length > 0 && !busy) &&
                <CSVLink
                  type="button"
                  disabled={!true}
                  className="button inline primary btn-export"
                  data={excelGraphData}
                  headers={csvHeader}
                  flag={"graph"}
                  csvHeaderDisplayName={fieldSelection}
                  filename={`${(this.state.currentAppliance) ? (this.state.currentAppliance).serial + "_" : ""}GraphExport.csv`}>Export
                </CSVLink>
              }
              {connected ?
                  (<TimeRangeSelector application={application}
                                      onSelectionChange={this.handleTimerangeSelectionChange}
                                      timeRangeFlag={this.state.timeRangeFlag}
                                      initTimeRangeFilterSelection={initTimeRangeFilterSelection.timeRangeSelection}
                                      timeRangeIndex={rangeIndex !== "custom" ? parseInt(rangeIndex) : rangeIndex}
                                      customTimeRangeSelection={initTimeRangeFilterSelection.customTimeRange}
                                      />) :
                  (<LogSelector
                      boundaries={data && data.boundaries}
                      disabled={!(data && data.boundaries && data.boundaries.length > 1)}
                      onSelectionChange={this.handleLogSelectionChange}/>)
              }
              {/* <PropertySelector ref={x => this.propertySelectorRef = x}
                                disabled={!(data && data.records)}
                                type={applianceType}
                                connected={connected}
                                mode={mode}
                                showProperties={showProperties}
                                fieldSelection={this.state.fieldSelection}
                                onSelectionChange={this.handlePropertySelectionChange}
              /> */}
              <button className="inline primary header-action-graph"
                      onClick={this.toggleFullScreen} style={{height: "34px"}} >
                <i className={`icon-graph-${this.state.fullscreen ? "minimize" : "maximize"}`}
                   title={this.state.fullscreen ? "Minimize" : "Maximize"} />
              </button>
              {
                panels.titles.length > 1 ? this.getGraphPanelTabs() : null
              }
            </div>
            <div className={`row graph-view`}>
              <div className={`graph-container${this.state.tinyEventMarkers ? " tiny-events" : " "} ${isLhsPanelOpen ? "lhs-expand" : "lhs-collapse"}`}>
                <StatsPanel type={applianceType}
                            connected={connected}
                            mode={mode}
                            fieldSelection={fieldSelection}
                            initialSelection={initSelection}
                            showProperties={showProperties}
                            busy={busy}
                            isLhsPanelOpen={isLhsPanelOpen}
                            toggleSidePanel={this.toggleSidePanel}
                            loading={this.state.statsLoading}
                            record={hoverRecord}
                            keyMap={data && data.meta && data.meta.keyMap}
                            onSelectionChange={this.handlePropertySelectionChange}
                />
                <Graph ref={x => this.graphComponent = x}
                       type={applianceType} mode={mode}
                       data={data}
                       keyMap={data && data.meta && data.meta.keyMap}
                       busyMessage={this.state.graphMessage}
                       config={this.state.graphConfig}
                       onGraphHover={this.handleGraphHover}
                       onFieldRemove={this.handleFieldRemove}
                       onFieldShuffle={this.handleFieldShuffle}
                       application={application}
                       setChartRef={(ref) => {
                         this.chartRef = ref;
                       }}
                       setLegendDragFrom={this.setLegendDragFrom}
                       legendDragFrom={this.state.legendDragFrom}
                       setHoverRecord={this.setHoverRecord}
                       setLockedHoverRecord={this.setLockedHoverRecord}
                       currentAppliance={this.state.currentAppliance}
                       hoverRecord={this.state.hoverRecord}
                       propModel={this.state.propModel}
                       lockedHoverRecord={this.state.lockedHoverRecord}
                       getGraphPanelDomByPanelTitle={this.getGraphPanelDomByPanelTitle}
                       selectedGraphPanel={this.state.selectedGraphPanel}
                       setPanelsVisibility={this.setPanelsVisibility}
                />
              </div>
            </div>
            <div
              style={{
                bottom: 0,
                position: "absolute",
                marginBottom: !this.state.fullscreen ? "17px" : "9px",
                left: !this.state.fullscreen ? isLhsPanelOpen ? "628px" : "362px" : isLhsPanelOpen ? "335px" : "78px"
              }}
            ><span>{this.state.deviceTimezone}</span></div>
          </div>
        ) : <div className="view-container" />}
      </ApplianceNav>
    );
  }
});
