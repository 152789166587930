/* global Promise setTimeout FormData */
import ApiClient from "../../components/api-client";
import * as _ from "lodash";

const {instance: Api, asJson: resAsJson} = ApiClient;

export default {
  uploadFile(file) {
    let data = new FormData();
    data.append("files", file);
    return Api.put("/configs/", {
      body: data
    }).then(resAsJson);
  },
  uploadMultipleFiles(files) {
    const formData = _.reduce(files, function (formData, file, i) {
      formData.append("files", file);
      return formData;
    }, new FormData());
    return Api.put("/configs/", {
      body: formData
    }).then(resAsJson);
  },
  getConfig(configType, name) {
    return Api.get(`/config/${configType}?name=${name}`)
    .then(resAsJson);
  },
  getEditableFormData(dataObject) {
    return _.map(dataObject, data => {
      const edit = _.reduce(data, function(result, value, key) {
        result[key] = false;
        return result;
      }, {});
      return {edit, data};
    });
  },
  convertForUpload(dataObject, fieldName) {
    const fName = fieldName || "data";
    return _.reduce(dataObject, function(hash, value) {
      const key = value[fName];
      hash = hash.concat(key);
      return hash;
    }, []);
  },
  ComparisonOperator: ["<", ">", "="],
  Severity: [1, 2, 3, 4, 5],
  valueChange(columns, editable, index, field, val) {
    _.forEach(columns, (obj, idx) => {
      _.forEach(obj["edit"], (value, key) => {
        obj["edit"][key] = false;
      });
    });
    let item = columns[index];
    item["data"][field] = val;
    item["edit"][field] = editable;
    return columns;
  },
  Columns: [{
    UiName: "",
    JsonPath: ""
  }],
  ErrorExceptions: [{
    ApplianceType: "",
    ErrorCodes: []
  }],
  Warnings: [{
    Tooltip: "",
    JsonPath: "",
    ApplicableDeviceTypes: [],
    ThresholdValue: "",
    ConditionOperator: "",
    Severity: ""
  }],
  fetchWidgetConfiguration() {
    return Api.get("/config/Matrix?name=DashboardWidgets").then(resAsJson);
  }
};
