import React from "react";

export default ({message, icon = "icon-loader", animation, children}) => {
  const msg = message ? <p className="_text-center">{message}</p> : null;
  return (
    <div className={`page-message icon ${(animation ? " anim" : "")}`}>
      <i className={`icon ${icon} ${animation}`}></i>
      {msg}
      <div className="content">
        {children}
      </div>
    </div>
  );
};
