/* global Promise setTimeout */
import ApiClient from "../../components/api-client";
import Util from "../../components/util";
import Config from "../../config";

const {instance: Api, asJson: resAsJson} = ApiClient;

let widgetConfigurations = null;
let exportData = {};
let imageUrl = '';
let AA_CONTEXT_DATA = {},
    currentAppliance = {},
    currentApplianceDetails = {}

const imgBasePath = `${Config.baseDir}components/dashboard-widgets/appliance-info/appliance-images`;

export default {
  setPollingFrequency(deviceId, frequency) {
    return Api.put(`/appliances/${deviceId}/pollingFrequency`, {
      body: JSON.stringify({
        "frequency": frequency
      })
    }).then(resAsJson);
  },
  getPollingFrequency(deviceId) {
    return Api.get(`/appliances/${deviceId}/pollingFrequency`, {
    }).then(resAsJson);
  },
  fetchApplianceState(selectedDeviceId) {
    return Api.get(`/appliances/${selectedDeviceId}/properties`, {
    })
      .then(resAsJson)
      .then(res => Object.assign(res, {deviceId: selectedDeviceId})); // for UI to consume
  },
  fetchApplianceStates(selectedDeviceId) {
    return Api.get(`/appliances/${selectedDeviceId}/properties`, {
    });
  },

  fetchApplianceDataPoints(selectedDeviceId) {
    return Api.get(`/appliances/${selectedDeviceId}/datapoints`, {
    }) .then(resAsJson);
  },

  fetchWidgetConfiguration() {
    if(widgetConfigurations)
      return Promise.resolve(widgetConfigurations);

    return new Promise(resolve => {
      const configs = {};
      // Note: This mess is because of lack of support for Promise.allSettled
      Api.get("/config/Matrix?name=DashboardWidgets").then(resAsJson)
        .then((widgets) => {
          configs.configWidgets = widgets && widgets.ConfigData;
          Api.get("/config/Matrix?name=PerApplianceType").then(resAsJson)
            .then(per => {
              configs.perApplianceType = per && per.ConfigData;
              resolve(widgetConfigurations = configs);
            }).catch(() => {
              resolve(widgetConfigurations = configs);
            });
        });
    });
  },
  fetchDiagnostics(appliance) {
    let params = "";
    if(appliance.deviceId) {
      params = `?deviceId=${appliance.deviceId.trim()}`;
    }else if(appliance.bda) {
      params = `?bda=${appliance.bda.trim()}`;
    }
    return Api.get(`/appliances/diagnostics${params}`, {
    }).then(resAsJson);
  },
  fetchDiagnosticsStatus(appliance) {
    let params = `?deviceId=${appliance.deviceId.trim()}`;
    return Api.get(`/enduser-app/diagnostic-status${params}`, {
    }).then(resAsJson);
  },
  fetchDeviceStatus(appliance) {
    return Api.get(`/appliances/devicestatus?pumaVersion=${appliance.pumaVersion}&deviceId=${appliance.deviceId}`, {
    }).then(resAsJson);
  },
  fetchSwVersionStatus(appliance) {
    return Api.get(`/appliances/softwareversionstatus?deviceId=${appliance.deviceId}`).then(resAsJson);
  },
  fetchFirmwareUpdates(appliance) {
    return Api.get(`/appliances/firmwareupdates?deviceId=${appliance.deviceId}`, {
    }).then(resAsJson);
  },
  fetchGroupDetails(appliance) {
    return Api.get(`/appliances/${appliance.deviceId}/details`, {
    }).then(resAsJson);
  },
  getTimeZoneId(appliance) {
    return Api.get(`/timezone/${appliance.deviceId}/timezone`,{}).then(resAsJson);
  },
  fetchTimeZones() {
    return Api.get(`/timezone/timezones`,{}).then(resAsJson);
  },
  createTimeZoneId(appliance, data) {
    return Api.post(`/timezone/${appliance.deviceId}/timezone`, {
      body: JSON.stringify({
        "timeZoneId": data.id,
        "isDSTObserved": data.supportsDaylightSavingTime,
        "isNewRegistration": false
      })
    }).then(resAsJson);
  },
  updateTimeZoneId(appliance, data) {
    const body = {
      "timeZoneId": data.id,
      "utcOffset": data.utcOffset,
      "isDSTObserved": data.supportsDaylightSavingTime,
      "isDSTActive": data.supportsDaylightSavingTime
    }
    return Api.put(`/timezone/${appliance.deviceId}/timezone`, {
      body: JSON.stringify(body)
    }).then(resAsJson);
  },
  fetchErrorCodes(appliance) {
    return Api.get(`/appliances/${appliance.deviceId}/errorcodes?applianceType=${appliance.type}`, {
    }).then(resAsJson);
  },
  getExportpdfData() {
    return exportData;
  },
  setExportpdfData(type, data) {
    exportData[type] = data;
  },
  getImageUrlData() {
    return imageUrl;
  },
  setCurrentApplianceInfo(appInfo) {
    currentAppliance = appInfo;
  },
  getCurrentApplianceInfo() {
    return currentAppliance;
  },
  setCurrentApplianceDetails(appDetails){
    currentApplianceDetails = appDetails;
  },
  getCurrentApplianceDetails(){
    return currentApplianceDetails;
  },
  setImageUrlData(path = `${imgBasePath}/no_image.svg`) {
    imageUrl = path;
  },
  getAAContextData(groupName) {
    return groupName ? AA_CONTEXT_DATA[groupName] : AA_CONTEXT_DATA;
  },
  setAAContextData(grouping, data = [], furtherGroupBy) {
    if (!grouping) {
      Object.assign(AA_CONTEXT_DATA, {
        ...data
      });
    } else {
      Object.assign(AA_CONTEXT_DATA, {
        [grouping]: data
      });
    }
  },
  fetchApplianceInfoImage(appliance) {
    console.log("appliance: ", appliance);
    return Api.get(`/subzerowolf/imageurl?applianceType=${appliance.type}`, {
    }).then(resAsJson);
  },
  fetchSmartConnectivitysStatus(appliance) {
    return Api.get(`/appliances/smartconnectivitystatus?deviceId=${appliance.deviceId}`, {
    }).then(resAsJson);
  },
  fetchNetworkConnInfo(appliance) {
    return Api.get(`/appliances/${appliance.deviceId}/networkinfo`, {
    }).then(resAsJson);
  },
  fetchApplianceName(appliance) {
    return Api.get(`/appliances/${appliance.type}/name`, {
    }).then(resAsJson);
  },
  fetchServiceStatus() {
    return Api.get('/servicestatus', {
    }).then(resAsJson);
  },
  fetchProvisioningReport(appliance) {
    const url  =  `/consumerapp/mobileAppLogs?deviceId=${appliance.deviceId}&serialNumber=${appliance.serial}`;
    return Api.get(url, {
    }).then(resAsJson);
  },
  applianceInfoData: {
    "CONNECTED STATUS": "connectedStatus",
    "MODEL NO": "model",
    "CONTROL BOARD MODEL": "controlBoardModel",
    "SERIAL NO": "serial",
    "TYPE": "type"
  },
  diagnosticBits: "",
  connectedModule: {
    "CONNECTED MODULE VERSION": "pumaVersion",
    "MAC": "deviceId",
    "BDA": "bda"
  },
  diagPropNameMapping: {
    "WIFI_CONN": "WiFi Connected",
    "WIFI_DHCP": "DHCP Lease",
    "WIFI_PASS": "WiFi Password",
    "WIFI_PROV": "WiFi Configured",
    "TYPE_DETECTION": "Appliance Type",
    "BLUETOOTH": "Bluetooth",
    "SUPPORT_SVC_CONN": "CAE BE Online",
    "REMOTE_SVC_CONN": "Ayla BE Online",
    "REMOTE_SVC_PROPS": "Ayla Props Reachable",
    "REMOTE_TIME_SYNC": "Ayla Time Sync",
    "APPLIANCE_CONN": "Appliance Connected",
    "WAN_ACCESS": "WAN Reachable",
    "AZURE_ENABLED": "Azure Enabled",
    "unknown": "Unknown diagnostic property"
  },
  timestampFormat(time) {
    const {days = "00", hours = "00", minutes = "00", seconds = "00"} = time;
    return `${days} Days ${Util.pad(hours)}:${Util.pad(minutes)}:${Util.pad(seconds)}`;
  }
};
