/* global location Promise FormData console AuthenticationContext */
import ApiClient from "../../components/api-client";
import Config from "../../config";
import Store from "store2";
import * as msal from "@azure/msal-browser";
console.log("msal:::", msal);
//Auth Error/Failure Codes
const ERR_CODE_MSAL_INIT_NOT_DONE = 'sz_msal_init_not_done';
const msalInitError = msal.AuthError(ERR_CODE_MSAL_INIT_NOT_DONE,"Initialization failed. Contact your administrator.");

const Storage = Store.namespace(Config.appnamespace);

let authInfo;
let callbackId;
let accessToken;
const SubZeroAuthService = {
  setAuth(user) {
    authInfo = user;
    this.storeAuth(user);
  },

  getAuth() {
    return authInfo || Storage.get("Auth");
  },

  storeAuth(user) {
    Storage.set("Auth", user);
  },

  getAuthToken() {
    const auth = this.getAuth();
    return auth ? auth.token : "";
  },

  setServiceCentralUser(user){
    Storage.set('scUser',JSON.stringify(user));
  },

  getServiceCentralUser(){
    const strJson = Storage.get('scUser');
    if(strJson){
      return JSON.parse(strJson);
    }
    return null;
  },

  setMsalEventCallbackId(id){
    callbackId = id;
  },
  
  getMsalEventCallbackId(){
    return callbackId;
  },

  getAuthScopes(){
    return [`${Config.clientId}/.default`]
  },

  getAPIToken(forceRefresh = false, accountInfo) {
    if(!this.msal){
      //Make sure to initialize MSAL by calling AuthService.getAuthContextObject()
      throw msalInitError;
    }
    const msal = this.msal;
    //Request token with SilentRequest arg
    return msal.acquireTokenSilent({
      redirectUri:Config.redirectUri,
      authority:this.getAuthorityUrl(),
      account:accountInfo,
      forceRefresh: forceRefresh,
      scopes:this.getAuthScopes()
    }).then((res)=>{
        this.setAccessToken(res.accessToken);
        return res;
    });
  },
  login() {
    if(!this.msal){
      //Make sure to initialize MSAL by calling AuthService.getAuthContextObject()
      throw msalInitError;
    }
    return this.msal.loginPopup({
      scopes: this.getAuthScopes()
    });
  },
  logout() {
    if(!this.msal){
      //Make sure to initialize MSAL by calling AuthService.getAuthContextObject()
      throw msalInitError;
    }
    return this.msal.logout();
  },
  getAuthorityUrl(){
    return Config.authority+'/'+Config.tenant;
  },
  getAuthContextObject(callback) {
    if(this.msal)
      return this.msal;
    
    const msalOptions = {
      auth:{
        clientId: Config.clientId,
        authority: this.getAuthorityUrl(),
        redirectUri: Config.redirectUri,
      },
      cache:{
        cacheLocation:Config.cacheLocation,
        storeAuthStateInCookie: Config.storeAuthStateInCookie
      },
      callback: callback,
      popUp: Config.popUp,
      loadFrameTimeout: Config.loadFrameTimeout,
    }
    console.log('MSAL Config options:',msalOptions);
    return this.msal = new msal.PublicClientApplication(msalOptions);
  },
  getAccessToken() {
    return Storage.get('__at__');
  },
  setAccessToken(data) {
    Storage.set('__at__', data);
  }
};

export default SubZeroAuthService;