/* global console */
import {Filters, Transformers, ChannelFactories} from "../common";
import {getWidgets} from "../widgets";

import {AuthService} from "../../../auth";

const isPromise = type => {
      return typeof type.then === "function";
    },
    createChannelConfig = (deviceInfo, configWidgets, perApplianceType, token, networkConnInfo) => {
      return {
        "name": `${deviceInfo.name} ${deviceInfo.model}`,
        "style": {
          "cssClass": "generic-dashboard"
        },
        "datalayer": {
          "name": "Generic Data",
          "channels": [
            // Diagnostic channel common to all dashboards
            ChannelFactories.userInfo(deviceInfo, token),
            ChannelFactories.diagnostics(deviceInfo, token),
            ChannelFactories.timeZone(deviceInfo, token),
            ChannelFactories.parameters(deviceInfo, token),
            ChannelFactories.applianceInfo(deviceInfo, token),
            ChannelFactories.currentState(),
            ChannelFactories.networkConnInfo(deviceInfo, networkConnInfo),
            ChannelFactories.mobNotificationInfo(deviceInfo, token),
            ChannelFactories.provisioningReport(deviceInfo,token),
            {
              "name": "/appliance/pollingFrequency",
              "type": "extended",
              "config": {
                "from": "/appliance/properties",
                "transform": data => {
                  return {frequency: data.active_polling_frequency};
                }
              }
            }
          ]
        },
        "widgets": getWidgets(deviceInfo, configWidgets, perApplianceType)
      };
    };

export default (deviceInfo, configWidgets, perApplianceType, networkConnInfo) => {
  const promiseToken = AuthService.getAPIToken();
  return promiseToken.then(authRes => {
    return createChannelConfig(deviceInfo, configWidgets, perApplianceType, authRes.accessToken, networkConnInfo);
  });
};
