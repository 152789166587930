/* global setTimeout */
import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import AnswerAdviser from "../../../modules/answer-adviser";

import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import Config from "../../../config";
import Util from "../../util";

const Widget = AsSubscribedComponent(ReactClass({
  renderComponent() {
    let param = AnswerAdviser.getCRMTicket();
		const {CRM_TICKET_ID} = param,
				crmTicketId = CRM_TICKET_ID ? `&CRM_TICKET_ID=${CRM_TICKET_ID}` : "";
    const {data: {appliance, latest: {records: diagnostics}, answerId}} = this.state,

        aaLink = Object.keys(diagnostics).length > 0
        ? (<div onClick={() => Util.trackAAEvent(Config.answerAdvisorLink)}>
          <a target="_blank" href={Config.answerAdvisorLink}>
          Answer Advisor
          </a>
       </div>)
        : this.state.flag === true
            ? (<span className="data-not-available"
                title="No Data to generate AA link">
              Answer Advisor
            </span>)
            : (<span>Please wait...</span>);
    return (
      <div className="answer-adviser">
        {/* {aaLink} */}
      </div>
    );
  },

  getInitialState() {
    return {
      data: {
        appliance: {
          pumaVersion: "",
          deviceId: "",
          bda: ""
        },
        latest: {
          records: {}
        }
      },
      flag: false
    };
  },

  checkDiagStatus(status) {
    const {data: {latest: {records: diagnostics = {}}}} = this.state,
        diagnosticsArray = Object.keys(diagnostics);
    return diagnosticsArray.length && diagnosticsArray.some(diagnostic => diagnostics[diagnostic] === status);
  },

  getDiagStatus() {
    if(this.checkDiagStatus("BAD"))
      return {className: "icon-alert-triangle status", title: "One or more diagnostic bits are bad"};
    else if(this.checkDiagStatus("UNKNOWN"))
      return{className: "icon-question_icon status", title: "One or more diagnostic bits are unknown"};
    else if(this.checkDiagStatus("GOOD"))
      return {className: "icon-check-circle status", title: "All diagnostic bits are good"};
    else
      return {};
  },

  render() {
    const {Header, Content} = WidgetPanel,
        panelContent = this.renderComponent(),
        status = this.getDiagStatus();

    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{this.props.title}</span>
          { Object.keys(status).length > 0
            ? <i className={`_pull-right icon ${status.className}`} title={status.title} />
            : null }
        </Header>
        {/* <Content>
          {panelContent}
        </Content> */}
      </WidgetPanel>
    );
  },
  onData(data, channel) {
    const {latest: {records}} = data,
        len = Object.keys(records).length;
    this.setState({
      channel: channel,
      data: data,
      flag: len === 0 ? true : false
    });
  }
}));

const Info = {
  id: "appliance-dashboard:consumerAppConnectivity",
  name: "Consumer App Connected Module",
  type: Widget,
  description: "Shows Consumer App Connected Module",
  icon: "",
  tags: "consumer app connectivity"
};

export default {
  Info,
  Widget
};
