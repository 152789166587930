import React from "react";
import ReactClass from "create-react-class";

import AuthService from "../auth/Service";
import Config from "../../config";
import util from "../../components/util";


export default ReactClass({
  displayName: "Navigation",
  getInitialState() {
    return ({
      toogleSubNav: false
    });
  },
  matchesPath(item) {
    const {route: {path}} = this.props, {activeWhen = []} = item;
    return (path && path.indexOf(item.target) > -1) || activeWhen.some(reg => reg.test(path));
  },

  onNavClick(item) {
    const {application: app} = this.props;
    if(item.target) {
      app.route(item.target);
      if(item.label != 'Appliance Search'){  
        util.trackEventWithProperties('CAT_PROJECT', 'Cat Project',{
          name:item.label
        });
      }
    }else {
      item.callback(app);
    }
  },

  toggleSubItems() {
    this.setState({
      expandSubNav: !this.state.expandSubNav
    });
    // if(!this.props.expand)
    //   this.props.toggle();
  },

  createSubNav(item, itemClass, i) {
    const subItem = this.state.expandSubNav
      ? (item.subItems.map((item, j) => {
        const subItemClass = this.matchesPath(item) ? "item subitem selected" : "item subitem";
        return (<li key={item.label + "_sub_" + j} className={subItemClass}
          onClick={this.onNavClick.bind(this, item)}>
          {item.label}
        </li>);
      }))
      : null;
    return (<ul key={`sub-nav-`+i}>
      <li key={item.label + i} className={itemClass}
        onClick={this.toggleSubItems}>
        <i className={"icon " + item.icon} />
        {item.label}
        <span className="pull-right">
          {(this.state.expandSubNav
            ? <i className={"icon icon-minus"} />
            : <i className={"icon icon-plus"} />)}
        </span>
      </li>
      <ul className="sub-menu">
        {subItem}
      </ul>
    </ul>);
  },
  render() {
    const items = this.props.navList
      ? this.props.navList.map((item, i) => {
        const itemClass = `heading item ${this.matchesPath(item) ? "selected" : ""}`;
        if (item.subItems) {
          return this.createSubNav(item, itemClass, i);
        } else {
          return (
            <ul key="sub-nav">
              <li key={item.label + i} className={itemClass}
                onClick={this.onNavClick.bind(this, item)}>
                <i className={"icon " + item.icon} />{item.label}
              </li>
            </ul>
          );
        }
      })
    : null;
    let navClassName = "navigation" +
        (this.props.expand ? " expand" : "");
    if(navClassName.indexOf("expand") === -1) {
      navClassName += (this.props.expand && this.state.expandSubNav) ? " expand" : "";
    }

    return ([
      <div key="nav-expand" className={navClassName}
        onMouseOver={() => this.props.toggle(true)}
        onMouseLeave={() => this.props.toggle(false)}>
        <a className="navigation-pin" onClick={this.props.pinLeftNav}>
          <img src="images/pin.svg" alt="" width="9" height="18" title="Sticky" />
        </a>
        <ul key="nav" className="menu">
          {items}
        {(this.props.busy
          ? <li className="anim spin-loader">
              <span className="icon spin icon-loader"></span>
            </li>
          : null)}
        </ul>
      </div>,
      <div key="mask-navigation-mob" className="mask-navigation show-mob-view"
        onClick={() => this.props.toggle(false, true)}></div>,
      <div key="mask-navigation-web" className="mask-navigation show-web-view"
        onClick={() => this.props.toggle(false)}></div>
    ]);
  }
});
