import {EmbedInWidget, WidgetPanel} from "ui-dashboard";
import UnitStatisticsInfo from "./Component";
import React from "react";

export default data => {
  const dashboard = {
    "name": "Unit Statistics",

    "style": {
      "cssClass": "unit-statistics-dashboard"
    },
    "datalayer": {
      "name": "Unit Statistics",
      "channels": []
    }
  };

  dashboard.widgets = Object.keys(data.applianceData).map((wInfo, i) => {
    return {
      id: "widget_" + i,
      type: UnitStatisticsInfo,
      cols: data.applianceType === "OVEN_E_SERIES" ? 12 : 4,
      title: wInfo,
      widgets: data.applianceData[wInfo]
    };
  });

  return dashboard;
};
