/* global */
import React, {Fragment} from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import {ProgressBar} from "../../../../../components/widgets";
import Config from "../../../../../config";

const widgetPath = `${Config.baseDir}modules/enduser-app/dashboard/widgets/water-filter`,
    Keys = {
      END_DATE: "water_filter_end_date",
      GALLONS_REMAINING: "water_filter_gal_remaining",
      PERCENT_REMAINING: "water_filter_pct_remaining"
    },
    getDaysRemaining = strDate => {
      if(! strDate) {
        return "Unknown";
      }
      const targetDate = new Date(strDate), targetTime = targetDate.getTime();
      if(isNaN(targetTime)) {
        return "Unknown";
      }
      return Math.floor((targetTime - new Date().getTime()) / 1000 / 60 / 60 / 24);
    },
    Widget = AsSubscribedComponent(ReactClass({
      displayName: "WaterFilterWidget",
      propTypes: {
        thresholdMin: PropTypes.number.isRequired,
        title: PropTypes.string,
        icon: PropTypes.string,
        iconDisabled: PropTypes.string
      },
      getInitialState() {
        return {};
      },
      componentDidUpdate() {
        this.props.layout.layout();
      },
      render() {
        const {Header, Content} = WidgetPanel,
            {
              title = "Water Filter",
              icon = `${widgetPath}/waterFilter.png`,
              iconDisabled = icon,
              panelClass = ""
            } = this.props,
            {disabled} = this.state,
            renderedIcon = disabled ? iconDisabled : icon,
            {data} = this.state,
            {
              thresholdMin = 0
            } = this.props,
            daysRemaining = data ? data.daysRemaining : 0,
            gallonsRemaining = data ? data.gallonsRemaining : 0,
            hasAlert = data && (daysRemaining < thresholdMin || gallonsRemaining === 0);

        let content;
        if(hasAlert) {
          content = this.renderAlert();
        }else {
          content = this.renderValue();
        }

        return (
          <WidgetPanel {...this.props}
            panelClass={`water-filter ${panelClass} ${hasAlert ? "alert-on" : ""}`}>
            <Header>
              <img className="icon" src={renderedIcon} alt="Icon" />
              <span className="title">{title}</span>
            </Header>
            <Content>
              {content}
            </Content>
          </WidgetPanel>
        );
      },
      renderAlert() {
        const {alertMessage = "Alert!! Replace!"} = this.props;
        return (
          <div className="message">
            <i className="icon icon-alert-triangle"></i>
            <p className="">{alertMessage}</p>
          </div>
        );
      },
      renderValue() {
        const {data} = this.state,
            value = data ? `${data.daysRemaining} Days` : "...",
            percentValue = data ? data.percentRemaining : 0;
        return (
          <Fragment>
            <div className="value">{value}</div>
            <ProgressBar value={percentValue} />
          </Fragment>
        );
      },
      onData(data, channel) {
        if(!data) {
          return;
        }

        const daysRemaining = getDaysRemaining(data[Keys.END_DATE]);

        this.setState({
          channel: channel,
          data: {
            daysRemaining,
            gallonsRemaining: data[Keys.GALLONS_REMAINING],
            percentRemaining: data[Keys.PERCENT_REMAINING]
          }
        });
      }
    })),

    Info = {
      id: "eua-dashboard:water-filter",
      name: "Air Filter Widget",
      description: "Enduser app dashboard air filter widget",
      type: Widget
    };

export default {
  Info,
  Widget
};
