import {routes as authRoutes} from "./modules/auth";
import {routes as dashboardRoutes} from "./modules/dashboard";
import {routes as graphRoutes} from "./modules/graph";
import {routes as catProjectRoutes} from "./modules/cat-projects";
import {routes as logRoutes} from "./modules/view-logs";
import {routes as unitStatisticsRoutes} from "./modules/unit-statistics";
import {routes as faultRouts} from "./modules/faults";
import {routes as adminRoutes} from "./modules/admin";
import {routes as mobileAppsLogRoutes} from "./modules/consumer-apps-logs";
import ErrorView from "./modules/common/ErrorView";
import {routes as aboutRoutes} from "./modules/about";

export default [
  {
    path: "/route-error",
    controller: context => {
      return {
        component: ErrorView,
        context
      };
    }
  },
  ...authRoutes,
  ...dashboardRoutes,
  ...catProjectRoutes,
  ...graphRoutes,
  ...logRoutes,
  ...unitStatisticsRoutes,
  ...faultRouts,
  ...adminRoutes,
  ...mobileAppsLogRoutes,
  ...aboutRoutes
];
