import Util from "../../../components/util";
/**
 *
 * @param {*} records
 * @returns
    // Input - records
    {
      "data_points": {
        "Upper Wine": {
          "Standard Data": {
            "input-label": input-value,
            ...
          },
          "Unique Data": {
            "input-label": input-value,
            ...
          }
        },
        ...
      }
    }
    // output
    [
      {
        tabName: "Upper Wine",
        attributes: [
          {
            label: "input-label",
            value: "input-value",
            valueTypeClassName: "", // If input-label contains "temperature" then add class "temp"
            "liClassName": "", Consider new tab & add class for dividing two sections (standard | unique) using "divider" class
            tooltip: {
              tooltipProps: {
                // tooltip props of <StatefulToolTip /> component
              },
              text: "",
              icon: ""
            }
          }
        ]
      }
    ]
 */
const KEY_PAGINATION_DATA = "Paginated Data";
const buildTransformedAttrObject = (attr,subAttr)=>{
  let orderArray = []
  attr.forEach((label, j) => {
        if(label == KEY_PAGINATION_DATA){
          return;//continue processing rest of the attribues
        }
        // let extraClassName = "",
        let extraColorName = "",
          // value = subAttr[label];
          // value = records[tabName][stdOrUnique][label].value;
          value = "format" in subAttr[label] 
            ? ( subAttr[label].value? Util.formatDate(new Date(parseInt(subAttr[label].value)), subAttr[label].format, false):'N/A' )
            : subAttr[label].value;

          extraColorName = subAttr[label].color ? subAttr[label].color  : "";
          let order = subAttr[label].order;
        // if(label.toLowerCase().indexOf("average temperature") > -1) {
        //   extraClassName = `${(value < 5 ? "normal" : (value >= 5 && value <= 10 ? "warning" : "danger"))}`
        // } else if(label.toLowerCase().indexOf("door opens (24hr)") > -1) {
        //   extraClassName = `${(value > 24 ? "count danger" : "")}`
        // }

        let json = {
          "label": label,
          "value": value,
          // "valueTypeClassName": `${label.toLowerCase().indexOf("temperature") > -1 ? "temp" : ""} ${extraClassName}`,
          "valueTypeColorName": `${extraColorName}`,
          // "liClassName": `${i > 0 && j == 0 ? "divider" : ""}`,
          "order": order
        }
        const mLabel = label.toLowerCase();
        if (mLabel.indexOf("average temperature - cabinet") > -1 
            || mLabel.indexOf("average temperature - drawer") > -1 ) {
          json.tooltip = {
            "tooltipProps": {
              "position": "right"
            },
            "text": "This temperature may vary up to 6°F from setpoint by design.",
            "icon": "icon icon-info"
          }
        }
        // attributes.push(json)
        orderArray.push(json)
      })
    ;
   return orderArray;
}
const buildPaginatedData = (paginatedData)=>{
  let paginatedModified = {
    label:"Paginated Data",
    value:"",
    numberOfPages:paginatedData.length,
    pages:{
    }
  };
  paginatedData.forEach((currentPage,i)=>{
    //attribues array
    let attr = Object.keys(currentPage);
    let attrArray = buildTransformedAttrObject(attr,currentPage);
    attrArray.sort((a,b) => {
      return a.order - b.order;
    });
    paginatedModified.pages[i.toString()] = attrArray;
  });
  return paginatedModified;
}
const transFormSZDataPoints = (records) => {
  const tabs = Object.keys(records);

  return tabs.reduce((acc, tabName, ind) => {
    const stdOrUniqueArray = Object.keys(records[tabName]);

    let attributes = []
    stdOrUniqueArray.forEach((stdOrUnique, i) => {
      const subAttr = records[tabName][stdOrUnique],
        attr = Object.keys(subAttr)
      let orderArray = buildTransformedAttrObject(attr,subAttr);
      //process paginated data if available
      let paginatedData = subAttr[KEY_PAGINATION_DATA];
      let paginatedModified;
      if(paginatedData){
        paginatedModified = buildPaginatedData(paginatedData);
      }
    
      orderArray.sort((a,b) => {
        return a.order - b.order;
      })
      attributes = [...attributes, ...(i > 0 
        ? orderArray.map((ele, idx) => {return {...ele, liClassName: idx == 0 ? "divider" : ""}})
        : orderArray)]
      if(paginatedData){
        attributes.push(paginatedModified);
      }
    })
    acc.push({
      tabName,
      attributes
    })

    return acc;
  }, [])
};

export default {
  transFormSZDataPoints
}