/* global console setTimeout Promise */
import pathToRegexp from "path-to-regexp";
import {createHashHistory} from "history";

import Util from "./util";

const isPromise = type => {
  return typeof type.then === "function";
};

/*
const EventEmitterProto = {
  on(event, handler) {
    const handlers = this.eventHandlers[event] || (this.eventHandlers[event] = []);
    handlers.push(handler);
    return {
      dispose() {
        const index = handlers.indexOf(handler);
        if(index !== -1) {
          handlers.splice(index, 1);
        }
      }
    };
  },
  once(event, handler) {
    let subs;
    subs = this.on(event, (...args) => {
      subs.dispose();
      handler(event, ...args);
    });
  },
  emit(event, ...args) {
    let handlers = this.eventHandlers[event] || [];
    handlers.forEach(h => {
      h(event, ...args);
    });
  }
};
*/


const RouterProto = {
  // constructor: Router,
  on(evt, handler) {
    this.emitter.on(evt, handler);
  },
  matches(path) {
    return this.routes.some(route => route.pattern.test(path));
  },
  match(path) {
    let params, matchedRoute;
    this.routes.some(route => {
      const res = route.pattern.exec(path);
      if(res) {
        matchedRoute = route;
        params = {};
        route.keys.forEach((key, i) => {
          params[key.name] = res[i + 1];
        });
        return true;
      }
      return false;
    });
    if(matchedRoute) {
      return {
        path: path,
        params: params,
        controller: matchedRoute.controller
      };
    }
    return null;
  },
  resolve(path, context = {}) {
    const routeInfo = this.match(path);
    if(routeInfo) {
      const ctx = Object.assign({}, context, {
            route: {
              path: routeInfo.path,
              params: routeInfo.params
            }
          }),
          controller = routeInfo.controller;
      if(typeof controller === "function") {
        let ret = routeInfo.controller(ctx);
        if(!isPromise(ret)) {
          ret = Promise.resolve(ret);
        }
        ret.then(data => {
          if(data.redirect) {
            return this.resolve(ret.redirect, {
              redirect: true,
              from: routeInfo.path
            });
          }else {
            this.emitter.emit("route", ret);
            return data;
          }
        });
        // ret.then(() => this.emitter.emit("route", routeInfo));
        return ret;
      }else {
        return Promise.resolve(controller);
      }
    }
    return Promise.reject(`Route not found ${path}`);
  },
  route(path) {
    path === this.history.location.pathname
      ? this.history.replace(path)
      : this.history.push(path);
  },
  start(listener) {
    if(!this.history) {
      const history = this.history = createHashHistory({
        getUserConfirmation(message, callback) {
          callback(true);
          /*
          setTimeout(() => {
            const val = !!Math.round(Math.random());
            console.log(message, val);
            callback(val);
          }, 1000);
          */
        }
      });
      history.block((location, action) => {
        return "Are you sure?";
      });
      this.stopHistoryListener = history.listen((location, action) => {
        const path = location.pathname || "/error";
        listener(path, action, location);
      });
    }
  },
  stop() {
    if(this.history) {
      this.stopHistoryListener();
      this.history = null;
      this.stopHistoryListener = null;
    }
  },
  addRoutes(routes = []) {
    routes.forEach(r => {
      this.routes.push(makeRoute(r));
    });
    console.log(this.routes);
  }
};


const makeRoute = route => {
  const keys = [], pattern = pathToRegexp(route.path, keys);
  return {
    path: route.path,
    controller: route.controller,
    pattern,
    keys
  };
};


export default {
  create(routes) {
    return Object.create(RouterProto, {
      routes: {
        value: routes.map(makeRoute)
      },
      emitter: {
        value: Util.createEventEmitter()
      }
    });
  }
};
