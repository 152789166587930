export const mapping = {
  "External Ice Disp": "modules/unit-statistics/images/exdisp_icon.svg",
  "External Water Disp": "modules/unit-statistics/images/exdisp_icon.svg",
  "Overview": "modules/unit-statistics/images/overview_icon.svg",
  "Usage": "modules/unit-statistics/images/usage_icon.svg",
  "Air Purifier": "modules/unit-statistics/images/air_icon.svg",
  "Water System": "modules/unit-statistics/images/water_icon.svg",
  "Cooling System": "modules/unit-statistics/images/cooling_icon.svg",
  "Upper Oven": "modules/unit-statistics/images/upoven_icon.svg",
  "Lower Oven": "modules/unit-statistics/images/dnoven_icon.svg",
  "Door Info": "modules/unit-statistics/images/door_icon.svg",
  "Accent Lighting": "modules/unit-statistics/images/light_icon.svg"
};
