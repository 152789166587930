import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";

import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import Util from "../../util";
import DashboardService from "../../../modules/dashboard/Service";
import Config from "../../../config";

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "TimeZone",
  getInitialState() {
    return {
      appliance: {},
      data: {
        currentDeviceTimeZone: {},
        timeZones: [],
      },
      isTimeZone: true,
      currentDeviceTimeZoneId: {},
      isLoading: true,
    };
  },
  componentDidUpdate() {
    this.props.layout.layout();
  },
  fetchTimezoneValue(event) {
    let {data: {currentDeviceTimeZone, timeZones}} = this.state;
    if(event.target.value !== "") {
      if(currentDeviceTimeZone.id !== event.target.value) {
          this.setState({
            isTimeZone: false,
            currentDeviceTimeZoneId: timeZones.find(obj => obj.id === event.target.value)
          })
      } else {
        this.setState({
          isTimeZone: true,
          currentDeviceTimeZoneId: {
            id: currentDeviceTimeZone.id,
            utcOffset: currentDeviceTimeZone.utcOffset
          }
        })
      }
    } else {
      this.setState({
        isTimeZone: true
      })
    }
  },
  updateNewTimeZone() {
    let {data: {currentDeviceTimeZone, timeZones}} = this.state;
    this.setState({
      isLoading: true
    })
    if(Object.keys(currentDeviceTimeZone).length === 0) {
        DashboardService.createTimeZoneId(this.state.appliance, this.state.currentDeviceTimeZoneId).then((res) => {
          this.setState({
            isTimeZone: true,
            data: {
                currentDeviceTimeZone: this.state.currentDeviceTimeZoneId,
                timeZones: timeZones,
              }
          });
      })
      .catch((err) => {
        this.setState({
          isTimeZone: true
        });
      })
    } else {
      DashboardService.updateTimeZoneId(this.state.appliance, this.state.currentDeviceTimeZoneId).then((res) => {
        console.log("Time zone updated successfully");
        this.setState({
          isTimeZone: true,
          data: {
              currentDeviceTimeZone: this.state.currentDeviceTimeZoneId,
              timeZones: timeZones,
            }
        });
      }).catch((err) => {
        this.setState({
          isTimeZone: true
        });
      })
    }
    setTimeout(() => {
      this.setState({
        isLoading: false
      })
    }, 2000);
  },
  renderComponent() {
    let {data: {currentDeviceTimeZone, timeZones = []}, isLoading} = this.state;
    const {onAction} = this.props;
    onAction && onAction("timeZone", []);
    let items = [];
    items =  <select onChange={this.fetchTimezoneValue} >
    <option key="" value="">Select a timezone</option>
    {timeZones && timeZones.map((timezone) => {
      return (
      <option
        key={timezone.id}
        // value={{
        //   timeZoneId: timezone.id,
        //   utcOffset: timezone.utcOffset
        // }}
        value={timezone.id}
        selected={currentDeviceTimeZone.id === timezone.id}
      >{timezone.displayName}
      </option>)
    })}
  </select>;

    return (
      <ul className="h-list error-codes" style={{
        display: "flex"
      }}>
        {items}
        <button 
          style={{
            marginLeft: "2%",
            width: "17%",
            backgroundColor: "#e8e8e8"
          }}
          disabled={this.state.isTimeZone}
          onClick={this.updateNewTimeZone}
        >
          {!isLoading ? 
            <img
              src={!this.state.isTimeZone ? `${Config.baseDir}images/tick_icon.svg` : `${Config.baseDir}images/tick_dark_icon.svg`}
              alt="Icon"
              style={{
                width: "18px"
              }}
            /> : 
            <i className="anim icon icon-loader spin"></i> }
        </button>
      </ul>
    );
  },

  render() {
    const {Header, Content} = WidgetPanel,
        panelContent = this.renderComponent();
    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{this.props.title}</span>
        </Header>
        <Content>
          {panelContent}         
        </Content>
      </WidgetPanel>
    );
  },

  onData(response, channel) {
    if(response.data.currentDeviceTimeZone.timeZoneId) {
      this.setState({
        appliance: response.appliance,
        channel: channel,
        data: {
          currentDeviceTimeZone: {
            isDSTActive: response.data.currentDeviceTimeZone.isDSTActive,
            isDSTObserved: response.data.currentDeviceTimeZone.isDSTActive,
            id: response.data.currentDeviceTimeZone.timeZoneId,
            utcOffset: response.data.currentDeviceTimeZone.utcOffset,
          },
          timeZones: response.data.timeZones,
        }
      });
    } else {
      this.setState({
        appliance: response.appliance,
        channel: channel,
        data: {
          currentDeviceTimeZone: {},
          timeZones: response.data.timeZones,
        }
      })
    }
    this.setState({
      isLoading: false
    })
  }
}));

const Info = {
  id: "appliance-dashboard:time-zone",
  name: "Appliance Time Zone",
  type: Widget,
  description: "Shows current appliance's time zone",
  icon: "",
  tags: "appliance time-zone"
};

export default {
  Info,
  Widget
};
