/* global console */

import React from "react"; // This import is required even if it isn't used
import PropTypes from "prop-types";
import ReactClass from "create-react-class";

import {Layout} from "ui-dashboard";
import {DataLayer} from "ui-datalayer";

const widgetActionHandler = (id, action, data) => {
  console.log("WidgetAction", id, action, data);
};

export default ReactClass({
  displayName: "Dashboard",
  propTypes: {
    config: PropTypes.object,
    onWidgetAction: PropTypes.func,
    onChange: PropTypes.func,
    context: PropTypes.object
  },

  getInitialState() {
    return {
      DataLayer: null
    };
  },

  componentWillMount() {
    const {config} = this.props;
    this.initializeDashboard(config);
  },
  componentWillUnmount() {
    this.destroyChannels();
  },

  componentWillReceiveProps(nextProps) {
    const {config} = this.props;
    if(nextProps.config !== config) {
      if(this.DataLayer) {
        this.destroyChannels();
      }
      this.initializeDashboard(nextProps.config);
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.config !== nextProps.config;
  },

  handleWidgetAction(id, actionName, data) {
    const {onWidgetAction = widgetActionHandler} = this.props;
    return onWidgetAction(id, actionName, data);
  },

  render() {
    const {config: {style = {}, widgets: widgetConfig}, context} = this.props,
        widgets = widgetConfig.map(w => {
          let widget = Object.assign({}, w);
          widget.onAction = (act, data) => {
            return this.handleWidgetAction(w.id, act, data);
          };
          return widget;
        });
    return (
      <div className={"dashboard " + style.cssClass}>
        <Layout DataLayer={this.DataLayer} widgets={widgets} context={context}></Layout>
      </div>
    );
  },

  initializeDashboard(config) {
    const {datalayer: datalayerConfig} = config;
    this.DataLayer = DataLayer(datalayerConfig.name);
    this.createChannels(datalayerConfig);
  },

  componentDidUpdate(previousProps, previousState) {
    this.props.onChange && this.props.onChange(this.DataLayer);
  },
  componentDidMount() {
    this.props.onChange && this.props.onChange(this.DataLayer);
  },
  createChannels(datalayerConfig) {
    const {channels = []} = datalayerConfig, DataLayer = this.DataLayer;
    channels.forEach(config => {
      DataLayer.createChannel(config);
    });
  },

  destroyChannels() {
    if(this.DataLayer) {
      this.DataLayer.destroyAllChannels();
    }
  }
});
