/* global */
import React, {Fragment} from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import Config from "../../../../../config";

const defaultTransform = data => data,
    Widget = AsSubscribedComponent(ReactClass({
      displayName: "OptionsWidget",
      propTypes: {
        title: PropTypes.string,
        icon: PropTypes.string,
        iconDisabled: PropTypes.string
      },
      getInitialState() {
        return {};
      },
      componentDidUpdate() {
        this.props.layout.layout();
      },
      render() {
        const {Header, Content} = WidgetPanel,
            {title, icon, iconDisabled = icon} = this.props,
            {disabled} = this.state,
            renderedIcon = disabled ? iconDisabled : icon,
            panelContent = this.renderContent();

        return (
          <WidgetPanel {...this.props} panelClass="options">
            <Header>
              <img className="icon" src={renderedIcon} alt="Icon" />
              <span className="title">{title}</span>
            </Header>
            <Content>
              {panelContent}
            </Content>
          </WidgetPanel>
        );
      },
      renderContent() {
        const {data} = this.state;
        if(!data) {
          return (<span>...</span>);
        }
        const value = data || [],
            optionStr = value.length == 1 ? "option" : "options",
            message = `${value.length || "No"} ${optionStr} in use`;
        return (
          <Fragment>
            <span className="message">{message}</span>
            <span className="options">{[...value].join(", ")}</span>
          </Fragment>
        );
      },
      onData(data, channel) {
        const {transform = defaultTransform} = this.props;
        if(!data) {
          return;
        }

        this.setState({
          channel: channel,
          data: transform(data)
        });
      }
    })),

    Info = {
      id: "eua-dashboard:options",
      name: "Options Widget",
      description: "Enduser app dashboard options widget",
      type: Widget
    };

export default {
  Info,
  Widget
};
