/* global setTimeout clearTimeout console window */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import * as _ from "lodash";

const stringValue = value => value === null || typeof(value) === "undefined" ? "" : String(value);

const Form = ReactClass({
  displayName: "Form",
  propTypes: {
    rules: PropTypes.object,
    onChange: PropTypes.func
  },

  getInitialState() {
    const fields = {};
    React.Children.forEach(this.props.children, (child, i) => {
      const fld = this.createFieldProps(child, i),
          fldInfo = Object.assign({}, fld, {
            valid: true,
            pristine: true,
            value: fld.defaultValue || ""
          });
      // console.log(fldInfo);
      fields[fld.name] = fldInfo;
    });
    return {
      pristine: true,
      valid: true,
      fields
    };
  },

  validateField(name, value, fields) {
    const {rules = {}} = this.props,
        // {fields} = this.state,
        fieldRules = rules[name];
    let invalidResult;
    if(fieldRules) {
      fieldRules.some(r => {
        const v = r(value, fields[name], fields);
        if(v && !v.valid) {
          invalidResult = v;
          return true;
        }
        return false;
      });
    }
    return invalidResult;
  },

  isFormValid(fields) {
    let valid = true;
    Object.keys(fields).some(k => {
      const v = this.validateField(k, fields[k].value, fields),
          fieldValid = v ? v.valid : true;
      if(!fieldValid) {
        valid = false;
        return true;
      }
    });
    return valid;
  },

  handleFieldChange(name, event) {
    const {target} = event,
        type = target.type,
        value = (type === "checkbox" || type === "radio") ?
            target.checked : target.value || target.textContent,
        {fields} = this.state;

    let validation = this.validateField(name, value, fields),
        fieldValid = validation ? validation.valid : true;
    const newFields = Object.assign({}, fields, {
          [name]: {
            valid: fieldValid,
            message: validation ? validation.message : "",
            label: fields[name].label,
            pristine: false,
            value
          }
        }),
        newState = {
          valid: fieldValid ? this.isFormValid(newFields) : false,
          pristine: false,
          fields: newFields
        };
    if(newState.valid) {
      Object.keys(newState.fields).forEach(k => {
        const f = newFields[k];
        f.valid = true,
        f.message = "";
      });
    }
    this.setState(newState);
    // console.log(newState);
    this.props.onChange && this.props.onChange(newState);
  },

  componentDidMount() {
    setTimeout(() => {
      const {fields, pristine} = this.state,
          valid = this.isFormValid(fields),
          newState = {
            valid,
            pristine,
            fields
          };
      // console.log("Did mount", newState);
      this.setState(newState, () => {
        this.props.onChange && this.props.onChange(newState);
      });
    }, 200);
  },

  createFieldProps(fld, index) {
    const {name} = fld.props,
        fieldId = fld.key || "field_" + index,
        fieldName = name || fieldId;
    return Object.assign(
      {
        id: fieldId,
        name: fieldName
      },
      fld.props,
      {className: "field"}
    );
  },

  renderField(fld, index) {
    const newProps = this.createFieldProps(fld, index),
        {name, label, className = ""} = newProps,
        showLabel = newProps["data-showlabel"] === false ? false : true,
        hint = newProps["data-hint"],
        fieldModel = this.state.fields[name],
        events = {
          onChange: e => {
            this.handleFieldChange(name, e);
            newProps.onChange && newProps.onChange(e);
          },
          onInput: e => {
            this.handleFieldChange(name, e);
            newProps.onInput && newProps.onInput(e);
          }
        };

    return (
      <div className={`field-container type-${newProps.type || ""} valid-${fieldModel.valid} ${name}`}>
        {
          (label && showLabel) ?
              <label htmlFor={newProps.id}>
                <span>{label} </span>
                {hint ? <span className="hint">{hint}</span> : ""}
              </label>
              :
              ""
        }
        {/* field */}
        <fld.type className={className} {...newProps} {...events}>{fld.props.children}</fld.type>
        {!fieldModel.valid ? <span className="v-msg hint">{fieldModel.message}</span> : ""}
      </div>
    );
  },

  preventSubmit(e) {
    e.preventDefault();
    return false;
  },

  render() {
    // console.log("Children", this.props.children);
    const fields = React.Children.map(this.props.children, (fld, i) => {
      return (this.renderField(fld, i));
    });
    return (
      <form onSubmit={this.preventSubmit} className={"form " + (this.props.className || "")}>
        {fields}
      </form>
    );
  }
});



const MultiValueInput = ReactClass({
  displayName: "MultiValueInput",
  propTypes: {
    delimiter: PropTypes.any,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    values: PropTypes.array,
    validator: PropTypes.object,
    isDisabled: PropTypes.bool
  },

  getDefaultProps() {
    return {
      delimiter: /\s|\n|\r\n/,
      onChange: () => {},
      placeHolder: "",
      values: [],
      isDisabled: false,
      searchValue: ''
    };
  },

  getInitialState() {
    const {validator} = this.props,
        states = {
          values: this.props.values || []
        };
    if(validator) {
      states.valid = true;
    }
    return states;
  },

  componentWillReceiveProps(nextProps = []) {
    this.state.values = nextProps.values;
  },

  setSearchValue(v) {
    if(this.state.searchValue) {
      this.setState({
        values: [...this.state.valus, this.state.searchValue]
      });
      this.setState({
        searchValue: ''
      });
    }
    this.setState({
      searchValue: v
    });
    this.removeValue(v);
  },

  setInputValue(e) {
    this.setState({
      searchValue: e.target.value
    });
  },

  handleKeyAction(e) {
    const {validator} = this.props,
        {keyCode} = e,
        val = this.input.value;
    if(validator) {
      this.isValidValues(val, false);
    }
    if(keyCode === 13 || keyCode === 9) { // Enter or tab key
      if(validator) {
        this.isValidValues(val, true);
      } else {
        this.storeValue(val);
        this.input.value = "";
        this.setState({
          searchValue: ''
        });
      }
    }
  },

  handleBlur(e) {
    const {validator} = this.props,
        val = this.input.value;
    if(validator) {
      this.isValidValues(val, true);
    } else {
      this.storeValue(val);
      this.input.value = "";
      this.setState({
        searchValue: ''
      });
    }
    this.setState({
      searchValue: ''
    });
  },

  handlePaste(e) {
    const {validator, delimiter} = this.props;
    let val = "";
    if (window.clipboardData && window.clipboardData.getData) { // IE
      val = window.clipboardData.getData("Text");
    } else if (e.clipboardData && e.clipboardData.getData) {
      val = e.clipboardData.getData("text/plain");
    }

    val = (_.uniq(val.split(delimiter).filter(v => v.length))).join(" ");

    if(validator) {
      this.isValidValues(val, true);
    } else {
      this.storeValue(val);
      this.input.value = "";
      this.setState({
        searchValue: ''
      });
      this.input.focus();
    }
    e.preventDefault();
    e.stopPropagation();
  },

  isValidValues(val, flag) {
    const {values} = this.state, {delimiter} = this.props;
    let valid = true;
    if(!val) {
      valid = true;
    }
    const newVals = val.split(delimiter).filter(v => v.length && values.indexOf(v) === -1);
    if(newVals.length > 0) {
      const validVals = [],
          invalidVals = [];
      newVals.forEach(v => {
        if(this.validate(v)) {
          validVals.push(v);
        } else {
          invalidVals.push(v);
        }
      });
      if(validVals.length === newVals.length) {
        valid = true;
        if(flag) {
          this.storeValue(val);
          this.input.value = "";
          this.setState({
            searchValue: ''
          });
          this.input.focus();
        }
      } else {
        valid = false;
        if(flag) {
          if(validVals.length > 0) {
            this.storeValue(validVals.join(" "));
          }
          this.input.value = invalidVals.join(" ");
          this.input.focus();
        }
      }
    } else {
      valid = true;
    }
    this.setState({
      valid
    });
  },

  validate(val) {
    const {validator: {pattern: regExp}} = this.props;
    val = stringValue(val);
    if(!regExp.test(val)) {
      return false;
    }
    return true;
  },

  storeValue(val) {
    const {values} = this.state, {onChange, delimiter} = this.props;
    if(val.trim().length) {
      const newVals = val.split(delimiter).filter(v => v.length && values.indexOf(v) === -1),
          newValues = newVals.length ? [...values, ...newVals] : values;
      if(newValues !== values) {
        this.setState({
          values: newValues
        });
        onChange(newValues);
      }
    }
  },

  removeValue(val, e) {
    const {values} = this.state,
        newValues = values.filter(v => v !== val),
        {onChange} = this.props;
    if(newValues.length !== values.length) {
      this.setState({
        values: newValues
      });
      if(typeof onChange === "function") {
        onChange(newValues);
      }
    }
  },
  removeAllValues() {
    const {onChange} = this.props;
    this.setState({
      values: []
    });
    if(typeof onChange === "function") {
      onChange([]);
    }
  },

  componentDidMount() {
    if(!this.props.avoidFocus) {
      this.input.focus();
    }
  },

  render() {
    const {validator, isDisabled} = this.props,
        {message} = validator || "",
        disabled = isDisabled === true ? "disabled" : "",
        {values, valid} = this.state,
        valueLabels = values.map((v, i) => {
          return (
            <label key={"label_" + i} className="value">
              <span onClick={() => {this.setSearchValue(v)}}>{v}</span>
              <i className="icon icon-x-circle activable"
                  onClick={this.removeValue.bind(this, v)}></i>
            </label>
          );
        });

    return (
    [
      <div key="multivalue-input-cont" className={`multivalue-input-cont ${disabled}` }>
        <div className={`multivalue-input ${disabled}`}>
          {valueLabels}
          <div>
            <div className="input">
              <input key="input" type="text"
                className="inline"
                ref={input => {
                  this.input = input;
                }}
                value={this.state.searchValue}
                onBlur={this.handleBlur}
                onPaste={this.handlePaste}
                placeholder={this.props.placeholder}
                onKeyUp={this.handleKeyAction}
                onChange={this.setInputValue}
                disabled={isDisabled}/>
              {values.length
                ? <i className="icon icon-x-circle clear-all"
                  onClick={this.removeAllValues} title="Clear All"></i>
                : null}
            </div>
          </div>
        </div>
      </div>,
      (validator && !valid
        ? <div key="error-msg" className="error">{message}</div>
        : null)
    ]
    );
  }
});



const Switch = ReactClass({
  getInitialState() {
    return {
      value: !!this.props.checked
    };
  },

  /*
  handleChange(e) {
    const box = e.target, checked = box.checked, {onChange} = this.props;
    this.setState({value: checked}, () => {
      onChange && onChange({
        target: {
          value: checked,
          type: "change"
        }
      });
    });
  },
  */

  handleClick(e) {
    const box = this.checkbox,
        checked = box.checked, {onChange} = this.props;
    this.setState({value: checked}, () => {
      onChange && onChange({
        type: "click",
        target: {
          value: checked
        }
      });
    });
  },

  componentWillReceiveProps(nextProps) {
    const {value} = this.state, {checked, onChange} = nextProps, newVal = !!checked;
    if(value !== newVal) {
      this.setState({value: newVal}, () => {
        onChange && onChange({
          type: "change",
          target: {
            value: newVal
          }
        });
      });
    }
  },

  render() {
    const {value} = this.state;
    return (
      <label className={"switch " + (this.props.className || "")}>
        <input ref={checkbox => this.checkbox = checkbox}
            type="checkbox" defaultValue={value}
            onClick={this.handleClick}
            checked={value} />
        <span className="trigger"></span>
        <span className="backdrop"></span>
      </label>
    );
  }
});



const SpinButton = ReactClass({
  displayName: "SpinButton",
  propTypes: {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    busy: PropTypes.bool,
    icon: PropTypes.string,
    className: PropTypes.string
  },
  getInitialState() {
    return {};
  },
  render() {
    const {onClick, disabled, icon = "", busy, className = ""} = this.props,
        buttonClass = "spin-button " + className + (busy ? " busy anim" : "");
    return (
      <button className={buttonClass} disabled={disabled || busy} onClick={onClick}>
        {busy ? <i className="icon icon-loader spin" /> : <i className={"icon " + icon} />}
        &#160; {this.props.children}
      </button>
    );
  }
});



export {
  Form,
  MultiValueInput,
  Switch,
  SpinButton
};
