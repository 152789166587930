import React from "react";
import ReactClass from "create-react-class";
import Util from "../../components/util";

export default ReactClass({
  displayName: "UtcClock",
  getInitialState() {
    return ({
      dateTime: this.getCurrentDateTime()
    });
  },
  componentDidMount() {
    this.clockInterval = setInterval(
      () => this.setDateTime(),
      1000
    );
  },
  getCurrentDateTime() {
    return Util.formatDate(new Date(), "yyyy-MM-dd HH:mm:ss", false);
  },
  setDateTime() {
    this.setState({
      dateTime: this.getCurrentDateTime()
    });
  },
  componentWillUnmount() {
    clearInterval(this.clockInterval);
  },
  render() {
    return (
      <div className="clock">{this.state.dateTime}</div>
    );
  }
});
