// import React from "react";
// import ReactClass from "create-react-class";
// import PropTypes from "prop-types";
import Config from "../../config";
import ApiClient from "../../components/api-client";
import initialization from "../../modules/initialization";


const diagnosticsParamsMapping = {
  "WIFI_CONN": "WiFiConnected",
  "WIFI_DHCP": "DHCPLease",
  "WAN_ACCESS": "WANReachable",
  "WIFI_PASS": "WiFIPassword",
  "REMOTE_SVC_PROPS": "AylaPropsReachable",
  "REMOTE_SVC_CONN": "AylaBEOnline",
  "SUPPORT_SVC_CONN": "CAEBEOnline",
  "TYPE_DETECTION": "ApplianceType",
  "APPLIANCE_CONN": "ApplianceConnected",
  "BLUETOOTH": "Bluetooth",
  "WIFI_PROV": "WiFiConfigured",
  "REMOTE_TIME_SYNC": "AylaTimeSync",
  "AZURE_ENABLED": "AzureEnabled"
};

const defaultParams = {
  project: "Sub-Zero",
  quest: "KNOWLEDGE_CENTER",
  state: "dialog-end",
  locale: "en",
  branch: "prd"
};

const capatalize = str => {
  return str.charAt(0) + str.substring(1);
};

const generateDiagnosticLinkParams = (appliance, diagRecords, frmDashboard) => {
  // const {latest: {records}} = diagnostics,
  const params = Object.assign({}, defaultParams);

  // params.DS_SERIAL_NUMBER = appliance.serial;
  // params.slot0 = "MODEL_NUMBERS";
  // params.value0 = appliance.model;

  let props = Object.keys(diagRecords).reduce((props, diag) => {
    const value = diagRecords[diag];
    let str;
    // Ignore unknown values
    if(value !== "UNKNOWN") {
      str = diagnosticsParamsMapping[diag] + "_" + capatalize(value);
      props.push(str);
    }
    return props;
  }, []);

  // params.slot1 = "ERROR_CODES";
  // params.value1= props.join(",");
  if(frmDashboard) {
    props.unshift(appliance.azureTwinConnectedStatus === "Connected"
      ? `ConnectionState_Connected`
      : `ConnectionState_Disconnected`);
  }

  params.query = props.join(",");
  let init = initialization.getData();
  if(init.CRM_TICKET_ID) {
    params.CRM_TICKET_ID = init.CRM_TICKET_ID[0];
  }
  return params;
};

const generateErrorCodeLinkParams = (appliance, errorCode, project = defaultParams.project) => {
  const params = Object.assign({}, defaultParams),
      {deviceCode, locationCode, failureCode} = errorCode;

  params.DS_SERIAL_NUMBER = appliance.serial;
  params.slot0 = "MODEL_NUMBERS";
  params.value0 = appliance.model;
  // params.slot1 = "ERROR_CODES";
  params.query = `${deviceCode},${locationCode},${failureCode}`;
  let init = initialization.getData();
  if(init.CRM_TICKET_ID) {
    params.CRM_TICKET_ID = init.CRM_TICKET_ID[0];
  }
  params.project = project;
  return params;
};

const generateAppInfoLinkParams = (appliance, query, flagForExtraParams = true) => {
  const params = Object.assign({}, defaultParams);

  if(flagForExtraParams) {
    params.slot0 = "MODEL_NUMBERS";
    params.value0 = appliance.model;
  }

  params.query = query;
  let init = initialization.getData();
  if(init.CRM_TICKET_ID) {
    params.CRM_TICKET_ID = init.CRM_TICKET_ID[0];
  }
  return params;
};

const generateDiagnosticLink = (appliance, diagnosticsItem, frmDashboard) => {
  const params = generateDiagnosticLinkParams(appliance, diagnosticsItem.records, frmDashboard);
  return `${Config.aaUrl}?${ApiClient.asQueryParameters(params)}`;
};

const generateErrorCodeLink = (appliance, errorCode, project) => {
  const params = generateErrorCodeLinkParams(appliance, errorCode, project);
  return `${Config.aaUrl}?${ApiClient.asQueryParameters(params)}`;
};

const generateAppInfoLink = (appliance, query, flagForExtraParams) => {
  const params = generateAppInfoLinkParams(appliance, query, flagForExtraParams);
  return `${Config.aaUrl}?${ApiClient.asQueryParameters(params)}`;
};

const getCRMTicket = _ => {
  let params = {};
		let init = initialization.getData();
		if(init.CRM_TICKET_ID) {
			params.CRM_TICKET_ID = init.CRM_TICKET_ID[0];
    }
    return params;
}
export default {
  generateDiagnosticLinkParams,
  generateDiagnosticLink,
  generateErrorCodeLinkParams,
  generateErrorCodeLink,
  generateAppInfoLinkParams,
  generateAppInfoLink,
  getCRMTicket
};
