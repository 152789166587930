import React from "react";
import ReactClass from "create-react-class";
import AnswerAdviser from "../../../modules/answer-adviser";
import Util from "../../util";
import Config from "../../../config";

const getReadableDate = utcMS => {
  return Util.formatDate(utcMS, "yyyy-MM-dd HH:mm:ss", true);
};

const AppAttribute = ReactClass({
      displayName: "AppAttribute",
      render() {
        const {appliance, label, value, className = "", keyForAALink = ""} = this.props,
            classs = `row ${className}`,
            szgVersion = `${Config.answerAdvisorLink}/sub-zero/common/sub-zero-group-owners-app-updates`,
            hrefLink = (keyForAALink && value
                ? (keyForAALink === "version"
                   ? // Query param value for AA link for AppInfo(hard coded only)
                     szgVersion
                   : AnswerAdviser.generateAppInfoLink(appliance, "K34394676"))
                : ""),
            aaLink = (hrefLink
                ? (<div onClick={() => Util.trackAAEvent(hrefLink)}>
                  <a target="_blank" href={hrefLink}>
                      Answer Advisor
                    </a>
                  </div>)
                : null);

        return (
          <div className={classs}>
            <div className="prop">
              <span>{label}</span>
              {aaLink}
            </div>
            <div className="value">
              <label>{value || "N/A"}</label>
            </div>
          </div>
        );
      }
    });
const ConsumerAppInfoPanel = ReactClass({
  displayName: "ConsumerAppInfoPanel",
  render() {
    const {appliance, appInfo: {
      phoneModel,
      mobileOsVersion,
      userId,
      version,
      timestamp,
      appLogType}} = this.props;
    return (
      <div className="consumer-app-info-panel">
        <div className="consumer-app-details">
            <AppAttribute appliance={appliance} label="Device Model"
                value={phoneModel}></AppAttribute>
            <AppAttribute appliance={appliance} label="Device Operating System"
                value={mobileOsVersion}>
                {/* // keyForAALink={"mobileOsVersion"}> */}
                </AppAttribute>
            <AppAttribute appliance={appliance} label="User Id"
                value={userId}></AppAttribute>
            <AppAttribute appliance={appliance} label="SZG App Version"
                value={version} keyForAALink={"version"}></AppAttribute>
            <AppAttribute appliance={appliance} label="Time Stamp"
                value={timestamp && timestamp != "0" ? Util.formatDate(new Date(timestamp), "yyyy-MM-dd HH:mm:ss z", false) : ""} ></AppAttribute>
        </div>
      </div>
    );
  }
});

export default ConsumerAppInfoPanel;
