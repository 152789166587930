"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* global console */
/**
 * K2 Inc. Confidential
 *
 * Copyright © 2015 K2 Inc. All rights reserved.
 *
 * Notice: All information contained herein is, and remains the property of K2 Inc. and its
 * suppliers, if any. The intellectual and technical concepts contained herein are proprietary to K2
 * Inc. and/or its suppliers, and may be covered by U.S. and foreign patents or patents in process,
 * and are protected by trade secret or copyright law. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from K2 Inc.
 */

function WidgetRegistry() {
  var widgets = {};

  /**
   * A simple registry for registering widgets (widget types) for the dashboard. A widget is a react
   * component that sits inside a widget wrapper (another component defined in Widget.jsx) and is
   * rendered by the dashboard
   */
  var Registry = {
    /**
     * Adds a new widget class (The actual React class) to the registry.
     * @param {String} id         The id of the widget. This must be unique in the registry.
     *                            This should ideally be
     *                            a ns like string e.g. com.k2inc.widget.guage
     *
     * @param {Object} descriptor An object describing the widget.
     *
     * The widget descriptor can contain following:
     * <pre>
     *   {
     *     type: require("../components/widgets/meter/Meter"),
     *     name: "Meter Widget",                      // A name for this widget
     *     description: "A simple meter widget", // A short description of this widget
     *     // An icon URL for the widget
     *     icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAOAElEQVR42s2beXAT1="
     *   }
     * </pre>
     */
    add: function add(id, descriptor) {
      if (!descriptor.type) {
        throw new Error("Widget type not specified in the descriptor");
      }
      widgets[id] = descriptor;
      return this;
    },

    /**
     * Looks up a widget type for the specified id
     * @param {String} id         The registry id of the widget.
     * @returns {ReactWidgetType} The react widget class.
     */
    getWidget: function getWidget(id) {
      var descriptor = widgets[id];
      if (descriptor) {
        return descriptor.type;
      }
      console.log("Widget not found with id " + id);
      return null;
    },

    /**
     * Gets the widget descriptor associated with the specified id
     * @param {String} id The widget id.
     * @returns {Object} The widget descriptor.
     */
    getWidgetInfo: function getWidgetInfo(id) {
      var descriptor = widgets[id];
      if (!descriptor) {
        console.log("Widget info not found for " + id);
      }
      return descriptor;
    },

    /**
     * Gets all the available widgets in this registry
     * @returns {Array} An array of widget descriptors
     */
    getAll: function getAll() {
      return Object.keys(widgets).map(function (k) {
        return widgets[k];
      });
    }
  };

  return Registry;
}

exports.default = WidgetRegistry(); // export an instance of Widget registry