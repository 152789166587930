import React from "react";
import ReactClass from "create-react-class";
import Config from "../../config";
import {AuthService} from "../auth";
import Util from "../../components/util";
import AI from "../../appInsights";
import catService from "../cat-projects/Service";
import DashboardService from "../dashboard/Service";
import util from "../../components/util";

export default ReactClass({
  displayName: "AppBar",
  getInitialState() {
    return {};
  },

  navigateTo(path) {
    const {application: app} = this.props;
    app.route(path);
  },
  signOut() {
    const {application: app} = this.props;
    AuthService.logout()
    .then((res)=>{
      console.log('Logout success.');
      this.navigateTo('/');
    })
    .catch((e)=>{
      console.log('Failed to logout- ',e);
      app.notifications.error( "Unable to logout. Please try again.");
    });
  },
  currentApplianceInfo() {
    const currentAppliance = DashboardService.getCurrentApplianceInfo(),
        projectName = catService.getCatProjectName(),
        currentApplianceDetails = DashboardService.getCurrentApplianceDetails();
    let proName;
    if(projectName) {
      proName = `Project Name: ${projectName}`;
    }
    else if(currentAppliance) {
      const firmwareVersion = currentAppliance.pumaVersion || currentApplianceDetails.FirmwareVersion || '';
      proName = `Serial Number: ${currentAppliance.serial}%0D%0AModel Number: ${currentAppliance.model}%0D%0AConnected Module Version: ${firmwareVersion}`;
    }
    else {
      proName = "";
    }
    const feedbackEmailLink = `mailto:${Config.feedbackEmail}?subject=CAP feedback
    &body=${proName}`;
    const applianceSerial = currentAppliance ? currentAppliance.serial || "": "";
    const applianceModel = currentAppliance ? currentAppliance.model || "": "";
    util.trackEventWithProperties("FEEDBACK", 'Feedback',{
      applianceSerial: applianceSerial,
      applianceModel: applianceModel,
      feebackEmail: Config.feedbackEmail
    });
    window.open(feedbackEmailLink, "_blank");
  },
  render() {
        const {requiresAuth, expand} = this.props,
        iconClass = `icon icon-${expand ? "x-circle" : "menu"}`;
    return (
      <div className="app-bar" key="appbar">
        {(!requiresAuth
          ? <i className={iconClass} onClick={this.props.toggleSidebar}></i>
          : null)}
        <div className="logo">
          <img src="images/Tri-Brand_4-Color_Badge.png" alt="Sub-zero Logo" />
          <h3 className="title">
            Connected Appliance Portal
          </h3>
        </div>
        <div className="actions">
          {/* <span className={"action" +
              (route.path === "/user/me" ? " active": "")} title="User Profile"
              onClick={this.navigateTo.bind(this, "/user/me")}>
            <i className="icon icon-user"></i>
          </span> */}
          {(!requiresAuth
            ? <div>
                <button className="primary inline feedback" onClick={this.currentApplianceInfo}>
                  {/* <a href={feedbackEmailLink} target="_blank">Feedback</a> */}
                  Feedback
                </button>
                <button className="primary inline signout" title="Sign out" onClick={this.signOut}>
                Sign out</button>
              </div> 
            : null)}
        </div>
      </div>
    );
  }
});
