"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; /* global console */
/**
 * K2 Inc. Confidential
 *
 * Copyright © 2015 K2 Inc. All rights reserved.
 *
 * Notice: All information contained herein is, and remains the property of K2 Inc. and its
 * suppliers, if any. The intellectual and technical concepts contained herein are proprietary to K2
 * Inc. and/or its suppliers, and may be covered by U.S. and foreign patents or patents in process,
 * and are protected by trade secret or copyright law. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from K2 Inc.
 */

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _createReactClass = require("create-react-class");

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _WidgetRegistry = require("./WidgetRegistry");

var _WidgetRegistry2 = _interopRequireDefault(_WidgetRegistry);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
const React         = require("react"),
    // cn           = require("classnames"),
    WidgetRegistry  = require("./WidgetRegistry");
*/
/**
 * A wrapper for widgets that is used by the dashboard to control the widget layout.
 * All the configuration is passed to the underlying widget instance.
 * Widget lookup is done using the WidgetRegistry.
 */
var WidgetWrapper = (0, _createReactClass2.default)({
  displayName: "WidgetWrapper",
  propTypes: {
    // how many columns this widget should span
    cols: _propTypes2.default.number.isRequired,
    onWidgetAdd: _propTypes2.default.func.isRequired,
    config: _propTypes2.default.any,
    context: _propTypes2.default.object,
    type: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func])
  },

  /**
   * Looks up the widget and stores it so that is ready in the render method
   */
  componentWillMount: function componentWillMount() {
    var widgetType = this.props.type,
        valType = typeof widgetType === "undefined" ? "undefined" : _typeof(widgetType);

    if (valType === "string") {
      this.widgetClass = _WidgetRegistry2.default.getWidget(widgetType);
    } else if (valType === "function") {
      this.widgetClass = widgetType;
    } else {
      console.warn("Invalid widget type has to be a string id or a react component");
    }

    if (!this.widgetClass) {
      console.warn("Widget class not found for " + widgetType);
    }
  },

  /**
   * Notifies the dashboard that the widget was successtully added
   */
  componentDidMount: function componentDidMount() {
    /*
    if(!this.widgetClass) {
      console.log("Widget class was not available, won't notify dashboard.");
      return;
    }
    */
    this.props.onWidgetAdd(this);
  },

  render: function render() {
    var config = _extends({ context: this.props.context }, this.props.config, { layout: this.props.layout }),
        className = "widget-wrapper span_" + this.props.cols;
    if (this.widgetClass) {
      this.widget = _react2.default.createElement(this.widgetClass, config, null);
    } else {
      this.widget = _react2.default.createElement(
        "div",
        { className: "widget-panel widget-error" },
        "Widget not found ",
        this.props.type
      );
    }
    return _react2.default.createElement(
      "div",
      { className: className },
      this.widget
    );
  }
});

exports.default = WidgetWrapper;