import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import HideByApplianceType from "../../../modules/dashboard/HideByApplianceType";
import DashboardService from "../../../modules/dashboard/Service";

const PollingFrequency = ReactClass({
  displayName: "PollingFrequency",
  getInitialState() {
    return ({
      frequency: ""
    });
  },
  componentWillMount() {
    const {currentAppliance} = this.props;
    // this.validate = {
    //   valid: false,
    //   message: ""
    // };
    DashboardService.getPollingFrequency(currentAppliance.deviceId).then(pollingFrequency => {
      this.setState({
        frequency: pollingFrequency.frequency
      })
      }).catch(errRes => {
        this.setState({
          frequency: ""
        })
      })
  },
  // componentWillReceiveProps(newProps) {
  //   const {frequency, isPolling} = newProps;
  //   if(!isPolling) {
  //     this.setState({
  //       frequency: frequency
  //     });
  //   }
  // },
  // changeLogFrequency(evt) {
  //   const val = +evt.target.value == 0 ? "": +evt.target.value,
  //       {min, max} = evt.target;
  //   let isValidate = true,
  //       message = "";

  //   if(!val) {
  //     isValidate = false;
  //     message = "Please enter value";
  //   } else if(val < +min || val > +max) {
  //     isValidate = false;
  //     message = `Please enter a value between ${min} to ${max}`;
  //   }

  //   this.validate = {
  //     valid: isValidate,
  //     message
  //   };

  //   this.setState({
  //     frequency: val
  //   });
  // },
  // onSet() {
  //   const {onChangeFrequency} = this.props,
  //       {frequency} = this.state;

  //   if(frequency)
  //     onChangeFrequency(frequency);
  // },
  // selectText(event) {
  //   event.target.select();
  // },
  render() {
    const {frequency} = this.state;
        // {isPolling, frequency: oldFrequency} = this.props,
        // {valid, message} = this.validate;
    return(
      <div className="polling-frequency" title="Contact connecteddiagnostics@subzero.com to change">
        <div className="frequency-val row">
          <label>Polling Rate</label>
          <span>{`${frequency} SEC`}</span>
          {/* <div className="log-frequency"> */}
            {/* <input name="logFrequency" className="inline"
              // onChange={this.changeLogFrequency}
              type="number" value={frequency} min="5" max="60"
              readOnly={isPolling} onDoubleClick={this.selectText} /> */}
            {/* <span>Sec</span> */}
          {/* </div> */}
          {/* {
            isPolling
            ? <div className="anim">
                <i className="icon spin icon-loader"></i>
              </div>
            : <HideByApplianceType name="pollingFrequency::set">
                <button className="button primary inline"
                  onClick={this.onSet} disabled={!valid || oldFrequency === frequency}>SET</button>
              </HideByApplianceType>
          } */}
        </div>
        {/* {!valid ? <span className="error">{message}</span> : ""} */}
      </div>
    );
  }
});


export default PollingFrequency;
