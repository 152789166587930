import Service from "../Service";
import {createTransform} from "./transforms";

const fetchData = appliance => {
      return Service.fetchEndUserApplianceData(appliance);
    },
    createAppChannel = (appliance, name = "/app/appliance") => {
      return {
        name,
        factory() {
          return {
            open(context) {
              fetchData(appliance)
                .then(data => {
                  console.log(`Emitting data from ${name}`, data);
                  context.emit("data", data);
                })
                .catch(error => {
                  console.log(`Emitting error from ${name}`, error);
                  context.emit("error", error);
                });
            },
            close() {}
          };
        }
      };
    },
    createConfiguration = appliance => {
      return Service.getWidgetConfig(appliance.type)
          .then(widgetConfig => {
            if(!widgetConfig) {
              return null;
            }
            const {model} = appliance,
                {name, applianceClass, components} = widgetConfig,
                dashboardConfigs = components.map(component => {
                  const {name, widgets} = component,
                      channelName = `/app/appliance/${applianceClass}`,
                      channel = createAppChannel(appliance, channelName);

                  // This is the actual dashboard config
                  return {
                    name,
                    style: {
                      cssClass: `${applianceClass} ${model}`
                    },
                    datalayer: {
                      name: `${model}-datalayer`,
                      rootChannel: channelName,
                      channels: [
                        // Root channel
                        channel
                      ]
                    },
                    widgets: widgets.map(w => {
                      const widget = Object.assign({}, w, {
                        cols: w.cols || 6,
                        channel: channelName
                      });
                      if(w.transform) {
                        widget.transform = createTransform(w.transform);
                      }
                      console.log(widget);
                      return widget;
                    })
                  };
                });

            return {
              name,
              dashboards: dashboardConfigs
            };
          });
    };

export default createConfiguration;
