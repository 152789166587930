/* global Promise*/

import _ from "lodash";

import ApiClient from "../../components/api-client";

const {instance: Api, asJson: resAsJson} = ApiClient;

let CACHE = {},
    FETCHING = {};

class PropertyModel {
  constructor(cfg) {
    this.cfg = cfg;
  }

  keep(properties) {
    this.cfg.properties = _.filter(this.cfg.properties, prop => {
      return _.includes(properties, prop.name);
    });
    return this;
  }

  getAll() {
    return this.cfg.properties.map(prop => {
      // 20+,Good_13-19,Fair_12-,Poor
      let vmConfig = prop.valueMapping;
      if (_.includes(["Enum", "Bool", "EnumLine", "MixedEnumNumber", "OfflineState"], prop.type) && (typeof(vmConfig) === "string" || !vmConfig)) {
        if (/_?\d+\+,/.test(vmConfig) || /_?\d+\-(\d+)?,/.test(vmConfig)) {
          const fnBody = vmConfig.split("_")
            .reduce((acc, each) => {
              const spl = each.split(","),
                  n = spl[0].replace(/\+$|\-$/g, "");
              if (/^\d+\+$/.test(spl[0])) {
                acc.push(`if (v >= ${n}) { return "${spl[1]}"; }`);
              } else if (/^\d+\-$/.test(spl[0])) {
                acc.push(`if (v <= ${n}) { return "${spl[1]}"; }`);
              } else if (/^\d+\-\d+$/.test(spl[0])) {
                const limits = spl[0].split("-");
                acc.push(`if (v >= ${limits[0]} && v <= ${limits[1]}) { return "${spl[1]}"; }`);
              }
              return acc;
            }, []).join(" else ") + ` else { return v } `;
          prop.valueMapping = new Function("v", fnBody);
          prop.valuesDescription = vmConfig.replaceAll(",",":").replaceAll("_","\r\n");
        } else {
          const vmap = vmConfig.split("_")
            .reduce((acc, each) => {
              const spl = each.split(",");
              acc[spl[0]] = spl[1];
              return acc;
            }, {});
          const stateArray = _.map(_.keys(vmap),item=>{
            return parseInt(item);
          })
          prop.minimum = _.min(stateArray);
          prop.maximum = _.max(stateArray);
          prop.valueMapping = v => vmap[v];
          prop.valuesDescription = vmConfig.replaceAll(",",":").replaceAll("_","\r\n");
        }
      }
      return prop;
    });
  }

  forPreFetch(fetchModes = ["load", "render"]) {
    return _.uniqBy(_.filter(this.cfg.properties, prop => _.includes(fetchModes, prop.preFetch)), "id");
  }

  byType(...types) {
    return _.filter(this.cfg.properties, prop => _.includes(types, prop.type));
  }

  byTypeGrouped(...types) {
    return _(this.cfg.properties)
      .filter(prop => _.includes(types, prop.type))
      .groupBy("group")
      .map((properties, group) => {
        return {
          group: group === "undefined" ? "<Ungrouped>" : group,
          properties
        };
      })
      .value();
  }

  byValueField(...valueFields) {
    return _.filter(this.cfg.properties, prop => _.includes(valueFields, prop.name));
  }

  static forModel(model, configType = "Connected") {
    const modelType = model || "NA";
    const headers = {"Accept-Encoding": "gzip"},
        id = modelType + "::" + configType,
        qs = `name=${modelType}`;

    let promise;
    if (CACHE[id]) {
      promise = Promise.resolve(CACHE[id]);
    } else if (FETCHING[id]) {
      promise = FETCHING[id];
    } else {
      promise = FETCHING[id] = Api.get(`/config/Graph_${configType}?${qs}`, {headers})
          .then(resAsJson)
          .then(response => {
            CACHE[id] = response;
            delete FETCHING[id];
            return response;
          })
      ;
    }

    return promise.then(response => response.ConfigData);
  }

  static getCached(model, configType = "Connected") {
    const id = model + "::" + configType;
    return (CACHE[id] || {}).ConfigData;
  }
}

export default PropertyModel;
