import React from "react";
import deepAssign from "assign-deep";
import ReactClass from "create-react-class";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import {Gauge} from "dashboard-widgets";

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "WaterFilterWidget",
  getInitialState: function() {
    return {
      options: {
        max: 100,
        dialStartAngle: -90,
        dialEndAngle: -90.001,
        label: function(v) {
          return Math.round(v) + "%";
        }
      },
      data: {
      }
    };
  },

  render: function() {
    const {Header, Content} = WidgetPanel,
        {data, options} = this.state,
        gaugeOptions = deepAssign({}, options);
    let {filterLifeRemaining = 0, remainingGallons} = data;

    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{this.props.title}</span>
        </Header>
        <Content>
          <div className="gauge-wrapper span_6">
            <p className="label">Filter Life</p>
            <Gauge.Component value={filterLifeRemaining} {...gaugeOptions}></Gauge.Component>
          </div>
          <div className="gallons span_6">
            <div className="label">Gallons</div>
            <div className="value">{remainingGallons !== null ? remainingGallons : "N/A"}</div>
          </div>

        </Content>
      </WidgetPanel>
    );
  },

  onData: function(data, channel) {
    this.setState({
      channel: channel,
      data: data
    });
  }
}));

const Info = {
  id: "appliance-dashboard:waterfilter",
  name: "Water System",
  type: Widget,
  description: "Show water system status",
  icon: "",
  tags: "appliance refrigerator"
};

export default {
  Info,
  Widget
};
