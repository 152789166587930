/* global setTimeout clearTimeout console */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";

export default ReactClass({
  displayName: "LoadingIndicator",
  render() {
    return (
      <div className="loading-indicator">
        <div className="slider"></div>
      </div>
    );
  }
});