/* global setTimeout clearTimeout console */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import Util from "../util";

const Tab = ReactClass({
  displayName: "Tab",
  propTypes: {
    name: PropTypes.string
  },
  render() {
    return <div className="tab-panel">{this.props.children}</div>
  }
});



const ResponsiveTabs = ReactClass({
  tabNavStrip: null,
  scrollIndex: 0,
  displayName: "Responsive Tabs",
  propTypes: {
    className: PropTypes.string,
    onSelectionChanged: PropTypes.func,
    activeTab: PropTypes.number,
    children(props, propName) {
      const children = props[propName];
      React.Children.forEach(children, function(child) {
        // console.log(child);
        if(child.type !== Tab) {
          throw new Error("Expected 'Tab'" + " but found " +
              (child.type.displayName || child.type));
        }
      });
      return null;
    }
  },

  getInitialState() {
    const total = this.props.children.length;
    return {
      activeTab: this.props.activeTab || 0,
      disableLeftArrow: true,
      disableRightArrow: total==1,
    };
  },

  selectTab(index) {
    Util.trackPageWidgetEvent("WIDGET_CLICK", "Performance History", "");
    const tabLength = this.props.children.length;
    let idx = Number(index), previousIndex = this.state.activeTab;
    idx = isNaN(idx) ? 0 : idx;
    const {onSelectionChanged} = this.props;
    if(typeof onSelectionChanged === "function") {
      onSelectionChanged(index, previousIndex);
    }

    this.scrollIndex = tabLength > 2 && (tabLength - 1) == idx ? idx - 1 : idx;

    this.setState({
      activeTab: idx
    }, () => {
      setTimeout(() => {
        this.scrollToTab();
      }, 100)
    })

  },
  checkingTabsWidth() { 
    if(this.tabNavStrip) {
      const tabContainerWidth = this.tabNavStrip.clientWidth,
        tabsWidth = [...this.tabNavStrip.childNodes].reduce((width, tab) => {
          width += tab.clientWidth;
          return width;
        }, 0);

      return (tabContainerWidth > tabsWidth);
    }
    return false;
  },
  disableLeftArrowOnTabWidth() {

    const flag = this.checkingTabsWidth();

    this.setState({
      ...(flag ? {
        disableLeftArrow: flag,
        disableRightArrow: flag
      } : {
        disableRightArrow: flag
      })
    })

  },

  scrollToTab() {
    const { tabNavStrip, scrollIndex } = this,
      scrollTab = tabNavStrip.childNodes[scrollIndex];

    tabNavStrip.scrollLeft = scrollTab.offsetLeft;

    const {scrollLeft, clientWidth, scrollWidth} = tabNavStrip,
      disableLeft = scrollLeft <= 0 ? true : false,
      disableRight = scrollLeft + clientWidth >= scrollWidth ? true : false;

    this.setState({
      disableLeftArrow: this.state.activeTab==0,//disableLeft,
      disableRightArrow: this.state.activeTab==this.props.children.length-1 ,//disableRight
    })
  },

  renderStrip() {
    const self = this,
      { state: { activeTab, disableLeftArrow, disableRightArrow}, props: { name, children } } = this;

    const tabs = this.props.children.map((tab , i) => {
      return {
        iconClassName: tab.props.iconClassName,
        title: tab.props.title,
      }
    });
    let items;
    items = tabs.map(function(tab, i) {
      const selectedTabClass = children.length > 1 ? "underline" : "";
      const classNames = i === activeTab ? "tab-item selected" : "tab-item";
      return (
        <div className={`${classNames} ${selectedTabClass}`} key={"TabItem_" + i} id={`${name}-${i}`} onClick={self.selectTab.bind(self, i)}>
          {tab.title}
        </div>
      );
    });

    // if(this.tabNavStrip && this.tabNavStrip.childNodes.length !== this.props.children.length) {
    //   setTimeout(() => {
    //     this.disableLeftArrowOnTabWidth();
    //   }, 100)
    // }
    return (
      <div className="tab-heading">
        { children.length > 1
          ? <button id={`${name}-left-arrow`}
            className={"icon icon-chevron-left"} disabled={disableLeftArrow}
            onClick={() => {
              // this.scrollIndex = this.scrollIndex > 0 ? --this.scrollIndex : 0;
              // this.scrollToTab();
              const index = this.props.children.length>0 ? --this.state.activeTab:0;
              this.selectTab(index);
            }} />
          : null
        }
        <div className={"tabs-nav"} ref={(ele) => {
          this.tabNavStrip = ele
        }}>
          {items}
        </div>
        {children.length > 1
          ? <button id={`${name}-right-arrow`}
            className={`icon icon-chevron-right`} disabled={disableRightArrow}
            onClick={() => {
              // this.scrollIndex = this.scrollIndex < tabs.length - 1 ? ++this.scrollIndex : tabs.length - 1;
              // this.scrollToTab();
              const index = this.state.activeTab<this.props.children.length-1 ? ++this.state.activeTab : this.props.children.length-1;
              this.selectTab(index);
            }} />
          : null
        }
      </div>
    );
  },

  renderContent() {
    const idx = this.state.activeTab || 0;
    return this.props.children[idx];
  },

  render() {
    const clazz = this.props.className || "";
    return (
      <div className={"responsive-tabs " + clazz}>
        {this.renderStrip()}
        <div className="tabs-content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
});
ResponsiveTabs.Tab = Tab;

export default ResponsiveTabs;
