import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import SortableTableHeader from "./sortable-table-header";
import SortableTableBody from "./sortable-table-body";

const SortableTable = ReactClass({
  displayName: "SortableTable",
  propTypes: {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    style: PropTypes.object,
    row: PropTypes.object,
    iconStyle: PropTypes.object,
    iconDesc: PropTypes.node,
    iconAsc: PropTypes.node,
    iconBoth: PropTypes.node,
    className: PropTypes.string
  },
  onStateChange(sortCol) {
    if (this.props.onHeaderClick &&
      typeof (this.props.onHeaderClick) === "function") {
      this.props.onHeaderClick(sortCol);
    }
  },
  render() {
    const {data, sortOrder, sortCol} = this.props;
    return (
      <table
        className={this.props.className}
        style={this.props.style} >
        <SortableTableHeader
          columns={this.props.columns}
          sortOrder={sortOrder}
          sortCol={sortCol}
          onStateChange={this.onStateChange}
          iconStyle={this.props.iconStyle}
          iconDesc={this.props.iconDesc}
          iconAsc={this.props.iconAsc}
          iconBoth={this.props.iconBoth} />
        <SortableTableBody
          columns={this.props.columns}
          data={data}
          sortOrder={sortOrder}
          sortCol={sortCol}
          row={this.props.row} />
      </table>
    );
  }
});

export default SortableTable;
