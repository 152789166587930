/* global Promise setTimeout */
import ApiClient from "../../components/api-client";
import Util from "../../components/util";
import * as _ from "lodash";

const {instance: Api, asJson: resAsJson} = ApiClient,
    timeOut5 = 300000;
let appliancesData = null,
    projectName = '';

export default {
  fetchApplianceList(projectName, isHistory) {
    if(isHistory && appliancesData) {
      return Promise.resolve(appliancesData);
    }
    return Api.get(`/projects/${projectName}/devices`)
    .then(resAsJson)
    .then(app => {
      appliancesData = app;
      Util.throttle(() => {
        appliancesData = null;
      }, timeOut5)();
      return app;
    });
  },
  sortData(sortingData) {
    let {sortCol: prev, sortOrder: order, data, currentCol} = sortingData;

    if (data && Array.isArray(data)) {
      data = data.slice();
      if (prev !== currentCol) {
        order = true;
        data = _.sortBy(data, currentCol);
      } else {
        order = !order;
        data.reverse();
      }
    }

    return {
      sortCol: currentCol,
      prevSortCol: prev,
      sortOrder: order,
      data: data
    };
  },
  getCatProjectName() {
    return projectName;
  },
  setCatProjectName(name) {
    projectName = name;
  },
};
