/* console Promise */
import UnitStatisticsView from "./View";

export default [
  {
    path: "/appliance/:selectedDeviceId?/unit-statistics/:selectedSerial?",
    controller: context => {
      return {
        component: UnitStatisticsView,
        context
      };
    }
  }
];
