import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import AnswerAdviser from "../answer-adviser";
import util from "../../components/util";

export default ReactClass({
  displayName: "Faults",
  propTypes: {
    faults: PropTypes.array.isRequired,
    busy: PropTypes.bool,
    appliance: PropTypes.object,
    errorMessage: PropTypes.string
  },
  getAnswerAdviserLink(errorStatus) {
    const {appliance} = this.props,
        errCodes = errorStatus.split(" "),
        errorCode = {
          "deviceCode": errCodes[0] || null,
          "locationCode": errCodes[1] || null,
          "failureCode": errCodes[2] || null
        };
    return AnswerAdviser.generateErrorCodeLink(appliance, errorCode);
  },
  render() {
    const {faults, busy, errorMessage} = this.props;
    return (<div className="row">
      <table className="col_12 records-list zebra">
        <thead>
          <tr>
            <th></th>
            <th>Active</th>
            <th>Time</th>
            <th>Error Code</th>
            <th>Component</th>
            <th>Location</th>
            <th>Failure type</th>
          </tr>
        </thead>
        <tbody>
          {(faults.length > 0
            ? (faults.map((fault, i) => {
              const href = this.getAnswerAdviserLink(fault["Error code"]);
              return (<tr key={"log_" + i}>
                <td>{i+1}</td>
                <td>{fault["Active"]}</td>
                <td>{util.formatDate(fault["Time"], "YYYY-MM-DD HH:mm:ss")}</td>
                <td>
                  <a target="_blank" href={href}
                    onClick={e => {
                      e.stopPropagation(); return false;
                    }}>
                      {fault["Error code"]}
                  </a>
                </td>
                <td>{fault["Component"]}</td>
                <td>{fault["Location"]}</td>
                <td>{fault["Failure Type"]}</td>
              </tr>);
            }))
            : (busy
              ? (<tr>
                <td className="anim center" colSpan="7">
                  <i className="icon spin icon-loader" />
                </td>
              </tr>)
              : (<tr>
                  <td className="empty center" colSpan="7">
                    {errorMessage && errorMessage.length > 0 ? errorMessage : "NO RECORDS FOUND"}
                  </td>
                </tr>))
          )}
        </tbody>
      </table>
    </div>);
  }
});
