/* global console, Image history */
import React from "react";
import ReactClass from "create-react-class";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";

const handler = () => {};

const Widget = AsSubscribedComponent(ReactClass({
      displayName: "AccountInfoWidget",
      getInitialState: function() {
        return {
          data: {}
        };
      },
      componentDidUpdate() {
        this.props.layout.layout();
      },
      reloadDevicesLists(action) {
        const {onAction = handler} = this.props;
        let typeField = ({ userId: this.state.data.uniqueUserId} ) 
        onAction(action, {...this.state.data, typeField});
      },
      render: function() {
        const {Header, Content} = WidgetPanel,
            {data: {emailHint, phoneHint}} = this.state;
        return (
          <WidgetPanel {...this.props}>
            <Header>
              <span>{this.props.title}</span>
            </Header>
            <Content>
              <div className="account-info">
                <div>
                  <span>Email </span>
                  <label id="user-mail" className= {`${ emailHint?  "label" : ""}`} 
                      onClick={this.reloadDevicesLists.bind(this, "reloadDevices")}>
                    {emailHint || 'N/A'}
                  </label>
                </div>
                <div>
                  <span>Phone No. </span>
                  <label id="user-phone" className= {`${ phoneHint?  "label" : ""}`}
                      onClick={this.reloadDevicesLists.bind(this, "reloadDevices")}>
                    {phoneHint || 'N/A'}
                  </label>
                </div>
              </div>
            </Content>
          </WidgetPanel>
        );
      },
      onData: function(data, channel) {
        this.setState({
          channel: channel,
          data: data
        });
      }
    }));

const Info = {
  id: "appliance-dashboard:accountInfo",
  name: "Account Info",
  type: Widget,
  description: "Show Account Info",
  icon: "",
  tags: "account info"
};

export default {
  Info,
  Widget
};
