/* global console */
import {Filters, Transformers, ChannelFactories} from "../common";
import {AuthService} from "../../../auth";
import {getWidgets} from "../widgets";

const TEST_ERROR_CODES =
    [
      {
        "epoch": 379011677,
        "errorCode": {
          "deviceCode": "1",
          "device": null,
          "locationCode": "A",
          "location": "Air Filter",
          "failureCode": "1",
          "failure": "Short/Closed",
          "priority": 1
        },
        "active": true
      },
      {
        "epoch": 379029982,
        "errorCode": {
          "deviceCode": "6",
          "device": null,
          "locationCode": "G",
          "location": null,
          "failureCode": "6",
          "failure": "Miswire overheat",
          "priority": 2
        },
        "active": true
      },
      {
        "epoch": 379033643,
        "errorCode": {
          "deviceCode": "7",
          "device": null,
          "locationCode": "H",
          "location": "High Voltage Sys",
          "failureCode": "7",
          "failure": "Bad Ohms",
          "priority": 3
        },
        "active": true
      },
      {
        "epoch": 379008016,
        "errorCode": {
          "deviceCode": "0",
          "device": null,
          "locationCode": "x",
          "location": null,
          "failureCode": "0",
          "failure": "Open",
          "priority": 4
        },
        "active": true
      }
    ],
    createChannelConfig = (deviceInfo, configWidgets, perApplianceType, token, networkConnInfo) => {
      return {
        "name": `${deviceInfo.name} ${deviceInfo.model}`,
        "style": {
          "cssClass": "oven-dashboard"
        },
        "datalayer": {
          "name": "Oven Data",
          "channels": [
            // Diagnostic channel common to all dashboards
            ChannelFactories.userInfo(deviceInfo, token),
            ChannelFactories.diagnostics(deviceInfo, token),
            ChannelFactories.parameters(deviceInfo, token),
            ChannelFactories.errorCodes(deviceInfo, token),
            ChannelFactories.timeZone(deviceInfo, token),
            ChannelFactories.applianceInfo(deviceInfo, token),
            ChannelFactories.currentState(),
            ChannelFactories.networkConnInfo(deviceInfo, networkConnInfo),
            ChannelFactories.mobNotificationInfo(deviceInfo, token),
            ChannelFactories.provisioningReport(deviceInfo,token),
            {
              "name": "/appliance/pollingFrequency",
              "type": "extended",
              "config": {
                "from": "/appliance/properties",
                "transform": data => {
                  return {frequency: data.active_polling_frequency};
                }
              }
            }
          ]
        },
        "widgets": getWidgets(deviceInfo, configWidgets, perApplianceType)
      };
    };


export default (deviceInfo, configWidgets, perApplianceType, networkConnInfo) => {
  const promiseToken = AuthService.getAPIToken();
  return promiseToken.then(authRes => {
    return createChannelConfig(deviceInfo, configWidgets, perApplianceType, authRes.accessToken, networkConnInfo);
  });
};
