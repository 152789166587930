import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import Config from "../../config";

import AnswerAdviser from ".././../modules/answer-adviser";
import Util from "../../components/util";

const displayMapping = {
      "main": {
        "label": "MAIN SW",
        "title": "Main Appliance Software Version"
      },
      "uim": {
        "label": "UIM SW",
        "title": "User Interface Module Software Version"
      },
      "uim_slave": {
        "label": "UIM SLAVE SW",
        "title": "Secondary User Interface Module Software Version"
      },
      "wash_pump": {
        "label": "WASH PUMP SW",
        "title": "Wash Pump Drive Software Version"
      },
      "rs485": {
        "label": "RS485 SW",
        "title": "Software Version for RS485 communication"
      },
      "conf": {
        "label": "CONFIG SW",
        "title": "Appliance Model Configuration File Version"
      },
      "cfg": {
        "label": "CONFIG SW",
        "title": "Appliance Model Configuration File Version"
      },

      "hv": {
        "label": "HV SW",
        "title": "High Voltage Software Version"
      },
      "hiv": {
        "label": "HIV SW",
        "title": "High Voltage Software Version"
      },
      "lov": {
        "label": "LOV SW",
        "title": "Low Voltage Software Version"
      },
      "disp": {
        "label": "DISP SW",
        "title": "External Dispenser Software Version"
      },
      "lv": {
        "label": "LV SW",
        "title": "Low Voltage Software Version"
      },
      "ccm": {
        "label": "CCM SW",
        "title": "Central Control Module Software Version"
      },
      "lcd_mem": {
        "label": "LCD Mem SW",
        "title": "Memory file for LCD display on the UIM"
      },
      "smartGridState": {
        "label": "SMART GRID STATE",
        "title": "Smart Grid State"
      },
      "model": {
        "label": "MODEL NO",
        "title": "Model No"
      },
      "type": {
        "label": "TYPE",
        "title": "Type"
      },
      "firmware": {
        "label": "FW VERSION",
        "title": "Fw Version"
      }
    },
    AppAttribute = ReactClass({
      displayName: "AppAttribute",
      render() {
        const {label, value, className = "", title=""} = this.props,
            classs = `row subrow ${className}`,
            tooltip = displayMapping[label] ? displayMapping[label]["title"] : title,
            dispText = displayMapping[label] ? displayMapping[label]["label"] : label;

        return (<div className={classs}>
          <label title={tooltip}>
            {dispText}
          </label>
          <span>{value || "N/A"}</span>
        </div>);
      }
    });

const ConsumerAppInfoComponent = ReactClass({
  displayName: "ConsumerAppInfoComponent",
  getInitialState() {
    return {
    };
  },
  render() {
    const {appliance:
            {softwareVersions = []},
            noOfVersionsInCol = 2,
            noOfAttrsInCol = 2
          } = this.props,
        {appliance} = this.props,
        applianceAttrRows = (appliance
          ? Object.keys(appliance).map(key => {
            if(typeof appliance[key] === "string") {
              return (<AppAttribute key={key} label={key} value={appliance[key]} />);
            }
          }).filter(attr => attr)
          : []),
        versionsRows = (softwareVersions
            ? Object.keys(softwareVersions).map(key => {
              return (<AppAttribute key={key} label={key} value={softwareVersions[key]} />);
            })
            : []),
        aaLink = (
            <a target="_blank"
                // Query param value for AA link for AppInfo (hard coded only)
                href={Config.answerAdvisorLink}
                onClick={() => Util.trackAAEvent(Config.answerAdvisorLink)}>
              Answer Advisor
            </a>),
        classs = "line";

    return (
      <div className="appliance-info-section">
        <div className="row answer-adviser">
          <p className="title">APPLIANCE INFO</p>
          {/* {aaLink} */}
        </div>
        <div className="Appliance-info row">
          {(applianceAttrRows
            ? (applianceAttrRows.map((key, i) => {
              if (i % noOfAttrsInCol === 0) {
                return (<div className={classs} key={"Appliance_Info_" + i}>
                  {applianceAttrRows.slice(i, i + noOfAttrsInCol)}
                </div>);
              }
            }))
            : null)}
          {(versionsRows
            ? (versionsRows.map((key, i) => {
              if (i % noOfVersionsInCol === 0) {
                return (<div className={classs} key={"Appliance_Info_" + i}>
                  {versionsRows.slice(i, i + noOfVersionsInCol)}
                </div>);
              }
            }))
            : null)}
        </div>
    </div>);
  }
});

export default ConsumerAppInfoComponent;
