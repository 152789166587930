"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _createReactClass = require("create-react-class");

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _uiDashboard = require("ui-dashboard");

var _Component = require("./Component");

var _Component2 = _interopRequireDefault(_Component);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 *  {
 *    id: "some-widget",
 *    type: "widget-registry-name of this widget",
 *    title: "Some Title",
 *    channel: "/some/channel/name",
 *    cols: x,
 *    panelClass: "css-class",
 *
 *    data: {}, // Initial data to this chart widget
 *    guage: { // Gauge options
 *
 *    }
 *  }
 */

// import ReactDOM from "react-dom";
var Widget = (0, _createReactClass2.default)({
  displayName: "GuageWidget",
  propTypes: {
    configure: _propTypes2.default.bool
  },

  // mixins: [SubscriberMixin],

  getInitialState: function getInitialState() {
    return {
      data: this.props.data,
      editable: true,
      configure: false
    };
  },

  render: function render() {
    var Header = _uiDashboard.WidgetPanel.Header,
        Content = _uiDashboard.WidgetPanel.Content,
        panelContent = this.state.configure ? this.renderSettings() : this.renderComponent();

    return _react2.default.createElement(
      _uiDashboard.WidgetPanel,
      null,
      _react2.default.createElement(
        Header,
        null,
        this.props.title
      ),
      _react2.default.createElement(
        Content,
        null,
        panelContent
      )
    );
  },

  renderComponent: function renderComponent() {
    var data = this.state.data,
        props = this.pros;
    return _react2.default.createElement(_Component2.default, { value: data, max: props.max });
  },

  renderSettings: function renderSettings() {
    return _react2.default.createElement(
      "div",
      null,
      "Settings not supported at this point"
    );
  },

  onData: function onData(data, channel) {
    this.setState({
      channel: channel,
      data: data
    });
  }
});
// import {SubscriberMixin} from "datalayer";
exports.default = (0, _uiDashboard.AsSubscribedComponent)(Widget);