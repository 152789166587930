"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* global console */
/**
* K2 Inc. Confidential
*
* Copyright © 2015 K2 Inc. All rights reserved.
*
* Notice: All information contained herein is, and remains the property of K2 Inc. and its
* suppliers, if any. The intellectual and technical concepts contained herein are proprietary to K2
* Inc. and/or its suppliers, and may be covered by U.S. and foreign patents or patents in process,
* and are protected by trade secret or copyright law. Dissemination of this information or
* reproduction of this material is strictly forbidden unless prior written permission is obtained
* from K2 Inc.
*/

// import ReactDOM from "react-dom";


exports.default = EmbedInWidget;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _createReactClass = require("create-react-class");

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _WidgetPanel = require("./WidgetPanel");

var _WidgetPanel2 = _interopRequireDefault(_WidgetPanel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Header = _WidgetPanel2.default.Header,
    Content = _WidgetPanel2.default.Content,
    getDisplayName = function getDisplayName(component) {
  return component.displayName || "Component";
},
    getDisplayNames = function getDisplayNames() {
  for (var _len = arguments.length, comps = Array(_len), _key = 0; _key < _len; _key++) {
    comps[_key] = arguments[_key];
  }

  return comps.map(function (comp) {
    return getDisplayName(comp);
  });
};

/**
 * Embeds the components into a widget panel for dashboard.
 * @wrappedComponents {Array} A list of components to embed into the panel.
 * @return {Function} A WidgetPanel component
 */
function EmbedInWidget() {
  for (var _len2 = arguments.length, components = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    components[_key2] = arguments[_key2];
  }

  var Panel = (0, _createReactClass2.default)({
    render: function render() {
      var props = this.props;
      var children = components.map(function (comp, i) {
        var newProps = _extends({ key: comp.id || i }, props.config || props);
        return _react2.default.createElement(comp, newProps, []);
      });

      return _react2.default.createElement(
        _WidgetPanel2.default,
        props,
        _react2.default.createElement(
          Header,
          null,
          props.title
        ),
        _react2.default.createElement(
          Content,
          props,
          children
        )
      );
    }
  });
  Panel.displayName = "WidgetPanel(" + getDisplayNames(components).join(", ") + ")";
  return Panel;
}