"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _createReactClass = require("create-react-class");

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _assignDeep = require("assign-deep");

var _assignDeep2 = _interopRequireDefault(_assignDeep);

var _svgGauge = require("svg-gauge");

var _svgGauge2 = _interopRequireDefault(_svgGauge);

var _defaultOptions = require("./default-options");

var _defaultOptions2 = _interopRequireDefault(_defaultOptions);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// import ReactDOM from "react-dom";
var Component = (0, _createReactClass2.default)({
  displayName: "Gauge",
  componentDidMount: function componentDidMount() {
    this.renderGauge(this.props);
  },

  shouldComponentUpdate: function shouldComponentUpdate(nextProps, nextState) {
    var props = this.props;
    if (props.value !== nextProps.value) {
      this.renderGauge(nextProps);
    }
    return false;
  },

  render: function render() {
    return _react2.default.createElement("div", { className: "gauge-container", ref: "gauge" });
  },

  renderGauge: function renderGauge(props) {
    var containerElem = this.refs.gauge,
        gaugeOptions = (0, _assignDeep2.default)({}, _defaultOptions2.default, props);
    var gauge = this.gauge;
    if (!gauge) {
      this.gauge = gauge = (0, _svgGauge2.default)(containerElem, gaugeOptions);
    } else {
      this.gauge.setValueAnimated(props.value, gaugeOptions.animDuration);
    }
  }
}); /**
     * K2 Inc. Confidential
     *
     * Copyright © 2017 K2 Inc. All rights reserved.
     *
     * Notice: All information contained herein is, and remains the property of K2 Inc. and its
     * suppliers, if any. The intellectual and technical concepts contained herein are proprietary to K2
     * Inc. and/or its suppliers, and may be covered by U.S. and foreign patents or patents in process,
     * and are protected by trade secret or copyright law. Dissemination of this information or
     * reproduction of this material is strictly forbidden unless prior written permission is obtained
     * from K2 Inc.
     */
exports.default = Component;