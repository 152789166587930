/* global console setTimeout */
import React from "react";
import ReactClass from "create-react-class";
import * as _ from "lodash";

import PropertyModel from "./PropertyModel";
import AI from "../../appInsights";

export default ReactClass({
  displayName: "StatsPanel",
  getInitialState() {
    return {
      originalPropGroups: [],
      propGroups: [],
      statsFilter: "",
      record: null,
      loading: true
    };
  },
  componentWillReceiveProps(nextProps) {
    const {type, mode, record, keyMap, showProperties, loading} = nextProps;

    if (type && type !== this.state.applianceType) {
      this.resetProperties(type, mode);
    } else if (showProperties && showProperties.length && !this.state.showProperties) {
      this.setState({showProperties});
      this.resetProperties(type, mode, showProperties);
    }
    setTimeout(() => {
      this.state.statsFilter !== "" && this.filterStats(this.state.statsFilter);
    }, 10);

    this.setState({record, loading});

    if (keyMap) {
      this.setState({keyMap});
    }
  },

  resetProperties(type, mode, showProperties = undefined) {
    PropertyModel.forModel(type, mode).then(propModel => {
      if (!propModel) {
        return;
      }

      this.propertyModel = new PropertyModel(propModel);
      if (showProperties && showProperties.length) {
        this.propertyModel.keep(showProperties);
      }

      const groups = _.clone(this.propertyModel.byTypeGrouped("Temp", "RPM", "Numeric", "Number", "Bool", "Enum", "EnumLine", "MixedEnumNumber"));
      //Removing color from appliance config file to avoid setting selected color in stats panel
      const selectedFieldsArray = groups.map((obj) => {
          return {
            group: obj.group,
            properties: obj.properties
          }
        }
      );
      this.setState({
        originalPropGroups: groups,
        propGroups: groups,
        applianceType: type
      });
    }, errResponse => {
    });
  },

  togglePropCollapse(entry, index, selectedAttributesLength) {
    const before = this.state.propGroups.slice(0, index),
        after = this.state.propGroups.slice(index + 1)
    ;
    this.setState({
      propGroups: [
        ...before,
        Object.assign(entry, {
          collapsed: ( entry.collapsed === undefined
            ? selectedAttributesLength === 0
              ? false
              : true
            : !entry.collapsed )
        }),
        ...after
      ]
    });
  },

  expandAll() {
    this.setState({
      propGroups: this.state.propGroups.map(
        entry => Object.assign(entry, {collapsed: false})
      )
    });
  },
  collapseAll() {
    this.setState({
      propGroups: this.state.propGroups.map(
        entry => Object.assign(entry, {collapsed: true})
      )
    });
  },

  filterStats(filterStr) {
    const origProps = this.state.originalPropGroups;
    if (filterStr !== "") {
      AI.trackEvent("GraphStatsPanelFilter", {"Appliance Type": this.state.applianceType, "Filter String": filterStr});
    }

    this.expandAll();

    setTimeout(() => {
      this.setState({
        statsFilter: filterStr,
        propGroups: (filterStr.trim().length > 1
          ? _.cloneDeep(origProps).map(group => {
              group.properties = group.properties
                .filter(prop =>
                  prop.displayName.toLowerCase().indexOf(filterStr.toLowerCase()) !== -1
                );
              return group;
            })
          : origProps)
      });
    }, 100)

  },

  clearAll() {
    const {onSelectionChange} = this.props;
    onSelectionChange && onSelectionChange([])
  },
  render: function() {
    const {props: {fieldSelection, onSelectionChange, busy, isLhsPanelOpen, toggleSidePanel}, state: {record, keyMap, loading, propGroups}} = this,
        displayValue = (record, prop, keyMap) => {
          const value = record[keyMap[prop.name]];
          switch(prop.type) {
            case "Enum":
            case "EnumLine":
            case "MixedEnumNumber":
              return prop.valueMapping(value) || value;
            case "Bool":
              return prop.valueMapping(value) || value;
            default:
              return value;
          }
        },
        iconClass = (record, prop, keyMap) => {
          const value = record[keyMap[prop.name]];
          const iconValueMapping = {
            Bool: {
              "Yes": `icon-graph-onoff on`,
              "No": `icon-graph-onoff off`,
              "On": `icon-graph-onoff on`,
              "Off": `icon-graph-onoff off`,
              "Invalid": `icon-graph-onoff invalid`,
              "Active": `icon-graph-active`,
              "Not Active": `icon-graph-inactive`,
              "Inactive": `icon-graph-inactive`
            },
            Enum: {
              "High": "icon-graph-high",
              "Medium": "icon-graph-medium",
              "Low": "icon-graph-low",
              "No Feedback": "icon-graph-nofeedback"
            }
          };
          /*
          .icon-graph-error
          .icon-graph-info
          .icon-graph-droplet
          .icon-graph-maximize
          .icon-graph-minimize
          */
          switch(prop.type) {
            case "Enum":
            case "EnumLine":
            case "MixedEnumNumber":
            case "Bool":
              return iconValueMapping.Bool[prop.valueMapping(value)] ||
                iconValueMapping.Enum[prop.valueMapping(value)];
            default:
              return undefined;
          }
        };

    const empty = !loading && propGroups && propGroups.length === 0;
    return (
      <div>
        <div id="divStatsPanel" className="stats-panel">
          <div className="header">
            <label id="lblAddZoneEle">Add Zone Elements</label>
            <i className={`icon icon-chevron-left`} onClick={() => {
              toggleSidePanel && toggleSidePanel();
            }}></i>
          </div>
          <ul className="actions">
            <li>
              <input type="text"
                     placeholder="Filter Attributes"
                     value={this.state.statsFilter}
                     disabled={empty || loading}
                     onChange={e => this.filterStats(e.target.value)}
              />
              <i className="icon-graph-expand-all" title="Expand All"
                 disabled={empty || loading}
                 onClick={this.expandAll} />
              <i className="icon-graph-collapse-all" title="Collapse All"
                 disabled={empty || loading}
                 onClick={this.collapseAll} />
              <i id="aClearAll" className="icon-slash" 
                  onClick={this.clearAll}
                  title="Clear All"
                  disabled={empty || loading}/>
            </li>
          </ul>
          {!empty ? propGroups.map((entry, index) => {
            const filterSelectedProps = fieldSelection.filter(selected => entry.properties.some(prop => selected.id === prop.id )),
              filterLength = filterSelectedProps.length;
            const isGroupSelected = entry.properties.length > 0
              ? filterLength === entry.properties.length
              : false;

            return (<ul key={index} className={ `${ entry.collapsed === undefined
                    ? filterLength === 0
                      ? "collapsed"
                      : ""
                    : entry.collapsed
                      ? "collapsed"
                      : "" }` } >
                <li className="heading">
                  <label>
                    <div className={`chkBox ${busy ? "disabled" : ""}`} onClick={(e) => {
                      if(!busy) {
                        if (isGroupSelected) {
                          const filterList = fieldSelection.filter(selected => !entry.properties.some(prop => selected.id === prop.id));
                          onSelectionChange && onSelectionChange([...filterList]);
                        } else {
                          const uniqList = _.uniqBy([...fieldSelection, ...(entry.properties.map(p => { p.selected = true; return p }))], function (p) {
                            return p.id;
                          });
                          onSelectionChange && onSelectionChange([...uniqList]);
                        }
                      }
                    }}>
                      <i className={"icon-check " + (isGroupSelected ? "checked" : "unchecked")} />
                      <span>{entry.group}</span>
                    </div>
                  </label>
                  <i className="icon" onClick={() => this.togglePropCollapse(entry, index, filterLength)} >
                    { entry.collapsed === undefined && filterLength === 0
                      ? "+"
                      : entry.collapsed
                        ? "+"
                        : "-" }
                  </i>
                </li>
                <ul className="props-list">
                  {entry.properties.map((prop, index) => {
                    const isSelected = fieldSelection.filter(field => field.id === prop.id).length > 0;
                    //Fetching color from selected field
                    const selectedPropertyColor = isSelected ? fieldSelection.filter(field => field.id === prop.id) : {};
                    return (<li key={index} className={`${isSelected ? "selected" : ""} ${busy ? "disabled" : ""}`} onClick={() => {
                      if (!busy) {
                        if (!isSelected) {
                          onSelectionChange && onSelectionChange([...fieldSelection, { ...prop, selected: true }]);
                        } else {
                          const filterList = fieldSelection.filter(field => field.id !== prop.id)
                          onSelectionChange && onSelectionChange([...filterList]);
                        }
                      }
                    }}>
                      <label title={prop.displayName + ( prop.description ? '\r\n'+prop.description + '\n':'' ) + ( prop.valuesDescription ? '\r\n'+prop.valuesDescription : '' )} style={isSelected ? { "color": selectedPropertyColor[0].color } : {}}>{prop.displayName}</label>
                      <label title={record && keyMap && isSelected ? displayValue(record, prop, keyMap) : "-"}>
                        {record && keyMap && isSelected && (<i className={iconClass(record, prop, keyMap)} />)}
                        {record && keyMap && isSelected ? displayValue(record, prop, keyMap) : "-"}
                      </label>
                    </li>)
                  })}
                </ul>
              </ul>)
          }) : (
            <div className="stats-empty-indicator">
              <h1>No attribute data available</h1>
            </div>
          )}
        </div>
        {loading === true && (
          <div className="stats-loading-indicator">
            <h1>LOADING...</h1>
          </div>
        )}
        {!isLhsPanelOpen && (
          <i className={`icon icon-chevron-right ico-expand`} onClick={() => {
            toggleSidePanel && toggleSidePanel();
          }}></i>
        )}
      </div>
    );
  }
});
