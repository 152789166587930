/* global location Promise FormData console AuthenticationContext */
import ApiClient from "../../components/api-client";
import Config from "../../config";
import Store from "store2";
import * as msal from "@azure/msal-browser";
import AuthService from "./Service";
import AI from "../../appInsights";
console.log("msal:::", msal);
//Auth Error/Failure Codes
const ERR_CODE_MSAL_INIT_NOT_DONE = 'sz_msal_init_not_done';
const msalInitError = msal.AuthError(ERR_CODE_MSAL_INIT_NOT_DONE,"Initialization failed. Contact your administrator.");

const Storage = Store.namespace(Config.appnamespace);

let authInfo;
let callbackId;
let accessToken;
const ScAuthService = {
  setAuth(user) {
    authInfo = user;
    this.storeAuth(user);
  },

  getAuth() {
    return authInfo || Storage.get("Auth");
  },

  storeAuth(user) {
    Storage.set("Auth", user);
  },

  getAuthToken() {
    const auth = this.getAuth();
    return auth ? auth.token : "";
  },

  setServiceCentralUser(user){
    Storage.set('scUser',JSON.stringify(user));
  },

  getServiceCentralUser(){
    const strJson = Storage.get('scUser');
    if(strJson){
      return JSON.parse(strJson);
    }
    return null;
  },

  setMsalEventCallbackId(id){
    callbackId = id;
  },
  
  getMsalEventCallbackId(){
    return callbackId;
  },

  getAuthScopes(){
    return [`${Config.clientId}/.default`]
  },

  getAPIToken(forceRefresh = false, accountInfo) {
    return new Promise((resolve,reject)=>{
      // We may need to some way to get new token silently 
      // if needed else user will be logged out and need
      // to sign in again and again
      const user = this.getAuth();
      if(forceRefresh || (!user)){
        this.setAuth(null);
        reject('NeedSCLogin:Need re-login');
      }
      else if(Config.auth.token || (user && user.token)){
        resolve({
          accessToken: user.token || Config.auth.token
        });
      }
      else{
        reject(new Error('Not logged in.'));
      }
    });
    // const jsonBody = {
    //   "client_id":"158612ec-d272-4277-b0d1-abc1ed80e146",
    //   "client_secret":"g1A8Q~u9IobLXaUeAsvoXRrenjWiaaMjgksP6bhj",
    //   "grant_type":"client_credentials",
    //   "scope":"https://subzerowolf.onmicrosoft.com/158612ec-d272-4277-b0d1-abc1ed80e146/.default"
    // };
    // var formBody = [];
    // for (var property in jsonBody) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(jsonBody[property]);
    //   formBody.push(encodedKey + "=" + encodedValue);
    // }
    // formBody = formBody.join("&");
    // const url = `https://login.microsoftonline.com/1399eebf-ed21-4b2a-a4b5-edcd23e7f7a6/oauth2/v2.0/token`
    // const options = {
    //   method:'POST',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   },
    //   body:formBody,
    // };
    // return fetch(url,options);
    // .then(res=> res.json())
    // .then(resBody=>{
    //   console.log('Token Res:',resBody);
    //   // AuthService.setServiceCentralUser(resBody);
    //   app.showLoading(false);
    //   app.route('/appliance-search');
    //   util.setLoggedInUsingSc(true);
    // }).catch(e=>{
    //   console.log("Can't get token",e);
    //   app.notifications.error("Can't get token");
    //   app.showLoading(false);
    // }) 
  },
  login() {
    if(!Config.scLoginUrl){
      console.log("Missing login URL.");
      return;
    }
    const encodedReturnUrl = encodeURI(Config.redirectUri);
    const scLoginUrl = `${Config.scLoginUrl}/Login/CAPLogin?key=${Config.scKey}&returnUrl=${encodedReturnUrl}`;
    window.open(encodeURI(scLoginUrl),"_self");
  },
  logout() {
    return new Promise((resolve,reject)=>{
      try{
        const userSessoinId = sessionStorage.getItem("userSessoinId"),
        timeStamp = new Date().getTime();
        const user = this.getAuth();
        AI.trackUserLoginEvent("USER_LOGOUT",{email: user.email, userName: user.username , 
            groups: JSON.stringify([]),
            userSessoinId, timeStamp});
        sessionStorage.removeItem('userSessoinId');
        sessionStorage.removeItem('applianceSessionID');
        AuthService.setAuth(null);
        Config.auth.token = null;
        resolve(true)
      }
      catch(e){
        console.log('Failed to logout:',e);
      }

    });
  },
  getAuthorityUrl(){
    return Config.authority+'/'+Config.tenant;
  },
  getAuthContextObject(callback) {
    //dummy implementation
    return {
      getActiveAccount:()=>null,
      handleRedirectPromise:()=> new Promise((resolve,reject)=>{
        resolve(null);
      }),
      addEventCallback:(mockCallback)=>null,
      getAllAccounts:()=> ['mock'],
    }
  },
  getAccessToken() {
    return accessToken;
  },
  setAccessToken(data) {
    accessToken = data;
  }
};

export default ScAuthService;