import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";

const Status = ReactClass({
  displayName: "Status",
  propTypes: {
    className: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string
  },
  render() {
    const {className = "", icon, label, value} = this.props;

    const labelComponent = typeof label !== "undefined" ? (
      <div className="label">{label}</div>
    ) : null;

    const valueComponent = typeof value !== "undefined" ? (
      <span className="value">{value}</span>
    ) : null;

    return (
      <div className={"status-component " + className}>
        {labelComponent}
        <img className="icon" src={icon} alt={label || "Icon"} />
        {valueComponent}
      </div>
    );
  }
});


export default Status;
