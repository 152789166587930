/* global console, Image history */
import React from "react";
import ReactClass from "create-react-class";
import { WidgetPanel, AsSubscribedComponent } from "ui-dashboard";
import Config from "../../../config";
import WifiConnected from "./Component";
import AnswerAdviser from "../../../modules/answer-adviser";
import Util from "../../util";
const imgBasePath = `${Config.baseDir}components/dashboard-widgets/network-conn-info/images`;

const Widget = AsSubscribedComponent(ReactClass({
	displayName: "NetworkConnInfoWidget",
	getInitialState: function () {
		return {
			data: {}
		};
	},
	componentDidUpdate() {
		this.props.layout.layout();
	},
	render: function () {
		const { Header, Content } = WidgetPanel,
      { data: { networkName, connectionQuality, type } } = this.state,
      totalBar = 8,
      maxrssi = 70,
      totalFill = connectionQuality
                ? type && type.substring(0,2) === "1." 
					? Math.ceil(parseInt(connectionQuality) / (maxrssi / totalBar)) 
					: Math.ceil((95 - Math.abs(parseInt(connectionQuality))) / (maxrssi / totalBar))
				: 0;
		let param = AnswerAdviser.getCRMTicket();
		const {CRM_TICKET_ID} = param,
				crmTicketId = CRM_TICKET_ID ? `&CRM_TICKET_ID=${CRM_TICKET_ID}` : "",
				aaUrl = `${Config.answerAdvisorLink}/multi-brand/connected-appliance-wifi-signal-strength`;
		return (
			<WidgetPanel {...this.props}>
				<Header>
					<span>{this.props.title}</span>
				</Header>
				<Content>
					<div className="network-info">
						<div id="nwt-name">
							<span>Network Name </span>
							<label id="network-name">{networkName || 'N/A'}</label>
							<img src={`${imgBasePath}/ntwk_name.svg`} id="ntwk_name"/>
						</div>
						<div id="conn-quality">
							<span>Connection Quality </span>
              <WifiConnected totalBar={totalBar} fill={totalFill} rssi={type && type.substring(0,2) === "1."  ? parseInt(connectionQuality) : (95 - Math.abs(parseInt(connectionQuality)))} />							
						</div>
						<div onClick={() => Util.trackAAEvent(aaUrl)}>
							<a href={`${Config.answerAdvisorLink}/multi-brand/connected-appliance-wifi-signal-strength`} 
								target="_blank" id="conn-quality-aa" className="aalink">Answer Advisor</a>	
						</div>
					</div>
				</Content>
			</WidgetPanel>
		);
	},
	onData: function (data, channel) {
		this.setState({
			channel: channel,
			data: data
		});
	}
}));

const Info = {
	id: "appliance-dashboard:networkConnInfo",
	name: "Network Conn Info",
	type: Widget,
	description: "Show Network Conn Info",
	icon: "",
	tags: "network conn info"
};

export default {
	Info,
	Widget
};
