/* global console setTimeout clearTimeout*/
import React from "react";
import ReactClass from "create-react-class";
import dashboardWidgets from "../../components/dashboard-widgets";
import {Dashboard} from "../../components/dashboard";
import Util from "../../components/util";
import jsPDF from "jspdf";
import * as _ from "lodash";

import {
  DialogContainer,
  Notifications,
  LoadingIndicator,
  MultiValueInput,
  Tabs
} from "../../components/widgets";

import {
  Service as ApplianceService,
  ApplianceLeftNav,
  DiagnosticsPanel,
  ApplianceInfoComponent} from "../appliance";

import initialization from "../../modules/initialization";
import {Factories as DashboardFactories} from "./channel-config";
import {AuthService} from "../auth";
import Config from "../../config";
import DashboardService from "./Service";
import SSEChannel from "../../components/sse";
import ResourcesComponent from "../common/Resources";
import {AppEmulation} from "../enduser-app";

import ApiClient from "../../components/api-client";
import AnswerAdviser from "../../modules/answer-adviser";

const {asJson: resAsJson} = ApiClient;

const EMPTY_DASH = {
  name: "Simple",
  datalayer: {
    name: "None",
    channels: []
  },
  widgets: []
};
let fetchApplStateInitPromise = null, fetchApplStatePollPromise = null;
export default ReactClass({
  displayName: "DashboardView",
  pollingId: null,
  shouldPoll: false,
  getInitialState() {
    return {
      sidePanel: false,
      fetchingAppliances: false,
      appliances: [],
      dashboardConfig: EMPTY_DASH,
      initialData: initialization.getData(),
      currentAppliance: null,
      diagnosticsData: {},
      reloadDashboard: false,
      errorCodeData: []
    };
  },
  cleanUp() {
    this.stopApplianceStatePolling();
  },
  componentWillUnmount() {
    this.abortPromise(fetchApplStateInitPromise);
    this.abortPromise(fetchApplStatePollPromise);
    this.cleanUp();
  },
  componentWillMount() {
    const Appliances = ApplianceService.getCurrentApplianceInfos();
    if(Appliances && Appliances.length > 0) {
      this.setState({
        fetchingAppliances: false,
        appliances: Appliances
      });
    }
    const projectName = ApplianceService.getSelectedCatProjectName();
    if(projectName === null) {
      const {route: {params: {selectedDeviceId, selectedSerial}}, application: app} = this.props;
      if(selectedDeviceId)
        app.route(`/appliance/${selectedDeviceId}/dashboard/${selectedSerial ? selectedSerial + "/" : ""}`);
      else
        app.route(`/appliance/dashboard/${selectedSerial}/`);
    }
  },
  componentDidMount() {
    const {route: {params: {selectedDeviceId, selectedSerial}}} = this.props,
        Appliances = ApplianceService.getCurrentApplianceInfos(),
        {deviceId} = this.state.initialData;
    if(!deviceId || !deviceId.length) {
      if(Appliances && Appliances.length > 0) {
        this.setCurrentAppliance(Appliances);
      } else {
        if(selectedDeviceId) {
          this.setState({
            fetchingAppliances: true
          });
          ApplianceService.getApplianceInfoFromDeviceId(selectedDeviceId).then(infos => {
            if(infos.length > 0) {
              this.setState({
                fetchingAppliances: false,
                appliances: infos
              });
              ApplianceService.setCurrentApplianceInfos(infos);
              this.setCurrentAppliance(infos);
            } else {
              this.routeToApplianceSearch();
            }
          }).catch(err => {
            this.routeToApplianceSearch();
            throw err;
          });
        } else {
          if(selectedSerial) {
            this.setState({
              fetchingAppliances: true
            });
            ApplianceService.getApplianceInfoFromSerialNo(selectedSerial).then(infos => {
              if(infos.length > 0) {
                this.setState({
                  fetchingAppliances: false,
                  appliances: infos
                });
                ApplianceService.setCurrentApplianceInfos(infos);
                this.setCurrentAppliance(infos);
              } else {
                this.routeToApplianceSearch();
              }
            }).catch(err => {
              this.routeToApplianceSearch();
              throw err;
            });
          } else {
            this.routeToApplianceSearch();
          }
        }
      }
    }else {
      if(Appliances.length === 0)
        this.fetchApplianceInfo();
      else
        this.setCurrentAppliance(Appliances);
    }
  },
  setCurrentAppliance(Appliances) {
    const {route: {params: {selectedDeviceId, selectedSerial}}} = this.props,
        selectedIdx = (selectedDeviceId
            ? Appliances.map(e => e.deviceId).indexOf(selectedDeviceId)
            : Appliances.map(e => e.serial).indexOf(selectedSerial));
    if(selectedIdx > -1) {
      ApplianceService.setActiveAppliance(Appliances[selectedIdx]);
      this.showDashboard(Appliances[selectedIdx]);
    }
    else if(Appliances.length) {
      ApplianceService.setActiveAppliance(Appliances[selectedIdx]);
      this.showDashboard(Appliances[0]);
    }
  },
  showNotification() {
    this.props.application.notifications.info(this.props.route.path);
  },

  routeToApplianceSearch() {
    const {application} = this.props;
    application.route("/appliance-search");
  },

  fetchApplianceInfo(params) {
    const {deviceId, macAppId, bda} = this.state.initialData,
        {application} = this.props,
        notifications = application.notifications;

    this.setState({
      fetchingAppliances: true
    });

    ApplianceService.getApplianceInfo(!params ? this.state.initialData : params).then(infos => {
      if(infos.length > 0) {
        this.setState({
          fetchingAppliances: false,
          appliances: infos
        });
        ApplianceService.setCurrentApplianceInfos(infos);
        this.setCurrentAppliance(infos);
      } else {
        notifications.error("Error fetching appliance information.");
      }
    }).catch(err => {
      notifications.error("Error fetching appliance information.");
      this.setState({
        fetchingAppliances: false,
        appliances: []
      });
      throw err;
    });
  },

  renderDashboard() {
    const {fetchingAppliances, appliances, dashboardConfig, reloadDashboard, currentAppliance} = this.state,
        {application, route: {params}} = this.props,
        ctx = Object.assign({}, application, {routeParams: params}, {applianceDetails: currentAppliance});
    return fetchingAppliances || reloadDashboard?
    (
      <div className="dashboard-message">
        <div className="_text-center anim">
          <i className="icon icon-loader spin"></i>
        </div>
        <p>Loading appliance info...</p>
      </div>
    ) :
    appliances.length ?
    (
      <Dashboard onWidgetAction={this.onWidgetAction}
                 context={ctx}
                 config={dashboardConfig}
                 onChange={this.handleDashboardChanged}
      />
    ) :
    (
      <div className="dashboard-message">
        <i className="icon icon-info"></i>
        <p>No appliances were found. Please try again.</p>
        <button className="primary inline"
            onClick={this.routeToApplianceSearch}>
          <i className="icon-refresh-cw"></i> Try Again
        </button>
      </div>
    );
  },
  renderSidePanel() {
    const {diagnosticsData, currentAppliance, sidePanel} = this.state;
    return (
      sidePanel ? <DiagnosticsPanel diagnostics={diagnosticsData} appliance={currentAppliance} />
          : <div />
    );
  },
  setErrorCodes(data) {
    this.setState({
      errorCodeData: data
    })
  },
  onWidgetAction(id, action, data) {
    const act = id + "/" + action, {application} = this.props;
    switch(act) {
      case "connectivity/settings":
        if(data.appliance === null || data.latest === null) {
          application.notifications.warn("Data is not yet available.");
        }else if (!data.history) {
          application.notifications.warn("History is not yet available.");
        }else{
          this.showDiagnostics(data);
        }
        break;
      case "connectivity/refresh":
        this.fetchDiagnostics();
        break;
      case "provisioningReport/refresh":
        this.fetchProvisioningReport();
        break;
      case "applianceInfo/route-to":
        application.route(data);
        break;
      case "applianceInfo/exportPdf":
        this.exportPdf(data);
        break;
      case "pollingFrequency/setPollingFrequency":
        return this.setPollingFrequency(data);
      case "pollingFrequency/getPollingFrequency":
        return this.fetchPollingFrequency(data);
      case "accountInfo/reloadDevices":
        this.reloadDeviceList(data);
        break;
      case "errorcodes/errorsCode":
        this.setErrorCodes(data);
        break;
      case "timezones/timeZone":
        break;
      default:
        console.log("Unhandled Widget Action", id, action, data);
    }
  },
  exportPdf(data) {
    let exp = DashboardService.getExportpdfData();
    const doc = new jsPDF(),
        pageHeight = 280;
    let index = 40, conIndex = 40, conLeft = 110, spaceConst = 7;

    doc.setDrawColor("#777");
    doc.setLineWidth(0.4);
    doc.line(5, 20, 205, 20);
    doc.setFontSize(16);
    doc.setTextColor("#777");
    doc.text(`Serial: ${data["serial"]}`, 10, 15);
    doc.text(`MAC: ${data["deviceId"]}`, conLeft, 15);

    doc.text("APPLIANCE INFO", 10, 30);
    doc.text("CONNECTED MODULE", conLeft, 30);
    doc.setFontSize(10);

    _.forEach(DashboardService.applianceInfoData, (v, k)=> {
      doc.text(k, 12, index);
      doc.text(`: ${data[v]}`, 60, index);
      index += spaceConst;
    });

    _.forEach(DashboardService.connectedModule, (v, k)=> {
      doc.text(k, conLeft, conIndex);
      doc.text(`: ${data[v]}`, conLeft + 60, conIndex);
      conIndex += spaceConst;
    });
    doc.setFontType("bold");
    doc.setTextColor("#666");
    doc.text("SOFTWARE VERSIONS", 12, index);
    index += spaceConst;
    doc.setFontType("normal");
    _.forEach(data.versions, (v, k)=> {
      doc.text(k.toUpperCase(), 15, index);
      doc.text(`: ${v}`, 60, index);
      index += spaceConst;
    });
    index += spaceConst;
    conIndex += spaceConst;
    doc.setFillColor("#fcfcfc");
    doc.rect(conLeft - 2, conIndex - 5, 92, 29, 'F');
    doc.setFontSize(16);
    doc.setTextColor("#777");
    doc.text("WIRELESS NETWORK", conLeft, conIndex + 1)
    conIndex += spaceConst;
    doc.setFontSize(10);
    _.forEach((Object.keys(exp.networkInfo).reverse()).map((key) => {
        if(key == "networkName") {
          doc.text("NETWORK NAME", conLeft, 5 + conIndex);
          doc.text(`: ${exp.networkInfo[key]}`, conLeft + 60, conIndex + 5);
        }else {
          const totalBar = 8,
          maxrssi = 70,
          totalFill = exp.networkInfo['connectionQuality']
                ? Math.ceil(parseInt(exp.networkInfo['connectionQuality']) /(maxrssi / totalBar))
                : 0;
          let color = exp.networkInfo['connectionQuality'] <= 14
              ? "rgb(255,0,24)"
              : ((exp.networkInfo['connectionQuality'] >= 15 && exp.networkInfo['connectionQuality'] <= 24) ? "#fced65" : "rgb(122,157,70)");
          doc.text("CONNECTION QUALITY", conLeft, 5 + conIndex);
          doc.text(": ", conLeft + 60, conIndex + 5);
          for(let i = 0; i < totalBar; i++) {
            i < totalFill ? doc.setFillColor(color) : doc.setFillColor("#ddd");
            doc.rect(conLeft + 62 + (2 * i), conIndex + 2,  1, 4, 'F');
          }
        }
      conIndex += spaceConst;
    }))
    doc.setTextColor("#666");
    if(exp.errors && exp.errors.length) {
      conIndex += 10;
      doc.setFontSize(16);
      doc.text("ERROR CODES", conLeft, conIndex + 10);
      conIndex += spaceConst + 10;
    }
    if (this.diagnosticsResp && this.diagnosticsResp.latest) {
      doc.text("DIAGNOSTIC BITS INFO", 10, index);
      index += spaceConst;
    }

    doc.setFontSize(10);
    if (this.diagnosticsResp && this.diagnosticsResp.latest) {
      const {latest: {records}} = this.diagnosticsResp;
      _.forEach(records, (v, k) => {
        doc.setFontType("normal");
        doc.setTextColor("#777");
        doc.text(DashboardService.diagPropNameMapping[k], 15, index);
        doc.setFontType("bold");
        if(v == "GOOD")
          doc.setTextColor("#94B75F");
        else if(v == "BAD")
          doc.setTextColor("#D66B55");
        doc.text(`: ${v}`, 60, index);
        index += spaceConst;
      });
    }
    doc.setFontType("normal");
    doc.setTextColor("#777");
    _.forEach(exp.errors, (v, idx) => {
      let reMajorDevType = /^\d+\.\d+/,
          devMajorType = data["type"].match(reMajorDevType) && data["type"].match(reMajorDevType)[0];
      const {active, time, errorCodeString, errorCode, errorCodesLabel, desc} = v,
          {location, failure, type, device} = errorCode,
          code = errorCodeString,
          projectMapping = {
            "1.6": 'Water Products'
          },
          link = AnswerAdviser.generateErrorCodeLink(data, errorCode, projectMapping[devMajorType]),
          errLabel = errorCodesLabel.toUpperCase();
      let splitDesc = doc.splitTextToSize(desc, 95),
          splitDecLen = splitDesc.length *  4;
      doc.setFillColor("#fcfcfc");
      if(idx % 2 > 0) {
        doc.setFillColor("#f0f0f0");
      }
      doc.rect(conLeft - 2, conIndex - 5, 92, splitDecLen + 16, 'F');
      if(conIndex > pageHeight) {
        if(conLeft == 110) {
          conLeft = 10;
          doc.addPage();
        }else {
          conLeft = 110;
        }
        conIndex = 10;
      }
      
      doc.setFillColor("#000");
      doc.text(splitDesc, conLeft, conIndex);
      doc.text(`Error Code`, conLeft,  splitDecLen + 2 + conIndex);
      
      doc.setFillColor("#6d6d6d");
      doc.rect(20 + conLeft, splitDecLen + conIndex - 2 , 15, 5, 'F');
      doc.setTextColor("#fff");
      doc.text(`${code}`, 21 + conLeft, splitDecLen + 2 + conIndex);
      let errRectWidth;
      switch(errorCodesLabel.toLowerCase()) {
        case "active":
          doc.setFillColor("#D66B55");
          errRectWidth = 15;
          break;
        case "inactive":
          doc.setFillColor("#bdbdbd");
          errRectWidth = 18;
          break;
        case "low":
          doc.setFillColor("#59bc2a");
          errRectWidth = 10;
          break;
        case "high": 
          doc.setFillColor("#D66B55");
          errRectWidth = 12;
          break;
        case "medium": 
          doc.setFillColor("#e7b726");
          errRectWidth = 15;
          break;
        case "fault": 
          doc.setFillColor("#D66B55");
          errRectWidth = 14;
          break;
        case "warning": 
          doc.setFillColor("#e7b726");
          errRectWidth = 19;
          break;
      }
      
      doc.rect(37 + conLeft, splitDecLen + conIndex - 2, errRectWidth, 5, 'F');
      doc.setTextColor("#fff");
      doc.text(`${errLabel}`, 38 + conLeft, splitDecLen + 2 + conIndex);
      doc.setTextColor("#00acba");

      doc.setFillColor("#2DC4D3");
      doc.rect(39 + conLeft + errRectWidth, splitDecLen + conIndex - 2, 35, 5, 'F');
      doc.setTextColor("#fff");
      doc.text(`${Util.formatDate(new Date(v.timestamp),'YYYY-MM-DD HH:mm:ss')}`, 40 + conLeft + errRectWidth, splitDecLen + 2 + conIndex);
      
      doc.setTextColor("#00acba");
      doc.textWithLink("Learn more about this error code", conLeft, splitDecLen + 7 + conIndex, {
        url: `${link}`
      });
      doc.setTextColor("#666");
      conIndex += splitDecLen + spaceConst*2;
    });
    doc.save(`appliance_${data["serial"]}.pdf`);
  },
  setPollingFrequency(frequency) {
    const {currentAppliance: {deviceId}} = this.state;
    DashboardService.setPollingFrequency(deviceId, frequency);
  },
  fetchProvisioningReport(){
    const {currentAppliance, datalayer} = this.state;
    DashboardService.fetchProvisioningReport(currentAppliance)
    .then((res)=>{
      datalayer && datalayer.publish("/appliance/provisioning-report", res);
    });
  },
  fetchPollingFrequency(freq) {
    const {application: {notifications}} = this.props,
        {currentAppliance: {deviceId}} = this.state;
    return DashboardService.getPollingFrequency(deviceId).then(res => {
      const {frequency: latestFrequency} = res;
      if(+freq === +latestFrequency) {
        notifications.success("Successfully set polling frequency.");
      } else {
        notifications.error("Failed to set polling frequency for this device.");
      }
      return res;
    }).catch(errRes => {
      throw errRes.json().then(err => {
        notifications.error(err.errorMessage || "Failed to set polling frequency for this device.");
        return err;
      });
    });
  },
  reFetchDiagnostics() {
    const panelState = this.state.sidePanel;
    if(panelState) {
      Util.throttle(() => {
        this.fetchDiagnostics();
      }, 15000)();
    }
  },
  fetchDiagnostics() {
    const {currentAppliance, datalayer, sidePanel} = this.state;
    DashboardService.fetchDiagnostics(currentAppliance).then(res => {
      DashboardService.fetchDiagnosticsStatus(currentAppliance).then(response => {
        res.records = response.records;
        this.diagnosticsResp = _.cloneDeep(res);
        datalayer && datalayer.publish("/appliances/diagnostics/", res);
        if (sidePanel) this.reFetchDiagnostics();
      })
      .catch(errRes => {
        datalayer && datalayer.publish("/appliances/diagnostics/", res);
        if (sidePanel) this.reFetchDiagnostics();
      });
    }).catch(errRes => {
      if (sidePanel) this.reFetchDiagnostics();
    });
  },
  reloadDeviceList(data) {
    this.fetchApplianceInfo(data.typeField);
  },
  fetchDeviceStatuss() {
    const {currentAppliance: appliance, datalayer} = this.state;
    DashboardService.fetchDeviceStatus(appliance).then(deviceStatus => {
      const response = {
        appliance,
        deviceStatus
      }
      datalayer && datalayer.publish("/appliances/deviceStatus", response);
      }).catch(errRes => {
        datalayer && datalayer.publish("/appliances/deviceStatus", {appliance: {}, deviceStatus: {}});
      })
  },
  fetchErrorCodess() {
    const {currentAppliance: appliance, datalayer} = this.state;
    DashboardService.fetchErrorCodes(appliance).then(errors => {
      const response = {
        appliance,
        errors
      }
    
      
      datalayer && datalayer.publish("/appliances/errors", response);
      }).catch(errRes => {
        datalayer && datalayer.publish("/appliances/errors", {});
      })
  },
  fetchTimeZone() {
    const {currentAppliance: appliance, datalayer} = this.state;
    DashboardService.fetchTimeZones().then(timeZones => {
      DashboardService.getTimeZoneId(appliance).then(currentDeviceTimeZone => {
        const data = {
          currentDeviceTimeZone,
          timeZones
        }
        datalayer && datalayer.publish("/appliances/timezone", data);
      }).catch(errRes => {
        datalayer && datalayer.publish("/appliances/timezone", {});
      })
    }).catch(errRes => {
      datalayer && datalayer.publish("/appliances/timezone", {});
    })
  },
  showDiagnostics(data) {
    const panelState = this.state.sidePanel, {application} = this.props;
    if(panelState) {
      // panel already showing, so we are closing the panel, hence don't update diagnostics
      this.toggleSidePanel();
    }else {
      if(data) {
        this.setState({
          diagnosticsData: data,
          sidePanel: !panelState
        }, _=> this.reFetchDiagnostics());
      }else {
        application.notifications.warn("Diagnostics data is not yet available.");
        this.setState({
          diagnosticsData: data,
          sidePanel: !panelState
        }, _=> this.reFetchDiagnostics());
      }
    }
  },
  selecteAppliance(deviceId, serial) {
    this.cleanUp();
    const {application: app} = this.props,
        projectName = ApplianceService.getSelectedCatProjectName();
    if(deviceId)
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/${deviceId}/dashboard/${serial ? serial + "/" : ""}`);
    else
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/dashboard/${serial}/`);
  },
  showDashboard(appliance) {
    if (appliance) {
      const name = appliance.name,
          dashboardFactory = DashboardFactories.getFactory(appliance.type);
      this.setState({
        dashboardConfig: EMPTY_DASH,
        sidePanel: false,
        sidebar: false,
        reloadDashboard: true
      });

      ApplianceService.setActiveAppliance(appliance);
      DashboardService.fetchNetworkConnInfo(appliance).then(res => {
        DashboardService.setExportpdfData("networkInfo", res);
        this.dashboardConfig(dashboardFactory, appliance, res);
      }).catch(errRes => {
        this.dashboardConfig(dashboardFactory, appliance, {});
      });
    }
  },
  dashboardConfig(dashboardFactory, appliance, networkConnInfo) {
    DashboardService.fetchWidgetConfiguration().then(({configWidgets, perApplianceType}) => {
      dashboardFactory(appliance, configWidgets, perApplianceType[appliance.type], networkConnInfo).then(config => {
        setTimeout(() => {
          this.setState({
            dashboardConfig: config,
            currentAppliance: appliance,
            applListSidePanel: false,
            reloadDashboard: false
          }, () => {
            this.abortPromise(fetchApplStateInitPromise);
            if(appliance.deviceId) {
              fetchApplStateInitPromise = DashboardService.fetchApplianceStates(appliance.deviceId);
              fetchApplStateInitPromise.then(resAsJson)
              .then(res => Object.assign(res, {deviceId: appliance.deviceId}))
              .then(applianceInfo => {
                this.handleApplianceProperties(applianceInfo);
                this.pollApplianceState();
                this.fetchDiagnostics();
                this.fetchDeviceStatuss();
                this.fetchErrorCodess();
                this.fetchTimeZone();
              }, err => {
                console.error(err);
                // In error we are calling following functions, it is observed that
                //if we call it just before fetchApplStateInitPromise, doesn't
                //work some how.
                this.fetchDiagnostics();
                this.fetchDeviceStatuss();
                this.fetchErrorCodess();
                this.fetchTimeZone();
              });
            } else {
              console.log("Device id is not available");
            }
          });
        }, 300);
      });
    }).catch(err => {
      console.log(err);
    });
  },
  handleDashboardChanged(DataLayer, config) {
    this.setState({
      datalayer: DataLayer
    });
  },
  toggleSidePanel() {
    const panelState = !this.state.sidePanel;
    this.setState({
      sidePanel: panelState
    }, _=> this.reFetchDiagnostics());
  },
  renderApplianceList() {
    const {route: {params: {selectedDeviceId, selectedSerial}}} = this.props;
    return <ApplianceLeftNav appliances={this.state.appliances}
      application={this.props.application}
      selectedDeviceId={selectedDeviceId}
      selectedSerial={selectedSerial}
      onApplianceSelected={this.selecteAppliance}
      fetchingAppliances={this.state.fetchingAppliances}
      fetchApplianceInfo={this.fetchApplianceInfo}
      />;
  },
  render: function() {
    const {route, application} = this.props,
        {fetchingAppliances, reloadDashboard, currentAppliance, errorCodeData} = this.state;
    return (
      <div className="view dashboard-view layout">
        {this.renderApplianceList()}
        <div className="main-content">
          {
            (!fetchingAppliances && !reloadDashboard && currentAppliance)
            ? <div className="top-header">
                <ResourcesComponent currentAppliance={currentAppliance} errorCodeData={errorCodeData}/>
                <AppEmulation application={application} appliance={currentAppliance} />                  
              </div>
            : null
          }
          <div className="app-dashboard">
            {this.renderDashboard()}
          </div>
        </div>
        <div className={"sidebar sidebar-right " + (this.state.sidePanel ? "in" : "")}>
          <i className="activable icon-x-circle _pull-right" onClick={this.toggleSidePanel} />
          {this.renderSidePanel()}
        </div>
      </div>
    );
  },

  pollApplianceState() {
    const {currentAppliance} = this.state;
    this.shouldPoll = true;
    this.abortPromise(fetchApplStatePollPromise);
    this.pollingId = setTimeout(() => {
      fetchApplStatePollPromise = DashboardService.fetchApplianceStates(currentAppliance.deviceId)
        .then(resAsJson)
        .then(res => Object.assign(res, {deviceId: currentAppliance.deviceId}))
        .then(appliance => {
          this.handleApplianceProperties(appliance);
          if (this.shouldPoll) {
            console.log("Should poll to true");
            this.pollApplianceState();
          }
        }, err => {
        });
    }, Config.fallbackPollingInterval);
  },
  abortPromise(promise) {
    if(promise && promise._abort) {
      promise._abort();
    }
  },
  stopApplianceStatePolling() {
    this.shouldPoll = false;
    clearTimeout(this.pollingId);
  },
  handleApplianceProperties(properties) {
    const {datalayer} = this.state;
    if(properties && datalayer) {
      const {appliances} = this.state,
          updatedAppl = appliances.map(appliance => {
            if(appliance.deviceId === properties.deviceId) {
              appliance.connectedStatus = properties.connectedStatus;
            }
            return appliance;
          });
      this.setState({
        appliances: updatedAppl
      });
      datalayer.publish("/appliance/properties", properties);
    }
  }
});