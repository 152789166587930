/* global console setTimeout Promise history */
import React from "react";
import ReactDOM from "react-dom";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import PageMessage from "./PageMessage";

export default ReactClass({
  displayName: "ErrorView",
  getInitialState: function() {
    return {};
  },

  back() {
    history.back();
  },

  render: function() {
    const {error, message} = this.props;

    return (
      <div className="view error-view">
        <div className="content">
          <PageMessage icon="icon-x-circle" message={error.status + " : "+ message}>
          </PageMessage>
          <div className="back-btn">
            <button className="inline primary" onClick={this.back}>Back</button>
          </div>
        </div>
      </div>
    );
  }
});
