/* global console setTimeout */
import React from "react";
import ReactClass from "create-react-class";
import * as _ from "lodash";

export default ReactClass({
  displayName: "ScalePropertySelector",
  getInitialState() {
    return {
      relatedAttributes: [],
      relatedSelections: {}
    };
  },

  setRelatedSelection(propId, mode) {
    const {relatedSelections} = this.state,
        checked = !(relatedSelections[propId] ? relatedSelections[propId].selected : false);
    this.handleRelatedSelectionChange(propId, mode)({
      target: {
        checked
      }
    });
  },

  handleRelatedSelectionChange(propId, mode) {
    return evt => {
      const {relatedSelections} = this.state;

      if (evt.target.checked && !mode) {
        mode = "this-line";
      }

      let cfg = Object.assign(
        {},
        relatedSelections[propId] || {},
        {selected: evt.target.checked, mode}
      );
      this.setState({
        relatedSelections: Object.assign({}, relatedSelections, {
          [propId]: cfg
        })
      });
    };
  },

  componentDidMount() {
    const {prop, model} = this.props,
        relAttr = prop.relatedAttributes || [];

    if (relAttr.length) {
      const models = model.byValueField(...relAttr);
      this.setState({
        relatedAttributes: models,
        relatedSelections: {}
      });
    }

    if (prop.againstAttr) {
      this.setState({
        setPointAttr: model.byValueField(prop.againstAttr)[0]
      });
    }
  },

  getSelections() {
    const {relatedSelections} = this.state;
    return {relatedSelections};
  },

  render: function() {
    const {relatedAttributes, relatedSelections, setPointAttr} = this.state;
    return (
        <section className="type-selector type-enum">
          <ul className="plot-location">
            {setPointAttr && (
              <li>
                <input type="checkbox" value={relatedSelections[setPointAttr.id] && relatedSelections[setPointAttr.id].selected}
                       onChange={this.handleRelatedSelectionChange(setPointAttr.id, "set-point")} />
                <span onClick={() => this.setRelatedSelection(setPointAttr.id, "set-point")}>
                  Against Set-Point - "{setPointAttr.displayName}"
                </span>
              </li>
            )}
          </ul>

          {relatedAttributes.length ? (
              <div className="related-attributes">
                <h3>Related Attributes</h3>
                <ul>
                  {relatedAttributes.map((attr, index) => (
                      <li key={index}>
                        <input type="checkbox" value={relatedSelections[attr.id] && relatedSelections[attr.id].selected}
                               onChange={this.handleRelatedSelectionChange(attr.id)} />
                        <span onClick={() => this.setRelatedSelection(attr.id)}>
                          {attr.displayName}
                        </span>

                        {_.includes(["Enum", "Bool"], attr.type) && (
                            <ul className="inline-radio-group">
                              <li>
                                <input type="radio" value={"this-line"}
                                       checked={relatedSelections[attr.id] && relatedSelections[attr.id].mode === "this-line"}
                                       onChange={this.handleRelatedSelectionChange(attr.id, "this-line")} />
                                <span onClick={() => this.setRelatedSelection(attr.id, "this-line")}>
                                  On This Line
                                </span>
                              </li>
                              <li>
                                <input type="radio" value={"x-axis"}
                                       checked={relatedSelections[attr.id] && relatedSelections[attr.id].mode === "x-axis"}
                                       onChange={this.handleRelatedSelectionChange(attr.id, "x-axis")} />
                                <span onClick={() => this.setRelatedSelection(attr.id, "x-axis")}>
                                  On X-Axis
                                </span>
                              </li>
                            </ul>
                        )}
                      </li>
                  ))}
                </ul>
              </div>
          ) : ""}
        </section>
    );
  }
});
