/* global console, Image */
import React from "react";
import ReactClass from "create-react-class";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import AnswerAdviser from "../../../modules/answer-adviser";

const WifiConnected = ReactClass({
  displayName: "WifiConnected",
  render() {
    let item = [];
    const {totalBar, fill, rssi} = this.props,
        rssiClass = rssi < 12
          ? "red"
          :((rssi >= 12 && rssi <= 20) ? "yellow" : "green");
    for(let i=0; i< totalBar; i++) {
      if(i < fill)
        item.push(<div key={"Wifi_"+i} className={"wifi " + rssiClass}></div>);
      else
        item.push(<div key={"Wifi_"+i} className="wifi"></div>);
    }
    return (
      <div className="status" title={rssi}>
        {item}
      </div>
    );
  }
});

const mapping = {
  "-1": {
    "tooltip": "Unknown - Unsupported",
    "className": "RED"
  },
  "0": {
    "tooltip": "Open - Unsupported",
    "className": "RED"
  },
  "1": {
    "tooltip": "WEP - Unsupported",
    "className": "RED"
  },
  "2": {
    "tooltip": "WPA - PSK",
    "className": "BLUE"
  },
  "3": {
    "tooltip": "WPA2 - PSK",
    "className": "BLUE"
  },
  "4": {
    "tooltip": "WPA - TKIP CCMP",
    "className": "BLUE"
  },
  "5": {
    "tooltip": "WPA - CCMP",
    "className": "BLUE"
  },
  "6": {
    "tooltip": "WPA - TKIP",
    "className": "BLUE"
  },
  "7": {
    "tooltip": "WPA2 - TKIP CCMP",
    "className": "Blue"
  },
  "8": {
    "tooltip": "WPA2 - CCMP",
    "className": "BLUE"
  },
  "9": {
    "tooltip": "WPA2 - TKIP",
    "className": "BLUE"
  }
};

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "ConsumerWifiScanListWidget",
  getInitialState: function () {
    return {
      data: [],
      appliance: {}
    };
  },
  render: function () {
    const {Header, Content} = WidgetPanel,
        totalBar = 8,
        maxrssi = 70,
        {data: {appliance, accessPoints = []}} = this.state,
        query = "K30214100", // Query param value for AA link for WifiScanList(hardcoded only)
        aaLink = (appliance
            ? (<a target="_blank" className="_pull-right"
                href={AnswerAdviser.generateAppInfoLink(appliance, query, false)}>
                Answer Advisor
              </a>)
            : null),
        rows = accessPoints.map(item => {
          const clazz = (item.encryption >= 1
            ? "icon-lockClose_icon"
            : ( item.encryption === -1
               ? "icon-lockIssue_icon" : "icon-lockOpen_icon")),
              totalFill = item.rssi
                ? appliance.type && appliance.type.substring(0,2) === "1."
                    ? Math.ceil(item.rssi / (maxrssi / totalBar))
                    : Math.ceil((95 - Math.abs(item.rssi)) / (maxrssi / totalBar))
                : 0,
              mappingItem = mapping[item.encryption],
              toolTip = mappingItem.tooltip,
              color = mappingItem.className;
          return (
            <li key={item.ssid}>
              <div className="icon-cont">
                <i className={
                  `icon icon-check-circle ${item.type !== "selected" ? "faded" : ""}`
                } />
              </div>
              <div title={item.ssid}
                   className={`wifi-ssid text ${item.type === "selected" ? "selected" : ""}`}>
                {item.ssid}
              </div>
              <div className="icon-cont">
                {(clazz === "icon-lockIssue_icon"
                  ? <i className={`icon ${clazz} ${color}`} title={toolTip} >
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                  : <i className={`icon ${clazz} ${color}`} title={toolTip} />)}
              </div>
              <div className="wifi-widget">
                <WifiConnected totalBar={totalBar} fill={totalFill} 
                    rssi={appliance.type && appliance.type.substring(0,2) === "1." ? item.rssi : (95 - Math.abs(item.rssi))}/>
              </div>
              {/* <div className="text">
                {item.type === "selected"
                    ? <i className="icon icon-check-circle"></i>
                    : <i className="empty"></i>
                  }
                <span className={item.type === "selected" ? "selected" : ""}
                    title={item.ssid}>{item.ssid}</span>
              </div>
              <div className="wifi-status">
                <div className="wifi-bar">
                  <i className={clazz}></i>
                  <WifiConnected totalBar={totalBar} fill={totalFill} rssi={item.rssi}/>
                </div>
                {aaLink}
              </div> */}
            </li>
          );
        });
    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{this.props.title}</span>
        </Header>
        <Content>
          {/* <div className="row aa_link">
            {aaLink}
          </div> */}
          <div className="consumer-wifi-scan-list">
            <ul>
              {rows}
            </ul>
          </div>
        </Content>
      </WidgetPanel>
    );
  },
  onData: function (data, channel) {
    this.setState({
      channel: channel,
      data: data
    });
  }
}));

const Info = {
  id: "appliance-dashboard:consumerWifiScanList",
  name: "Consumer Wifi Scan List",
  type: Widget,
  description: "Show Consumer Wifi Scan List",
  icon: "",
  tags: "consumer wifi scan list"
};

export default {
  Info,
  Widget
};
