/* global Promise setTimeout */
import ApiClient from "../../components/api-client";
// import Config from "../../config";
import mock from "./mockData"

const {instance: Api, asJson: resAsJson} = ApiClient;
    // AnonApiServer = ApiClient.create({
    //   apiUrl: `http://localhost:7001${Config.apiUrl}`
    // });

export default {
  fetchUserNotificationSettings(deviceId, serial) {
    // Comment For Testing - Backed API is not Ready
    // return Api.get(`/consumerapp/usernotificationsettings?${deviceId ? `deviceId=${deviceId}&` : ""}serialNumber=${serial}`)
    //   .then(resAsJson);

    return new Promise((resolve) => {
      setTimeout(() => {
        const { userNotificationSettings } = mock;
        resolve(userNotificationSettings)
      }, 1000)
    })
  },
};
