/* global location Promise FormData console AuthenticationContext */
import ApiClient from "../../components/api-client";
import Config from "../../config";
import Store from "store2";
import * as msal from "@azure/msal-browser";
import ScAuthService from './ScAuthService';
import SubZeroAuthService from "./SubZeroAuthService";
import util from "../../components/util";
console.log("msal:::", msal);
//Auth Error/Failure Codes
const ERR_CODE_MSAL_INIT_NOT_DONE = 'sz_msal_init_not_done';
const msalInitError = msal.AuthError(ERR_CODE_MSAL_INIT_NOT_DONE,"Initialization failed. Contact your administrator.");

const Storage = Store.namespace(Config.appnamespace);

let authInfo;
let callbackId;
let accessToken;
const getAuthService=()=>{
  if(util.isLoggedInUsingSC()){
    return ScAuthService;
  }
  else{
    return SubZeroAuthService;
  }
}
const AuthService = {
  setAuth(user) {
    // authInfo = user;
    // this.storeAuth(user);
    getAuthService().setAuth(user);
  },

  getAuth() {
    //return authInfo || Storage.get("Auth");
    return getAuthService().getAuth();
  },

  storeAuth(user) {
    //Storage.set("Auth", user);
    getAuthService().storeAuth(user);
  },

  getAuthToken() {
    const auth = this.getAuth();
    return auth ? auth.token : "";
  },

  setServiceCentralUser(user){
    Storage.set('scUser',JSON.stringify(user));
  },

  getServiceCentralUser(){
    const strJson = Storage.get('scUser');
    if(strJson){
      return JSON.parse(strJson);
    }
    return null;
  },

  setMsalEventCallbackId(id){
    // callbackId = id;
    getAuthService().setMsalEventCallbackId(id);
  },
  
  getMsalEventCallbackId(){
    // return callbackId;
    return getAuthService().getMsalEventCallbackId();
  },

  getAuthScopes(){
    // return [`${Config.clientId}/.default`]
    return getAuthService().getAuthScopes();
  },

  getAPIToken(forceRefresh = false, accountInfo) {
    // if(!this.msal){
    //   //Make sure to initialize MSAL by calling AuthService.getAuthContextObject()
    //   throw msalInitError;
    // }
    // const msal = this.msal;
    // //Request token with SilentRequest arg
    // return msal.acquireTokenSilent({
    //   redirectUri:Config.redirectUri,
    //   authority:this.getAuthorityUrl(),
    //   account:accountInfo,
    //   forceRefresh: forceRefresh,
    //   scopes:this.getAuthScopes()
    // });
    return getAuthService().getAPIToken(forceRefresh,accountInfo);
  },
  login() {
    // if(!this.msal){
    //   //Make sure to initialize MSAL by calling AuthService.getAuthContextObject()
    //   throw msalInitError;
    // }
    // return this.msal.loginPopup({
    //   scopes: this.getAuthScopes()
    // });
    return getAuthService().login();
  },
  logout() {
    // if(!this.msal){
    //   //Make sure to initialize MSAL by calling AuthService.getAuthContextObject()
    //   throw msalInitError;
    // }
    // return this.msal.logout();
    return getAuthService().logout();
  },
  getAuthorityUrl(){
    // return Config.authority+'/'+Config.tenant;
    return getAuthService().getAuthorityUrl();
  },
  getAuthContextObject(callback) {
    // if(this.msal)
    //   return this.msal;
    
    // const msalOptions = {
    //   auth:{
    //     clientId: Config.clientId,
    //     authority: this.getAuthorityUrl(),
    //     redirectUri: Config.redirectUri,
    //   },
    //   cache:{
    //     cacheLocation:Config.cacheLocation,
    //     storeAuthStateInCookie: Config.storeAuthStateInCookie
    //   },
    //   callback: callback,
    //   popUp: Config.popUp,
    //   loadFrameTimeout: Config.loadFrameTimeout,
    // }
    // console.log('MSAL Config options:',msalOptions);
    // return this.msal = new msal.PublicClientApplication(msalOptions);
    return getAuthService().getAuthContextObject(callback);
  },
  getAccessToken() {
    // return accessToken;
    return getAuthService().getAccessToken();
  },
  setAccessToken(data) {
    // accessToken = data;
    getAuthService().setAccessToken(data);
  }
};

export default AuthService;