/* global location setTimeout console localStorage  */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import AuthService from "./Service";
import Config from "../../config";
import Service from "../../Service";
import Util from "../../components/util";
import AI from "../../appInsights";
import util from "../../components/util";
import ScAuthService from "./ScAuthService";

export default ReactClass({
  displayName: "MultipleAuthFlowComponent",
  getInitialState() {
    return {
      emailId:'',
    };
  },
  propTypes: {
    application: PropTypes.object
  },
  loginUsingMSAuth(){
    util.setLoggedInUsingSc(false);
    const {application: app} = this.props;
    app.showLoading();
    localStorage.clear();
    AuthService.getAuthContextObject();
    AuthService.login()
    .then((authRes)=>{
      console.log('Login success');
      const jwtToken = Util.parseAccessToken(authRes.accessToken),
          userSessoinId = Util.uniqueSessionId(),
          timeStamp = new Date().getTime();
          Util.setUserSessionId(userSessoinId);
          // sessionStorage.setItem("userSessoinId", userSessoinId);
      AI.trackUserLoginEvent("USER_LOGIN", {email: jwtToken.email, userName: jwtToken.name , 
          groups: JSON.stringify(jwtToken.groups),
          userSessoinId, timeStamp});
      AuthService.getAuthContextObject().setActiveAccount(authRes.account);
      AuthService.setAccessToken(authRes.accessToken);
      // console.log('Active account:',AuthService.getAuthContextObject().getActiveAccount());
      // const apiToken = res.accessToken;
      // this.setAuthInfo(apiToken);
      const token = authRes.accessToken;
      AuthService.setAuth({token});
      Service.recordLogin(token).then(_ => {
        app.fetchCatProjects();
      });
      app.showLoading(false);
      app.route('/appliance-search');
    })
    .catch((e)=>{
      console.log('Login Error:',e);
      app.notifications.error("User sign-in failed. Try refreshing page and try again");
      app.showLoading(false);
    });
  },
  loginUsingServiceCentral(){
    // util.setLoggedInUsingSc(true);
    // https://service.subzero.com/Login/SSOLogin?key={SC_KEY}&returnUrl={returnUrl}
    ScAuthService.login();
  },
  login() {
    if(!this.state.emailId){
      this.setState({
        ...this.state,
        errorMessage:'Enter your Email ID/User ID',
      })
      return;
    }
    this.setState({
      ...this.state,
      errorMessage:null,
    })
    if(this.state.emailId.endsWith('@subzero.com' )
        || this.state.emailId.endsWith('@bruviti.com')){
      this.loginUsingMSAuth();
    }
    else{
      //navigate to the service central login
      this.loginUsingServiceCentral();
    }
  },

  handleEmailIdOnChange(event){
    this.setState({...this.state, emailId:event.target.value})
  },
  handleKeyPress(event){
    if(event.key === 'Enter'){
      this.login();
    }
  },
  render() {
    const imagePath = `${Config.baseDir}images/microsoft_logo.png`,
        {application: app} = this.props,
        loadingState = app.getLoadingState();
    return (
      <div className="from-container auth">
        <div className="login-from">
          <h2 className="header">Sign In</h2>
          <p>SubZero Connected Appliance Portal requires signing in using your Email ID/User ID</p>
          <p></p>
          <input type="email"
              onKeyPress={this.handleKeyPress}
              value={this.state.emailId}
              onChange={this.handleEmailIdOnChange}
              placeholder="Email ID/User ID" 
              disabled={false}/>
          {this.state.errorMessage && ( <p className="error">{this.state.errorMessage}</p>)}
          <button id="next" onClick={this.login} className="primary span_6 right inline" disabled={loadingState}>
            {/* <img src={imagePath} /> */}
            <span>Next</span>
          </button>
          <h4 id="WelcomeMessage"></h4>
        </div>
      </div>
    );
  }
});
