import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";

import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import AnswerAdviser from "../../../modules/answer-adviser";
import Util from "../../../components/util";
import DashboardService from "../../../modules/dashboard/Service";
import Config from "../../../config";

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "ErrorCodes",
  getInitialState() {
    return {
      data: {
        appliance: {},
        errorCodes: []
      }
    };
  },
  componentDidUpdate() {
    this.props.layout.layout();
  },
  prioritizationOfErrorCodes() {
    const { type: devType = "" } = this.state.data.appliance,
      activeRegx = /^1\.[1|2|5|7]\..*$/gm,
      priorityRegx = /^1\.[3|4|8]\..*$/gm,
      typeRegx = /^1\.[6]\..*$/gm;
    let sortOn = "active",
        errorCodess = this.state.data.errorCodes || [];
    if (activeRegx.test(devType)) {
      sortOn = "active";
      errorCodess && errorCodess.sort((a, b) => {
        return b[sortOn] - a[sortOn];
      })
    } else if (priorityRegx.test(devType)) {
      sortOn = "priorityInt";
      let posArr = errorCodess && errorCodess.filter(a => a.errorCode[sortOn] > 0).sort((a,b) => (a.errorCode[sortOn]-b.errorCode[sortOn])),
          negArr = errorCodess && errorCodess.filter(a => a.errorCode[sortOn] <= 0).sort((a,b) => (b.errorCode[sortOn]-a.errorCode[sortOn]));
      errorCodess = [...posArr, ...negArr];
    } else if (typeRegx.test(devType)) {
      sortOn = "type";
      errorCodess && errorCodess.sort((a, b) => {
        if(a.errorCode[sortOn] < b.errorCode[sortOn]) {
          return -1;
        }
        if(a.errorCode[sortOn] > b.errorCode[sortOn]) {
          return 1;
        }
        return 0;
      })
    }
    return {
      errorCodess,
      sortOn
    }
  },
  priorityLabel(priorityInt) {
    if(priorityInt == 1)
      return "High";
    else if(priorityInt == 2)
      return "Medium";
    else
      return "Low";
  },
  renderComponent() {
    let {channel, data: {appliance, errorCodes = []}} = this.state;
    const {type: devType = ""} = appliance;
    const {errorCodess, sortOn} = this.prioritizationOfErrorCodes(),
      projectMapping = {
        "1.6": 'Water Products'
      };
    let errorCodesLabel = "",
        classs = "";
    let errorCodesArr = errorCodess && errorCodess.map((error, i) => {
      const {active, epoch, errorCode, time, errorCodeString} = error,
        {device, failure, location, type, priorityInt} = errorCode,
        code = errorCodeString,
        reMajorDevType = /^\d+\.\d+/,
        devMajorType = devType && devType.match(reMajorDevType) && devType.match(reMajorDevType)[0],
        link = AnswerAdviser.generateErrorCodeLink(appliance, errorCode, projectMapping[devMajorType]);
      let desc;
      switch(devMajorType) {
        case "1.6":
          desc = [
            location || "Unknown location",
            failure || "Unknown failure",
            type || "Unknown type"
          ].join(" | ");
          break;
        // Change for SZ bug 1247 ====
        // Ryan asked to hide the device param because that info from the error code is
        // not available with SZ currently, in future when that info is available
        // they will ask us to display it on the UI
        case "1.3":
        case "1.4":
        case "1.8":
          desc = [
            location || "Unknown location",
            failure || "Unknown failure"
          ].join(" | ");
          break;
        // ========== //
        default:
          desc = [
            device || "Unknown device",
            location || "Unknown location",
            failure || "Unknown failure"
          ].join(" | ");
          break;
      }
      switch (sortOn) {
        case "active":
          errorCodesLabel = active ? "active" : "inactive";
          classs = active ? "active" : "inactive";
          break;
        case "priorityInt":
          errorCodesLabel = this.priorityLabel(priorityInt);
          classs = priorityInt == 1 ? "high" : (priorityInt == 2 ? "medium" : "low");
          break;
        case "type":
          errorCodesLabel = type == "Fault" ? "Fault" : "Warning";
          classs = type == "Fault" ? "fault" : "warning";
          break;
      }

      return {...error, errorCodesLabel, classs,desc, link}
    });
    DashboardService.setExportpdfData("errors", errorCodesArr);
    const {onAction} = this.props;
    onAction && onAction("errorsCode", errorCodesArr);
      let items = errorCodesArr && errorCodesArr.map((error, i) => {
        const errorDescription = error.errorCode.device.concat(" ", error.errorCode.location, " ", error.errorCode.failure),
            aaUrl = `${Config.answerAdvisorLink}?keyword=${errorDescription}&f:@productfamily=[${appliance.productFamily}]`;
        // const diffs = Math.abs((new Date())- (new Date(error.timestamp)));
        // const days = Math.ceil(diffs/(1000*60*60*24.0));
      return (
        <li key={"error_" + i} className="error-code ">
          <div className="desc">{error.desc}</div>
          <span className="label">Error Code</span>
          <span className="pill code">{error.errorCodeString || "N/A"}</span>
          {(error.active === null
            ? null
          : <span className={"pill status " + error.classs}>{error.errorCodesLabel}</span>)}
          {/* <span className="pill days">{days} {days == 1 ?' Day':' Days'}</span> */}
          <span className="pill timestamp">{Util.formatDate(new Date(error.timestamp),'YYYY-MM-DD HH:mm:ss')}</span>
          <div className="link" onClick={() => Util.trackAAEvent(aaUrl)}>
            <a href={`${Config.answerAdvisorLink}?keyword=${encodeURIComponent(errorDescription)}&f:@productfamily=[${appliance.productFamily}]`} target="_blank">Learn more about this error code</a>
          </div>
        </li>
      );
    });
    

    if(!items.length) {
      items = <li className="error-code">No error codes for this appliance</li>;
    }

    return (
      <ul className="h-list error-codes">
        {items}
      </ul>
    );
  },

  render() {
    const {Header, Content} = WidgetPanel,
        panelContent = this.renderComponent();
    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{this.props.title}</span>
        </Header>
        <Content>
          {panelContent}
        </Content>
      </WidgetPanel>
    );
  },

  onData(data, channel) {
    this.setState({
      channel: channel,
      data: data
    });
  }
}));

const Info = {
  id: "appliance-dashboard:error-codes",
  name: "Appliance Error Codes",
  type: Widget,
  description: "Shows current appliance's error codes",
  icon: "",
  tags: "appliance error-codes"
};

export default {
  Info,
  Widget
};
