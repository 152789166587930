/* global EventSource */
import Util from "./util";

/**
 * Creates a EventSource that emits events to specified channel
 * @param {Object} channel The channel e.g. SSEChannel instance
 * @return {EventSource} The configured EventSource object
 */
const createEventSource = channel => {
  const config = channel.config, source = new EventSource(config.url);

  ["open", "error", "close"].forEach(type => {
    source.addEventListener(type, e => {
      console.log("type", type);
      channel.eventHelper.emit(type, e);
    });
  });

  source.addEventListener("message", function(e) {
    const strEvent = e.data;
    let event;
    try {
      event = JSON.parse(strEvent);
      console.log("event", e);
      channel.eventHelper.emit(event.type || "message", event);
    }catch(error) {
      channel.eventHelper.emit("error", strEvent);
    }
  });
  return source;
};

/**
 * A channel to SSE (Server Sent Events)
 * {
 *   url: "http://server.com/sse"
 * }
 * @param {Object} config The configuration for this sse channel
 */
function SSEChannel(config) {
  // this.eventHelper = EventHelper();
  this.eventHelper = Util.createEventEmitter();
  this.config = config;
  this.state = {};
  // console.log("config", config);
}
SSEChannel.prototype = {
  constructor: SSEChannel,

  open() {
    this.on("error", () => {
      this.state.open = false;
    });
    this.on("open", () => {
      this.state.open = true;
    });
    this.on("close", () => {
      this.state.open = false;
    });
    this.source = createEventSource(this);
  },

  emit(type, data) {
    console.log("emit", data);
    this.eventHelper.emit(type, data);
  },

  isOpen() {
    return this.state.open;
  },

  on(eventType, listener) {
    return this.eventHelper.on(eventType, listener);
  },

  readyState() {
    return this.source ? this.source.readyState : null;
  },

  close() {
    if(this.source) {
      this.source.close();
    }
  }
};


export default SSEChannel;
