import React, {Component} from 'react';
import PropTypes from "prop-types";
import ReactClass from "create-react-class";

const Icon = ReactClass({
  displayName: "Icon",
  propTypes: {
    icon: PropTypes.string.isRequired
  },
  render() {
    const className = this.props.icon;
    return (
      <i
        className={className}
        style={this.props.style}
        align="right" />
    );
  }
});

const SortIconAsc = ReactClass({
  displayName: "SortIconAsc",
  render() {
    return (
      <Icon icon="icon icon-chevron-up" style={this.props.style} />
    );
  }
});

const SortIconDesc = ReactClass({
  displayName: "SortIconDesc",
  render() {
    return (
      <Icon icon="icon icon-chevron-down" style={this.props.style} />
    );
  }
});

export {
  Icon,
  SortIconAsc,
  SortIconDesc

}
