/* console Promise */
import View from "./View";
import ApplianceSearchView from "./ApplianceSearch";
// import Service from "./Service";


const getParameters = paramStr => {
  return paramStr.split("&").reduce((parameters, param) => {
    const p = param.split("=");
    parameters[p[0]] = p[1];
    return parameters;
  }, {});
};

export default [
  {
    path: "/appliance/:selectedDeviceId?/dashboard/:selectedSerial?/:parameters?",
    controller: context => {
      const {params} = context.route, parameters = getParameters(params.parameters || "");
      return {
        component: View,
        context: Object.assign({}, context, parameters)
      };
    }
  },
  {
    path: "/project/:projectName/appliance/:selectedDeviceId?/dashboard/:selectedSerial?/:parameters?",
    controller: context => {
      const {params} = context.route, parameters = getParameters(params.parameters || "");
      return {
        component: View,
        context: Object.assign({}, context, parameters)
      };
    }
  },
  // {
  //   path: "/dashboard",
  //   controller: context => {
  //     // const {route: {params}} = context;
  //     return {
  //       component: View,
  //       context
  //     };
  //     // return Service.fetchSomething().then(data => {
  //     //   const ctx = Object.assign({data: data}, context);
  //     //   return {
  //     //     component: View,
  //     //     context: ctx
  //     //   };
  //     // });
  //   }
  // },
  {
    path: "/appliance-search",
    controller: context => {
      return {
        component: ApplianceSearchView,
        context
      };
    }
  }
];
