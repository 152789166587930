/* global console */
import {Filters, Transformers, ChannelFactories} from "../common";
import {getWidgets} from "../widgets";
import {AuthService} from "../../../auth";

const TEST_ERROR_CODES = [
  {
    "epoch": 379011677,
    "errorCode": {
      "deviceCode": "1",
      "device": null,
      "locationCode": "A",
      "location": "Air Filter",
      "failureCode": "1",
      "failure": "Short/Closed",
      "priority": 1
    },
    "active": true
  },
  {
    "epoch": 379029982,
    "errorCode": {
      "deviceCode": "6",
      "device": null,
      "locationCode": "G",
      "location": null,
      "failureCode": "6",
      "failure": "Miswire overheat",
      "priority": 2
    },
    "active": true
  },
  {
    "epoch": 379033643,
    "errorCode": {
      "deviceCode": "7",
      "device": null,
      "locationCode": "H",
      "location": "High Voltage Sys",
      "failureCode": "7",
      "failure": "Bad Ohms",
      "priority": 3
    },
    "active": true
  },
  {
    "epoch": 379008016,
    "errorCode": {
      "deviceCode": "0",
      "device": null,
      "locationCode": "x",
      "location": null,
      "failureCode": "0",
      "failure": "Open",
      "priority": 4
    },
    "active": true
  }],
    isPromise = type => {
      return typeof type.then === "function";
    },
    createChannelConfig = (deviceInfo, configWidgets, perApplianceType, token, networkConnInfo) => {
      return {
        "name": `${deviceInfo.name} ${deviceInfo.model}`,
        "style": {
          "cssClass": "refrigerator-dashboard"
        },
        "datalayer": {
          "name": "Refrigerator Data",
          "channels": [
            // Diagnostic channel common to all dashboards
            ChannelFactories.userInfo(deviceInfo, token),
            ChannelFactories.diagnostics(deviceInfo, token),
            ChannelFactories.parameters(deviceInfo, token),
            ChannelFactories.timeZone(deviceInfo, token),
            ChannelFactories.errorCodes(deviceInfo, token),
            ChannelFactories.applianceInfo(deviceInfo, token),
            ChannelFactories.currentState(),
            ChannelFactories.networkConnInfo(deviceInfo, networkConnInfo),
            ChannelFactories.mobNotificationInfo(deviceInfo, token),
            ChannelFactories.provisioningReport(deviceInfo,token),
            {
              "name": "/appliance/pollingFrequency",
              "type": "extended",
              "config": {
                "from": "/appliance/properties",
                "transform": data => {
                  return {frequency: data.active_polling_frequency};
                }
              }
            },
            {
              "name": "/appliance/refrigerator",
              "type": "extended",
              "config": {
                "from": "/appliance/properties",
                "transform": data => {
                  const filtered = Filters.keyPattern(data, /^fridge\.|^general\./);
                  filtered.appliance = deviceInfo;
                  // filtered["fridge.fault_table"] = TEST_ERROR_CODES;
                  return filtered;
                }
              }
            },
            /*
            {
              "name": "/appliance/refrigerator/appliance-data",
              "type": "extended",
              "config": {
                "from": "/appliance/refrigerator",
                "transform": data => {
                  const iceMaker = data["fridge.ice_maker_on"],
                      maxIce = data["fridge.max_ice_on"];
                  return {
                    airPurification: [
                      {label: "Mode",
                        value: data["fridge.pure_air_on"]
                        ? "ON" : "OFF"}
                    ],
                    iceMaker: [
                      {label: "Mode",
                        value: (!iceMaker && !maxIce || !iceMaker && maxIce
                        ? "OFF"
                        : iceMaker && maxIce
                          ? "Max Ice"
                          : "Normal")},
                      {label: "Night Ice",
                        value: +data["fridge.mode.night_icemaking_enabled"]
                        ? "ON" : "OFF"}
                    ],
                    refrigeratorZone: [
                      {label: "Setpoint",
                        value: data["fridge.refrigerator_setpoint"]
                          ? +data["fridge.refrigerator_setpoint"]
                          : "N/A"
                      }
                    ],
                    freezerZone: [
                      {label: "Setpoint",
                        value: data["fridge.freezer_setpoint"]
                          ? +data["fridge.freezer_setpoint"]
                          : "N/A"
                      }
                    ]
                  };
                }
              }
            },
            {
              "name": "/appliance/refrigerator/water-filter",
              "type": "extended",
              "config": {
                "from": "/appliance/refrigerator",
                "transform": data => {
                  return {
                    remainingGallons: data["fridge.water_filter_gallons_remaining"],
                    filterLifeRemaining: data["fridge.water_filter_life_remaining_percent"]
                  };
                }
              }
            }
            */
          ]
        },
        "widgets": getWidgets(deviceInfo, configWidgets, perApplianceType)
      };
    };


export default (deviceInfo, configWidgets, perApplianceType, networkConnInfo) => {
  const promiseToken = AuthService.getAPIToken();
  return promiseToken.then(authRes => {
    return createChannelConfig(deviceInfo, configWidgets, perApplianceType, authRes.accessToken, networkConnInfo);
  });
};
