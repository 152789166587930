/* global console */
import {Filters, Transformers, ChannelFactories} from "../dashboard/channel-config/common";
import {AuthService} from "../auth";
import Config from "../../config";
import util from "../../components/util";

// const MOCK_URL = "https://testszdashboard.com" + Config.apiUrl;

const isPromise = type => {
      return typeof type.then === "function";
    },
    createChannelConfig = (appliance, token) => {
      const SERVER_URL = Config.apiServerUrl + Config.apiUrl,
          consumerAppParams = appliance
              ? `serialNumber=${appliance.serial}&deviceId=${appliance.deviceId}`
              : "";
      // consumerAppParams = `serialNumber=1392243R`;
      let scHeaders = {};
      if(util.isLoggedInUsingSC()){
         scHeaders = util.getSCAuthHeaders();
      }
      return {
        "name": "Consumer App Logs",

        "style": {
          "cssClass": "consumer-app-logs"
        },

        "datalayer": {
          "name": "Consumer App Logs",
          "channels": [
            {
              "name": "/consumer/appLogs",
              "type": "http",
              "config": {
                "url": `${SERVER_URL}/consumerapp/logs?${consumerAppParams}`,
                "method": "GET",
                "responseType": "json",
                "headers": {
                  "Authorization": `Bearer ${token}`,
                  "Accept": "application/json",
                  ...scHeaders
                }
              }
            },
            {
              "name": "/consumer/app-info",
              "type": "extended",
              "config": {
                "from": "/consumer/appLogAtIndex",
                "transform": data => {
                  const appInfo = Object.assign({}, data.appInfo, {timestamp: data.timestamp}, {appLogType: data.appLogType});
                  return {
                    appliance,
                    appInfo
                  };
                }
              }
            },
            {
              "name": "/consumer/diagnostics/connectivity",
              "type": "extended",
              "config": {
                "from": "/consumer/appLogAtIndex",
                "transform": data => {
                  if(!data.diagnostics) {
                    data = {diagnostics: {}};
                  }
                  let connectivity = Object.assign({}, {
                        appliance,
                        latest: {
                          timestamp: data.timestamp,
                          records: data.diagnostics
                        }
                      }),
                      connectivityData = Transformers.connectivity(connectivity);
                  return connectivityData;
                }
              }
            },
            {
              "name": "/consumer/wifi-scan-list",
              "type": "extended",
              "config": {
                "from": "/consumer/appLogAtIndex",
                "transform": data => {
                  return {
                    appliance,
                    accessPoints: data.accessPoints
                  };
                }
              }
            },
            {
              "name": "/consumer/error-codes",
              "type": "extended",
              "config": {
                "from": "/consumer/appLogAtIndex",
                "transform": data => {
                  return {
                    appliance,
                    errorCodes: data.faults
                  };
                }
              }
            }
          ]
        },
        "widgets": [
          {
            "id": "consumerAppInfo",
            "type": "appliance-dashboard:consumerAppInfo",
            "title": "App Info",
            "cols": 4,
            "channel": "/consumer/app-info"
          },
          {
            "id": "consumerAppConnectivity",
            "type": "appliance-dashboard:consumerAppConnectivity",
            "title": "Connected Module",
            "cols": 4,
            "channel": "/consumer/diagnostics/connectivity",
            "panelClass": "consumer-app-connectivity"
          },
          {
            "id": "error-codes",
            "type": "appliance-dashboard:error-codes",
            "title": "Error Codes",
            "cols": 4,
            "channel": "/consumer/error-codes"
          },
          {
            "id": "consumerWifiScanList",
            "type": "appliance-dashboard:consumerWifiScanList",
            "title": "App Wifi Scan List",
            "cols": 4,
            "channel": "/consumer/wifi-scan-list",
            "panelClass": "consumer-wifi-scan"
          }
        ]
      };
    };

export default (data) => {
  const promiseToken = AuthService.getAPIToken();
  return promiseToken.then(authRes => {
    return createChannelConfig(data, authRes.accessToken);
  });
};

