import Storage from "store2";
// import {getApiToken} from "../../components/api-client";
import ApiClient from "../../components/api-client";

const initialization = {
  getData: () => {
    let queryString = window.location.search, oldToken = ApiClient.getApiToken();
    if(!queryString) {
      return {
        token: oldToken
      };
    }
    const initData = queryString.substring(1)
        .split("&")
        .reduce((params, part) => {
          const pair = part.split("="),
              key = pair[0],
              value = pair[1];
          if(key) {
            let old = params[key];
            if(old) old.push(value);
            else params[key] = [value];
          }
          return params;
        }, {});

    if(!initData.token) {
      initData.token = oldToken;
    }
    return initData;
  }
};

export default initialization;
