import * as _ from "lodash";
import Util from "../../../components/util";
// Polyfill IE
if (!Array.from) {
  Array.from = (function () {
    const toStr = Object.prototype.toString;
    const isCallable = function (fn) {
      return typeof fn === "function" || toStr.call(fn) === "[object Function]";
    };
    const toInteger = function (value) {
      const number = Number(value);
      if (isNaN(number)) {
        return 0;
      }
      if (number === 0 || !isFinite(number)) {
        return number;
      }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    const maxSafeInteger = Math.pow(2, 53) - 1;
    const toLength = function (value) {
      const len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };

    // The length property of the from method is 1.
    return function from(arrayLike /* , mapFn, thisArg */) {
      // 1. Let C be the this value.
      const C = this;

      // 2. Let items be ToObject(arrayLike).
      const items = Object(arrayLike);

      // 3. ReturnIfAbrupt(items).
      if (arrayLike == null) {
        throw new TypeError('Array.from requires an array-like object - not null or undefined');
      }

      // 4. If mapfn is undefined, then let mapping be false.
      const mapFn = arguments.length > 1 ? arguments[1] : void undefined;
      let T;
      if (typeof mapFn !== 'undefined') {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError('Array.from: when provided, the second argument must be a function');
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          T = arguments[2];
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      const len = toLength(items.length);

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method 
      // of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      const A = isCallable(C) ? Object(new C(len)) : new Array(len);

      // 16. Let k be 0.
      let k = 0;
      // 17. Repeat, while k < len… (also steps a - h)
      let kValue;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len;
      // 20. Return A.
      return A;
    };
  }());
}
//ref https://github.com/abdennour/react-csv#readme

const isJsons = ((array) => Array.isArray(array) && array.every(
  row => (typeof row === "object" && !(row instanceof Array))
));

const isArrays = ((array) => Array.isArray(array) && array.every(
  row => Array.isArray(row)
));

const jsonsHeaders = ((array) => Array.from(
  array.map(json => Object.keys(json))
    .reduce((a, b) => new Set([...a, ...b]), [])
));

const jsons2arrays = (jsons, headers, flag, csvHeaderDisplayName) => {
  headers = headers || jsonsHeaders(jsons);

  // allow headers to have custom labels, defaulting to having the header data key be the label
  let headerLabels = headers;
  let headerKeys = headers;
  if (isJsons(headers)) {
    headerLabels = headers.map((header) => {
      if(header.label) {
        return header.label;
      }
    });
    headerKeys = headers.map((header) => {
      if(header.key) {
        return header.key;
      }
    });
  }

  //This condition is added for moving timestamp column in CSV to the first position only for graph export.
  // below flag variable is used to identify that this is graph CSV export.
  if(flag == 'graph') {
    var firstLable = "Timestamp";
    var firstKey = "ts";
    headerLabels.sort(function(x,y){ return x == firstLable ? -1 : y == firstLable ? 1 : 0; });
    headerKeys.sort(function(x,y){ return x == firstKey ? -1 : y == firstKey ? 1 : 0; });
  }
  const data = jsons.map((object) => {
    return headerKeys.map((header) => {
      return (header in object) ? header === 'ts' ? Util.formatDate(object[header], "YYYY-MM-DD HH:mm:ss", false) : object[header] : '';
    })
  });
  //To display selected graph, display name as column header in CSV.
  if(flag === 'graph'){
    let tempCSVHeaderDisplayName = ['Timestamp'];
    headerLabels.map((value) => {
      return _.find(csvHeaderDisplayName, (item) =>{
        if(value === item.name) {
          if(!tempCSVHeaderDisplayName.includes(item.displayName) && item.displayName !== "Offline State") {
            tempCSVHeaderDisplayName.push(item.displayName);
          }
        }
      })
    });
    return [tempCSVHeaderDisplayName, ...data];
  }
  return [headerLabels, ...data];
};

const elementOrEmpty = (element) => element || element === 0 ? element : '';

const joiner = ((data, separator = ',') =>
  data.map((row, index) => row.map((element) => "\"" + elementOrEmpty(element) + "\"").join(separator)).join(`\n`)
);

const arrays2csv = ((data, headers, separator) =>
  joiner(headers ? [headers, ...data] : data, separator)
);

const jsons2csv = ((data, headers, separator, flag, csvHeaderDisplayName) =>
  joiner(jsons2arrays(data, headers, flag, csvHeaderDisplayName), separator)
);

const string2csv = ((data, headers, separator) =>
  (headers) ? `${headers.join(separator)}\n${data}` : data
);

const toCSV = (data, headers, separator, flag, csvHeaderDisplayName) => {
  if (isJsons(data)) return jsons2csv(data, headers, separator, flag, csvHeaderDisplayName);
  if (isArrays(data)) return arrays2csv(data, headers, separator);
  if (typeof data === 'string') return string2csv(data, headers, separator);
  throw new TypeError(`Data should be a "String", "Array of arrays" OR "Array of objects" `);
};

const buildURI = ((data, uFEFF, headers, separator, flag = "", csvHeaderDisplayName = []) => {
  const csv = toCSV(data, headers, separator, flag, csvHeaderDisplayName),
      blob = new Blob([uFEFF ? "\uFEFF" : "", csv], {type: "text/csv"}),
      dataURI = `data:${(typeof safari !== "undefined") ? "application" : "text"}/csv;charset=utf-8,${uFEFF ? "\uFEFF" : ""}${encodeURI(csv)}`,
      URL = window.URL || window.webkitURL;

  return (typeof URL.createObjectURL === "undefined")
    ? dataURI
    : URL.createObjectURL(blob);
});

export {
  buildURI,
  toCSV,
  string2csv,
  jsons2csv,
  arrays2csv,
  joiner,
  elementOrEmpty,
  jsons2arrays,
  jsonsHeaders,
  isArrays,
  isJsons
};
