import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import Util from "../../../components/util";

const SortableTableRow = ReactClass({
  displayName: "SortableTableRow",
  onRowClick(e) {
    if (this.props.onRowClick &&
      typeof (this.props.onRowClick) === "function") {
      this.props.onRowClick(this.props.data, e);
    }
    e.preventDefault();
    e.stopPropagation();
  },
  onColumnClick(e, item) {
    if (item.onColumnClick &&
      typeof (item.onColumnClick) === "function") {
      item.onColumnClick(this.props.data, e);
    }
    e.preventDefault();
    e.stopPropagation();
  },
  render() {
    let rowClass = this.props.rowClass || false,
        tds = this.props.columns.map(function (item, index) {
          let value = this.props.data[item.key];
          if (item.render) {
            value = item.render(this.props.data);
          }
          if(item.key == "Last Update") {
            value = Util.formatDate(new Date(this.props.data["lastUpdateEpoc"]), "HH:mm:ss.ms a MM/dd/yyyy z", false)
          }

          return (
            <td
              key={index}
              style={item.dataStyle}
              {...(item.dataProps || {})}
              onClick={(item.onColumnClick && typeof (item.onColumnClick) === "function") ? (e) => this.onColumnClick(e, item) : undefined}
              >
              {value || "--"}
            </td>
          );
        }.bind(this));
    return (
      <tr className={rowClass ? rowClass : undefined}
        onClick={this.onRowClick} title={this.props.data.Tooltip || ""}>
        {tds}
      </tr>
    );
  }
});

export default ReactClass({
  displayName: "SortableTableBody",
  propTypes: {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired
  },
  render() {
    const {row} = this.props,
        {rowClassSelector, rowClassMap} = row || "";
    let bodies = this.props.data.map(((item, index) => {
    const rowClass =rowClassSelector.reduce((className, ele) => {
        return `${className} ${(row && ele && rowClassMap)
        ? rowClassMap[item[ele]] || rowClassMap.default
        : (item[ele] && item[ele].toString().toLowerCase()) || ""}`
      }, "")
      return (
        <SortableTableRow
          key={index}
          data={item}
          rowClass={rowClass}
          onRowClick={(row && row.onClick) ? row.onClick : undefined}
          columns={this.props.columns} />
      );
    }));

    return (
      <tbody>
        {bodies}
      </tbody>
    );
  }
});
