import React from "react";
import ReactClass from "create-react-class";

import ApplianceInfo from "../common/ApplianceInfoComponent";

import {Service as ApplianceService, ApplianceLeftNav} from "../appliance";
import GraphService from "./Service";
import ResourcesComponent from "../common/Resources";
import TimeRangeSelector from "./TimeRangeSelector";

import PropertySelector from "./PropertySelectorSelectData";

export default ReactClass({
  displayName: "GraphFilters",
  getInitialState() {
    return {
      fieldSelection: [],
      appliances: [],
      selectedAppliance: {},
      mode: "Connected",
      busy: false,
      timeSelection: {},
      timeRangeIndex: 2,
      customTimeRange: {},
    };
  },
  componentWillMount() {
    const {route: {params: {deviceId, selectedSerial}}} = this.props,
      appliances = ApplianceService.getCurrentApplianceInfos();
    if(appliances && appliances.length > 0) {

      const selectAppIdx = deviceId
          ? appliances.map(a => a.deviceId).indexOf(deviceId)
          : appliances.map(a => a.serial).indexOf(selectedSerial),
        selectedAppliance = appliances[selectAppIdx],
        { connectedStatus } = selectedAppliance,
        mode = ["ONLINE"].indexOf(connectedStatus) > -1 ? "Connected" : "Non-Connected";

      this.setState({
        fetchingAppliances: false,
        appliances,
        selectedAppliance,
        // mode
      });
    } else {
      if(deviceId) {
        this.setState({
          busy: true
        });
        ApplianceService.getApplianceInfoFromDeviceId(deviceId).then(infos => {
          if(infos.length > 0) {
            const currentAppliance = infos[0],
              {connectedStatus} = currentAppliance,
              mode = ["ONLINE"].indexOf(connectedStatus) > -1 ? "Connected" : "Non-Connected";

            this.setState({
              appliances: infos,
              selectedAppliance: infos[0],
              // mode,
              busy: false
            });
            ApplianceService.setCurrentApplianceInfos(infos);
          } else {
            this.routeToApplianceSearch();
          }
        }).catch(err => {
          this.routeToApplianceSearch();
          throw err;
        });
      } else {
        this.routeToApplianceSearch();
      }
    }
  },
  routeToApplianceSearch() {
    const {application: app} = this.props;
    app.route("/appliance-search");
  },
  selectAppliance(deviceId, serial) {
    const {application: app} = this.props,
        projectName = ApplianceService.getSelectedCatProjectName();

    if(deviceId)
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/${deviceId}/dashboard/`);
    else
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/dashboard/${serial}/`);
  },

  getCurrentAppliance() {
    const {appliances} = this.state,
        {route: {params: {deviceId, selectedSerial}}} = this.props,
        selectAppIdx = deviceId
            ? appliances.map(a => a.deviceId).indexOf(deviceId)
            : appliances.map(a => a.serial).indexOf(selectedSerial);

    return appliances[selectAppIdx];
  },
  renderApplianceList() {
    const {route: {params: {deviceId, selectedSerial}}} = this.props;
    return <ApplianceLeftNav appliances={this.state.appliances}
      application={this.props.application}
      selectedDeviceId={deviceId}
      selectedSerial={selectedSerial}
      onApplianceSelected={this.selectAppliance}
      fetchingAppliances={this.state.fetchingAppliances} />;
  },

  handleTimerangeSelectionChange(timeSelection, timeRangeIndex, range) {
    this.setState({
      timeSelection,
      timeRangeIndex: timeRangeIndex
    });
    if(timeRangeIndex === "custom" && range) {
      this.setState({
        customTimeRange: range
      });
    }
  },

  handlePropertySelectionChange(selection) {
    this.setState({
      fieldSelection: selection
    })
  },

  back() {
    history.back();
  },

  onSelectGraph() {
    const {props: {route: {params: {deviceId}}, application: app}, state: {mode, fieldSelection}} = this;
    GraphService.setFilterSelection(fieldSelection);
    GraphService.setTimeRangeFilterSelection(this.state.timeSelection, this.state.timeRangeIndex, this.state.customTimeRange);
    app.route(`/appliance/${deviceId}/${mode}/graph`);
  },

  render() {
    const {fieldSelection, selectedAppliance, busy, mode} = this.state,
        {type: applianceType, connectedStatus} = selectedAppliance,
        applianceInfo = selectedAppliance && !busy
        ? ([
            <ResourcesComponent key={"resource-1"} currentAppliance={selectedAppliance}/>,
              <ApplianceInfo key={"appliance-info-1"} application={this.props.application}
                  appliance={selectedAppliance}></ApplianceInfo>
          ])
        : (<div className="loading">
            <i className={"icon icon-loader"} />
          </div>);

    return (<div className="view layout view-graph-filters">
      {this.renderApplianceList()}
      <div className="main-content">
        {applianceInfo}
        <div className="data-filter">
          <PropertySelector
            header={(
              <header className="content-header">
                {/* <div id="divReturn" className="return-section" onClick={this.back}>
                  <i className="icon icon-chevron-left" />
                  <span>Return</span>
                </div> */}
                <p id="pTitleGraphFilter" className="title">Select Data</p>
              </header>
            )}
            footer={(
              <footer className="row">
                <div>
                  <button id="btnGraph" className="primary inline"
                    disabled={(fieldSelection && fieldSelection.length === 0)}
                    onClick={this.onSelectGraph}>Graph</button>
                  <TimeRangeSelector
                    application={this.props.application}
                    onSelectionChange={this.handleTimerangeSelectionChange}
                    timeRangeIndex={this.state.timeRangeIndex}
                  />
                </div>
              </footer>
            )}
            // disabled={!(data && data.records)} // Not need
            type={applianceType}
            // connected={connected} // Not Need
            mode={mode} // connected
            showProperties={[]} // hard code empty Array
            fieldSelection={fieldSelection}
            onSelectionChange={this.handlePropertySelectionChange}
          />
        </div>
      </div>
    </div>)
  }
});
