/* global location setTimeout console localStorage  */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import AuthService from "./Service";
import Config from "../../config";
import Service from "../../Service";
import Util from "../../components/util";
import AI from "../../appInsights";

export default ReactClass({
  displayName: "AuthComponent",
  propTypes: {
    application: PropTypes.object
  },
  login() {
    const {application: app} = this.props;
    app.showLoading();
    localStorage.clear();
    AuthService.login()
    .then((authRes)=>{
      console.log('Login success');
      const jwtToken = Util.parseAccessToken(authRes.accessToken),
          userSessoinId = Util.uniqueSessionId(),
          timeStamp = new Date().getTime();
          Util.setUserSessionId(userSessoinId);
          //sessionStorage.setItem("userSessoinId", userSessoinId);
      AI.trackUserLoginEvent("USER_LOGIN", {email: jwtToken.email, userName: jwtToken.name , 
          groups: JSON.stringify(jwtToken.groups),
          userSessoinId, timeStamp});
      AuthService.getAuthContextObject().setActiveAccount(authRes.account);
      AuthService.setAccessToken(authRes.accessToken);
      // console.log('Active account:',AuthService.getAuthContextObject().getActiveAccount());
      // const apiToken = res.accessToken;
      // this.setAuthInfo(apiToken);
      const token = authRes.accessToken;
      AuthService.setAuth({token});
      Service.recordLogin(token).then(_ => {
        app.fetchCatProjects();
      });
      app.showLoading(false);
      app.route('/appliance-search');
    })
    .catch((e)=>{
      console.log('Login Error:',e);
      app.notifications.error("User sign-in failed. Try refreshing page and try again");
      app.showLoading(false);
    });
  },

  render() {
    const imagePath = `${Config.baseDir}images/microsoft_logo.png`,
        {application: app} = this.props,
        loadingState = app.getLoadingState();

    return (
      <div className="from-container auth">
        <div className="login-from">
          <h2 className="header">Sign In</h2>
          <p>Subzero Dashboard requires signing in with Microsoft Account</p>
          <p></p>
          <button id="SignIn" onClick={this.login} className="primary span_6 inline" disabled={loadingState}>
            <img src={imagePath} />
            <span>Sign In Using Microsoft Account</span>
          </button>
          <h4 id="WelcomeMessage"></h4>
        </div>
      </div>
    );
  }
});
