/* global setTimeout clearTimeout console */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";

export default ReactClass({
  displayName: "DialogContainer",
  calculateCenter(elem, inPercentage) {
    const innerWidth = window.innerWidth,
        elemWidth = elem.offsetWidth,
        xPos = (innerWidth - elemWidth) / 2;
    if(!inPercentage) {
      return xPos;
    }else {
      return 100 * xPos / innerWidth;
    }
  },

  getInitialState() {
    return {
      show: this.props.show,
      showDialog: false
    };
  },

  show() {
    const self= this;
    self.setState({
      show: true
    });

    setTimeout(function() {
      self.refs.dialog.style.left = self.calculateCenter(self.refs.dialog) + "px";
      // self.refs.dialog.style.left = calculateCenter(self.refs.dialog, true) + "%";
      self.setState({
        showDialog: true
      });
    }, 50);
  },

  hide(callback) {
    const self = this;
    self.setState({
      showDialog: false
    });

    setTimeout(function() {
      self.setState({
        show: false
      });
      if(callback) {
        callback();
      }
    }, 250);
  },

  dismiss() {
    if (this.props.sticky === false) {
      this.hide();
    }
  },

  componentDidMount() {
    if(this.props.show) {
      this.show();
    }
  },

  render() {
    const containerClasses = this.state.show ? "dialog-container showing" : "dialog-container",
        dialogClasses = this.state.showDialog ? "dialog in" : "dialog";

    return (
      <div className={containerClasses} onClick={this.dismiss}>
        <div ref="dialog" className={dialogClasses}>
          {this.props.children}
        </div>
      </div>
    );
  }
});
