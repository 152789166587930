import {WidgetRegistry} from "ui-dashboard";
import StatusWidget from "./status/Widget";
import OptionsWidget from "./options/Widget";
import AirFilterWidget from "./air-filter/Widget";
import WaterFilterWidget from "./water-filter/Widget";

const addWidget = widgetDef => {
  const {Info: info} = widgetDef, {id} = info;
  WidgetRegistry.add(id, info);
};

addWidget(StatusWidget);
addWidget(OptionsWidget);
addWidget(AirFilterWidget);
addWidget(WaterFilterWidget);
