import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";

export default ReactClass({
  displayName: "ViewLogs",
  propTypes: {
    logs: PropTypes.array.isRequired,
    busy: PropTypes.bool,
    routeTo: PropTypes.func,
    errorMessage: PropTypes.string
  },
  render() {
    const {logs, busy, routeTo, errorMessage} = this.props;
    return (<div className="row">
      <table className="col_12 records-list zebra">
        <thead>
          <tr>
            <th>Log Type</th>
            <th>Date</th>
            <th>Servicer</th>
          </tr>
        </thead>
        <tbody>
          {(logs.length > 0
            ? (logs.map((log, i) => {
              return (<tr key={"log_" + i} onClick={() => {
                if(!log.error) {
                  routeTo(log);
                }
              }}
              className={log.error ? "default-cursor error-text" : ""}
              >
                <td>{log.logType}</td>
                <td>{log.date} UTC</td>
                <td>{log.servicer}</td>
              </tr>);
            }))
            : (busy
              ? (<tr>
                <td className="anim center" colSpan="3">
                  <i className="icon spin icon-loader" />
                </td>
              </tr>)
              : (<tr>
                  <td className="empty center" colSpan="3">
                    {errorMessage && errorMessage.length > 0 ? errorMessage : "NO RECORDS FOUND"}
                  </td>
                </tr>))
          )}
        </tbody>
      </table>
    </div>);
  }
});
