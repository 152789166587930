import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";

import {Icon, SortIconDesc, SortIconAsc} from "./icons";

const SortableTableHeaderItem = ReactClass({
  displayName: "SortableTableHeaderItem",
  propTypes: {
    headerProps: PropTypes.object,
    sortable: PropTypes.bool,
    iconStyle: PropTypes.object,
    iconDesc: PropTypes.node,
    iconAsc: PropTypes.node,
    iconBoth: PropTypes.node
  },
  getDefaultProps() {
    return {
      headerProps: {},
      sortable: true
    };
  },
  onClick(e) {
    if (this.props.sortable)
      this.props.onClick(this.props.sortKey);
  },
  render() {
    let sortIcon;
    if (this.props.sortable && (this.props.sortCol === this.props.sortKey)) {
      if (this.props.sortOrder) {
        if (this.props.iconAsc) {
          sortIcon = <Icon icon={this.props.iconAsc} style={this.props.iconStyle} />;
        } else {
          sortIcon = <SortIconAsc style={this.props.iconStyle} />;
        }
      } else {
        if (this.props.iconDesc) {
          sortIcon = <Icon icon={this.props.iconDesc} style={this.props.iconStyle} />;
        } else {
          sortIcon = <SortIconDesc style={this.props.iconStyle} />;
        }
      }
    }

    return (
      <th
        style={this.props.style}
        onClick={this.onClick}
        {...this.props.headerProps} >
        {this.props.header}
        {sortIcon}
      </th>
    );
  }
});

export default ReactClass({
  displayName: "SortableTableHeader",
  propTypes: {
    columns: PropTypes.array.isRequired,
    onStateChange: PropTypes.func,
    iconStyle: PropTypes.object,
    iconDesc: PropTypes.node,
    iconAsc: PropTypes.node,
    iconBoth: PropTypes.node
  },
  onClick(sortCol) {
    this.props.onStateChange(sortCol);
  },
  render() {
    const headers = this.props.columns.map(((column, index) => {
      return (
        <SortableTableHeaderItem
          sortable={column.sortable}
          key={index}
          index={index}
          header={column.header || column.key}
          sortKey={column.sortCol || column.key}
          sortOrder={this.props.sortOrder}
          sortCol={this.props.sortCol}
          onClick={this.onClick}
          style={column.headerStyle}
          headerProps={column.headerProps}
          iconStyle={this.props.iconStyle}
          iconDesc={this.props.iconDesc}
          iconAsc={this.props.iconAsc}
          iconBoth={this.props.iconBoth} />
      );
    }));

    return (
      <thead>
        <tr>
          {headers}
        </tr>
      </thead>
    );
  }
});
