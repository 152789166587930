/* global console, Image history */
import React from "react";
import ReactClass from "create-react-class";

const WifiConnected = ReactClass({
  displayName: "WifiConnected",
  render() {
    let item = [];
    const { totalBar, fill, rssi } = this.props,
      rssiClass = rssi < 12
        ? "red"
        : ((rssi >= 12 && rssi <= 20) ? "yellow" : "green");
    for (let i = 0; i < totalBar; i++) {
      if (i < fill)
        item.push(<div key={"Wifi_" + i} className={"wifi " + rssiClass}></div>);
      else
        item.push(<div key={"Wifi_" + i} className="wifi"></div>);
    }
    return (
      <div className="status" title={rssi ? rssi : "Unknown"} id="rssi">
        {item}
      </div>
    );
  }
});

export default WifiConnected;