/* global Promise */
import React from "react";
import ReactClass from "create-react-class";
import Draggable from "react-draggable";

import Config from "../../config";
import {Dashboard} from "../../components/dashboard";
import {LoadingIndicator, Tabs, Notifications} from "../../components/widgets";
import configFactory from "./dashboard/config-factory";
import "./dashboard/widgets";
import Service from "./Service";
import Util from "../../components/util";

const modulePath = `${Config.baseDir}modules/enduser-app`,
    isArray = that => {
      return Object.prototype.toString.call(that) === "[object Array]";
    },
    MissingConfig = props => {
      const {appliance} = props;
      return (
        <div className="missing-config">
          <i className="icon icon-alert-triangle"></i>
          <span className="message">
            No configuration was found for {appliance.name} ({appliance.type})
          </span>
        </div>
      );
    },
    cancelable = promise => {
      let canceled = false, complete = false;
      const p = new Promise((res, rej) => {
        promise.then(
          val => (complete = true, canceled ? rej({canceled: true}) : res(val)),
          error => (complete = true, canceled ? rej({canceled: true}) : rej(error))
        );
      });
      return {
        promise: p,
        cancel() {
          canceled = true;
        },
        isDone() {
          return complete;
        }
      };
    };

export default ReactClass({
  displayName: "AppEmulation",
  getInitialState() {
    return {
      toggleActive: false,
      busy: false
    };
  },
  componentWillUnmount() {
    this.request && this.request.cancel();
  },
  render() {
    const {toggleActive, busy} = this.state,
        {appliance} = this.props,
        iconSrc = `${modulePath}/images/eua-toggle${toggleActive ? "-active" : ""}.png`,
        loadingIndicator = busy ? <LoadingIndicator /> : null,
        iconDisabled = appliance.connectedStatus !== "ONLINE" ? "disable" : "";

    return (
      <div className={`eua-wrap ${toggleActive ? "showing-window" : ""} ${iconDisabled}`}>
        <img src={iconSrc} alt="" onClick={() => this.toggleDisplay()}
          title="SZG Owner's App View"/>

        <Draggable handle=".eua-window-container > header" scale={1} bounds=".view">
          <section className="eua-window-container">
            <header>
              SZG Owner's App
              <i className="icon icon-refresh-cw" onClick={this.refreshDashboard} />
              <i className="icon icon-x" onClick={this.toggleDisplay}/>
            </header>
            <div className="eua-window-content">
              {loadingIndicator}
              {this.renderDashboard()}
            </div>
          </section>
        </Draggable>
      </div>
    );
  },
  renderDashboard() {
    const {dashboardConfig, toggleActive, busy} = this.state,
        {appliance} = this.props,
        ctx = this.getDashboardContext();

    if(!toggleActive) {
      return null;
    }

    if(dashboardConfig) {
      const {name, dashboards} = dashboardConfig;
      if(dashboards.length > 1) {
        const tabs = dashboards.map(d => {
          return (
            <Tabs.Tab key={d.name} title={d.name}>
              <Dashboard ref={comp => this.setDatalayer(comp)}
                onWidgetAction={this.onWidgetAction}
                context={ctx}
                config={d}
                onChange={this.onDashboardChange} />
            </Tabs.Tab>
          );
        });
        return (
          <Tabs className="content" activeTab={0} onSelectionChanged={this.onTabChange}>
            {tabs}
          </Tabs>
        );
      }else {
        return (
          <Dashboard ref={comp => this.setDatalayer(comp)}
            onWidgetAction={this.onWidgetAction}
            context={ctx}
            config={dashboards[0]}
            onChange={this.onDashboardChange} />
        );
      }
    }else {
      if(! busy) {
        return (
          <MissingConfig appliance={appliance} />
        );
      }
      return null;
    }
  },
  setBusy(isBusy = true) {
    this.setState({busy: isBusy});
  },
  toggleDisplay() {
    const {appliance} = this.props;
    Util.trackPageWidgetEvent("WIDGET_CLICK", "SZG Owner's App", appliance && appliance.deviceId);
    this.setState({toggleActive: !this.state.toggleActive}, _ => {
      const {toggleActive} = this.state;
      if(toggleActive) {
        this.setBusy();
        this.getDashboardConfig()
            .then(dashboardConfig => {
              console.log(dashboardConfig);
              this.setState({
                busy: false,
                dashboardConfig
              });
            })
            .catch(err => {
              this.setBusy(false);
            });
      }else {
        this.setState({
          dashboardConfig: null,
          busy: false
        });
      }
    });
  },
  getDashboardConfig() {
    const {appliance} = this.props;
    return configFactory(appliance);
  },
  getDashboardContext() {
    return {};
  },
  onTabChange(index) {
    this.setState({
      tabIndex: index
    });
  },
  publishData(data, isError) {
    const {dashboardConfig, tabIndex} = this.state;
    this.setBusy(false);
    if(!dashboardConfig) {
      return;
    }
    let channel, {dashboards, name} = dashboardConfig;
    channel = dashboards[tabIndex || 0].datalayer.rootChannel;

    // console.log(`Publishing data to ${channel}`);
    // console.log(data);
    // const {datalayer: {rootChannel}} = dashboardConfig;
    this.datalayer.publish(channel, data, isError);
  },
  refreshDashboard() {
    const {appliance} = this.props, {busy} = this.state;
    if(busy) {
      return;
    }

    this.setBusy();
    let req = this.request = cancelable(Service.fetchEndUserApplianceData(appliance));
    req.promise.then(data => {
      this.publishData(data);
    }).catch(error => {
      if(!error.canceled) {
        this.publishData(error, true);
      }
    });
  },
  onWidgetAction(id, action, data) {
    console.info("onWidgetAction", id, action, data);
  },
  notifyError(error) {
    const errorMapping = {
      '500': 'Unable to fetch SZG Owner\'s App data. The device is possibly offline.',
      'generic': 'There was an error fetching data'
    };

    const {application: {notifications}} = this.props,
        code = error && error.status,
        message = error && code ? errorMapping[code] : errorMapping.generic;
    notifications.error(message);
  },
  setDatalayer(comp) {
    this.datalayer = comp ? comp.DataLayer : null;
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
    if(this.datalayer) {
      const {dashboardConfig, tabIndex} = this.state,
          {dashboards, name} = dashboardConfig,
          channel = dashboards[tabIndex || 0].datalayer.rootChannel;

      console.log("Subscribing to root channel", channel);
      this.subscription = this.datalayer.subscribe(
        channel,
        data => {},
        error => {
          // console.log("Got error ------------------", error);
          this.notifyError(error);
        },
        onComplete => {
          // console.log("Completed ------------------");
          // this.subscription.unsubscribe();
        }
      );
    }
  }
});
