/* global setTimeout clearTimeout console */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import DashboardService from "./Service";
import ApplianceService from "../appliance/Service";

export default ReactClass({
  displayName: "HideByApplianceType",
  getInitialState() {
    return {
      appliance: {},
      byType: {}
    };
  },
  componentWillMount() {
    this.setState({appliance: ApplianceService.getActiveAppliance()});
    DashboardService.fetchWidgetConfiguration().then(({perApplianceType}) => {
      this.setState({
        byType: perApplianceType
      });
    });
  },
  render() {
    const {byType, appliance} = this.state,
        config = byType[appliance && appliance.type] || {},
        uiElements = config.hideUIElements || [],
        widgets = config.hideWidgets || [],
        {name} = this.props;

    return (name && (uiElements.indexOf(name) !== -1 || widgets.indexOf(name) !== -1)) ?
      null : this.props.children
    ;
  }
});
