/* global console setTimeout history*/
import React from "react";
import ReactClass from "create-react-class";
import Config from "../../config";


export default ReactClass({
  displayName: "AboutView",
  render() {
    return (
      <div className="view about-view">
        <div className="main-content">
          <div className="section">
            <h2 className="section-title">
            Connected Appliance Portal
            </h2>
            <div className="version-info">Version: @buildVersion@</div>
          </div>
        </div>
      </div>
    );
  }
});
