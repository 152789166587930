/* global setTimeout clearTimeout console */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";

const NotificationMessage = ReactClass({
  displayName: "NotificationMessage",
  getInitialState() {
    return {classes: ["notification"]};
  },
  close(e) {
    const self = this, classes = this.state.classes;
    clearTimeout(self.timer);

    // Animation
    classes.splice(classes.indexOf("enter"), 1);
    self.forceUpdate(function() {
      setTimeout(function() {
        self.props.onclose(self.props.message);
      }, 400);
    });
  },
  render() {
    const message = this.props.message;
    let iconElem = "";

    if(message.icon) {
      iconElem = (<i className={"icon " + message.icon} />);
    }

    return (
      <div className={this.state.classes.join(" ")} onClick={this.close}>
        {iconElem}
        <div className="content">
          <span>{message.content}</span>
          <i className="_pull-right icon-x-circle"></i>
        </div>
      </div>
    );
  },
  componentDidMount() {
    const self = this, message = this.props.message,
        type = message.type || "info",
        ttl = message.ttl + 200;

    // Animation
    setTimeout(function() {
      self.setState({
        classes: self.state.classes.concat([type, "enter"])
      });
    }, 200);

    if(!message.sticky) {
      this.timer = setTimeout(function() {
        self.close(message);
      }, ttl);
    }
  }
});



const Notifications = ReactClass({
  displayName: "Notifications",
  getInitialState() {
    return {
      messages: []
    };
  },
  uuid() {
    if(!this.counter) {
      this.counter = 0;
    }
    return "msg_" + (this.counter++);
  },
  onNotificationClosed(message) {
    const messages = this.state.messages, index = messages.indexOf(message);
    if(index !== -1) {
      messages.splice(index, 1);
    }
    this.forceUpdate();
  },
  render() {
    const self = this;
    const messages = this.state.messages.map(function(m) {
      m.key = m.key || self.uuid();
      return (
        <NotificationMessage key={m.key} message={m} onclose={self.onNotificationClosed} />
      );
    });
    return (
      <div className="notifications">
        {messages}
      </div>
    );
  },
  message(msgObj) {
    console.log(msgObj);
    this.setState({
      messages: this.state.messages.concat([{
        type: msgObj.type || "info",
        icon: msgObj.icon,
        content: msgObj.content,
        ttl: msgObj.ttl,
        sticky: !!msgObj.sticky
      }])
    });
  },
  createNotification(message, sticky) {
    let isSticky, timeout;
    if(typeof sticky === "number") {
      timeout = sticky;
      isSticky = false;
    }else {
      isSticky = !!sticky;
      timeout = 5000;
    }
    return {
      sticky: isSticky,
      ttl: timeout,
      content: message
    };
  },
  info(message, sticky) {
    const notf = this.createNotification(message, sticky);
    notf.type = "info";
    notf.icon = "icon-info";
    this.message(notf);
  },
  warn(message, sticky) {
    const notf = this.createNotification(message, sticky);
    notf.type = "warn";
    notf.icon = "icon-alert-triangle";
    this.message(notf);
  },
  error(message, sticky) {
    const notf = this.createNotification(message, sticky);
    notf.type = "error";
    notf.icon = "icon-alert-octagon";
    this.message(notf);
  },
  success(message, sticky) {
    const notf = this.createNotification(message, sticky);
    notf.type = "success";
    notf.icon = "icon-check-circle";
    this.message(notf);
  },
  clear() {
    this.setState({
      messages: []
    });
  }
});

export default Notifications;
