/* global Promise setTimeout */
import ApiClient from "../../components/api-client";

const {instance: Api, asJson: resAsJson} = ApiClient;
let logData = null;

export default {
  fetchLogs(deviceId, serial) {
    return Api.get(`/appliances/logs?${deviceId ? `deviceId=${deviceId}&` : ""}serialNumber=${serial}`)
      .then(resAsJson);
  },
  setLogData(data = null) {
    logData = data;
  },
  getLogData() {
    return logData;
  }
};
