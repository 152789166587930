/* global File*/
import React from "react";
import ReactClass from "create-react-class";
import Service from "./Service";
import * as _ from "lodash";
import Navbar from "./NavBar";

const FilterComponent = ReactClass({
  displayName: "FilterComponent",
  getInitialState() {
    this.timeOut = null;
    return {
      val: ""
    };
  },
  handleChange(e) {
    const {target: {value: val}} = e;
    this.setState({
      val
    });
    if(this.timeOut)
      window.clearTimeout(this.timeOut);
    this.timeOut = window.setTimeout(() => {
      if ((this.props.onFilter &&
          typeof (this.props.onFilter) === "function")) {
        this.props.onFilter(val);
      }
    }, 500);
  },
  onSave() {
    if ((this.props.onSave &&
        typeof (this.props.onSave) === "function")) {
      this.props.onSave();
    }
  },
  onDiscard() {
    if ((this.props.onDiscard &&
        typeof (this.props.onDiscard) === "function")) {
      this.setState({
        val: ""
      }, _ => {
        this.props.onDiscard();
      });
    }
  },
  render() {
    const {val} = this.state,
        {busy} = this.props;
    return (
      <div className="row search-box">
        <div className="filter">
            <div className="search-input">
              <input type="text" className="search" placeholder="Model Number"
                  value={val}
                  onChange={this.handleChange}/>
              <i className="icon icon-search"></i>
            </div>
        </div>
        <div>
            <button className="button inline primary"
                disabled={busy ? true : false}
                onClick={this.onSave}> Save </button>
            <button className="button inline primary"
                disabled={busy ? true : false}
                onClick={this.onDiscard}> Discard </button>
        </div>
      </div>
    );
  }
});

const Checkbox = ReactClass({
  getInitialState: function() {
    return {checked: !!this.props.checked};
  },
  handleCheck: function() {
    const checked = !this.state.checked;
    this.setState({checked});

    if ((this.props.onSelected &&
        typeof (this.props.onSelected) === "function")) {
      this.props.onSelected(checked);
    }
  },
  render: function() {
    const {title = ""} = this.props,
        {checked} = this.state;
    return (
      <div className="checkbox container">
          <i className={`icon ${(checked ? "icon-check" : "icon-x")}`} onClick={this.handleCheck} />
      </div>
    );
  }
});

const TableComponent = ReactClass({
  displayName: "TableComponent",
  componentDidMount() {
    this.tbody.css = `{height: calc(100% - 230px)`;
  },
  onValueChange(model, widgetName, isChecked) {
    if ((this.props.onUpdate &&
        typeof (this.props.onUpdate) === "function")) {
      this.props.onUpdate(model, widgetName, isChecked);
    }
  },
  render() {
    const {data = [], widgetType} = this.props,
        columnHeader = widgetType.map(wt => {
          return (<th key={wt.id}>{wt.displayName}</th>);
        }),
        rows = Object.keys(data).map(key => {
          const widgetIds = data[key],
              allWidgets = widgetType.map(w => w.id),
              widgets = allWidgets.map(w => {
                return (
                  <td key={`td-${key}-${w}`}>
                    <Checkbox key={`${key}-${w}`} checked={(widgetIds.indexOf(w) > -1)}
                        onSelected={isChecked => this.onValueChange(key, w, isChecked)} />
                  </td>
                );
              });

          return (<tr key={key}>
            <td>{key}</td>
            {widgets}
          </tr>);
        });
    return (
      <div className="table">
        <table className="records-list">
          <thead>
            <tr>
              <th>Model</th>
              {columnHeader}
            </tr>
          </thead>
          <tbody ref={tbody => this.tbody =tbody}>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }
});

export default ReactClass({
  displayName: "WidgetConfigOnModelWise",
  getInitialState() {
    this.widgetType = [
      {
        "displayName": "Air Purification",
        "id": "airPurification",
        "applicableFor": ["fridge", "oven", "dishwasher"]
      }, {
        "displayName": "Water Filter",
        "id": "waterfilter",
        "applicableFor": ["fridge", "dishwasher"]
      }, {
        "displayName": "Icemaker",
        "id": "icemaker",
        "applicableFor": ["fridge"]
      }, {
        "displayName": "Refrigerator Zone",
        "id": "refrigeratorzone",
        "applicableFor": ["fridge"]
      }, {
        "displayName": "Freezer Zone",
        "id": "freezerzone",
        "applicableFor": ["fridge"]
      }
    ];
    return {
      records: [],
      loading: false,
      busy: false
    };
  },
  onFilterData(modelNumber) {
    const records = _.cloneDeep(this.copyOriginalData.ConfigData),
        filteredData = _.pickBy(records, function(value, key) {
          return _.includes(key.toLowerCase(), modelNumber.toLowerCase());
        });
    this.setState({
      records: filteredData
    });
  },
  componentDidMount() {
    this.setState({
      loading: true
    });
    Service.fetchWidgetConfiguration().then(res => {
      this.copyOriginalData = _.cloneDeep(res);
      this.setState({
        records: _.cloneDeep(res.ConfigData),
        loading: false
      });
    }).catch(err => {
      this.setState({
        records: [],
        loading: false
      });
    });
  },
  onUpdate(model, widgetName, isChecked) {
    let records = _.cloneDeep(this.state.records),
        widgets = records[model];
    if(isChecked) {
      widgets.push(widgetName);
    } else {
      const index = widgets.indexOf(widgetName);
      if (index !== -1) {
        widgets.splice(index, 1);
      }
    }
    this.setState({
      records
    });
  },
  saveConfigurations() {
    const {records} = this.state,
        {application: app, notifications} = this.props,
        newRecords = Object.assign({}, this.copyOriginalData.ConfigData, records),
        updateFileData = Object.assign({}, this.copyOriginalData, {"ConfigData": newRecords});

    // console.log("Records : ", records);
    // console.log("Original : ", this.copyOriginalData);
    // console.log("Updated : ", updateFileData);

    this.setState({
      busy: true
    });

    app.showLoading(true);

    const file = new File([JSON.stringify(updateFileData)], "Matrix_DashboardWidgets.json", {
      type: "application/json",
      endings: "native"
    });
    Service.uploadFile(file).then(resp => {
      app.showLoading(false);
      app.notifications.success(resp.message);
      this.copyOriginalData = _.cloneDeep(updateFileData);
      this.setState({
        busy: false
      });
    }).catch(errJson => {
      app.showLoading(false);
      this.setState({
        busy: false
      });
      app.notifications.error("Failed to upload this configuration file.");
    });
  },
  discardConfigurations() {
    const records = _.cloneDeep(this.copyOriginalData.ConfigData);
    this.setState({
      records
    });
  },
  render() {
    const {records = [], loading, busy} = this.state,
        loadingClass = `widget-config-view ${loading ? "widget-loading" : ""}`;
    return (
      <div className="view">
      <Navbar {...this.props}/>
        <div className={loadingClass}>
          <div className="section">
            <h2 className="section-title">Model Wise Widget Configuration</h2>
            {loading
              ? <div className="loading">
                  <div className="_text-center anim">
                    <i className="icon icon-loader spin"></i>
                  </div>
                  <p className="_text-center">Loading Widget Configuration ...</p>
                </div>
              : <div>
                  <FilterComponent onFilter={this.onFilterData}
                      busy={busy}
                      onSave={this.saveConfigurations}
                      onDiscard={this.discardConfigurations} />
                  <TableComponent data={records} widgetType={this.widgetType}
                      onUpdate={this.onUpdate} />
                </div>}
          </div>
        </div>
      </div>
    );
  }
});
