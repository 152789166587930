"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _createReactClass = require("create-react-class");

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// const React = require("react");


var Header = (0, _createReactClass2.default)({
  displayName: "Header",
  render: function render() {
    return _react2.default.createElement(
      "div",
      { className: "header" },
      this.props.children
    );
  }
}); /**
     * K2 Inc. Confidential
     *
     * Copyright © 2015 K2 Inc. All rights reserved.
     *
     * Notice: All information contained herein is, and remains the property of K2 Inc. and its
     * suppliers, if any. The intellectual and technical concepts contained herein are proprietary to K2
     * Inc. and/or its suppliers, and may be covered by U.S. and foreign patents or patents in process,
     * and are protected by trade secret or copyright law. Dissemination of this information or
     * reproduction of this material is strictly forbidden unless prior written permission is obtained
     * from K2 Inc.
     */

var Content = (0, _createReactClass2.default)({
  displayName: "Content",
  render: function render() {
    return _react2.default.createElement(
      "div",
      { className: "content" },
      this.props.children
    );
  }
});

/**
 * A convenient Panel component that widgets can use to show a panel with header and content in the
 * dashboard
 * Usage:
 * <pre>
 *   <WidgetPanel id="one">
 *     <Header>
 *       <!-- Any content -->
 *      </Header>
 *     <!-- Any content -->
 *   </WidgetPanel>
 * </pre>
 */
var WidgetPanel = (0, _createReactClass2.default)({
  displayName: "WidgetPanel",
  propTypes: {
    id: _propTypes2.default.string,
    panelClass: _propTypes2.default.string,
    // Only allow Header and Content
    children: function children(props, propName, componentName) {
      var children = props[propName];
      if (_react2.default.Children.count(children) > 2) {
        throw new Error("Only one header and one content element allowed in panel");
      }
      _react2.default.Children.forEach(children, function (child, i) {
        if (child.type !== Header && child.type !== Content) {
          throw new Error("Expected 'Header' or 'Content' " + "but found " + (child.type.displayName || child.type));
        }
      });
    }
  },

  render: function render() {
    var classname = "widget-panel " + (this.props.panelClass || "");
    return _react2.default.createElement(
      "div",
      { id: "panel_" + this.props.id, className: classname },
      this.props.children
    );
  }
});

WidgetPanel.Header = Header;
WidgetPanel.Content = Content;

// module.exports = WidgetPanel;
exports.default = WidgetPanel;