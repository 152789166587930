/* global console */
import React from "react";
import ReactClass from "create-react-class";

import DialogService from "../../components/widgets/DialogService";
import DatePicker from "react-datepicker";

const parseDate = (str = '', type) => {
  let s = str.split(/\D/);
  return type === "start" ?
      new Date(s[0], --s[1], s[2], 0, 0, 0).getTime():
      new Date(s[0], --s[1], s[2], 23, 59, 59).getTime()
  ;
};

const diffInDays = (start, end) => {
  return Math.abs((parseDate(end, "end") - parseDate(start, "start")) / (1000 * 60 * 60 * 24));
};

const lpad = (str, char, upto) => {
  const count = (upto - String(str).length) + 1;
  return count > 1 ? new Array(count).join(char) + str : str;
};

const formatSimpleDate = date => {
  date = date && typeof(date) === 'string' ? new Date(date) : date;
  return date ? [
    date.getFullYear(),
    lpad(date.getMonth() + 1, '0', 2),
    lpad(date.getDate(), '0', 2)
  ].join('-') : '';
};

const TIME_RANGES = [
      {period: "DD", count: 1, label: "Last 24 Hrs"},
      {period: "DD", count: 3, label: "Last 3 Days"},
      {period: "DD", count: 7, label: "Last 7 Days"},
      {period: "DD", count: 15, label: "Last 15 Days"}
      /*,
      {period: "DD", count: 30, label: "Last 30 Days"},
      {period: "DD", count: 90, label: "Last 3 Months"}
      */
    ],
    DAY_MULTIPLIER = {
      HH: 1/24,
      DD: 1,
      MM: 30,
      YY: 365
    },
    DATE_FORMAT = "yyyy/MM/dd";

const CustomDateInput = ReactClass({
  displayName: "CustomDateInput",
  render() {
    return <input onClick={this.props.onClick} readOnly={true}
            value={this.props.value} className="date-picker" />;
  }
});
const CustomDateRange = ReactClass({
  displayName: "CustomDateRange",
  getInitialState() {
    return {
      today: new Date(),
      startDisplay: this.props.startDate || null,
      endDisplay: this.props.endDate || null,
      start: formatSimpleDate(this.props.startDate),
      end: formatSimpleDate(this.props.endDate)
    };
  },
  handleChange(key, value) {
    this.setState({
      [key]: formatSimpleDate(value),
      [key + "Display"]: value
    });
  },
  render() {
    return (
      <section className="dialog-content custom-date-range-selector">
        <div className="dialog-header">
          <h4>Select Custom Date Range</h4>
        </div>
        <div className="dialog-body">
          <ul>
            <li>
              <label>Start Date: </label>
              <DatePicker
                customInput={<CustomDateInput />}
                selected={this.state.startDisplay}
                selectsStart
                startDate={this.state.startDisplay}
                maxDate={this.state.today}
                dateFormat={DATE_FORMAT}
                onChange={e => this.handleChange("start", e)} 
                />
            </li>
            <li>
              <label>End Date: </label>
              <DatePicker
                customInput={<CustomDateInput />}
                selected={this.state.endDisplay}
                selectsEnd
                startDate={this.state.startDisplay}
                endDate={this.state.today}
                onChange={e => this.handleChange("end", e)}
                minDate={this.state.startDisplay}
                maxDate={this.state.today}
                dateFormat={DATE_FORMAT}
                className="date-picker"
              />
            </li>
          </ul>
          <footer className="dialog-footer">
            <button className="inline primary"
                    disabled={
                      !(this.state.start && this.state.end) ||
                      (parseDate(this.state.start, "start") > parseDate(this.state.end, "end")) ||
                      (diffInDays(this.state.start, this.state.end) > 15)
                    }
              onClick={() => this.props.dialog.close(this.state)}>Apply</button>
            <button className="inline" onClick={() => this.props.dialog.close(false)}>Close</button>
            <h5>* Maximum 15 days allowed</h5>
          </footer>
        </div>
      </section>
    );
  }
});


export default ReactClass({
  displayName: "TimeRangeSelector",
  getInitialState() {
    return {
      panelShowing: false,
      selectedTimeRangeIdx: 2,
      selectedTimeRangeType: "preconfigured",
      customTimeRange: {}
    };
  },

  componentDidMount() {
    this.handleRangeSelection(
      TIME_RANGES[this.state.selectedTimeRangeIdx],
      this.state.selectedTimeRangeIdx,
      false
    );
    if(this.props.timeRangeIndex !== "custom") {
      this.setState({
        selectedTimeRangeIdx: this.props.timeRangeIndex
      });
    } else {
      this.setState({
        selectedTimeRangeType: this.props.timeRangeIndex,
        customTimeRange: {
          startDisplay: this.props.customTimeRangeSelection.startDisplay,
          endDisplay: this.props.customTimeRangeSelection.endDisplay
        }
      });
    }
  },

  togglePanel() {
    const newState = !this.state.panelShowing;
    this.setState({panelShowing: newState});

    if (newState === true) {
      DialogService.makeDialog(this.panel, this.button, () => {
        this.setState({panelShowing: false});
      });
    }
  },

  handleRangeSelection(range, index, bToggle = true) {
    let endTime = new Date(),
        startTime = new Date(),
        diffDays = DAY_MULTIPLIER[range.period] * range.count;

    startTime.setDate(endTime.getDate() - diffDays);

    this.setState({
      selectedTimeRangeType: "preconfigured",
      selectedTimeRangeIdx: index
    });
    if(this.props.timeRangeFlag && this.props.timeRangeFlag === 1) {
      this.props.onSelectionChange({
        startTime: this.props.initTimeRangeFilterSelection[startTime],
        endTime: this.props.initTimeRangeFilterSelection[endTime]
      }, index);
    }
    else {
      this.props.onSelectionChange({
        startTime: startTime.getTime(),
        endTime: endTime.getTime()
      }, index);
    }
    if (bToggle) {
      this.togglePanel();
    }
  },

  showCustomTimeRangeSelector() {
    this.togglePanel();
    this.props.application.showDialog(CustomDateRange, {
      startDate: this.state.selectedTimeRangeType === 'custom' ? this.state.customTimeRange.startDisplay : null,
      endDate: this.state.selectedTimeRangeType === 'custom' ? this.state.customTimeRange.endDisplay : null,
      onClose: (range) => {
        if (!range) { return; }
        this.props.onSelectionChange({
          startTime: parseDate(range.start, "start"),
          endTime: parseDate(range.end, "end")
        }, "custom", range);
        this.setState({
          customTimeRange: range,
          selectedTimeRangeType: "custom"
        });
      }
    });
  },

  render: function() {
    const customOption = (
          <li key="__CUSTOM__" onClick={() => this.showCustomTimeRangeSelector()}
              className={this.state.selectedTimeRangeType === "custom" ? "selected" : ""}>
            <i className="icon-check"/>
            Custom...
          </li>
        ),
        optionsList = TIME_RANGES.map((range, idx) => (
          <li key={idx} onClick={() => this.handleRangeSelection(range, idx)}
              className={
                this.state.selectedTimeRangeType !== "custom" &&
                this.state.selectedTimeRangeIdx === idx ? "selected" : ""
              }>
            <i className="icon-check" />
            {range.label}
          </li>
        ));

    return (
      <div className="dropdown-dialog-container timerange-selector-container">
        <button id={`btnTimeRange`} className="inline primary header-action-graph dropdown-dialog-button"
                ref={x => this.button = x}
                disabled={this.props.disabled || false}
                onClick={this.togglePanel}>
          Time Range&#160;<i className="icon-chevron-down" />
        </button>
        <section ref={x => this.panel = x}
            className={"dropdown-dialog timerange-selector-panel " +
                (this.state.panelShowing ? "in" : "")}>
          <ul className="timerange-list">
            {optionsList.concat([customOption])}
          </ul>
        </section>
      </div>
    );
  }
});
