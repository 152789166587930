import util from "../../components/util";

const UserPermissions = {
    allowSendFirmwareRequest(){
        return !util.isLoggedInUsingSC();
    },
    allowViewCustomerInfo(){
        return !util.isLoggedInUsingSC();
    },
    allowViewCatProjects(){
        return !util.isLoggedInUsingSC();
    }
}

export default UserPermissions;