/* global console setTimeout */
import React from "react";
import ReactClass from "create-react-class";

import * as _ from "lodash";

export default ReactClass({
  displayName: "GraphLegendComponent",

  handleLegendDrag(e, field) {
    this.props.onFieldMoveStart(this.props.index);
    e.dataTransfer.setData("text/plain", JSON.stringify({field, from: this.props.index}));
  },

  handleLegendDragCancel(e) {
    this.props.onFieldMoveCancel();
  },

  preventDefault: function (event) {
    event.preventDefault();
  },

  handleLegendDrop(e) {
    this.props.onFieldMoved(
      Object.assign(
        {to: this.props.index},
        JSON.parse(e.dataTransfer.getData("text/plain"))
      )
    );
  },

  render: function() {
    const {config, record} = this.props,
        fields = config && config.dataSets && config.dataSets[0]
    ;

    let list = (fields && fields.fieldMappings) || [];

    list = list
      .filter(f => f.hideInLegend !== true)
      .filter(f => String(f.panelIdx) === String(this.props.index))
      .map(f => {
        return {
          id: f.prop.id,
          field: f.toField,
          event: !!f.event,
          label: f.prop ? f.prop.displayName :
              _.findKey(this.props.keyMap, key => key === f.toField),
          color: f.color,
          value: record ? record[f.toField] : "-"
        };
      });

    return (
      <section className={
        `graph-legend ${this.props.index} ${this.props.droppable ? "droppable" : !list.length ? "empty" : ""} ${this.props.className}`
      }
      //style={{top: this.props.top + "%"}}
      //height of the legend is updated based on the index of the legend and selected graph panel.
      style={
        {
          top: (this.props.index == 0) ? 0 + "%" : (this.props.index !== this.props.selectedGraphPanel) ? 50 + "%" : 5 + "%",
          display: (this.props.selectedGraphPanel !== -1) ? (this.props.index == this.props.selectedGraphPanel) ? "inline" : "none" : "inline"
        }}
      >
        <table>
          <tbody>
          {!this.props.droppable ? list.map(entry => (
            <tr key={`${entry.field} ${entry.label}`} className="legend-entry" draggable="true"
                onDragStart={e => this.handleLegendDrag(e, entry.field)}
                onDragEnd={e => this.handleLegendDragCancel(e)}>
              <td onClick={() => this.props.onFieldToggle(entry.field)}>
                <i className={`color`} style={{backgroundColor: entry.color}}>
                  {entry.event === true ? "!" : ""}
                </i>
              </td>
              <td className="current-value">{entry.value === undefined || entry.value === null ? '' : entry.value}</td>
              <td className="label"
                  onMouseOver={() => this.props.onFieldHover(entry.field)}
                  onMouseOut={() => this.props.onFieldHover()}
                  title={entry.label}
              >
                {entry.label}
              </td>
              <td onClick={() => this.props.onFieldRemove(entry)}>
                <i className="icon-x" />
              </td>
            </tr>
          )) : (
              <tr className="legend-dropzone"
                  onDragOver={this.preventDefault}
                  onDrop={e => this.handleLegendDrop(e, this.props.index)}>
                <td>Drop here...</td>
              </tr>
          )}
          </tbody>
        </table>
      </section>
    );
  }
});
