/* global console, Image history */
import React from "react";
import ReactClass from "create-react-class";

import Config from "../../../config";

import {Tabs} from "../../../components/widgets"
import { WidgetPanel, AsSubscribedComponent } from "ui-dashboard";
import Util from "../../util";

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "MobileNotificationWidget",
  getInitialState: function () {
    return {
      data: {},
    };
  },
  componentDidUpdate() {
    this.props.layout.layout();
  },

  renderNotification(data, tab) {
    return data.length > 0
      ? data.map((mobNotification, idx) => {
        return (
          <div key={`notification-section${idx}`}>
            {
              mobNotification.name !== null || mobNotification.time !== null
                ? <div className="notification-section" id={`notification-section${idx}`}>
                  <div className="name">{mobNotification.name || 'N/A'}</div>
                  <div className="time">{mobNotification.epochTime != "0" ? Util.formatDate(new Date(parseInt(mobNotification.epochTime)), "yyyy-MM-dd HH:mm:ss", false) : 'N/A'}</div>
                </div>
                : null
            }
          </div>
        )
      })
      : (<div className="empty">No mobile notifications available for {tab}.</div>);
  },

  render: function () {
    const { Header, Content } = WidgetPanel,
      { data } = this.state;
    const tabs = data
      ? Object.keys(data)
      : [];
    const { experimentalFeatures: IS_FEATURE_SHOW } = Config;

    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{this.props.title}</span>
        </Header>
        <Content>
          {
            tabs.length > 0
            ? (<Tabs activeTab={0}>
              {
                tabs
                .filter(tab => {
                  /* Hide dummy data widget - If IS_FEATURE_SHOW is false & Filter Service Tab which having dummy data*/
                  if(!IS_FEATURE_SHOW && tab.toLowerCase() === "service")
                    return false;
                  return true;
                })
                .map((tab, index) => {
                  return (<Tabs.Tab key={`${tab}-${index}`} title={tab}>
                  <div className="mob-notification-list">
                    {this.renderNotification(data[tab], tab)}
                  </div>
                </Tabs.Tab>)
                })
              }
            </Tabs>)
            : <div className="empty">No mobile notifications available.</div>
          }
        </Content>
      </WidgetPanel>
    );
  },
  onData: function (data, channel) {
    this.setState({
      channel: channel,
      data: data
    });
  }
}));

const Info = {
  id: "appliance-dashboard:mobileNotification",
  name: "Mobile Notification",
  type: Widget,
  description: "Show Mobile Notification",
  icon: "",
  tags: "mobile notification"
};

export default {
  Info,
  Widget
};
