/* global console */
import {Channel} from "ui-datalayer";
import util from "../../../../components/util";
import Config from "../../../../config";
import TransForm from "../transFormDataPoint"

const Filters = {
  keyPattern(data, keyRegexp) {
    const filtered = Object.keys(data)
        .filter(k => {
          // return k.indexOf("fridge.") === 0;
          return keyRegexp.test(k);
        })
        .reduce((acc, k, i) => {
          acc[k] = data[k];
          return acc;
        }, {});
    console.log("Filtered on " + keyRegexp + "....", filtered);
    return filtered;
  }
};


const Transformers = {
  connectivity: data => {
    const {latest: {records} = {}, history = {}} = data,
        connectivity = Object.keys(records).reduce((acc, k, i) => {
          const val = records[k];
          acc[k] = val === "GOOD" ? 1 : (val === "BAD" ? 0 : -1);
          return acc;
        }, {});
    data.connectivity = connectivity;

    if(history && history.records !== 100) {
      let records = history.records || 0;
      history.items = (history.items || []).map(itm => {
        let {GOOD: good = 0, BAD: bad = 0, UNKNOWN: unknown = 0} = itm.status;
        itm.status = {
          GOOD: good === 0 ? 0 : Math.round((100 * good) / records),
          BAD: bad === 0 ? 0 : Math.round((100 * bad) / records),
          UNKNOWN: unknown === 0 ? 0 : Math.round((100 * unknown) / records)
        };
        return itm;
      });
    }
    console.log("Connectivity Transformed", data);
    return data;
  },
  calOfConsumerAppLogsCnt: data => {
    let currentTime = new Date(),
        currentMiliSecondTime = currentTime.getTime();
    const hrToMiliSec = (24 * 60 * 60 * 1000),
        count = data.history.details
        .filter(detail => detail.consumerAppLog)
        .filter(detail => {
          const {timestamp} = detail,
              last24hrTime = currentMiliSecondTime - hrToMiliSec; // for last 24 hr timing
          if (timestamp > last24hrTime)
            return true;
        }).length;
    return count;
  }
};

const ChannelFactories = {
  getBaseUrl() {
    return Config.apiServerUrl + Config.apiUrl;
  },
  applianceInfo(appliance) {
    return {
      "name": "/appliance/info",
      "factory": function(config) {
        return {
          open: function(context) {
            context.emit("data", appliance);
          },
          close: function() {}
        };
      }
    };
  },
  currentState() {
    return {
      "name": "/appliance/current-state/data-point",
      "type": "extended",
      "config": {
        "from": "/appliance/properties",
        "transform": data => {
          //override date format, this can be removed later if server
          //sends same format.
          const overrideDateFormat = (uData)=>{
            if(uData && uData["Paginated Data"]){
              const pages = uData["Paginated Data"];
              pages.forEach((item)=>{
                item["Start Time"].format = "MM/DD/YYYY HH:mm";
              });
            }
          }
          //update date format for  upper and lower oven cavity
          if(data['data_points']){
            if(data['data_points']["Upper Cavity"]){
              overrideDateFormat(data['data_points']["Upper Cavity"]["Unique Data"]);
            }
            if(data['data_points']["Lower Cavity"]){
              overrideDateFormat(data['data_points']["Lower Cavity"]["Unique Data"]);
            }
            if(data['data_points']["Oven"]){
              overrideDateFormat(data['data_points']["Oven"]["Unique Data"]);
            }
            if(data['data_points']["Dishwasher"]){
              overrideDateFormat(data['data_points']["Dishwasher"]["Unique Data"]);
            }
          }
          const { data_points } = data;
          if("error" in data_points) {
            return data_points;
          }else {
            return (data_points ? TransForm.transFormSZDataPoints(data_points) : {} )
          }
        }
      }
    }
  },
  userInfo(appliance) {
    return {
      "name": "/user/info",
      "factory": function() {
        return {
          open: function(context) {
            context.emit("data", appliance);
          },
          close: function() {}
        };
      }
    };
  },
  networkConnInfo(appliance, networkConnInfo) {
    return {
      "name": "/appliance/networkConn-info",
      "factory": function() {
        return {
          open: function(context) {
            context.emit("data", {...appliance, ...networkConnInfo});
          },
          close: function() {}
        };
      }
    };
  },
  mobNotificationInfo(appliance, token) {
    let scHeaders = {};
    if(util.isLoggedInUsingSC()){
       scHeaders = util.getSCAuthHeaders();
    }
    return {
      "name": "/appliance/mobile-notification",
      "type": "http",
      "config": {
        "url": `${this.getBaseUrl()}/appliances/${appliance.deviceId}/mobilenotification`,
        "method": "GET",
        "responseType": "json",
        "params": {},
        "headers": {
          "Authorization": `Bearer ${token}`,
          "Accept": "application/json",
          ...scHeaders
        }
      }
    };
  },
  provisioningReport(appliance, token) {
    let scHeaders = {};
    if(util.isLoggedInUsingSC()){
       scHeaders = util.getSCAuthHeaders();
    }
    return {
      "name": "/appliance/provisioning-report",
      "type": "http",
      "config": {
        "url": `${this.getBaseUrl()}/consumerapp/mobileAppLogs?deviceId=${appliance.deviceId}&serialNumber=${appliance.serial}`,
        "method": "GET",
        "responseType": "json",
        "params": {},
        "headers": {
          "Authorization": `Bearer ${token}`,
          "Accept": "application/json",
          ...scHeaders
        }
      }
    };
  },
  diagnostics(appliance, token) {
    return {
      "name": "/appliances/diagnostics/connectivity",
      "type": "extended",
      "config": {
        "from": "/appliances/diagnostics/",
        "transform": data => {
          if(!data.latest && !data.history) {
            data = {latest: {records: {}},
              history: {details: []},
              lastRecordedTimeStamp:data.lastRecordedTimeStamp,
              records: {}
            };
          } else if(!data.records) {
            data = {latest: {records: {}},
            history: {details: []},
            lastRecordedTimeStamp:data.lastRecordedTimeStamp,
            records:{}
          };
          }
          let connectivityData = Transformers.connectivity(data),
              consumerAppLogsCount = data.history ? Transformers.calOfConsumerAppLogsCnt(data) : 0;
          connectivityData.appliance = appliance;
          return Object.assign({}, connectivityData, {consumerAppLogsCount});
        }
      }
    };
  },
  parameters(appliance, token) {
    return {
      "name": "/appliance/parameters",
      "type": "http",
      "config": {
        "interval": 10000,
        "url": `${this.getBaseUrl()}/appliances/${appliance.deviceId}/parameters`,
        "method": "GET",
        "responseType": "json",
        "params": {},
        "headers": {
          "Authorization": `Bearer ${token}`,
          "Accept": "application/json"
        }
      }
    };
  },
  timeZone(appliance, token) {
    return {
      "name": "/appliance/time-zone",
      "type": "extended",
      "config": {
        "from": "/appliances/timezone",
        "transform": data => {
          return {
            appliance: appliance,
            data: data
          };
        }
      }
    };
  },
  errorCodes(appliance, token) {
    return {
      "name": "/appliance/error-codes",
      "type": "extended",
      "config": {
        "from": "/appliances/errors",
        "transform": data => {
          return {
            appliance: appliance,
            errorCodes: data.errors
          };
        }
      }
    };
  }
};



export {
  Filters,
  Transformers,
  ChannelFactories
};
