import React from "react";
import {string, array, oneOfType, bool} from "prop-types";


const propTypes = {
  data: oneOfType([string, array]).isRequired,
  headers: array,
  target: string,
  separator: string,
  filename: string,
  uFEFF: bool
};

const defaultProps = {
  separator: ",",
  filename: "file.csv",
  uFEFF: true
};

const PropsNotForwarded = [
  `data`,
  `headers`
];

export {
  propTypes,
  defaultProps,
  PropsNotForwarded
};
