import ApplianceList from "./ApplianceList";
import ApplianceLeftNav from "./ApplianceLeftNav";
import DiagnosticsPanel from "./DiagnosticsPanel";
import Service from "./Service";
import ApplianceInfoComponent from "../../components/dashboard-widgets/appliance-info/Component";
import DiagnosticStatusComponent from "./diagnostic-status";

export {
  DiagnosticStatusComponent,
  ApplianceInfoComponent,
  ApplianceList,
  DiagnosticsPanel,
  Service,
  ApplianceLeftNav
};
