/* global console setTimeout AmCharts history */
import React from "react";
import ReactClass from "create-react-class";
import * as _ from "lodash";

import ApplianceInfo from "../common/ApplianceInfoComponent";
import LoadingIndicator from "../../components/widgets/LoadingIndicator";
import ResourcesComponent from "../common/Resources";

import {Service as ApplianceService, ApplianceLeftNav} from "../appliance";
import PollingFrequency from "../../components/dashboard-widgets/polling-frequency/Component"

export default ReactClass({
  displayName: "ApplianceNav",
  getInitialState() {
    return {
      fetchingAppliances: false,
      appliances: [],
      currentAppliance: {}
    };
  },

  componentWillMount() {
    const appliances = ApplianceService.getCurrentApplianceInfos(),
        {route} = this.props,
        {deviceId, serial} = route.params;
    if((deviceId || serial) && appliances && appliances.length > 0) {
      this.setState({fetchingAppliances: false, appliances});
      this.setCurrentAppliance(appliances, deviceId, serial);
    } else {
      this.setState({
        fetchingAppliances: true
      });
      ApplianceService.getApplianceInfoFromDeviceId(deviceId)
          .then(appliances => {
            if(appliances.length > 0) {
              this.setState({
                fetchingAppliances: false,
                appliances
              });
              ApplianceService.setCurrentApplianceInfos(appliances);
              this.setCurrentAppliance(appliances, deviceId);
            } else {
              this.routeToApplianceSearch();
            }
          }).catch(err => {
            this.routeToApplianceSearch();
            throw err;
          });
    }
  },

  setCurrentAppliance(Appliances, selectedDeviceId, selectedSerial) {
    const selectedIdx = selectedDeviceId
        ? Appliances.map(e => e.deviceId).indexOf(selectedDeviceId)
        : Appliances.map(e => e.serial).indexOf(selectedSerial),
        currentAppliance = selectedIdx > -1 ? Appliances[selectedIdx] : Appliances[0];
    this.setState({currentAppliance}, _ => {
      typeof(this.props.onLoad) === "function" &&
      this.props.onLoad({currentAppliance});
    });
  },

  back() {
    history.back();
  },

  getCurrentAppliance() {
    const {appliances} = this.state,
        {route: {params: {deviceId, serial}}} = this.props,
        selectAppIdx = deviceId
            ? appliances.map(a => a.deviceId).indexOf(deviceId)
            : appliances.map(a => a.serial).indexOf(serial);

    return appliances[selectAppIdx];
  },

  routeToApplianceSearch() {
    const {application} = this.props;
    application.route("/appliance-search");
  },
  selectAppliance(deviceId, serial) {
    const {application: app} = this.props,
        projectName = ApplianceService.getSelectedCatProjectName();

    if(deviceId)
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/${deviceId}/dashboard/`);
    else
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/dashboard/${serial}/`);
  },
  renderApplianceList() {
    const {currentAppliance: {deviceId, serial}} = this.state;
    return <ApplianceLeftNav appliances={this.state.appliances}
              application={this.props.application}
              selectedDeviceId={deviceId}
              selectedSerial={serial}
              onApplianceSelected={this.selectAppliance}
              fetchingAppliances={this.state.fetchingAppliances} />;
  },

  render: function() {
    const {application, graphType, route} = this.props,
        currentAppliance = this.getCurrentAppliance(),
        path = route.path === `/appliance/${currentAppliance && currentAppliance.deviceId}/Connected/graph`
    ;

    return (

      <div className={`view layout ${this.props.className}`}>
        {this.renderApplianceList()}
        <div className="main-content">
          {currentAppliance ? <ResourcesComponent currentAppliance={currentAppliance}/> : null}
          <div className="appInfo-polling-details">
            {currentAppliance &&
                <ApplianceInfo appliance={currentAppliance} application={application} graphType={graphType}/>}
                {currentAppliance && currentAppliance.connectedStatus === "ONLINE" && path
                    ? <PollingFrequency currentAppliance={currentAppliance}></PollingFrequency>
                    : <div></div>
            }
          </div>
          {!currentAppliance ? <LoadingIndicator /> : (this.props.children)}
        </div>
      </div>
    );
  }
});
