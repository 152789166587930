/* global console setTimeout */
import React from "react";
import ReactClass from "create-react-class";

import * as _ from "lodash";
import AmCharts from "@amcharts/amcharts3-react";

import GraphLegend from "./GraphLegend";
import PropertyModel from "./PropertyModel";
import AI from "../../appInsights";
import config from "../../config";


// AmCharts.useUTC = true;

function setOpacity(graph, opacity) {
  const container = graph.chart.div,
      className = "amcharts-graph-" + graph.id,
      items = container.getElementsByClassName(className);
  if (undefined === items) {
    return;
  }

  for (let x in items) {
    if ("object" !== typeof items[x]) {
      continue;
    }

    //Set opacity to all Graph Line paths(needed because
    //we are using gapField property to show gaps on graph
    //line when device is offline)
    let paths = items[x].getElementsByTagName("path");
    for(var i=0;i<paths.length;i++){
      var path = paths[i];
      if (path) {
        path.style.strokeOpacity = path.style.fillOpacity = opacity;
      }
    }

    // set bullet opacity
    let bullets = items[x].getElementsByClassName("amcharts-graph-bullet");
    for (let y in bullets) {
      if ("object" !== typeof bullets[y])
        continue;
      bullets[y].style.fillOpacity = bullets[y].style.strokeOpacity = opacity;
      for(let i=0, labels = bullets[y].getElementsByTagName('text'); i < labels.length; i++) {
        labels[i].style.opacity = opacity;
      }
    }

    // set label opacity
    let labels = items[x].getElementsByClassName("amcharts-graph-label");
    for (let y in labels) {
      if ("object" !== typeof labels[y])
        continue;
      labels[y].style.opacity = opacity == 1 ? 1 : 0;
    }
  }
}
const imgBasePath = `${config.baseDir}components/dashboard-widgets/connectivity/images`;

export default ReactClass({
  displayName: "GraphComponent",

  componentWillMount() {
    window.addEventListener("resize", () => {
      this.chartRef && this.chartRef.state.chart.invalidateSize();
    });
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.config && nextProps.config.chartCursorSettings) {
      nextProps.config.chartCursorSettings.listeners.push({
        event: "changed", method: evt => {
          if (evt && (evt.index !== undefined)) {
            this.setHoverRecord(this.props.data.getRecordAt(evt.index))
          }
        }
      });
    }
  },

  setHoverRecord(hoverRecord) {
    this.props.onGraphHover(hoverRecord);
    this.props.setHoverRecord(hoverRecord);
  },

  handleFieldToggle(field) {
    const panels = this.chartRef.state.chart.panels;

    let targetPanel, targetGraph;

    panels.forEach(p => {
      targetGraph = _.find(p.graphs, g => {
        if (g.valueField === field) {
          targetPanel = p;
          return true;
        }
      }) || targetGraph;
    });

    if (targetGraph) {
      targetPanel[targetGraph.hidden ? "showGraph" : "hideGraph"](targetGraph);

      AI.trackEvent("GraphToggleProp",
          Object.assign({field},
              _.pick(this.props.currentAppliance, "deviceId", "model", "name", "serial", "type")));
    }
  },

  handleFieldHover(field) {
    const panels = this.chartRef.state.chart.panels;

    panels.forEach(p => {
      p.graphs.forEach(g => {
        setOpacity(g, field ? (g.valueField === field ? 1 : 0.3) : 1);
      });
    });
  },

  handleFieldMove(moveMeta) {
    this.props.onFieldShuffle(moveMeta);
    this.props.setLegendDragFrom(-1);
  },

  setTimeTo({startTime, endTime}) {
    if (startTime && endTime) {
      this.chartRef.state.chart.zoom(new Date(startTime), new Date(endTime));
    }
  },

  setHoverLock() {
    this.props.setLockedHoverRecord(this.props.hoverRecord);
  },

  resetHoverLock() {
    this.props.setLockedHoverRecord(null);
  },

  getMinMaxText(index){
    const panelClassName = "amChartsPanel amcharts-stock-panel-div amcharts-stock-panel-div-stockPanel"+index;  
    const collection = document.getElementsByClassName(panelClassName);
    if(collection && collection.length>0){
      const panelDom = collection[0];
      if(panelDom.style.height=="100%"){
        return "Minimize";
      }
      else{
        return "Maximize";
      }
    }
  },

  render() {
    const defaultPanelHeight = 50;
    const data = this.props.data,
        {propModel, hoverRecord} = this.props,
        panels = propModel && propModel.panels || {sizes: [defaultPanelHeight, defaultPanelHeight], titles: ["", ""]}
        ;
    // console.log("Before Testing:", propModel, "configs:",this.props.config);
    let config = this.props.config;
    config.panels = config.panels.map((p, index) =>{ 
      const panelDom = this.props.getGraphPanelDomByPanelTitle(panels.titles[index]);
      if(panelDom){
        panelDom.style.height = panels.sizes[index]+'%';
      };
      return Object.assign(p, {
      percentHeight: panels.sizes[index],
      title: panels.titles[index]
    })});
    // console.log("After Testing:", propModel, "configs:",this.props.config);
    return data && data.records && propModel ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row"
        }}
      >
        <div className="graph" onClick={(e) => {
          if (e.target && e.target.classList.contains('amcharts-plot-area')) {
            this.setHoverLock()
          }
        }} onMouseLeave={() => {
          if (this.props.lockedHoverRecord) {
            setTimeout(() => {
              const cursors = this.chartRef.state.chart.chartCursors;
              cursors.forEach((c) => {
                c.showCursorAt(this.props.lockedHoverRecord.ts)
              })
            }, 10)
            this.setHoverRecord(this.props.lockedHoverRecord)
        }
        }}>
          <AmCharts.React ref={x => {
              this.chartRef = x
              this.props.setChartRef(x);
            }}
            className="graph-component"
            options={config} />
          {panels.sizes.map((height, index) => (
            <GraphLegend key={index} index={index}
                        top={index === 0 ? 0 : panels.sizes[index-1]}
                        keyMap={this.props.keyMap} config={config}
                        record={hoverRecord}
                        onFieldMoveStart={index => this.props.setLegendDragFrom(index)}
                        onFieldMoveCancel={() => this.props.setLegendDragFrom(-1)}
                        onFieldMoved={this.handleFieldMove}
                        droppable={[-1, index].indexOf(this.props.legendDragFrom) === -1}
                        onFieldRemove={field => this.props.onFieldRemove(field)}
                        onFieldToggle={this.handleFieldToggle}
                        onFieldHover={this.handleFieldHover}
                        selectedGraphPanel={this.props.selectedGraphPanel}
            />
          ))}

          {this.props.selectedGraphPanel==-1 && panels.titles.map((title, index) => (
            <div key={index} className="panel-container"
                style={{top: ((90 / panels.titles.length) * index) + '%'}}>
                  <div className="panel-title">{title}</div>
            </div>
          ))}
        </div>
    </div>
    ) : (<div className="busy-indicator">{this.props.busyMessage || "Loading..."}</div>);
  }
});
//{this.getMinMaxText(index)}</button>   