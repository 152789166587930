/* global console */
import React from "react";
import ReactClass from "create-react-class";

import Util from "../../components/util";

import DialogService from "../../components/widgets/DialogService";
import Service from "./Service";

export default ReactClass({
  displayName: "LogSelector",
  getInitialState() {
    return {
      panelShowing: false,
      boundaries: [],
      selectedLogIndex: -1
    };
  },

  componentWillReceiveProps({boundaries}) {
    if (boundaries) {
      boundaries = boundaries.map(b => {
        return Object.assign({
          startTimeStr: Util.formatDate(b.startTime, "yyyy-MM-dd HH:mm:ss"),
          endTimeStr: Util.formatDate(b.endTime, "yyyy-MM-dd HH:mm:ss")
        }, b);
      });
      this.setState({boundaries});
    }
  },

  togglePanel() {
    const newState = !this.state.panelShowing;
    this.setState({panelShowing: newState});

    if (newState === true) {
      DialogService.makeDialog(this.panel, this.button, () => {
        this.setState({panelShowing: false});
      });
    }
  },

  handleLogSelection(logEntry, index, bToggle = true) {
    this.setState({
      selectedLogIndex: index
    });
    this.props.onSelectionChange({logEntry});
    if (bToggle) {
      this.togglePanel();
    }
  },

  render: function() {
    return (
      <div className="dropdown-dialog-container">
        <button className="inline primary header-action dropdown-dialog-button"
                ref={x => this.button = x}
                disabled={this.props.disabled}
                onClick={this.togglePanel}>
          Select Log&#160;<i className="icon-chevron-down" />
        </button>
        <section ref={x => this.panel = x}
            className={"dropdown-dialog logentry-selector-panel " +
                (this.state.panelShowing ? "in" : "")}>
          <ul className="logentry-list">
            {this.state.boundaries.map((logEntry, idx) => (
              <li key={idx} onClick={() => this.handleLogSelection(logEntry, idx)}
                  className={this.state.selectedLogIndex === idx ? "selected" : ""}>
                <i className="icon-check" />
                <div className="space-between">
                  <span>{logEntry.startTimeStr}</span>
                  <span>-</span>
                  <span>{logEntry.endTimeStr}</span>
                </div>
              </li>
            ))}
          </ul>
        </section>
      </div>
    );
  }
});
