/* global console setTimeout */
import React from "react";
import ReactClass from "create-react-class";

export default ReactClass({
  displayName: "OvenCookingModes",
  showData(i) {
    const {onSelectMode} = this.props;
    onSelectMode(i);
  },
  render: function() {
    const {widgets, selectedMode} = this.props,
        {Events} = widgets,
        rows = Events.map((event, i) => {
          const classs = (selectedMode === i
            ? "selected"
            : "");
          return (
            <tr className={classs} key={i} onClick={this.showData.bind(this, i)}>
              <td>{i + 1}</td>
              <td>{event.Mode}</td>
            </tr>
          );
        });
    return (
      <div className="oven-cooking-modes col_3">
        <table>
          <thead>
            <tr>
              <td></td>
              <td>Cooking Mode</td>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }
});

