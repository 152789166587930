import ReactClass from "create-react-class";
import React from "react";
import {buildURI, toCSV} from "./service";
import {defaultProps, propTypes} from "./metaProps";

const CSVLink = ReactClass({
  displayName: "CSVLink",
  propTypes: propTypes,

  getDefaultProps() {
    return defaultProps;
  },
  buildURI() {
    return buildURI(...arguments);
  },

/**
   * In IE11 this method will trigger the file download
   */
  handleLegacy(evt, data, headers, separator, filename) {
    // If this browser is IE 11, it does not support the `download` attribute
    if (window.navigator.msSaveOrOpenBlob) {
      // Stop the click propagation
      evt.preventDefault()

      let blob = new Blob([toCSV(data, headers, separator)])
      window.navigator.msSaveBlob(blob, filename)

      return false
    }
  },
  render() {
    const {data, headers, separator, filename, uFEFF, children, type, disabled, className, flag, csvHeaderDisplayName} = this.props;

    return (
      <a download={filename}
        type={type}
        disabled={disabled}
        className={className}
        ref={link => (this.link = link)}
        href={this.buildURI(data, uFEFF, headers, separator, flag, csvHeaderDisplayName)}
        onClick={evt => this.handleLegacy(evt, data, headers, separator, filename)}>
        {children}
      </a>
    );
  }
});

export default CSVLink;
