/* global console setTimeout */
import React from "react";
import ReactClass from "create-react-class";

export default ReactClass({
  displayName: "OvenCounts",
  render: function() {
    const {Counts} = this.props,
        rows = Object.keys(Counts).map((count, i) => {
          return (
            <tr key={i}>
              <td>{count}</td>
              <td>{Counts[count]}</td>
            </tr>
          );
        });
    return (
      <div className="oven-counts col_4">
        <table>
          <thead>
            <tr>
              <td>Mode</td>
              <td>Time Used</td>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }
});

