/* global setTimeout clearTimeout console */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";

export default ReactClass({
  displayName: "Progressbar",
  propTypes: {
    value: PropTypes.number,
    title: PropTypes.string
  },

  calcValue(valInPercentage) {
    let val = Number(valInPercentage);
    if(isNaN(val) || val < 0) {
      val = 0;
    }
    if(val > 100) {
      val = 100;
    }
    return val;
  },

  render() {
    const style = {
          width: this.calcValue(this.props.value) + "%"
        },
        {title = style.width} = this.props;
    return (
      <div className="progress">
        <div className="value" title={title} style={style}></div>
      </div>
    );
  }
});
