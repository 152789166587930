/* global console */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import Status from "./Component";

const {Header, Content} = WidgetPanel;

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "StatusWidget",
  propTypes: {
    valueKey: PropTypes.string.isRequired
  },
  getInitialState() {
    return {
      data: {}
    };
  },
  render() {
    const {title, icon, valueKey} = this.props,
        {data} = this.state,
        value = data[valueKey] || 0;
    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{title}</span>
        </Header>
        <Content>
          <Status icon={icon} value={value} />
        </Content>
      </WidgetPanel>
    );
  },
  onData(data, channel) {
    console.log("Status", data);
  }
}));

const Info = {
  id: "appliance-dashboard:status",
  name: "Status",
  type: Widget,
  description: "Shows some status",
  icon: "",
  tags: "appliance"
};

export default {
  Info,
  Widget
};
