/* global appInsights*/
import Config from "./config";

const AI = {
  init() {
    var appInsights = window.appInsights || function (a) {
      function b(a) {c[a] = function () {var b = arguments; c.queue.push(function () {c[a].apply(c, b)})}} var c = {config: a}, d = document, e = window; setTimeout(function () {var b = d.createElement("script"); b.src = a.url || "https://az416426.vo.msecnd.net/scripts/a/ai.0.js", d.getElementsByTagName("script")[0].parentNode.appendChild(b)}); try {c.cookie = d.cookie} catch (a) {} c.queue = []; for (var f = ["Event", "Exception", "Metric", "PageView", "Trace", "Dependency"]; f.length;)b("track" + f.pop()); if (b("setAuthenticatedUserContext"), b("clearAuthenticatedUserContext"), b("startTrackEvent"), b("stopTrackEvent"), b("startTrackPage"), b("stopTrackPage"), b("flush"), !a.disableExceptionTracking) {f = "onerror", b("_" + f); var g = e[f]; e[f] = function (a, b, d, e, h) {var i = g && g(a, b, d, e, h); return !0 !== i && c["_" + f](a, b, d, e, h), i}} return c
    }(Config.applicationInsight);
    window.appInsights = appInsights, appInsights.queue && 0 === appInsights.queue.length && appInsights.trackPageView();
  },
  trackRoutes(pageName = "", props = {}) {
    appInsights.trackPageView(pageName, window.location, props);
  },
  setAppContext (properties) {
    appInsights.queue.push(function () {
      appInsights.context.addTelemetryInitializer(function (envelope) {
        let telemetryItem = envelope.data.baseData;

        // To set custom properties:
        telemetryItem.properties = telemetryItem.properties || {};
        for (const key in properties) {
          telemetryItem.properties[key] = properties[key];
        }
      });
    });
  },
  trackComponent(componentName, props = {}) {
    const properties = Object.assign({}, {"Component Name": componentName}, props);
    this.trackMetric("React Component",
        null,
        1,
        null,
        null,
        properties);
  },
  trackMetric(name, average = null, sampleCount = 1, min = null, max = null, properties = {}) {
    appInsights.trackMetric(
      name,
      average,
      sampleCount,
      min,
      max,
      properties
      );
  },
  trackEvent(name, properties = {}, measurements = {}) {
    appInsights.trackEvent(
      name,
      properties,
      measurements
      );
  },
  trackUserLoginEvent(name, properties = {}) {
    appInsights.trackEvent(
      name,
      properties
      );
  },
  ai() {
    return appInsights;
  } 
};

export default AI;
