import React from "react";
import deepAssign from "assign-deep";
import ReactClass from "create-react-class";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import Config from "../../../config";

const deafultItems = [{
  label: "Status",
  value: "N/A"
}];
const Widget = AsSubscribedComponent(ReactClass({
  displayName: "AppliancePartStatusWidget",
  getInitialState: function() {
    return {
      data: {
        items: []
      }
    };
  },

  render: function() {
    const {Header, Content} = WidgetPanel,
        {icon, filterOn} = this.props,
        {data} = this.state,
        iconPath = `${Config.baseDir}components/dashboard-widgets/appliance-part-status/${icon}`;
    let items = data[filterOn] || [];
    if(!items.length) {
      items = deafultItems;
    }

    const table = items.map((itm, i) => {
      return (
        <li key={"item_" + i}>
          <span className="label">{itm.label}</span>
          <span className="value">{itm.value}</span>
        </li>
      );
    });

    return (
      <WidgetPanel {...this.props}>
        <Header>{this.props.title}</Header>
        <Content>
          <div className="appliance-status">
            <img className="icon" src={iconPath} alt="Icon" />
            <ul className="list">
              {table}
            </ul>
          </div>
        </Content>
      </WidgetPanel>
    );
  },

  onData: function(data, channel) {
    this.setState({
      channel: channel,
      data: data
    });
  }
}));

const Info = {
  id: "appliance-dashboard:part-status",
  name: "External Dispensor Widget",
  type: Widget,
  description: "Show external dispensor status",
  icon: "",
  tags: "appliance refrigerator"
};

export default {
  Info,
  Widget
};
