import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import Config from "../../../config";
import * as _ from "lodash";

const versionKeyMapping = {
  "uim_slave": "UIM Secondary"
};

const AppliancePanel = ReactClass({
  displayName: "AppliancePanel",
  render() {
    let swError = false;
    const {
        model,
        serial,
        type,
        controlBoardModel,
        versions = [],
        connectedStatus = "",
        deviceId,
        productFamily
      } = this.props.appliance,
        imgPath = this.props.imagePath,
        softwareVersionInfo = this.props.softwareVersionInfo,
        userDefinedDeviceName = this.props.userDefinedDeviceName,
        imgBasePath = `${Config.baseDir}components/dashboard-widgets/connectivity/images`,
        softwareVersionUpdateLink = `mailto:${Config.snowTicketTargetEmail}?subject=Appliance Software Update
            &body=Issue: Please update the appliance software.
            %0D%0AMAC Address: ${deviceId}`,
        status = !connectedStatus
            ? "N/A" 
            :connectedStatus === "UNAVAILABLE" ? connectedStatus.toLowerCase() : `Connected ${connectedStatus.toLowerCase()}`,
        versionsRows = versions
          ? Object.keys(versions).map(key => {
            const softwareVersionStatus = (softwareVersionInfo[key] && softwareVersionInfo[key].isBadSoftwareVersion)
                ? "softwareVersion-status" : "";
            if (softwareVersionInfo[key] && softwareVersionInfo[key].isBadSoftwareVersion) {
              swError = true
            }
            const label = versionKeyMapping[key] || key;
            return (
              <div key={key}>
                <span>{label}</span>
                <label className={softwareVersionStatus} id={key}>{versions[key] || "N/A"}</label>
              </div>
            );
          })
        : [],
        connStatusCondition = connectedStatus === "CAPABLE";
    return (
      <div className="appliance-info-panel">
        <div className="appliance-details">
          <div>
            <span>Connected Status </span>
            <label id="connected-status" className="status">{status}</label>
          </div>
          <div>
            <span>Name </span>
            <label id="user-defined-name">{userDefinedDeviceName || "N/A"}</label>
          </div>
          <div>
            <span>Model Group </span>
            <label>{model || "N/A"}</label>
          </div>
          <div>
            <span>Control Board Model </span>
            <label>{controlBoardModel || "N/A"}</label>
          </div>
          <div>
            <span>Serial No </span>
            <label>{serial || "N/A"}</label>
          </div>
          {(connStatusCondition
            ? null
            : <div>
                <span>Type </span>
                <label>{type || "N/A"}</label>
              </div>)}
          {(connStatusCondition
            ? null
            : versionsRows.length
              ? (<div className="versions-section">
                <span className="versions-heading">Software versions </span>
                {
                  swError
                    ? <a href={softwareVersionUpdateLink} target="_blank"
                      title={`Software out of date. Click to \nsubmit update request.`}>
                      <img src={`${imgBasePath}/frmwr_updt.svg`} id="software_version"/>
                    </a>
                    : null

                }
                    <div className="versions">
                      {versionsRows}
                    </div>
                    <a target="_blank" href={`${Config.resourcesLink.aaLink}#q=software%20version&numberOfResults=20&f:@productfamily=[${encodeURIComponent(productFamily)}]`}>
                      Software versions
                    </a>
                </div>)
              : (
                  <div className="no-versions">
                    <span>Software versions</span>
                    <label>N/A</label>
                  </div>
              ))}
              {
                swError
                  ? <div className="aalink">
                      <a href={`${Config.answerAdvisorLink}/multi-brand/connected-appliance-portal-bad-software-version`}
                          target="_blank" id="swVersion-aa">
                        Help on bad software version
                      </a>
                    </div>
                  : null
              }
        </div>
        <div className="appliance-img">
          <img src={imgPath} alt={model} />
        </div>
      </div>
    );
  }
});

AppliancePanel.propTypes = {
  appliance: PropTypes.shape({
    model: PropTypes.string,
    serial: PropTypes.string,
    imgPath: PropTypes.string
  })
};

export default AppliancePanel;
