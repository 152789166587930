/* global setTimeout clearTimeout console */
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";

const Tab = ReactClass({
  displayName: "Tab",
  propTypes: {
    name: PropTypes.string
  },
  render() {
    return <div className="tab-panel">{this.props.children}</div>
  }
});



const Tabs = ReactClass({
  displayName: "Tabs",
  propTypes: {
    className: PropTypes.string,
    onSelectionChanged: PropTypes.func,
    activeTab: PropTypes.number,
    children(props, propName) {
      const children = props[propName];
      React.Children.forEach(children, function(child) {
        // console.log(child);
        if(child.type !== Tab) {
          throw new Error("Expected 'Tab'" + " but found " + 
              (child.type.displayName || child.type));
        }
      });
      return null;
    }
  },

  getInitialState() {
    return {
      activeTab: this.props.activeTab || 0
    };
  },

  selectTab(index) {
    let idx = Number(index), previousIndex = this.state.activeTab;
    idx = isNaN(idx) ? 0 : idx;
    this.setState({activeTab: idx});
    const {onSelectionChanged} = this.props;
    if(typeof onSelectionChanged === "function") {
      onSelectionChanged(index, previousIndex);
    }
  },

  renderStrip() {
    const self = this;
    let items,
      children = self.props.children.filter((child) => child != null);
    items = children.map(function(tab, i) {
      const classNames = i === self.state.activeTab ? "tab-item selected" : "tab-item",
          icon = tab.props.iconClassName
              ? <i className={tab.props.iconClassName}></i>
              : null;
      return (
        <li className={classNames} key={"TabItem_" + i}>
          {icon}
          <a onClick={self.selectTab.bind(self, i)}>
            {tab.props.title}
          </a>
        </li>
      );
    });

    return (
      <ul className={"tabs-nav"}>
        {items}
      </ul>
    );
  },

  renderContent() {
    const idx = this.state.activeTab || 0;
    return this.props.children[idx];
  },

  render() {
    const clazz = this.props.className || "";
    return (
      <div className={"tabs " + clazz}>
        {this.renderStrip()}
        <div className="tabs-content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
});
Tabs.Tab = Tab;

export default Tabs;
