import RefrigeratorFactory from "./refrigerator";
import OvenFactory from "./oven";
import DishwasherFactory from "./dishwasher";
import GenericFactory from "./generic";
import DualFuelFactory from "./dual-fuel";
import ConfigProvider from "../../common/ConfigProvider";
// console.log(RefrigeratorFactory);

export const Factories = {
  getFactory: function getFactory(type){
    const typePrefix = type && type.substring(0,2) === "17" ? /^(\d\d\.\d{1,2}\.)/.exec(type) :/^(\d\.\d{1,2}\.)/.exec(type);
    let dashboardFactory = GenericFactory;
    if(typePrefix && typePrefix.length>0){
      const applianceType = typePrefix[1];
      const deviceGenericName = (ConfigProvider.getApplianceTypeConfig()[applianceType] || "GENERIC DEVICE").toUpperCase();
      if(deviceGenericName.includes('REFRIGERATOR')){
        dashboardFactory = RefrigeratorFactory;
      }
      else if(deviceGenericName.includes('OVEN')){
        dashboardFactory = OvenFactory;
      }
      else if(deviceGenericName.includes('DISHWASHER')){
        dashboardFactory = DishwasherFactory;
      }
      else if(deviceGenericName.includes('DUAL FUEL')){
        dashboardFactory = DualFuelFactory;
      }
      else{
        dashboardFactory = GenericFactory;
      }
    }
     return dashboardFactory;
  }
};
