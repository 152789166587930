/* global fetch console Promise */
import Config from "../../config";
import Util from "../../components/util";
import ApiClient from "../../components/api-client";
import * as _ from "lodash";
import ConfigProvider from "../common/ConfigProvider";

const {instance: Api, asQueryParameters, asJson: resAsJson} = ApiClient;



const getGenericName = (type = "") => {
  let typePrefix = type && type.substring(0,2) === "17" ? /^(\d\d\.\d{1,2}\.)/.exec(type) :/^(\d\.\d{1,2}\.)/.exec(type),
      deviceGenericName;
  if(!typePrefix) {
    deviceGenericName = "GENERIC DEVICE";
  }else {
    deviceGenericName = ConfigProvider.getApplianceTypeConfig()[typePrefix[1]] || "GENERIC DEVICE";
    //deviceGenericName = applianceTypes[typePrefix[1]] || ("GENERIC DEVICE");
  }
  return deviceGenericName;
};

const getApplianceFamily = (type)=>{
  if(!type){
    return "Unknown";//0
  }
  let typeComponents = type.split(".");
  if(typeComponents  && typeComponents.length>=2){
    return ConfigProvider.getApplianceFamilyConfig()[typeComponents[1]];
  }
  return "Unknown";//0
}


let currentApplianceInfos = [],
    activeAppliance = null,
    cacheSelectedCatProjectName = null,
    scrollTop = 0;

const ApplianceService = {
  getCurrentApplianceInfos() {
    return currentApplianceInfos;
  },
  getSelectedCatProjectName() {
    return cacheSelectedCatProjectName;
  },
  getScrollTopOfApplianceList() {
    return scrollTop;
  },
  setScrollTopOfApplianceList(top) {
    scrollTop = top;
  },
  getActiveAppliance() {
    return activeAppliance;
  },
  setActiveAppliance(appliance) {
    activeAppliance = appliance;
  },
  setCurrentApplianceInfos(appliances, catProjectName = null) {
    if(appliances.length > 0)
      currentApplianceInfos = appliances;

    cacheSelectedCatProjectName = catProjectName;
  },
  clearApplianceInfos() {
    currentApplianceInfos = [];
    cacheSelectedCatProjectName = null;
  },
  getGenericName: getGenericName,
  getApplianceFamily: getApplianceFamily,
  getApplianceInfo(params, requiredPromObj) {
    const {userId, emailId, phoneNo, serialNo, deviceId, bda, projectName} = params;
    let promise;
    if(projectName && projectName.length) {
      const queryParams = asQueryParameters({projectName: projectName});
      promise = this.getAppliances(queryParams, requiredPromObj);
    } else if(serialNo && serialNo.length) {
      const queryParams = asQueryParameters({serial: serialNo});
      promise = this.getAppliances(queryParams, requiredPromObj);
    }else if(deviceId && deviceId.length) {
      const queryParams = asQueryParameters({deviceId: deviceId});
      promise = this.getAppliances(queryParams, requiredPromObj);
    }else if(bda) {
      const queryParams = asQueryParameters({bda: bda});
      promise = this.getAppliances(queryParams, requiredPromObj);
    }else if(userId) {
      const queryParams = asQueryParameters({userId: userId});
      promise = this.getAppliances(queryParams, requiredPromObj);
    }else if(emailId) {
      const queryParams = asQueryParameters({email: emailId});
      promise = this.getAppliances(queryParams, requiredPromObj);
    }else if(phoneNo) {
      const queryParams = asQueryParameters({phone: phoneNo});
      promise = this.getAppliances(queryParams, requiredPromObj);
    }else {
      promise.reject("None of these User Id, Email, Phone No, Serial No, Device Id or BDA was found in params");
    }
    if(promise) {
      // promise.then(infos => {
      //   this.setCurrentApplianceInfos(infos);
      // });
      return promise;
    }
  },
  getAppliances(queryParams, requiredPromObj) {
    if(requiredPromObj) {
      return Api.get(`/appliances/info?${queryParams}`);
    }
    return Api.get(`/appliances/info?${queryParams}`).then(this.modifyAppliances);
  },
  getApplianceInfoFromDeviceId(applianceIds) {
    const queryParams = asQueryParameters({deviceId: applianceIds});

    return this.getAppliances(queryParams);
  },
  getApplianceInfoFromSerialNo(serialNo) {
    const queryParams = asQueryParameters({serial: serialNo});

    return this.getAppliances(queryParams);
  },

  getAppliancesOfUser(deviceUserIds) {
    if(deviceUserIds.length==0){
      return Promise.resolve([]);
    }
    const userIds = deviceUserIds.join(',');
    const queryParams = asQueryParameters({userIds: userIds});
    return Api.get(`/appliances/multiuserdevices?${queryParams}`).then(this.modifyAppliances);
  },

  getDeviceUserIds(deviceId) {
    const queryParams = asQueryParameters({deviceId: deviceId});
    return Api.get(`/appliances/deviceUserIds?${queryParams}`).then(resAsJson);
  },

  modifyAppliances(res) {
    return resAsJson(res)
    .then((appliances = []) => {
      return appliances.map(a=> {
        a.name = ApplianceService.getGenericName(a.type);
        a.productFamily = ApplianceService.getApplianceFamily(a.type)
        return a;
      })
    }).catch(err => {
      throw err;
    });
  }
};



export default ApplianceService;
