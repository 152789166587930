/* global Promise setTimeout */
import ApiClient from "./components/api-client";
import Config from "./config";

const {instance: Api, anonInstance: AnonApi, asJson: resAsJson} = ApiClient,
    AnonApiServer = ApiClient.create({
      apiUrl: Config.apiUrl.replace(/\//g, "") // To run in local comment this line and uncomment below line
      // apiUrl: Config.apiServerUrl + Config.apiUrl
    });
export default {
  getConfig() {
    return AnonApiServer.get(`/serverconfig`).then(res => {
      if(res.status >= 200 && res.status < 400) {
        return res.json();
      }else {
        return Promise.reject(res);
      }
    });
  },
  recordLogin(token) {
    return AnonApi.post("/users/loginAttempt", {
      body: JSON.stringify({"token": token})
    }).then(res => {
      console.log("Result : ", res);
    });
  },
  getCatProjectList() {
    return Api.get("/projects").then(resAsJson);
  },

  fetchApplianceTypeAndFamilyConfig(){
    return Api.get(`/config/Matrix?name=TypeAndFamily`).then(resAsJson);
  }
};
