/* global Promise setTimeout */
// import ApiClient from "../../components/api-client";
import logService from "../view-logs/Service";
// const {instance: Api, asJson: resAsJson} = ApiClient;

export default {
  fetchUnitStatistics() {
    const data = logService.getLogData();
    if(!data) {
      return Promise.reject(true);
    }
    return Promise.resolve(data);
  }
};

