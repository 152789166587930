"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Info = exports.Widget = exports.Component = undefined;

var _Component = require("./Component");

var _Component2 = _interopRequireDefault(_Component);

var _Widget = require("./Widget");

var _Widget2 = _interopRequireDefault(_Widget);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Info = {
  name: "Gauge",
  type: _Widget2.default,
  description: "A simple gauge",
  icon: "",
  tags: "gauge, meter"
};

exports.Component = _Component2.default;
exports.Widget = _Widget2.default;
exports.Info = Info;