/* global setTimeout clearTimeout */
import ReactClass from "create-react-class";
import Slider from "rc-slider";
import React from "react";
import {Tabs} from "../../components/widgets";
import AnswerAdviser from "../answer-adviser";
import DiagnosticStatus from "./diagnostic-status";
import Config from "../../config";
import Util from "../../components/util";


const propNameMapping = {
  "WIFI_CONN": {name: "WiFi Connected", shortName: "WiFi"},
  "WIFI_DHCP": {name: "DHCP Lease", shortName: "DHCP"},
  "WIFI_PASS": {name: "WiFi Password", shortName: "WiFi Pass"},
  "WIFI_PROV": {name: "WiFi Configured", shortName: "WiFi Prov"},
  "TYPE_DETECTION": {name: "Appliance Type", shortName: "Type"},
  "BLUETOOTH": {name: "Bluetooth", shortName: "BT"},
  "SUPPORT_SVC_CONN": {name: "CAE BE Online", shortName: "CAE BE", secondaryName: "NTP Server Sync", secondaryShortName: "NTP Server", applianceType: "17"},
  "REMOTE_SVC_CONN": {name: "Ayla BE Online", shortName: "Ayla BE"},
  "REMOTE_SVC_PROPS": {name: "Ayla Props Reachable", shortName: "Ayla Props"},
  "REMOTE_TIME_SYNC": {name: "Time Sync", shortName: "Time Sync"},
  "APPLIANCE_CONN": {name: "Appliance Connected", shortName: "Found"},
  "WAN_ACCESS": {name: "WAN Reachable", shortName: "WAN"},
  "AZURE_ENABLED": {name: "Azure Enabled", shortName: "Azure"},
  "unknown": {name: "Unknown diagnostic property", shortName: "Unknown"}
};
const propsOrder = [
  "WAN_ACCESS", "WIFI_DHCP", "WIFI_CONN", "WIFI_PASS", "WIFI_PROV", "TYPE_DETECTION",
  "APPLIANCE_CONN", "BLUETOOTH", "SUPPORT_SVC_CONN", "REMOTE_SVC_CONN", "REMOTE_TIME_SYNC",
  "REMOTE_SVC_PROPS", "AZURE_ENABLED"
];

export const omitKeys = ['REMOTE_SVC_CONN', 'REMOTE_SVC_PROPS'];

const DiagnosticsReport = props => {
  const {appliance, diagnostics: {latest: {records}= {}} = {}} = props,
      rows = Object.keys(records || {})
      .sort((a, b) => {
        return a < b ? -1 : 1;
      })
      .map( (k, i) => {
        const val = records[k],
            clazz = val;
        return (
          <div key={"item_" + i}>
            {
              omitKeys.indexOf(k) === -1
              && (<li className="item">
                <span className="name">{propNameMapping[k].applianceType && (appliance.type.substring(0,2) ===  propNameMapping[k].applianceType) 
                    ? propNameMapping[k].secondaryName && propNameMapping[k].secondaryName || k
                    : propNameMapping[k].name || k }</span>
                <span className={"status _pull-right " + clazz}>{val}</span>
              </li>)
            }
          </div>

        );
      });

  return (
    <ul className="list current-diagnostics">
      {rows}
    </ul>
  );
};
DiagnosticsReport.displayName = "DiagnosticReport";



const DiagnosticHistory = props => {
  const {appliance} = props;
  const time = new Date().getTime(),
      {
        diagnostics: {
          history: {
            records = 0,
            startDate,
            endDate,
            startTimeMs,
            endTimeMs,
            items = []} = {}
        } = {}
      } = props,
      sortedItems = items.sort((a, b) => {
        let {BAD: aBad = 0, UNKNOWN: aUnknown = 0} = a.status,
            {GOOD: bGood = 0, BAD: bBad = 0, UNKNOWN: bUnknown = 0} = b.status;
        return bBad - aBad;
      }),
      rows = sortedItems.map((h, i) => {
        const status = h.status, name = h.name;
        return (
          <div>
            {
              omitKeys.indexOf(h.name) === -1
              && (<li key={h.name + "_" + i}>
                {/* <span className="name">{propNameMapping[name].name || name}</span> */}
                <span className="name">{propNameMapping[name].applianceType && (appliance.type.substring(0,2) ===  propNameMapping[name].applianceType) 
                    ? propNameMapping[name].secondaryName && propNameMapping[name].secondaryName || name
                    : propNameMapping[name].name || name
                }</span>
                <DiagnosticStatus name={name} good={status.GOOD} bad={status.BAD}
                  unknown={status.UNKNOWN} />
              </li>)
            }
          </div>

        );
      });

  return (
    <div>
      <div className="history-range">
        <span className="records">Last {records} Records </span>
        {/* <span>{startDate} UTC to {endDate} UTC</span> */}
        <span>
          {Util.formatDate(new Date(startTimeMs), "yyyy-MM-dd HH:mm:ss z", false)} to {Util.formatDate(new Date(endTimeMs), "yyyy-MM-dd HH:mm:ss z", false)}
          </span>
      </div>
      <ul className="list diagnostics-history">
        {rows}
      </ul>
    </div>
  );
};
DiagnosticHistory.displayName = "DiagnosticHistory";



const DiagnosticRecord = props => {
  const {record: {timestamp, date, records, consumerAppLog}, appliance} = props,
      columns = propsOrder.map((propName, i) => {
        return (
          <div>
            {
              omitKeys.indexOf(propName) === -1
              && (<span key={propName + i} className={records[propName] || "UNKNOWN"}>
                {/* {propNameMapping[propName].shortName} */}
                {propNameMapping[propName].applianceType && (appliance.type.substring(0,2) ===  propNameMapping[propName].applianceType) 
                    ? propNameMapping[propName].secondaryShortName && propNameMapping[propName].secondaryShortName || propName
                    : propNameMapping[propName].shortName || propName}
              </span>)
            }
          </div>

        );
      }),
      icon = consumerAppLog
          ? <i className="icon icon-mobile_icon"></i>
          : null;

  return (
    <div className="diagnostic-record">
      <a href={`${Config.answerAdvisorLink}/multi-brand/service-connected-diagnostics-check-points-good`}
        target="_blank">
        {/* <div className="timestamp">{icon}<label>{date}</label></div> */}
        <div className="timestamp">{icon}<label>{Util.formatDate(new Date(timestamp), "yyyy-MM-dd HH:mm:ss", false)}</label></div>
        <div className="records">{columns}</div>
      </a>
    </div>
  );
};
DiagnosticRecord.displayName = "DiagnosticRecord";



const Range = Slider.createSliderWithTooltip(Slider.Range);
const debounce = (callback, timeout) => {
  let timerId;
  return (...args) => {
    if(timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};
const DiagnosticHistoryRecords = ReactClass({
  displayName: "DiagnosticHistoryRecords",
  getInitialState() {
    return {
      rangeMin: 1
    };
  },

  filterRecordsByRange(appliance) {
    const {diagnostics: {history: {details = []} = {}} = {}} = this.props,
        maxIndex = details.length;
    let {rangeMin, rangeMax = maxIndex} = this.state;
    if(rangeMax > maxIndex) {
      rangeMax = maxIndex;
    }
    return details.slice(rangeMin - 1, rangeMax).map((detail, i) => {
      return (
        <DiagnosticRecord record={detail} key={detail.date + i || ("record_" + i)} appliance={appliance} />
      );
    });
  },

  setRange([min, max]) {
    this.setState({rangeMin: min, rangeMax: max});
  },

  render() {
    const {diagnostics: {history: {details = []} = {}, appliance} = {}} = this.props,
        recordLength = details.length,
        {rangeMin, rangeMax = recordLength} = this.state,
        rows = this.filterRecordsByRange(appliance),
        formatter = val => {
          const rec = details[val - 1];
          return rec ? Util.formatDate(new Date(rec.timestamp), "yyyy-MM-dd HH:mm:ss:ms", false) : "";
        };

    return (
      <div className="diagnostic-records">
        <div className="filter-container">
          <div className="filter date">
            <label>
              <span>Choose a range: </span>
              {/* <span className="date-label">{details[rangeMin - 1].date} UTC</span>
              to
              <span className="date-label">{details[rangeMax - 1].date} UTC</span> */}
              <span className="date-label">{Util.formatDate(new Date(details[rangeMin - 1].timestamp), "yyyy-MM-dd HH:mm:ss z", false)}</span>
              to
              <span className="date-label">{Util.formatDate(new Date(details[rangeMax - 1].timestamp), "yyyy-MM-dd HH:mm:ss z", false)}</span>
            </label>
            <Range min={1} max={recordLength}
                allowCross={true}
                defaultValue={[1, 100]}
                tipProps={{placement: "bottom"}}
                onAfterChange={this.setRange}
                tipFormatter={formatter} />
          </div>
        </div>
        <div className="rows">
              {rows}
        </div>
      </div>
    );
  }
});



export default ReactClass({
  displayName: "DiagnosticsPanel",
  render() {
    const {diagnostics: {latest: {consumerAppLog}}} = this.props,
        icnClass = consumerAppLog ? "icon icon-mobile_icon" : "";
    return (
      <section className="alt-bg side-panel">
        <Tabs activeTab={0}>
          <Tabs.Tab title="Latest Diagnostics" iconClassName={icnClass}>
            <DiagnosticsReport {...this.props} />
          </Tabs.Tab>
          <Tabs.Tab title="History">
            <DiagnosticHistory {...this.props} />
          </Tabs.Tab>
          <Tabs.Tab title="Records">
            <DiagnosticHistoryRecords {...this.props} />
          </Tabs.Tab>
        </Tabs>
      </section>
    );
  }
});
