import ConfigProvider from "../common/ConfigProvider";

export default {
  getType: function getType(type){
    const typePrefix = type && type.substring(0,2) === "17" ? /^(\d\d\.\d{1,2}\.)/.exec(type) :/^(\d\.\d{1,2}\.)/.exec(type);
    let applianceTypeName = 'generic';
    if(typePrefix && typePrefix.length>0){
      const applianceType = typePrefix[1];
      const deviceGenericName = (ConfigProvider.getApplianceTypeConfig()[applianceType] || "GENERIC DEVICE").toUpperCase();
      if(deviceGenericName.includes('REFRIGERATOR WINE COOLER')){
        applianceTypeName = 'wine-cooler';
      }
      else if(deviceGenericName.includes('REFRIGERATOR')){
        applianceTypeName = 'fridge';
      }
      else if(deviceGenericName.includes('OVEN')){
        applianceTypeName = 'oven';
      }
      else if(deviceGenericName.includes('DISHWASHER')){
        applianceTypeName = 'dishwasher';
      }
      else if(deviceGenericName.includes('DUAL FUEL')){
        applianceTypeName = 'dual-fuel';
      }
      else{
        applianceTypeName = 'generic';
      }
    }
     return applianceTypeName;
  }
};
