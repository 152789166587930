/* global console */
import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";


const DiagnosticStatus = props => {
  let {name = "Diagnostic", good = 0, bad = 0, unknown = 100 - (good + bad)} = props;
  if (typeof unknown === undefined)
    unknown = (good !== undefined && bad === undefined)
      ? 100 - good
      : 100 - (good + bad);
  bad = (good !== undefined && unknown !== undefined)
    ? 100 - (good + unknown)
    : bad;

  console.log(name, good, bad, unknown);
  const title = `Bad: ${bad}% \nGood: ${good}% \nUnknown: ${unknown}%`;
  return (
    <div className="diagnostic-status" title={title}>
      <div className="status_bad" style={{width: bad + "%"}}></div>
      <div className="status_good" style={{width: good + "%"}}></div>
    </div>
  );
};

DiagnosticStatus.displayName = "DiagnosticStatus";
DiagnosticStatus.propTypes = {
  name: PropTypes.string.isRequired,
  good: PropTypes.number,
  bad: PropTypes.number,
  unknown: PropTypes.number
};
/*
DiagnosticStatus.propTypes = {
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    good: PropTypes.number,
    bad: PropTypes.number,
    unknown: PropTypes.number
  })
};
*/

export default DiagnosticStatus;
