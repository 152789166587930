export default {
  "consumerAppsLogs": [
    {
      "timestamp": 1527876945000,
      "appLogType": "Wifi Provisioning Issue",
      "appInfo": {
        "userId": "1001",
        "version": "1.1.0",
        "phoneModel": "ios_iphone7",
        "osVersion": "1_9.3.3"
      },
      "applianceInfo": {
        "smartGridState": "somevaluehere1",
        "model": "BI-36UFD_S-1",
        "type": "1.1.1.2",
        "firmware": "szwcatfw_4.10",
        "softwareVersions": {
          "main": "91.37.0",
          "conf": "0.1.1",
          "uim": "2.1.0"
        }
      },
      "diagnostics": {
        "WIFI_CONN": "GOOD",
        "WIFI_DHCP": "GOOD",
        "WAN_ACCESS": "GOOD",
        "WIFI_PASS": "GOOD",
        "REMOTE_SVC_PROPS": "GOOD",
        "REMOTE_SVC_CONN": "GOOD",
        "APPLIANCE_CONN": "GOOD",
        "WIFI_PROV": "GOOD",
        "REMOTE_TIME_SYNC": "GOOD",
        "SUPPORT_SVC_CONN": "GOOD",
        "TYPE_DETECTION": "GOOD",
        "BLUETOOTH": "GOOD",
        "AZURE_ENABLED": "GOOD"
      },
      "faults": [
        {
          "epoch": 132713213,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 46,
            "seconds": 53
          },
          "errorCode": {
            "deviceCode": "90",
            "device": "Control Faults",
            "locationCode": "5",
            "location": "Control System",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": false
        },
        {
          "epoch": 13010,
          "time": {
            "days": 0,
            "hours": 3,
            "minutes": 36,
            "seconds": 50
          },
          "errorCode": {
            "deviceCode": "50",
            "device": "Flow Meter Faults",
            "locationCode": "U",
            "location": "Water Systems",
            "failureCode": "87",
            "failure": "No Flow",
            "priority": ""
          },
          "active": true
        },
        {
          "epoch": 132713074,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 44,
            "seconds": 34
          },
          "errorCode": {
            "deviceCode": "30",
            "device": "Ice/Water/Accesory Faults",
            "locationCode": "9",
            "location": "Dispenser",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": true
        }
      ],
      "accessPoints": [
        {
          "ssid": "aj_nurun",
          "encryption": 3,
          "rssi": 70,
          "type": null
        },
        {
          "ssid": "LionGuest",
          "encryption": 0,
          "rssi": 20,
          "type": null
        },
        {
          "ssid": "LionDEV",
          "encryption": 8,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "Cima-Prive",
          "encryption": 8,
          "rssi": 20,
          "type": null
        },
        {
          "ssid": "NurunQA",
          "encryption": 3,
          "rssi": 44,
          "type": "selected"
        },
        {
          "ssid": "Corporatestays803",
          "encryption": 3,
          "rssi": 4,
          "type": null
        }
      ]
    },
    {
      "timestamp": 1525843945000,
      "appLogType": "Wifi Provisioning Issue",
      "appInfo": {
        "userId": "1002",
        "version": "1.2.0",
        "phoneModel": "ios_iphone7",
        "osVersion": "1_9.3.3"
      },
      "applianceInfo": {
        "smartGridState": "somevaluehere2",
        "model": "BI-36UFD_S-2",
        "type": "1.1.1.2",
        "firmware": "szwcatfw_4.10",
        "softwareVersions": {
          "main": "91.37.0",
          "conf": "0.1.1",
          "uim": "2.1.0"
        }
      },
      "diagnostics": {
        "WIFI_CONN": "GOOD",
        "WIFI_DHCP": "GOOD",
        "WAN_ACCESS": "BAD",
        "WIFI_PASS": "GOOD",
        "REMOTE_SVC_PROPS": "GOOD",
        "REMOTE_SVC_CONN": "BAD",
        "APPLIANCE_CONN": "GOOD",
        "WIFI_PROV": "GOOD",
        "REMOTE_TIME_SYNC": "BAD",
        "SUPPORT_SVC_CONN": "GOOD",
        "TYPE_DETECTION": "GOOD",
        "BLUETOOTH": "GOOD",
        "AZURE_ENABLED": "GOOD"
      },
      "faults": [
        {
          "epoch": 132713213,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 46,
            "seconds": 53
          },
          "errorCode": {
            "deviceCode": "90",
            "device": "Control Faults",
            "locationCode": "5",
            "location": "Control System",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": false
        },
        {
          "epoch": 13010,
          "time": {
            "days": 0,
            "hours": 3,
            "minutes": 36,
            "seconds": 50
          },
          "errorCode": {
            "deviceCode": "50",
            "device": "Flow Meter Faults",
            "locationCode": "U",
            "location": "Water Systems",
            "failureCode": "87",
            "failure": "No Flow",
            "priority": ""
          },
          "active": true
        },
        {
          "epoch": 132713074,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 44,
            "seconds": 34
          },
          "errorCode": {
            "deviceCode": "30",
            "device": "Ice/Water/Accesory Faults",
            "locationCode": "9",
            "location": "Dispenser",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": true
        }
      ],
      "accessPoints": [
        {
          "ssid": "aj_nurun1",
          "encryption": 3,
          "rssi": 33,
          "type": null
        },
        {
          "ssid": "LionGuest1",
          "encryption": 0,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "LionDEV1",
          "encryption": 8,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "Cima-Prive1",
          "encryption": 8,
          "rssi": 47,
          "type": null
        },
        {
          "ssid": "NurunQA1",
          "encryption": 3,
          "rssi": 17,
          "type": "selected"
        },
        {
          "ssid": "Corporatestays8031",
          "encryption": 3,
          "rssi": 4,
          "type": null
        }
      ]
    },
    {
      "timestamp": 1527876945000,
      "appLogType": "Pin Pairing Issue",
      "appInfo": {
        "userId": "1003",
        "version": "1.1.3",
        "phoneModel": "ios_iphone73",
        "osVersion": "1_9.3.3"
      },
      "applianceInfo": {
        "smartGridState": "somevaluehere3",
        "model": "BI-36UFD_S-3",
        "type": "1.1.1.2",
        "firmware": "szwcatfw_4.10",
        "softwareVersions": {
          "main": "91.37.0",
          "conf": "0.1.1",
          "uim": "2.1.0"
        }
      },
      "diagnostics": {
        "WIFI_CONN": "GOOD",
        "WIFI_DHCP": "GOOD",
        "WAN_ACCESS": "BAD",
        "WIFI_PASS": "GOOD",
        "REMOTE_SVC_PROPS": "GOOD",
        "REMOTE_SVC_CONN": "GOOD",
        "APPLIANCE_CONN": "UNKNOWN",
        "WIFI_PROV": "BAD",
        "REMOTE_TIME_SYNC": "GOOD",
        "SUPPORT_SVC_CONN": "GOOD",
        "TYPE_DETECTION": "GOOD",
        "BLUETOOTH": "BAD",
        "AZURE_ENABLED": "GOOD"
      },
      "faults": [
        {
          "epoch": 132713213,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 46,
            "seconds": 53
          },
          "errorCode": {
            "deviceCode": "90",
            "device": "Control Faults",
            "locationCode": "5",
            "location": "Control System",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": false
        },
        {
          "epoch": 13010,
          "time": {
            "days": 0,
            "hours": 3,
            "minutes": 36,
            "seconds": 50
          },
          "errorCode": {
            "deviceCode": "50",
            "device": "Flow Meter Faults",
            "locationCode": "U",
            "location": "Water Systems",
            "failureCode": "87",
            "failure": "No Flow",
            "priority": ""
          },
          "active": true
        },
        {
          "epoch": 132713074,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 44,
            "seconds": 34
          },
          "errorCode": {
            "deviceCode": "30",
            "device": "Ice/Water/Accesory Faults",
            "locationCode": "9",
            "location": "Dispenser",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": true
        }
      ],
      "accessPoints": [
        {
          "ssid": "aj_nurun3",
          "encryption": 3,
          "rssi": 70,
          "type": null
        },
        {
          "ssid": "LionGuest3",
          "encryption": 0,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "LionDEV3",
          "encryption": 8,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "Cima-Prive3",
          "encryption": 8,
          "rssi": 47,
          "type": null
        },
        {
          "ssid": "NurunQA3",
          "encryption": 3,
          "rssi": 44,
          "type": "selected"
        },
        {
          "ssid": "Corporatestays8033",
          "encryption": 3,
          "rssi": 4,
          "type": null
        }
      ]
    },
    {
      "timestamp": 1527876945000,
      "appLogType": "Pin Pairing Issue",
      "appInfo": {
        "userId": "1004",
        "version": "1.1.0",
        "phoneModel": "ios_iphone7",
        "osVersion": "1_9.3.3"
      },
      "applianceInfo": {
        "smartGridState": "somevaluehere4",
        "model": "BI-36UFD_S-4",
        "type": "1.1.1.2",
        "firmware": "szwcatfw_4.10",
        "softwareVersions": {
          "main": "91.37.0",
          "conf": "0.1.1",
          "uim": "2.1.0"
        }
      },
      "diagnostics": {
        "WIFI_CONN": "GOOD",
        "WIFI_DHCP": "GOOD",
        "WAN_ACCESS": "GOOD",
        "WIFI_PASS": "GOOD",
        "REMOTE_SVC_PROPS": "GOOD",
        "REMOTE_SVC_CONN": "GOOD",
        "APPLIANCE_CONN": "GOOD",
        "WIFI_PROV": "GOOD",
        "REMOTE_TIME_SYNC": "GOOD",
        "SUPPORT_SVC_CONN": "GOOD",
        "TYPE_DETECTION": "GOOD",
        "BLUETOOTH": "GOOD",
        "AZURE_ENABLED": "GOOD"
      },
      "faults": [
        {
          "epoch": 132713213,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 46,
            "seconds": 53
          },
          "errorCode": {
            "deviceCode": "90",
            "device": "Control Faults",
            "locationCode": "5",
            "location": "Control System",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": false
        },
        {
          "epoch": 13010,
          "time": {
            "days": 0,
            "hours": 3,
            "minutes": 36,
            "seconds": 50
          },
          "errorCode": {
            "deviceCode": "50",
            "device": "Flow Meter Faults",
            "locationCode": "U",
            "location": "Water Systems",
            "failureCode": "87",
            "failure": "No Flow",
            "priority": ""
          },
          "active": true
        },
        {
          "epoch": 132713074,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 44,
            "seconds": 34
          },
          "errorCode": {
            "deviceCode": "30",
            "device": "Ice/Water/Accesory Faults",
            "locationCode": "9",
            "location": "Dispenser",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": true
        }
      ],
      "accessPoints": [
        {
          "ssid": "aj_nurun",
          "encryption": 3,
          "rssi": 65,
          "type": null
        },
        {
          "ssid": "LionGuest",
          "encryption": 0,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "LionDEV",
          "encryption": 8,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "Cima-Prive",
          "encryption": 8,
          "rssi": 47,
          "type": null
        },
        {
          "ssid": "NurunQA",
          "encryption": 3,
          "rssi": 44,
          "type": "selected"
        },
        {
          "ssid": "Corporatestays803",
          "encryption": 3,
          "rssi": 4,
          "type": null
        }
      ]
    },
    {
      "timestamp": 1527876945000,
      "appLogType": "BTLE Scan Discovery",
      "appInfo": {
        "userId": "1005",
        "version": "1.1.0",
        "phoneModel": "ios_iphone7",
        "osVersion": "1_9.3.3"
      },
      "applianceInfo": {
        "smartGridState": "somevaluehere5",
        "model": "BI-36UFD_S-5",
        "type": "1.1.1.2",
        "firmware": "szwcatfw_4.10",
        "softwareVersions": {
          "main": "91.37.0",
          "conf": "0.1.1",
          "uim": "2.1.0"
        }
      },
      "diagnostics": {
        "WIFI_CONN": "GOOD",
        "WIFI_DHCP": "GOOD",
        "WAN_ACCESS": "GOOD",
        "WIFI_PASS": "GOOD",
        "REMOTE_SVC_PROPS": "GOOD",
        "REMOTE_SVC_CONN": "GOOD",
        "APPLIANCE_CONN": "GOOD",
        "WIFI_PROV": "GOOD",
        "REMOTE_TIME_SYNC": "GOOD",
        "SUPPORT_SVC_CONN": "GOOD",
        "TYPE_DETECTION": "GOOD",
        "BLUETOOTH": "GOOD",
        "AZURE_ENABLED": "GOOD"
      },
      "faults": [
        {
          "epoch": 132713213,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 46,
            "seconds": 53
          },
          "errorCode": {
            "deviceCode": "90",
            "device": "Control Faults",
            "locationCode": "5",
            "location": "Control System",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": false
        },
        {
          "epoch": 13010,
          "time": {
            "days": 0,
            "hours": 3,
            "minutes": 36,
            "seconds": 50
          },
          "errorCode": {
            "deviceCode": "50",
            "device": "Flow Meter Faults",
            "locationCode": "U",
            "location": "Water Systems",
            "failureCode": "87",
            "failure": "No Flow",
            "priority": ""
          },
          "active": true
        },
        {
          "epoch": 132713074,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 44,
            "seconds": 34
          },
          "errorCode": {
            "deviceCode": "30",
            "device": "Ice/Water/Accesory Faults",
            "locationCode": "9",
            "location": "Dispenser",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": true
        }
      ],
      "accessPoints": [
        {
          "ssid": "aj_nurun",
          "encryption": 3,
          "rssi": 70,
          "type": null
        },
        {
          "ssid": "LionGuest",
          "encryption": 0,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "LionDEV",
          "encryption": 8,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "Cima-Prive",
          "encryption": 8,
          "rssi": 47,
          "type": null
        },
        {
          "ssid": "NurunQA",
          "encryption": 3,
          "rssi": 44,
          "type": "selected"
        },
        {
          "ssid": "Corporatestays803",
          "encryption": 3,
          "rssi": 4,
          "type": null
        }
      ]
    },
    {
      "timestamp": 1527876945000,
      "appLogType": "BTLE Scan Discovery",
      "appInfo": {
        "userId": "1006",
        "version": "1.1.0",
        "phoneModel": "ios_iphone7",
        "osVersion": "1_9.3.3"
      },
      "applianceInfo": {
        "smartGridState": "somevaluehere6",
        "model": "BI-36UFD_S-6",
        "type": "1.1.1.2",
        "firmware": "szwcatfw_4.10",
        "softwareVersions": {
          "main": "91.37.0",
          "conf": "0.1.1",
          "uim": "2.1.0"
        }
      },
      "diagnostics": {
        "WIFI_CONN": "GOOD",
        "WIFI_DHCP": "GOOD",
        "WAN_ACCESS": "GOOD",
        "WIFI_PASS": "GOOD",
        "REMOTE_SVC_PROPS": "GOOD",
        "REMOTE_SVC_CONN": "GOOD",
        "APPLIANCE_CONN": "GOOD",
        "WIFI_PROV": "GOOD",
        "REMOTE_TIME_SYNC": "GOOD",
        "SUPPORT_SVC_CONN": "GOOD",
        "TYPE_DETECTION": "GOOD",
        "BLUETOOTH": "GOOD",
        "AZURE_ENABLED": "GOOD"
      },
      "faults": [
        {
          "epoch": 132713213,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 46,
            "seconds": 53
          },
          "errorCode": {
            "deviceCode": "90",
            "device": "Control Faults",
            "locationCode": "5",
            "location": "Control System",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": false
        },
        {
          "epoch": 13010,
          "time": {
            "days": 0,
            "hours": 3,
            "minutes": 36,
            "seconds": 50
          },
          "errorCode": {
            "deviceCode": "50",
            "device": "Flow Meter Faults",
            "locationCode": "U",
            "location": "Water Systems",
            "failureCode": "87",
            "failure": "No Flow",
            "priority": ""
          },
          "active": true
        },
        {
          "epoch": 132713074,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 44,
            "seconds": 34
          },
          "errorCode": {
            "deviceCode": "30",
            "device": "Ice/Water/Accesory Faults",
            "locationCode": "9",
            "location": "Dispenser",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": true
        }
      ],
      "accessPoints": [
        {
          "ssid": "aj_nurun",
          "encryption": 3,
          "rssi": 70,
          "type": null
        },
        {
          "ssid": "LionGuest",
          "encryption": 0,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "LionDEV",
          "encryption": 8,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "Cima-Prive",
          "encryption": 8,
          "rssi": 47,
          "type": null
        },
        {
          "ssid": "NurunQA",
          "encryption": 3,
          "rssi": 44,
          "type": "selected"
        },
        {
          "ssid": "Corporatestays803",
          "encryption": 3,
          "rssi": 4,
          "type": null
        }
      ]
    },
    {
      "timestamp": 1527876945000,
      "appLogType": "BTLE Scan Discovery",
      "appInfo": {
        "userId": "1007",
        "version": "1.1.0",
        "phoneModel": "ios_iphone7",
        "osVersion": "1_9.3.3"
      },
      "applianceInfo": {
        "smartGridState": "somevaluehere7",
        "model": "BI-36UFD_S-7",
        "type": "1.1.1.2",
        "firmware": "szwcatfw_4.10",
        "softwareVersions": {
          "main": "91.37.0",
          "conf": "0.1.1",
          "uim": "2.1.0"
        }
      },
      "diagnostics": {
        "WIFI_CONN": "GOOD",
        "WIFI_DHCP": "GOOD",
        "WAN_ACCESS": "GOOD",
        "WIFI_PASS": "GOOD",
        "REMOTE_SVC_PROPS": "GOOD",
        "REMOTE_SVC_CONN": "GOOD",
        "APPLIANCE_CONN": "GOOD",
        "WIFI_PROV": "GOOD",
        "REMOTE_TIME_SYNC": "GOOD",
        "SUPPORT_SVC_CONN": "GOOD",
        "TYPE_DETECTION": "GOOD",
        "BLUETOOTH": "GOOD",
        "AZURE_ENABLED": "GOOD"
      },
      "faults": [
        {
          "epoch": 132713213,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 46,
            "seconds": 53
          },
          "errorCode": {
            "deviceCode": "90",
            "device": "Control Faults",
            "locationCode": "5",
            "location": "Control System",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": false
        },
        {
          "epoch": 13010,
          "time": {
            "days": 0,
            "hours": 3,
            "minutes": 36,
            "seconds": 50
          },
          "errorCode": {
            "deviceCode": "50",
            "device": "Flow Meter Faults",
            "locationCode": "U",
            "location": "Water Systems",
            "failureCode": "87",
            "failure": "No Flow",
            "priority": ""
          },
          "active": true
        },
        {
          "epoch": 132713074,
          "time": {
            "days": 1536,
            "hours": 0,
            "minutes": 44,
            "seconds": 34
          },
          "errorCode": {
            "deviceCode": "30",
            "device": "Ice/Water/Accesory Faults",
            "locationCode": "9",
            "location": "Dispenser",
            "failureCode": "80",
            "failure": "No Communication",
            "priority": ""
          },
          "active": true
        }
      ],
      "accessPoints": [
        {
          "ssid": "aj_nurun",
          "encryption": 3,
          "rssi": 70,
          "type": null
        },
        {
          "ssid": "LionGuest",
          "encryption": 0,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "LionDEV",
          "encryption": 8,
          "rssi": 48,
          "type": null
        },
        {
          "ssid": "Cima-Prive",
          "encryption": 8,
          "rssi": 47,
          "type": null
        },
        {
          "ssid": "NurunQA",
          "encryption": 3,
          "rssi": 44,
          "type": "selected"
        },
        {
          "ssid": "Corporatestays803",
          "encryption": 3,
          "rssi": 4,
          "type": null
        }
      ]
    }
  ],
  "userNotificationSettings": [
    {
      "psid": "123456",
      "friendly_name": "iPhone 9",
      "notification_settings": {
        "door open alarm": true,
        "temperature alarm": true
      }
    },
    {
      "psid": "123457",
      "friendly_name": "iPhone 10",
      "notification_settings": {
        "door open alarm": true,
        "temperature alarm": false
      }
    },
    {
      "psid": "123458",
      "friendly_name": "iPhone 11",
      "notification_settings": {
        "door open alarm": false,
        "temperature alarm": true
      }
    },
    {
      "psid": "123459",
      "friendly_name": "iPhone 12",
      "notification_settings": {
        "door open alarm": false,
        "temperature alarm": false
      }
    }
  ],
}