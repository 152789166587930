/* global console setTimeout Promise history*/
import ReactClass from "create-react-class";
import * as _ from "lodash";
import React from "react";
import {Form} from "../../components/widgets";
import Config from "../../config";
import ApplianceService from "../appliance/Service";
import {AuthService} from "../auth";
import Service from "./Service";
import SortableTable from "./sortable-table/sortable-table";
import AI from "../../appInsights";
import AnswerAdviser from "../../modules/answer-adviser";
import Util from "../../components/util"
import util from "../../components/util";
import DashboardService from "../dashboard/Service";

const imgBasePath = `${Config.baseDir}modules/cat-projects/images`;

const Badge = ReactClass({
  displayName: "Badge",
  render() {
    const className = `badge ${this.props.className}`;
    return (
      <div>
        <span className={className}>&ensp;</span>
        <span className="label"> {this.props.label}</span>
      </div>
    );
  }
});
const Legend = ReactClass({
  displayName: "Legend",
  render() {
    return (
      <div className="section-control">
        <Badge className="badge-offline" label="Offline" />
        <Badge className="badge-low-filter" label="Low Filter" />
        <Badge className="badge-error" label="Error" />
      </div>
    );
  }
});

const SearchBox = ReactClass({
  displayName: "SearchBox",
  getInitialState: function () {
    return {
      exportUrl: ""
    };
  },
  onSearchData(form) {
    const {fields} = form,
        newSearchData = Object.keys(fields).reduce((acc, fname) => {
          const f = fields[fname];
          acc[fname] = f.value;
          return acc;
        }, {});
    this.props.onSearch(newSearchData);
  },
  componentWillMount() {
    const promiseToken = AuthService.getAPIToken(),
        {apiServerUrl, apiUrl} = Config;
    promiseToken.then(authRes => {
      const exportUrl = `${apiServerUrl}${apiUrl}/projects/${this.props.projectName}/devicesexport?token=Bearer ${authRes.accessToken}`;
      this.setState({exportUrl});
    });
  },
  notifyExport() {
    setTimeout(() => this.props.onExport(), 500);
  },
  render() {
    const {exportUrl} = this.state,
        {filterData, totalElements, data} = this.props,
        deviceId = data && data.filter(ele => {
          return ele.selected;
        }).map(ele => ele.deviceId).join(",");
    return (
      <div className="row search-box">
        <Form onChange={this.onSearchData} >
          <input name="serialNumber"
            type="text"
            label="Serial Number"
          />
          <input name="modelNumber"
            label="Model"
            type="text"
          />
        </Form>
        <div className="result">Showing <span className="result-txt">{filterData} &frasl; {totalElements}</span> appliance{totalElements == 1 ? "" : "s"}</div>
        <div className="pad-15 cat-export">
          <form method="post" enctype="multipart/form-data" action={exportUrl}
            target="_blank" className="export-form">
          <input  className="button inline primary export-btn" type="submit" value="Export" 
              id="catproject-export"/>
          <input name="deviceIds" type="text" value={deviceId}  type="hidden"/>
          </form>
        </div>
      </div>
    );
  }
});

const CatProject = ReactClass({
  displayName: "CatProject",
  getInitialState: function () {
    return {
      sortCol: "Severity",
      sortOrder: true,
      prevSortCol: null,
      data: this.props.data,
      busy: false,
      filterOn: null,
      selected: false,
      selectedItems: [],
      lastSelectedItem: null
    };
  },
  getTableHeaders() {
    const self = this,
        {headers, application, isLeftNavExpanded,route: {params: {projectName}}} = self.props,
        errorStatusArr = ["No Errors", "Unknown"];
    let tableData = [];
    if (!headers || (headers && !Array.isArray(headers)))
      return tableData;

    headers.forEach(function (item) {
      let tableHeadeObj = {
        header: item.label,
        key: item.label,
        sortCol: item.sortField || item.label
      };
      if(item.label == "Error Status") {
        tableHeadeObj.render = data => {
          const {"Error Status": errorStatus} = data;
          if (_.includes(errorStatusArr, errorStatus)) {
            return errorStatus;
          }
          return <span className="error-code-link">{errorStatus}</span>;
        };
        tableHeadeObj.onColumnClick = data => {
          if(_.includes(errorStatusArr, data["Error Status"])) {
            return false;
          }
          if(isLeftNavExpanded) {
            application.leftNavtoggle && application.leftNavtoggle();
          }
          self.fetchAppliances().then(() => {
            const {route: {params: {projectName}}} = self.props;
            ApplianceService.setScrollTopOfApplianceList(0);
            application.route(`/appliance/${data.deviceId}/connected/faults/${projectName}`);
          }).catch(err => {});
        };
      }
      tableData.push(tableHeadeObj);
    });
    let checkBoxClm = {
      header: "",
      key: "",
      sortCol: "",
      render: data => {
        const {selected} = data,
            imgSrc = selected ? `${imgBasePath}/chkbx_slcted.svg` : `${imgBasePath}/chkbx_unslct.svg`;
        return <img src={imgSrc} className="row-check" id="img" />;
      },
      onColumnClick: (data, e) => {
        const value = data;
            self.getNextValue(value, e.shiftKey);
      }
    }
    let clickClm = {
      header: "",
      key: "",
      sortCol: "",
      render: data => {
        return <img src={`${imgBasePath}/list_view.svg`} className="list-view" id="list-view"/>;
      },
      onColumnClick: data => {
        if (isLeftNavExpanded) {
          application.leftNavtoggle && application.leftNavtoggle();
        }
        self.fetchAppliances().then(() => {
          const userSessoinId = Util.getUserSessionId(),//sessionStorage.getItem("userSessoinId"),
              applianceSessionID = Util.uniqueSessionId();
          let param = AnswerAdviser.getCRMTicket();
          const {CRM_TICKET_ID} = param;
        sessionStorage.setItem("applianceSessionID", applianceSessionID);
        const applianceSessionEventData =  {model: data.Model,
          serial: data.Serial, 
          pumaVersion: data.Version,
          userSessoinId, applianceSessionID,
          CRM_TICKET_ID: CRM_TICKET_ID ? `${CRM_TICKET_ID}` : ""};
          if(!data.pumaVersion){
            DashboardService.fetchGroupDetails(data).then((groupDetails)=>{
              this.sendApplianceSessionEvent({
                ...applianceSessionEventData,
                firmwareVersion: groupDetails.FirmwareVersion
              });
            })
          }
          else{
            this.sendApplianceSessionEvent(applianceSessionEventData);
          }
          ApplianceService.setScrollTopOfApplianceList(0);
          application.route("/project/"+projectName+"/appliance/" + data.deviceId + "/dashboard/");
        })
        .catch(err => {console.log("errrr", err);});
      }
    }
    
    tableData.unshift(checkBoxClm);
    tableData.push(clickClm);
   
    return tableData;
  },

  sendApplianceSessionEvent(data){
    AI.trackUserLoginEvent("APPLIANCE_SESSION", data);
  },

  getNextValue(value, shiftKey) {
    const { selectedItems } = this.state,
        newSelectedItems = this.getNewSelectedItems(value, shiftKey);
  },
  getNewSelectedItems(value, shiftKey) {
    const { lastSelectedItem, data } = this.state,
        currentSelectedIndex = _.findIndex(data, value),
        lastSelectedIndex = lastSelectedItem && _.findIndex(data, item => {
          return item.deviceId === lastSelectedItem.deviceId;
        }) || 0;
    
    const maxIndex = Math.max(lastSelectedIndex === -1 ? 0 : lastSelectedIndex, currentSelectedIndex),
        minIndex = Math.min(lastSelectedIndex === -1 ? 0 : lastSelectedIndex, currentSelectedIndex);
    if(shiftKey && lastSelectedItem) {
      const isSelected = !value.selected,
          updatedData = data.map((item, idx) => {
            if( idx >= minIndex && idx <= maxIndex) {
              item.selected = isSelected;
            } 
          return item
      })
    this.setState({
      data: updatedData,
      lastSelectedItem: value
    })
  } else {
    data[currentSelectedIndex] = {...value, selected: !value.selected}
    this.setState({
      data: data,
      lastSelectedItem: value
    })
  }
  },
  filterData(serial, model) {
    return item => {
      let actualSerial = item.Serial && item.Serial.toString().toLowerCase(),
          actualModel = item.Model && item.Model.toString().toLowerCase(),
          expectedSerial = serial && serial.toString().toLowerCase(),
          expectedModel = model && model.toString().toLowerCase();

      if(expectedSerial && expectedModel && actualModel && actualSerial) {
        return actualSerial.indexOf(expectedSerial) !== -1 && actualModel.indexOf(expectedModel) !== -1;
      }
      if(expectedSerial && actualSerial) {
        return actualSerial.indexOf(expectedSerial) !== -1;
      }
      if(expectedModel && actualModel) {
        return actualModel.indexOf(expectedModel) !== -1;
      }
      if((expectedModel && !actualModel) || (expectedSerial && !actualSerial)) {
        return false;
      }
      return true;
    };
  },
  onDataFilter(filterOn) {
    const {serialNumber, modelNumber} = filterOn,
        dataList = this.props.data,
        filteredData = dataList.filter(this.filterData(serialNumber, modelNumber));
    this.setState({data: filteredData, filterOn});
  },
  onSortData(currentCol) {
    const {sortCol, sortOrder, data} = this.state,
        sortedData = Service.sortData({sortCol,
          sortOrder,
          currentCol,
          data: data
        });
    this.setState(sortedData);
  },
  notifyExport() {
    const {application} = this.props;
    application.notifications.success("Export started. Check your system downloads folder.");
  },
  fetchAppliances() {
    const {route: {params: {projectName}}, data: responseData, application} = this.props,
        {busy} = this.state,
        deviceIds = responseData.map(device => device.deviceId),
        createErrMsg = (arr, type) => {
          return arr.length === 1
          ? `${type} : ${arr.join()}
              is not compatible with SubZero Connected Portal`
          : `${type}s : ${arr.join(", ")}
              are not compatible with SubZero Connected Portal`;
        };
    let warnMessage = "";

    ApplianceService.clearApplianceInfos();
    if(!busy) {
      this.setState({
        busy: true
      });
      application.showLoading(true);
      return new Promise((resolve, reject) => {
        this.applianceInfoPromise = ApplianceService.getApplianceInfo({"projectName": projectName}, true);
        this.applianceInfoPromise.then(ApplianceService.modifyAppliances)
        .then(res => {
          this.setState({
            busy: false
          });
          application.showLoading(false);
          const unavailable = _.filter(deviceIds, devId => {
            return !_.find(res, appliance => devId === appliance.deviceId);
          });
          if(unavailable.length > 0) {
            warnMessage = createErrMsg(unavailable, "Device Id");
            application.notifications.warn(warnMessage, Config.notificationTimeOut);
          }
          if(res.length > 0) {
            ApplianceService.setCurrentApplianceInfos(res, projectName);
            resolve(res);
          }else {
            application.notifications.error("Error fetching appliance");
            reject("Error");
          }
        }).catch(errRes => {
          this.setState({
            busy: false
          });
          application.showLoading(false);
          if(errRes.name !== "AbortError") {
            application.notifications.error("Error fetching appliance");
          }
          reject(errRes);
        });
      });
    }
  },
  abortReq() {
    if(this.applianceInfoPromise && this.applianceInfoPromise._abort) {
      this.applianceInfoPromise._abort();
    }
  },
  componentWillUnmount() {
    this.abortReq();
  },
  render() {
    const {route: {params: {projectName}}, data: responseData,
            headers, application, isLeftNavExpanded} = this.props,
        {data, sortCol, sortOrder, filterOn} = this.state,
        columns = (headers) ? this.getTableHeaders() : [],
        rows = {
          rowClassSelector: ["Severity", "selected"],
          rowClassMap: {
            "default": "",
            "1": "bg-alert-1",
            "2": "bg-alert-2",
            "3": "bg-alert-3",
            "4": "bg-alert-4",
            "true": "selected"
          },
          onClick: (data,e) => {
            this.getNextValue(data, e.shiftKey);
          }
        };
    return (
      <div className="section">
        <h2 className="section-title">
          {projectName} Project &emsp;
              <Legend />
        </h2>
        {(responseData && responseData.length > 0) ?
          <div>
            <div>
              <SearchBox data={this.state.data} totalElements={this.props.data && this.props.data.length || 0} filterData={data && data.length || 0} onSearch={this.onDataFilter} onExport={this.notifyExport} projectName={projectName} />
            </div>

            {(data && data.length > 0) ? <div className="appliances-table">
              <SortableTable className="records-list"
                data={data || []}
                onHeaderClick={this.onSortData}
                columns={columns}
                sortOrder={sortOrder}
                sortCol={sortCol}
                row={rows}
              />
            </div> : (filterOn && (filterOn.serialNumber || filterOn.modelNumber)) ? <p className="error-text pad-15">No appliances found</p> : ""
            }
          </div>
          : <p className="error-text pad-15">{(responseData) ? "No appliances found" : "An error occurred while fetching appliances."}</p>
        }
      </div>
    );
  }
});

export default ReactClass({
  displayName: "CatProjectView",
  getInitialState: function () {
    return {
      loading: false,
      responseData: null
    };
  },
  componentWillMount() {
    const {route: {params: {projectName, isHistory}}} = this.props;
    this.setState({
      loading: true
    });
    if(projectName){
      Service.setCatProjectName(projectName)
    }
    Service.fetchApplianceList(projectName, isHistory)
    .then(res => {
      this.setState({
        loading: false
      });
      const sortRes = {
            data: Service.sortData({
              sortCol: "",
              sortOrder: true,
              currentCol: "Severity",
              data: res.data
            }).data || []
          };
          sortRes.data.forEach((ele)=>{
            ele.selected = false;
          });
          const responseData = Object.assign({}, res, sortRes);
      this.setState({
        responseData
      });
    },
    errRes => {
      this.setState({
        loading: false
      });
      this.setState({
        responseData: null
      });
    });
  },
  render() {
    const {isLeftNavExpanded} = this.props,
        {loading} = this.state,
        contentClass = `main-content ${isLeftNavExpanded ? " left-nav-expand" : ""}`,
        loadingClass = `view cat-project-view layout ${loading ? "cat-project-loading" : ""}`;
    return (
      <div className={loadingClass}>
        <div className={contentClass}>
          {loading ?
            <div className="loading">
              <div className="_text-center anim">
                <i className="icon icon-loader spin"></i>
              </div>
              <p className="_text-center">Loading appliances...</p>
            </div>
            : <CatProject {...this.props} {...this.state.responseData} />
          }
        </div>
      </div>
    );
  }
});