"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* global console */
/**
 * K2 Inc. Confidential
 *
 * Copyright © 2015 K2 Inc. All rights reserved.
 *
 * Notice: All information contained herein is, and remains the property of K2 Inc. and its
 * suppliers, if any. The intellectual and technical concepts contained herein are proprietary to K2
 * Inc. and/or its suppliers, and may be covered by U.S. and foreign patents or patents in process,
 * and are protected by trade secret or copyright law. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from K2 Inc.
 */

// import ReactDOM from "react-dom";


exports.default = AsSubscribedComponent;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _createReactClass = require("create-react-class");

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ObjectToString = Object.prototype.toString;
function isArray(that) {
  return ObjectToString.call(that) === "[object Array]";
}

function getDisplayName(Component) {
  return Component.displayName || Component.name || "Widget";
}

function AsSubscribedComponent(WrappedComponent) {
  var Component = (0, _createReactClass2.default)({
    propTypes: {
      DataLayer: _propTypes2.default.object,
      channel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.array])
    },

    getInitialState: function getInitialState() {
      return {
        subscriptions: {},
        // data reeived from channels
        data: [],
        channel: null
      };
    },

    componentDidMount: function componentDidMount() {
      var _this = this;

      var channelName = this.props.channel;

      if (!channelName) {
        console.log("Channel not specifed, will not subscribe to data layer");
        return;
      }
      if (isArray(channelName)) {
        channelName.forEach(function (cname) {
          _this.subscribeTo(cname);
        });
      } else {
        this.subscribeTo(channelName);
      }
    },

    componentWillUnmount: function componentWillUnmount() {
      this.unsubscribe();
    },

    render: function render() {
      return _react2.default.createElement(WrappedComponent, _extends({}, this.props, { ref: "component" }));
    },

    subscribeTo: function subscribeTo(channelName) {
      var _this2 = this;

      var DataLayer = this.props.DataLayer,
          subscriptions = this.state.subscriptions;


      if (!DataLayer) {
        console.log("No datalayer instance passed. Won't subscribe");
        return;
      }
      var subscription = DataLayer.subscribe(channelName, function (data) {
        return _this2.onChannelData(data, channelName);
      }, function (err) {
        return _this2.onChannelError(err, channelName);
      }, function (data) {
        return _this2.onChannelDone(data, channelName);
      });

      subscriptions[channelName] = subscription;
      this.setState({
        subscriptions: subscriptions
      });
    },

    unsubscribe: function unsubscribe() {
      var _this3 = this;

      Object.keys(this.state.subscriptions).forEach(function (cName) {
        return _this3.unsubscribeFrom(cName);
      });
    },

    unsubscribeFrom: function unsubscribeFrom(channelName) {
      var subscriptions = this.state.subscriptions,
          subs = subscriptions[channelName];


      if (subs) {
        subs.unsubscribe();
        delete subscriptions[channelName];
        this.setState({
          subscriptions: subscriptions
        });
      }
    },

    onChannelData: function onChannelData() {
      var component = this.refs.component;

      if (!component.onData) {
        console.log("Warning! Component using subscriber mixin MUST have onData(data, <channel>) " + "function to receive channel data");
        return;
      }
      component.onData.apply(component, arguments);
    },

    onChannelError: function onChannelError() {
      var component = this.refs.component;

      component.onDataError && component.onDataError.apply(component, arguments);
    },

    onChannelDone: function onChannelDone() {
      var component = this.refs.component;

      this.unsubscribe();
      component.onDataComplete && component.onDataComplete.apply(component, arguments);
    }
  });

  Component.displayName = "SubscribedComponent(" + getDisplayName(WrappedComponent) + ")";
  return Component;
}