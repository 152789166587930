/* global Promise console setTimeout */
import Config from "../../config";
import ApiClient from "../../components/api-client";

const APPLIANCE_ENDPOINT = "/enduser-app/appliance-data",
    CONFIG_ENDPOINT = "/config/EndUserAppConfig?name=Widgets",
    {instance: Api, asJson: resAsJson, asQueryParameters} = ApiClient,
    normalizeConfigData = configData => {
      return Object.keys(configData).reduce((acc, key) => {
        const multiKeys = key.split(","), config = configData[key];
        multiKeys.forEach(mk => {
          acc[mk.trim()] = config;
        });
        return acc;
      }, {});
    },
    Service = {
      fetchEndUserApplianceData(appliance) {
        const {deviceId} = appliance,
            query = asQueryParameters({
              deviceId
            });
        return Api.get(`${APPLIANCE_ENDPOINT}?${query}`)
            .then(resAsJson).
            then(data => {
              //console.log(data);
              return data;
            });
      },
      fetchWidgetConfigs() {
        return Api.get(`${CONFIG_ENDPOINT}`)
            .then(resAsJson)
            .then(data => {
              // console.log(data);
              return data;
            });
      },
      getWidgetConfig(applianceType) {
        if(!this.widgetConfigs) {
          return this.fetchWidgetConfigs()
            .then(configs => {
              this.widgetConfigs = configs;
              const normalizedConfigData = normalizeConfigData(this.widgetConfigs.ConfigData);
              // console.log(normalizedConfigData);
              this.widgetConfigs.ConfigData = normalizedConfigData;
            })
            .then(_ => {
              return this.widgetConfigs.ConfigData[applianceType];
            });
        }
        return Promise.resolve(this.widgetConfigs.ConfigData[applianceType]);
      }
    };

export default Service;
