/* global */
import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import Config from "../../../../../config";

const isArray = that => Object.prototype.toString.call(that) === "[object Array]",
    defaultTransform = data => data,
    Widget = AsSubscribedComponent(ReactClass({
      displayName: "StatusWidget",
      propTypes: {
        title: PropTypes.string,
        icon: PropTypes.string,
        iconDisabled: PropTypes.string
      },
      getInitialState() {
        return {};
      },
      componentDidUpdate() {
        this.props.layout.layout();
      },
      render() {
        const {Header, Content} = WidgetPanel,
            {title, icon, iconDisabled = icon, panelClass = ""} = this.props,
            {disabled} = this.state,
            renderedIcon = disabled ? iconDisabled : icon,
            panelContent = this.renderContent();
        return (
          <WidgetPanel {...this.props} panelClass={`status ${panelClass}`}>
            <Header>
              <img className="icon" src={renderedIcon} alt="Icon" />
              <span className="title">{title}</span>
            </Header>
            <Content>
              {panelContent}
            </Content>
          </WidgetPanel>
        );
      },
      renderContent() {
        const {data} = this.state,
            {content} = this.props,
            value = typeof data !== "undefined" ? data : content || "...";

        let renderedValue = value;
        if(isArray(value)) {
          const items = value.map(v => {
            return (
              <li key={v.name}>
                <span className="name">{v.name}</span>
                <span className="value">{v.value}</span>
              </li>
            );
          });
          renderedValue = (
            <ul className="list">
              {items}
            </ul>
          );
        }
        return (
          <div className="value">
            {renderedValue}
          </div>
        );
      },
      onData(data, channel) {
        const {transform = defaultTransform} = this.props;
        if(!data) {
          return;
        }

        this.setState({
          channel: channel,
          data: transform(data)
        });
      }
    })),

    Info = {
      id: "eua-dashboard:status",
      name: "Status Widget",
      description: "Enduser app dashboard status widget",
      type: Widget
    };

export default {
  Info,
  Widget
};
