/* global console */
import {Filters, Transformers, ChannelFactories} from "../common";
import {AuthService} from "../../../auth";
import {getWidgets} from "../widgets";

const TEST_ERROR_CODES = [
  {
    "epoch": 379011677,
    "errorCode": {
      "deviceCode": "1",
      "device": null,
      "locationCode": "A",
      "location": "Air Filter",
      "failureCode": "1",
      "failure": "Short/Closed",
      "priority": 1
    },
    "active": null,
    "time": null
  },
  {
    "epoch": 379029982,
    "errorCode": {
      "deviceCode": "6",
      "device": null,
      "locationCode": "G",
      "location": null,
      "failureCode": "6",
      "failure": "Miswire overheat",
      "priority": 2
    },
    "active": true,
    "time": {"days": 49153, "hours": 3, "minutes": 3, "seconds": 18}
  },
  {
    "epoch": 379033643,
    "errorCode": {
      "deviceCode": "7",
      "device": null,
      "locationCode": "H",
      "location": "High Voltage Sys",
      "failureCode": "7",
      "failure": "Bad Ohms",
      "priority": 3
    },
    "active": true,
    "time": null
  },
  {
    "epoch": 379011677,
    "errorCode": {
      "deviceCode": "1",
      "device": null,
      "locationCode": "A",
      "location": "Air Filter",
      "failureCode": "1",
      "failure": "Short/Closed",
      "priority": 1
    },
    "active": false,
    "time": null
  },
  {
    "epoch": 379029982,
    "errorCode": {
      "deviceCode": "6",
      "device": null,
      "locationCode": "G",
      "location": null,
      "failureCode": "6",
      "failure": "Miswire overheat",
      "priority": 2
    },
    "active": false,
    "time": {"days": 49153, "hours": 3, "minutes": 3, "seconds": 18}
  }],
    createChannelConfig = (deviceInfo, configWidgets, perApplianceType, token, networkConnInfo) => {
      return {
        "name": `${deviceInfo.name} ${deviceInfo.model}`,

        "style": {
          "cssClass": "dishwasher-dashboard"
        },

        "datalayer": {
          "name": "dishwasher Data",
          "channels": [
            // Diagnostic channel common to all dashboards
            ChannelFactories.userInfo(deviceInfo, token),
            ChannelFactories.diagnostics(deviceInfo, token),
            ChannelFactories.parameters(deviceInfo, token),
            ChannelFactories.applianceInfo(deviceInfo, token),
            ChannelFactories.timeZone(deviceInfo, token),
            ChannelFactories.currentState(),
            ChannelFactories.networkConnInfo(deviceInfo, networkConnInfo),
            ChannelFactories.mobNotificationInfo(deviceInfo, token),
            ChannelFactories.provisioningReport(deviceInfo,token),
            {
              "name": "/appliance/pollingFrequency",
              "type": "extended",
              "config": {
                "from": "/appliance/properties",
                "transform": data => {
                  return {frequency: data.active_polling_frequency};
                }
              }
            },
            ChannelFactories.errorCodes(deviceInfo, token)
          ]
        },
        "widgets": getWidgets(deviceInfo, configWidgets, perApplianceType)
      };
    };

export default (deviceInfo, configWidgets, perApplianceType, networkConnInfo) => {
  const promiseToken = AuthService.getAPIToken();
  return promiseToken.then(authRes => {
    return createChannelConfig(deviceInfo, configWidgets, perApplianceType, authRes.accessToken, networkConnInfo);
  });
};
