import React from "react";
import ReactClass from "create-react-class";
import Config from "../../config";
import AnswerAdviser from "../../modules/answer-adviser";
import DashboardService from "../../modules/dashboard/Service";
import { AuthService } from "../auth";
import Service from "../enduser-app/Service";
import * as _ from "lodash";
import Util from "../../components/util";
import AI from "../../appInsights";

export default ReactClass({
  displayName: "ResourcesComponent",
  getInitialState: function () {
    return {
      aaContext: { additional: {} },
      sendContextHandle: null,
    };
  },

  getFlattenObject(item) {
    let flattenObject = {};
    try {
      if (Array.isArray(item)) {
        const flatArray = _.flattenDeep(item);
        if (typeof flatArray[0] !== "object") {
          return flatArray;
        } else {
          flatArray.forEach((item) =>
            Object.assign(flattenObject, this.getFlattenObject(item))
          );
        }
      } else if (typeof item === "object") {
        item &&
          Object.keys(item).forEach((k) => {
            const value = item[k];
            if (typeof value === "object") {
              flattenObject[k] = this.getFlattenObject(value);
            } else {
              flattenObject[k] = value;
            }
          });
      } else {
        Object.assign(flattenObject, item);
      }
    } catch (error) {
      console.log({ error });
    }

    return flattenObject;
  },

  componentWillMount() {
    const { currentAppliance, errorCodeData } = this.props;
    const { deviceId, serial, type, model } = currentAppliance,
      promiseToken = AuthService.getAPIToken(),
      applianceType = "Refrigerator";

    promiseToken.then((authRes) => {
      const propsContext = {
        jwtToken: authRes.accessToken,
      };
      if (errorCodeData && errorCodeData.length > 0) {
        propsContext.faultCode = errorCodeData[0]["errorCodeString"];
        propsContext.problemDescription = errorCodeData[0]["desc"];
      }

      const additionalData = this.state.aaContext?.additional || {};

      this.setState({
        aaContext: {
          ...this.state.aaContext,
          skipLaunch: true,
          applianceType,
          modelNumber: model,
          serialNumber: serial,
          applianceImage: DashboardService.getImageUrlData(),
          subzeroApplianceType: type,
          ...propsContext,
          additional: { ...additionalData },
        },
      });
      if (!this.state.aaContext?.additional["Consumer Mobile App"] && (currentAppliance.connectedStatus === "ONLINE")) {
        deviceId &&
          Service.fetchEndUserApplianceData(currentAppliance)
            .then((mobileData) => {
              const flatMobileData = this.getFlattenObject(mobileData);
              console.log({ flatMobileData });
              const aaCon = this.state.aaContext
                ? { ...this.state.aaContext }
                : {};
              const additionalObj = this.state.aaContext.additional
                ? { ...this.state.aaContext.additional }
                : {};
              this.setState({
                aaContext: {
                  ...aaCon,
                  additional: {
                    ...additionalObj,
                    "Consumer Mobile App": flatMobileData,
                  },
                },
              });
            })
            .catch((error) => {
              console.log({ error });
            });
      }

      if (!this.state.aaContext?.additional["Current State"]) {
        DashboardService.fetchApplianceDataPoints(deviceId)
          .then((additionalAAContext) => {
            if(Object.keys(additionalAAContext).length > 0) {
              const userSessoinId =  Util.getUserSessionId(),//sessionStorage.getItem("userSessoinId"),
                  applianceSessionID = sessionStorage.getItem("applianceSessionID");
              AI.trackUserLoginEvent("CONNECTED_DATA",{
                userSessoinId, 
                applianceSessionID: applianceSessionID ? applianceSessionID : "", 
                model,
                serial,
                dataPoints: JSON.stringify(additionalAAContext)
              });
            }
            const flatContext = this.getFlattenObject(additionalAAContext);
            console.log({ flatContext });
            const aaCon = this.state.aaContext
              ? { ...this.state.aaContext }
              : {};
            const additionalObj = this.state.aaContext.additional
              ? { ...this.state.aaContext.additional }
              : {};
            this.setState({
              aaContext: {
                ...aaCon,
                additional: {
                  ...additionalObj,
                  "Current State": flatContext,
                },
              },
            });
          })
          .catch((error) => {
            console.log({ error });
          });
      }
    });
  },
  componentWillUnmount() {
    window.clearInterval(this.state.sendContextHandle);
  },
  openAgentAssist() {
    function sendMessage(win, body, retryCount = 30, pause = 2000) {
      window.addEventListener("message", function (event) {
        if (
          event &&
          event.data &&
          event.data.action === "bru:context.receive"
        ) {
          console.log("receipt acknowledge .. Stopped sending");
          window.clearInterval(interval);
        }
      });

      const interval = window.setInterval(() => {
        if (--retryCount === 0) {
          console.log("Stopped sending");
          window.clearInterval(interval);
        }
        console.log("sending #" + (10 - retryCount));
        win.postMessage(body, Config.agentAssistUrl);
      }, pause);

      this.setState({
        sendContextHandle: interval,
      });

      win.addEventListener("unload", function () {
        window.clearInterval(interval);
      });
    }

    console.log("started sending context");

    const handle = window.open(Config.agentAssistUrl, "_blank");
    if (handle) {
      console.log("Sending context to AA UI ->", this.state.aaContext);
      sendMessage(handle, {
        action: "bru:context.apply",
        origin: window.location.origin,
        body: this.state.aaContext,
      });
    }
  },
  render() {
    const refTypeRegx = /^1\.(1|2|5|7|11|12|13|14|17|18)\..*$/,
      { aaContext } = this.state,
      {
        location: { origin },
      } = window;
    let param = AnswerAdviser.getCRMTicket();
    const { CRM_TICKET_ID } = param,
      crmTicketId = CRM_TICKET_ID ? `&CRM_TICKET_ID=${CRM_TICKET_ID}` : "";

    const {
        currentAppliance: { serial, type },
      } = this.props,
      scLink = `${Config.resourcesLink.scLink}`,
      aaLink = `${Config.resourcesLink.aaLink}${crmTicketId}`,
      ckLink = `${Config.resourcesLink.ckLink}${serial}`,
      helpLink = Config.resourcesLink.helpLink;
    const classs = Object.keys(aaContext).length > 0 ? "" : "disabled";
    return (
      <div className="resources-section">
        <span>Resources</span>
        <div onClick={() => Util.trackAAEvent(aaLink)}>
          <a href={aaLink} target="_blank" id="answer-advisor">
            Answer Advisor
          </a>
        </div>
        <a href={ckLink} target="_blank" id="connectivity-kit">
          Connectivity Kit
        </a>
        <a href={scLink+`/KnowledgeBase/Serial?SerialNumber=${serial}`} target="_blank" id="service-central">
          Service Central
        </a>
        <a href={helpLink} target="_blank" id="help">
          CAP Help
        </a>
        {(Config.enableAgentAssist === true ||
          Config.enableAgentAssist === "true") &&
        refTypeRegx.test(type) ? (
          <a
            href="javascript:void(0)"
            onClick={this.openAgentAssist}
            id="virtual-assisstant"
            className={classs}
          >
            Agent Assist
          </a>
        ) : null}
      </div>
    );
  },
});
