import {WidgetRegistry} from "ui-dashboard";
import {Gauge} from "dashboard-widgets";
import Status from "./status/Widget";

import ApplianceInfo from "./appliance-info/Widget";
import Connectivity from "./connectivity/Widget";
import ErrorCodes from "./error-codes/Widget";
import TimeZone from "./time-zone/Widget";
import WaterFilter from "./water-filter/Widget";
import AppliancePartStatusWidget from "./appliance-part-status/Widget";
import ExternalDispensor from "./external-dispenser/Widget";
import PollingFrequency from "./polling-frequency/Widget";
import ConsumerAppInfo from "./consumer-app-info/Widget";
import ConsumerWifiScanList from "./consumer-wifi-scan-list/Widget";
import ConsumerAppConnectivity from "./consumer-app-connectivity/Widget";
import AccountInfo from "./account-info/Widget";
import NetworkConnInfo from "./network-conn-info/Widget";
import MobileNotification from "./mobile-notification/Widget";
import ProvisioningReport from './provisioning-report/Widget';


WidgetRegistry.add(Gauge.Info.id, Gauge.Info);
WidgetRegistry.add(Status.Info.id, Status.Info);

WidgetRegistry.add(ApplianceInfo.Info.id, ApplianceInfo.Info);
WidgetRegistry.add(Connectivity.Info.id, Connectivity.Info);
WidgetRegistry.add(ErrorCodes.Info.id, ErrorCodes.Info);
WidgetRegistry.add(TimeZone.Info.id, TimeZone.Info);
WidgetRegistry.add(WaterFilter.Info.id, WaterFilter.Info);
WidgetRegistry.add(AppliancePartStatusWidget.Info.id, AppliancePartStatusWidget.Info);
WidgetRegistry.add(ExternalDispensor.Info.id, ExternalDispensor.Info);
WidgetRegistry.add(PollingFrequency.Info.id, PollingFrequency.Info);
WidgetRegistry.add(ConsumerAppInfo.Info.id, ConsumerAppInfo.Info);
WidgetRegistry.add(ConsumerWifiScanList.Info.id, ConsumerWifiScanList.Info);
WidgetRegistry.add(ConsumerAppConnectivity.Info.id, ConsumerAppConnectivity.Info);
WidgetRegistry.add(AccountInfo.Info.id, AccountInfo.Info);
WidgetRegistry.add(NetworkConnInfo.Info.id, NetworkConnInfo.Info);
WidgetRegistry.add(MobileNotification.Info.id, MobileNotification.Info);
WidgetRegistry.add(ProvisioningReport.Info.id,ProvisioningReport.Info)
