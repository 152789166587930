/* global setTimeout clearTimeout console */
import React from "react";
import ReactClass from "create-react-class";

export default ReactClass({
  displayName: "Pagination",
  getInitialState() {
    return {
      selectedIndex: 0
    };
  },
  onChange() {
    this.props.onChangePage(this.state.selectedIndex);
  },
  render() {
    const {limit} = this.props;
    const {selectedIndex} = this.state;
    return (
      <ul className="pagination">
        <li className={selectedIndex === 0 ? 'disabled' : ''}>
          <a onClick={() => {
              if(selectedIndex > 0)
              this.setState({selectedIndex: selectedIndex - 1},() => {this.onChange()})}
            }>
            <i className="icon-chevron-left"></i>
          </a>
        </li>
        {[...Array(limit).keys()].map((no) =>
            <li key={`pagination_${no}`} className={selectedIndex === no ? 'active' : ''}>
              <a onClick={() => {
                this.setState({selectedIndex: no}, () => {this.onChange()})
              }}>{no + 1}</a>
            </li>
        )}
        <li className={selectedIndex === limit - 1 ? 'disabled' : ''}>
          <a onClick={() => {
            if(selectedIndex < limit - 1)
            this.setState({selectedIndex: selectedIndex + 1}, () => {this.onChange()})
          }}>
            <i className="icon-chevron-right"></i>
          </a>
        </li>
      </ul>
    );
  }
}); 